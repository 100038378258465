import { Box } from "@bosch/react-frok";
import { FC, useState } from "react";
import Styles from "./LiveChatBox.module.scss";
import SideNavigationLiveChat from "../../../layout/sideNavigationLiveChat/SideNavigationLiveChat";
import MainContent from "./MainContent";

export interface IChatProps {}

const LiveChatBox: FC<IChatProps> = (props) => {
    const [onIsOpenSideNav, setIsOpenSideNav] = useState<boolean>(false);
    return (
        <Box className={Styles["card-background"]}>
            <div className={Styles["main-box"]}>
                <div className={`${Styles["menu-content"]} ${!!onIsOpenSideNav ? Styles.open : ""}`}>
                    <SideNavigationLiveChat onOpenMenu={setIsOpenSideNav} />
                </div>
                <div className={Styles["main-content"]}>
                    <MainContent />
                </div>
            </div>
        </Box>
    );
};



export default LiveChatBox;
