import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsSignIn } from "../../redux/auth";
import { useAppDispatch } from "../../redux/hooks";
import { Constants } from "../../utils/helpers/Constants";
import { getAssignedApplicationsAsync } from "../../redux/account";

export const useGetAssignedApplication = () => {
  const dispatchReduxToolkit = useAppDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const isLoggedIn = useSelector(selectIsSignIn);

  useEffect(() => {
    (async () => {
      if (
        isLoggedIn &&
        !window.location.pathname.startsWith("/signoutcallback")
      ) {
        setShowLoader(true);
        await dispatchReduxToolkit(
          getAssignedApplicationsAsync({
            selectedApplicationId: Constants.REMOTE_DIAGNOSTICS_APPLICATION_ID,
          })
        );
        setShowLoader(false);
      }
    })();
  }, [isLoggedIn]);

  return [showLoader];
};
