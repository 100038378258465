import { ADS_APP_PREFIX } from "../constants/app.constants";
import { HOME_CDM_3_ROUTE } from "../constants/routes/cdm3v0Routes.constants";
import { RouteConfigure } from "../models/Route";
import { MinimalSideNavItem } from "../types/sideNavigation.type";

export const isAdsApp = () => {
  const pathNameSplit: string[] = window.location.pathname.split("/");

  return !!pathNameSplit[1] && pathNameSplit[1] === ADS_APP_PREFIX;
};

export const getCurrentRoutePath = (
  allRoutes: RouteConfigure[]
): string | undefined => {
  const homeRoutePath = HOME_CDM_3_ROUTE.path;
  const browserPathName = window.location.pathname;

  if (browserPathName === "/") return homeRoutePath;

  for (const route of allRoutes) {
    if (!!route.parentRoute) {
      let referencePathName = "";

      for (let i = 0; i < route.parentRoute.path.length; ++i) {
        if (!!browserPathName[i]) referencePathName += browserPathName[i];
      }

      if (referencePathName === route.parentRoute.path)
        return route.parentRoute.path;

      continue;
    }

    if (route.path === browserPathName) return route.path;
  }

  return undefined;
};

export const getParentSideNavItem = (
  sideNavItems: MinimalSideNavItem[]
): MinimalSideNavItem | undefined => {
  const browserPathName = window.location.pathname;

  for (const item of sideNavItems) {
    if (!item.childItems || item.childItems.length === 0) continue;

    for (const subItem of item.childItems) {
      if (subItem.path === browserPathName) return item;

      if (!!subItem.childItems) {
        let referencePathName = "";

        for (let i = 0; i < subItem.path.length; ++i)
          if (!!browserPathName[i]) referencePathName += browserPathName[i];

        if (referencePathName === subItem.path) return item;
      }
    }
  }

  return undefined;
};
