import { createSlice } from "@reduxjs/toolkit";
import { SimpleAccountEmployeeModel } from "../models/Account";
import { RootState } from "./store";
import { getContactPersonsAsync } from "../services/ContactPersonsService";

interface ContactPersonsState {
  membersList: SimpleAccountEmployeeModel[];
}

const initialState: ContactPersonsState = {
  membersList: [],
};

export const contactPersonsSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContactPersonsAsync.fulfilled, (state, { payload }) => {
      if (payload != null) {
        state.membersList = payload;
      }
    });
  },
});

export const selectTeamMembers = (rootState: RootState) =>
  rootState.contactPersion.membersList;
export default contactPersonsSlice.reducer;
