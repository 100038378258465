import { MenuHeaderItem } from "../../../models/pageConfiguration/MenuHeaderModel";
import { CdmVersion } from "../../../models/pageConfiguration/CdmVersion";
import { Cdm3v0MenuConfiguration } from "./Cdm3v0MenuHeaderConfiguration";
import { CdmUs1v0MenuConfiguration } from "./CdmUs1v0MenuHeaderConfiguration";

export const menuHeaderConfiguration: Record<CdmVersion, MenuHeaderItem[]> = {
  [CdmVersion.CDM3v0]: Cdm3v0MenuConfiguration,
  [CdmVersion.US1v0]: CdmUs1v0MenuConfiguration,
  // [CdmVersion.CDM3v0]: CdmUs1v0MenuConfiguration,
  // [CdmVersion.US1v0]: Cdm3v0MenuConfiguration,
};
