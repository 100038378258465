import LoadableComponent from "../../components/loading/LoadableComponent";
import { RouteConfigure } from "../../models/Route";
import { Constants } from "../../utils/helpers/Constants";
import {
  MY_SUBSCRIPTION_SHARED_ROUTE,
  DASHBOARD_REQUESTS_SHARED_ROUTE,
  DASHBOARD_SHARED_ROUTE,
  DASHBOARD_STATISTICS_SHARED_ROUTE,
  DASHBOARD_TEAM_CREATE_SHARED_ROUTE,
  DASHBOARD_TEAM_EDIT_SHARED_ROUTE,
  DASHBOARD_TEAM_SHARED_ROUTE,
  WALLET_SHARED_ROUTE,
  DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE,
  SHOPPING_CART_SHARED_ROUTE,
  CHECKOUT_SHARED_ROUTE,
  NEW_REQUEST_SHARED_ROUTE,
  MY_BOSCH_SHARED_ROUTE,
  SUPPORT_SHARED_ROUTE,
  CONTACT_US_SHARED_ROUTE,
  HOME_SHARED_ROUTE,
  SUBSCRIPTIONS_SHARED_ROUTE,
  SUBSCRIPTION_DETAILS_SHARED_ROUTE,
} from "./sharedRoutes.constants";

/**
 * CDM_US ONLY ROUTES
 */
export const CONTACTS_US_CDM_US_ROUTE: RouteConfigure = {
  ...CONTACT_US_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/ContactUs")),
};

export const HOME_CDM_US_ROUTE: RouteConfigure = {
  ...HOME_SHARED_ROUTE,
  component: LoadableComponent(
    () => import(`../../pages/CDMUS-1.0/homePage/HomePage`)
  ),
};

export const SUBSCRIPTION_CDM_US_ROUTE: RouteConfigure = {
  ...SUBSCRIPTIONS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import(`../../pages/CDMUS-1.0/Subscriptions`)
  ),
};

export const SUBSCRIPTION_DETAIL_CDM_US_ROUTE: RouteConfigure = {
  ...SUBSCRIPTION_DETAILS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import(`../../pages/CDMUS-1.0/SubscriptionDetail`)
  ),
  parentRoute: SUBSCRIPTION_CDM_US_ROUTE,
};

export const SHOPPING_CART_CDM_US_ROUTE: RouteConfigure = {
  ...SHOPPING_CART_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/ShoppingCart")
  ),
};

export const CHECK_OUT_CDM_US_ROUTE: RouteConfigure = {
  ...CHECKOUT_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDMUS-1.0/CheckOut")),
  parentRoute: SHOPPING_CART_CDM_US_ROUTE,
};

export const DASHBOARD_REQUESTS_CDM_US_ROUTE: RouteConfigure = {
  ...DASHBOARD_REQUESTS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/DashboardRequestCDMUs1v0Wrapper")
  ),
};

export const DASHBOARD_REQUEST_DETAILS_CDM_US_ROUTE: RouteConfigure = {
  ...DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/RequestDetails")
  ),
  parentRoute: DASHBOARD_REQUESTS_CDM_US_ROUTE,
};

export const DASHBOARD_TEAM_CDM_US_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/DashboardTeamCDMUs")
  ),
};

export const DASHBOARD_TEAM_EDIT_CDM_US_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_EDIT_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/TeamEditCdmUs")
  ),
  parentRoute: DASHBOARD_TEAM_CDM_US_ROUTE,
};

export const DASHBOARD_TEAM_CREATE_CDM_US_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_CREATE_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/TeamCreateCdmUs")
  ),
  parentRoute: DASHBOARD_TEAM_CDM_US_ROUTE,
};

export const DASHBOARD_STATISTICS_CDM_US_ROUTE: RouteConfigure = {
  ...DASHBOARD_STATISTICS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/Statistics")
  ),
};

export const DASHBOARD_WALLET_CDM_US_ROUTE: RouteConfigure = {
  ...WALLET_SHARED_ROUTE,
  path: "/dashboard/wallet",
  role: [Constants.USER_ROLE_MANAGER_ID],
  component: LoadableComponent(() => import("../../pages/CDMUS-1.0/Wallet")),
};

export const DASHBOARD_MY_SUBSCRIPTION_CDM_US_ROUTE: RouteConfigure = {
  ...MY_SUBSCRIPTION_SHARED_ROUTE,
  path: "/dashboard/my-sub",
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/MySubscription")
  ),
};

export const MY_REQUESTS_CDM_US_ROUTE: RouteConfigure = {
  ...NEW_REQUEST_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDMUS-1.0/newRequest/NewRequest")
  ),
};

export const DASHBOARD_CDM_US_ROUTE: RouteConfigure = {
  ...DASHBOARD_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDMUS-1.0/Dashboard")),
};

export const MY_PROFILE_CDM_US_ROUTE: RouteConfigure = {
  ...MY_BOSCH_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDMUS-1.0/MyProfile")),
};

export const SUPPORT_CDM_US_ROUTE: RouteConfigure = {
  ...SUPPORT_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDMUS-1.0/Support")),
};

export const WALLET_CDM_US_ROUTE: RouteConfigure = {
  ...WALLET_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDMUS-1.0/Wallet")),
  parentRoute: DASHBOARD_WALLET_CDM_US_ROUTE,
};
