import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import httpPCS from "../services/HttpServicePCS";
import { RootState } from "./store";

export interface MediaPlacementState {
  AudioHostUrl: string;
  AudioFallbackUrl: string;
  ScreenDataUrl: string;
  ScreenSharingUrl: string;
  ScreenViewingUrl: string;
  SignalingUrl: string;
  TurnControlUrl: string;
  EventIngestionUrl: string;
}

export interface MeetingDetailState {
  meetingId: string;
  ExternalMeetingId: string;
  MediaPlacement: MediaPlacementState;
  mediaRegion: string;
}

export interface AttendeeState {
  ExternalUserId: string;
  attendeeId: string;
  JoinToken: string;
}

export interface MeetingState {
  Title: string;
  Meeting: MeetingDetailState;
  Attendee: AttendeeState;
}

const initialMediaPlacementState: MediaPlacementState = {
  AudioHostUrl: "",
  AudioFallbackUrl: "",
  ScreenDataUrl: "",
  ScreenSharingUrl: "",
  ScreenViewingUrl: "",
  SignalingUrl: "",
  TurnControlUrl: "",
  EventIngestionUrl: "",
};

const initialMeetingDetailState: MeetingDetailState = {
  meetingId: "",
  ExternalMeetingId: "",
  MediaPlacement: initialMediaPlacementState,
  mediaRegion: "",
};

const initialAttendeeState: AttendeeState = {
  ExternalUserId: "",
  attendeeId: "",
  JoinToken: "",
};

export const initialState: MeetingState = {
  Title: "",
  Meeting: initialMeetingDetailState,
  Attendee: initialAttendeeState,
};

export const createMeeting = createAsyncThunk(
  "meeting/createMeeting",
  async (ticketRequest, thunkApi) => {
    let result: any;
    try {
      const response = await httpPCS.get(`/api/Chime/CreateMeeting`);
      console.log(response);
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const addAttendee = createAsyncThunk<any, any>(
  "meeting/addAttendee",
  async (attendeeRequest, thunkApi) => {
    let result: any;
    try {
      const response = await httpPCS.post(
        `/api/Chime/CreateAttendee`,
        attendeeRequest
      );
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const endMeeting = createAsyncThunk<any, string>(
  "meeting/endMeeting",
  async (meetingId, thunkApi) => {
    let result: any;
    try {
      const response = await httpPCS.get(
        `/api/Chime/DeleteMeeting?meetingId=${meetingId}`
      );
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const selectMeetingState = (rootState: RootState) => rootState.meeting;
export const selectAttendeeState = (rootState: RootState) =>
  rootState.meeting.Attendee;

const meetingReducerSlice = createSlice({
  name: "meetingReducer",
  initialState: initialState,
  reducers: {
    getMeetingState: (state) => {
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMeeting.fulfilled, (state, { payload }) => {
        state.Meeting = payload.dataObject.createMeetingResponse.meeting;
        state.Title = payload.dataObject.title;
      })
      .addCase(addAttendee.fulfilled, (state, { payload }) => {
        state.Meeting = payload.dataObject.createMeetingResponse.meeting;
        state.Attendee = payload.dataObject.attendee.attendee;
        state.Title = payload.dataObject.title;
      })
      .addCase(endMeeting.fulfilled, (state) => {
        state = {
          ...state,
          ...initialState,
        };
      });
  },
});

export const { getMeetingState } = meetingReducerSlice.actions;
export default meetingReducerSlice.reducer;
