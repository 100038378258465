import { useSelector } from "react-redux";
import {
  CartItemPrice,
  ShoppingCartItem,
} from "../models/ShoppingCart/ShoppingCartItem";
import { selectCountryCode } from "../redux/auth";
import {
  selectShoppingCartItems,
  ShoppingCartV3,
} from "../redux/shoppingCartV3Slice";
import { useCurrencyFormat } from "./useCurrencyFormat";

export const calculatePrice = (
  price: CartItemPrice | null,
  quantity: number
): CartItemPrice => {
  if (!price) {
    return {
      quantity: 0,
      netPrice: 0,
      vatAmmout: 0,
      actualPrice: 0,
    } as CartItemPrice;
  }
  return {
    ...price,
    quantity: quantity,
    netPrice: quantity * price.unitPrice,
    vatAmmout: (quantity * price.unitPrice * price.vatPercentage) / 100,
    actualPrice:
      quantity * price.unitPrice +
      (quantity * price.unitPrice * price.vatPercentage) / 100,
    voucherAppliedPrice: quantity * price.voucherAppliedPrice,
  } as CartItemPrice;
};

export const useShoppingCartItemPrice = () => {
  const format = useCurrencyFormat();
  const countryCode = useSelector(selectCountryCode);
  const shoppingCartItems = useSelector(selectShoppingCartItems);

  const calculateYearNetPrice = (shoppingCartItem: ShoppingCartItem) => {
    return shoppingCartItem.netPrice.yPrice.netPrice;
  };

  const calculateYearNetPriceWithFormat = (
    shoppingCartItem: ShoppingCartItem
  ) => {
    const price = calculateYearNetPrice(shoppingCartItem);
    return format(
      price,
      shoppingCartItem.netPrice.yPrice.currency?.symbol,
      countryCode
    );
  };

  const getSubscriptionPrice = (
    price: CartItemPrice,
    voucherCode: string | null | undefined
  ) => {
    return getSubscriptionPriceByShoppingCartItems(
      price,
      voucherCode,
      shoppingCartItems
    );
  };

  const getSubscriptionPriceByShoppingCartItems = (
    price: CartItemPrice,
    voucherCode: string | null | undefined,
    inputShoppingCartItems: ShoppingCartItem[]
  ) => {
    let ret = "";

    if (!voucherCode) {
      ret = format(
        price.netPrice +
          inputShoppingCartItems
            .filter((x) => !!x.addOnItem && x.addOnItem.isMandatory)
            .map((x) => {
              return x.voucherCode
                ? x.netPrice.yPrice.voucherAppliedPrice
                : x.netPrice.yPrice.netPrice;
            })
            .reduce((accumulator, current) => {
              return accumulator + current;
            }, 0),
        price.currency?.symbol,
        countryCode
      );
      return ret;
    }
    ret = format(
      price.voucherAppliedPrice +
        inputShoppingCartItems
          .filter((x) => !!x.addOnItem && x.addOnItem.isMandatory)
          .map((x) => {
            return x.voucherCode
              ? x.netPrice.yPrice.voucherAppliedPrice
              : x.netPrice.yPrice.netPrice;
          })
          .reduce((accumulator, current) => {
            return accumulator + current;
          }, 0),
      price.currency?.symbol,
      countryCode
    );
    return ret;
  };

  const getSubscriptionMonthlyPrice = (
    price: CartItemPrice,
    voucherCode: string | null | undefined
  ) => {
    let ret = "";
    if (!voucherCode) {
      ret = format(
        price.netPrice +
          shoppingCartItems
            .filter(
              (x: ShoppingCartItem) => !!x.addOnItem && x.addOnItem.isMandatory
            )
            .map((x: ShoppingCartItem) => {
              if (x.addOnItem?.allowMonthlyPayment)
                return x.netPrice.mPrice.netPrice;

              return x.netPrice.yPrice.netPrice;
            })
            .reduce((accumulator, current) => {
              return accumulator + current;
            }, 0),
        price.currency?.symbol,
        countryCode
      );

      return ret;
    }

    ret = format(
      price.voucherAppliedPrice +
        shoppingCartItems
          .filter(
            (x: ShoppingCartItem) => !!x.addOnItem && x.addOnItem.isMandatory
          )
          .map((x: ShoppingCartItem) => {
            if (x.voucherCode)
              return x.addOnItem?.allowMonthlyPayment
                ? x.netPrice.mPrice.voucherAppliedPrice
                : x.netPrice.yPrice.voucherAppliedPrice;

            return x.addOnItem?.allowMonthlyPayment
              ? x.netPrice.mPrice.netPrice
              : x.netPrice.yPrice.netPrice;
          })
          .reduce((accumulator, current) => {
            return accumulator + current;
          }, 0),
      price.currency?.symbol,
      countryCode
    );

    return ret;
  };

  const getSubscriptionOriginalPrice = (price: CartItemPrice) => {
    return getSubscriptionOriginalPriceByShoppingCartItems(
      price,
      shoppingCartItems
    );
  };

  const getSubscriptionOriginalPriceByShoppingCartItems = (
    price: CartItemPrice,
    inputShoppingCartItems: ShoppingCartItem[]
  ) => {
    const ret = format(
      price.netPrice +
        inputShoppingCartItems
          .filter(
            (x) => !!x.addOnItem && x.addOnItem.isMandatory && x.originalPrice
          )
          .map((x) => x.originalPrice?.yPrice.netPrice || 0)
          .reduce((accumulator, current) => {
            return accumulator + current;
          }, 0),
      price.currency?.symbol,
      countryCode
    );

    return ret;
  };

  const getSubscriptionOriginalMonthlyPrice = (price: CartItemPrice) => {
    const ret = format(
      price.netPrice +
        shoppingCartItems
          .filter(
            (x) => !!x.addOnItem && x.addOnItem.isMandatory && x.originalPrice
          )
          .map((x) => {
            if (x.addOnItem?.allowMonthlyPayment)
              return x.originalPrice?.mPrice.netPrice || 0;
            return x.originalPrice?.yPrice.netPrice || 0;
          })
          .reduce((accumulator, current) => {
            return accumulator + current;
          }, 0),
      price.currency?.symbol,
      countryCode
    );

    return ret;
  };

  const calculatePriceByQuantity = (price: CartItemPrice, quantity: number) => {
    return calculatePrice(price, quantity);
  };

  const adjustShoppingCartItemPrice = (
    price: CartItemPrice | null,
    quantity: number
  ): CartItemPrice => {
    if (!price) {
      return {
        quantity: 0,
        netPrice: 0,
        vatAmmout: 0,
        actualPrice: 0,
      } as CartItemPrice;
    }
    return {
      ...price,
      quantity: quantity,
      netPrice: quantity * price.netPrice,
      vatAmmout: (quantity * price.netPrice * price.vatPercentage) / 100,
      actualPrice:
        quantity * price.netPrice +
        (quantity * price.netPrice * price.vatPercentage) / 100,
      voucherAppliedPrice: quantity * price.voucherAppliedPrice,
    } as CartItemPrice;
  };

  const adjustShoppingCartPrice = (
    shoppingCart: ShoppingCartV3,
    quantity: number
  ) => {
    const cartItems: ShoppingCartItem[] = shoppingCart.shoppingItems.map(
      (item) => {
        if (item.addOnItem?.isMultitplyByWorkShop) {
          return {
            ...item,
            quantity: quantity,
            netPrice: {
              mPrice: adjustShoppingCartItemPrice(
                item.netPrice.mPrice,
                quantity
              ),
              yPrice: adjustShoppingCartItemPrice(
                item.netPrice.yPrice,
                quantity
              ),
            },
            originalPrice: {
              quantity: quantity,
              mPrice: adjustShoppingCartItemPrice(
                item.originalPrice.mPrice,
                quantity
              ),
              yPrice: adjustShoppingCartItemPrice(
                item.originalPrice.yPrice,
                quantity
              ),
            },
          };
        }

        if (!!item.subscriptionItem) {
          return {
            ...item,
            quantity: quantity,
          };
        }

        return item;
      }
    );

    let ret: ShoppingCartV3 = {
      ...shoppingCart,
      shoppingItems: cartItems,
      frontendSelectedNumberOfWorkshop: quantity,
      // cartPrice: calculatePrice(shoppingCart.cartPrice, quantity),
      // originalCartPrice: calculatePrice(
      //   shoppingCart.originalCartPrice,
      //   quantity
      // ),
    };

    if (quantity > 1) {
      ret = {
        ...ret,
        cartPrice: calculatePrice(shoppingCart.cartPrice, quantity),
        originalCartPrice: calculatePrice(
          shoppingCart.originalCartPrice,
          quantity
        ),
      };
    }

    return ret;
  };
  // const calculateCartYearNetPrice = (shoppingCartItems: ShoppingCartItem[]) => {
  //   return shoppingCartItems
  //     .map((item) => calculateYearNetPrice(item))
  //     .reduce((accumulator, current) => {
  //       return accumulator + current;
  //     }, 0);
  // };

  // const calculateCartYearNetPriceWithFormat = (
  //   shoppingCartItems: ShoppingCartItem[]
  // ) => {
  //   if (!shoppingCartItems || shoppingCartItems.length <= 0)
  //     return format(0, "", countryCode);

  //   const totalPrice = calculateCartYearNetPrice(shoppingCartItems);

  //   return format(
  //     totalPrice,
  //     shoppingCartItems[0].netPrice.currency.symbol,
  //     countryCode
  //   );
  // };

  // const calculateCartYearNetPriceVAT = (
  //   shoppingCartItems: ShoppingCartItem[]
  // ) => {
  //   return shoppingCartItems
  //     .map((item) => item.netPrice.vatAmmout)
  //     .reduce((accumulator, current) => {
  //       return accumulator + current;
  //     }, 0);
  // };

  // const calculateCartYearNetPriceVATWithFormat = (
  //   shoppingCartItems: ShoppingCartItem[]
  // ) => {
  //   if (!shoppingCartItems || shoppingCartItems.length <= 0)
  //     return format(0, "", countryCode);

  //   const totalPriceVAT = calculateCartYearNetPriceVAT(shoppingCartItems);

  //   return format(
  //     totalPriceVAT,
  //     shoppingCartItems[0].netPrice.currency.symbol,
  //     countryCode
  //   );
  // };

  // const calculateCartTotalPriceWithFormat = (
  //   shoppingCartItems: ShoppingCartItem[]
  // ) => {
  //   if (!shoppingCartItems || shoppingCartItems.length <= 0)
  //     return format(0, "", countryCode);

  //   // const totalPriceNet = calculateCartYearNetPrice(shoppingCartItems);
  //   // const totalPriceVAT = calculateCartYearNetPriceVAT(shoppingCartItems);
  //   // const total = totalPriceNet + totalPriceVAT;
  //   const total = shoppingCartItems
  //     .map((item) => item.netPrice.actualPrice)
  //     .reduce((accumulator, current) => {
  //       return accumulator + current;
  //     }, 0);

  //   return format(
  //     total,
  //     shoppingCartItems[0].netPrice.currency.symbol,
  //     countryCode
  //   );
  // };

  return {
    calculateYearNetPrice,
    calculateYearNetPriceWithFormat,
    getSubscriptionPrice,
    getSubscriptionPriceByShoppingCartItems,
    getSubscriptionOriginalPrice,
    getSubscriptionOriginalPriceByShoppingCartItems,
    getSubscriptionOriginalMonthlyPrice,
    calculatePriceByQuantity,
    getSubscriptionMonthlyPrice,
    adjustShoppingCartPrice,
    // adjustShoppingCartItemPrice
    // calculateCartYearNetPriceWithFormat,
    // calculateCartYearNetPriceVATWithFormat,
    // calculateCartTotalPriceWithFormat,
  };
};
