import { useTranslation } from "react-i18next";
import AdsComponentTitle from "../../../components/adsComponentTitle/AdsComponentTitle";
import style from "./AdsRequestCreated.module.scss";
import RDButton from "../../../../components/RDComponents/RDButton";
import { useAppDispatch } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import AdsPreconditionModal from "../adsPreconditionModal/AdsPreconditionModal";
import {
  resetNewRequestState,
  selectFormValues,
  updateFormValues,
} from "../../../../redux/newRequestSlice";
import { useAdsRedirect } from "../../../hooks/useAdsRedirect";
import { useSelector } from "react-redux";
import CustomLoader from "../../../../components/loading/CustomLoader";

const AdsRequestCreated = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formValues = useSelector(selectFormValues);
  const { goToStartSessionPage } = useAdsRedirect();
  const [isShowPreconditionModal, setShowPreconditionModal] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!formValues.brandSelected) dispatch(resetNewRequestState());

    return () => {
      dispatch(resetNewRequestState());
    };
  }, []);

  const onStartSession = () => {
    setShowPreconditionModal(true);
  };

  const onAcceptToStartNewSession = () => {
    setShowLoader(true);
    dispatch(
      updateFormValues({
        brandSelected: "",
      })
    );
    setShowPreconditionModal(false);

    const timeoutId = window.setTimeout(() => {
      window.clearTimeout(timeoutId);

      if (!formValues.newRequestSessionId) return;
      goToStartSessionPage(formValues.newRequestSessionId);
    }, 1000);
  };

  if (!formValues.newRequestSessionId) return <></>;

  return (
    <>
      <div className={style.container}>
        <AdsComponentTitle
          title={t("ADS_NewRequest_RequestCreatedSuccessfully")}
        />
        <div className={style.wrapper}>
          <div className={style.block}>
            <span>
              {t("ADS_NewRequest_RequestCreatedSuccessfully_Message1")}
            </span>
            <span>
              <b> {formValues.newRequestSessionId}</b>
            </span>
          </div>
          <div className={style.block}>
            <p
              dangerouslySetInnerHTML={{
                __html: t("ADS_NewRequest_RequestCreatedSuccessfully_Message2"),
              }}
            />
          </div>
          <div className={style.bottom}>
            <RDButton onClick={onStartSession}>
              {t("ADS_NewRequest_RequestCreatedSuccessfully_StartSession")}
            </RDButton>
          </div>
        </div>
      </div>
      {isShowPreconditionModal ? (
        <AdsPreconditionModal onAccept={onAcceptToStartNewSession} />
      ) : (
        <></>
      )}
      {isShowLoader ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default AdsRequestCreated;
