import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Constants } from "../utils/helpers/Constants";
import { RootState } from "./store";
import {
  StatisticState,
  StatisticDetailData,
  TicketStatistic,
} from "../models/statistic/StatisticModel";
import {
  getStatisticDataByMonth,
  getStatisticDataByWeek,
  getStatisticDataByYTD,
  getStatisticDataByYear,
} from "../services/StatisticService";

const initialState: StatisticState = {
  isLoadingData: false,
  currentStatisticView: Constants.StatisticSelectViewThisYear,
  mainOrgSummaryData: null,
  childOrgsSummaryData: null,
};

export const statisticCdmUSSlice = createSlice({
  name: "statisticCdmUSSlice",
  initialState,
  reducers: {
    setLoadingStatisticData: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoadingData: action.payload,
      };
    },
    setCurrentStatisticView: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        currentStatisticView: action.payload,
      };
    },
    setSummaryData: (
      state,
      action: PayloadAction<StatisticDetailData | null>
    ) => {
      return {
        ...state,
        summaryData: action.payload,
      };
    },
    setChildOrgsSummaryData: (
      state,
      action: PayloadAction<StatisticDetailData[] | null>
    ) => {
      return {
        ...state,
        childOrgsSummaryData: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatisticDataByYTD.fulfilled, (state, { payload }) => {
        if (
          payload !== null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          return {
            ...state,
            mainOrgSummaryData: payload.dataObject.mainOrganization,
            childOrgsSummaryData: [...payload.dataObject.childOrganizations],
          };
        }
      })
      .addCase(getStatisticDataByWeek.fulfilled, (state, { payload }) => {
        if (
          payload !== null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          return {
            ...state,
            mainOrgSummaryData: payload.dataObject.mainOrganization,
            childOrgsSummaryData: [...payload.dataObject.childOrganizations],
          };
        }
      })
      .addCase(getStatisticDataByMonth.fulfilled, (state, { payload }) => {
        if (
          payload !== null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          return {
            ...state,
            mainOrgSummaryData: payload.dataObject.mainOrganization,
            childOrgsSummaryData: [...payload.dataObject.childOrganizations],
          };
        }
      })
      .addCase(getStatisticDataByYear.fulfilled, (state, { payload }) => {
        if (
          payload !== null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          return {
            ...state,
            mainOrgSummaryData: payload.dataObject.mainOrganization,
            childOrgsSummaryData: [...payload.dataObject.childOrganizations],
          };
        }
      });
  },
});

export const selectStatisticLoadingStatus = (rootState: RootState) =>
  rootState.statistic.isLoadingData;
export const selectMainOrgSummaryData = (rootState: RootState) =>
  rootState.statistic.mainOrgSummaryData;
export const selectMainOrgServiceTypesStatisticData = (rootState: RootState) =>
  rootState.statistic.mainOrgSummaryData?.aggregatedServiceTypeStatistic;
export const selectMainOrgBrandsStatisticData = (rootState: RootState) =>
  rootState.statistic.mainOrgSummaryData?.aggregatedCarBrandStatistic;
export const selectChildOrgsSummaryData = (rootState: RootState) =>
  rootState.statistic.childOrgsSummaryData;
export const selectCurrentStatisticView = (rootState: RootState) =>
  rootState.statistic.currentStatisticView;
export const selectMainOrgCustomerId = (rootState: RootState) =>
  rootState.statistic.mainOrgSummaryData?.customerId;
export const selectMainOrgName = (rootState: RootState) =>
  rootState.statistic.mainOrgSummaryData?.name;

export const {
  setCurrentStatisticView,
  setSummaryData,
  setLoadingStatisticData,
  setChildOrgsSummaryData,
} = statisticCdmUSSlice.actions;

export default statisticCdmUSSlice.reducer;

export const getInProcessTicketsQty = (
  statisticDetailData: TicketStatistic
) => {
  const {
    totalTicketsCreated,
    successfulTickets,
    unresolvedTickets,
    cancelledByCustomerTickets,
  } = statisticDetailData;

  return (
    totalTicketsCreated -
    successfulTickets -
    unresolvedTickets -
    cancelledByCustomerTickets
  );
};
