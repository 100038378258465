import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "../utils/helpers/Constants";
import {
  selectAccount,
  selectAccountSubscription,
  selectImList,
  selectOriginComp,
} from "../redux/account";
import { selectIsSignIn } from "../redux/auth";
import TrialStatus from "../models/TrialStatus";
import { UserSubscriptionInfo } from "../models/paymentAdyen/userSubscriptionInfo";
import Account from "../models/Account";

export const useUserSubscription = () => {
  const [hasRDS500, setHasRDS500] = useState(false);
  const [hasCarRepair, setHasCarRepair] = useState(false);
  const isSignIn = useSelector(selectIsSignIn);
  const account = useSelector(selectAccount);
  const subscription = useSelector(selectAccountSubscription);
  const impersonationList = useSelector(selectImList);
  const originAccount = useSelector(selectOriginComp);

  useEffect(() => {
    if (subscription && subscription.id > 0 && subscription.isActive) {
      const rds500AddOn = subscription.subscription?.addOn?.find(
        (x: any) =>
          x.category &&
          x.category.id === Constants.SUBSCRIPTION_ADDON_RDS500_CATEGORY_ID
      );

      setHasRDS500(!!rds500AddOn);

      const carRepair = subscription.subscription?.addOn?.find(
        (x: any) =>
          x.category &&
          x.category.id === Constants.SUBSCRIPTION_ADDON_CAR_REPAIR_CATEGORY_ID
      );
      setHasCarRepair(!!carRepair);
    }
  }, [subscription]);

  const hasActivePayAsYouGoPlan = () => {
    return (
      subscription &&
      subscription.id > 0 &&
      subscription.subscription &&
      subscription.subscription.cdm3SubscriptionType &&
      subscription.subscription.cdm3SubscriptionType.id ===
        Constants.SUBSCRIPTION_PAY_AS_YOU_GO_SUBSCRIPTION_TYPE_ID
    );
  };

  const checkUserIsMapped = () => {
    return (
      isSignIn && !!account?.cdbId && subscription && subscription.isActive
    );
  };

  const checkUserMultipleWorkshop = () => {
    return !!impersonationList && impersonationList.length > 1;
  };

  const checkSubscriptionIsTrial = (
    userSubscription?: UserSubscriptionInfo
  ) => {
    const targetSubscription = userSubscription || subscription;

    return (
      !!targetSubscription?.trialStatus &&
      (targetSubscription?.trialStatus === TrialStatus.Active ||
        targetSubscription?.trialStatus === TrialStatus.Expired)
    );
  };

  /**
   * @returns : true -> Branch account | false -> Master account
   */
  const isBranchAccount = () => {
    return (
      (!!subscription?.subscription?.cdm3SubscriptionType?.id &&
        subscription?.subscription?.cdm3SubscriptionType?.id ===
          Constants.SUBSCRIPTION_ENTERPRISE_SUBSCRIPTION_TYPE_ID &&
        !!impersonationList &&
        impersonationList.length === 1) ||
      account?.cdbId !== originAccount?.cdbId
    );
  };

  const isAllowBuyCredit = () => {
    // return (
    //   checkUserIsMapped() &&
    //   !checkSubscriptionIsTrial() &&
    //   !isBranchAccount() &&
    //   account?.userRole?.id === Constants.USER_ROLE_MANAGER_ID
    // );

    return (
      checkUserIsMapped() &&
      !checkSubscriptionIsTrial() &&
      account?.userRole?.id === Constants.USER_ROLE_MANAGER_ID
    );
  };

  const isUserSubscriptionExpired = (
    account: Account,
    userSubscription: UserSubscriptionInfo
  ) => {
    return (
      !!account?.cdbId &&
      userSubscription &&
      !userSubscription.isActive &&
      !checkSubscriptionIsTrial(userSubscription)
    );
  };

  return {
    hasRDS500,
    hasCarRepair,
    hasActivePayAsYouGoPlan,
    checkUserIsMapped,
    checkUserMultipleWorkshop,
    checkSubscriptionIsTrial,
    isBranchAccount,
    isAllowBuyCredit,
    isUserSubscriptionExpired,
  } as const;
};
