import ResponseResultBase from "../models/ResponseResultBase";
import Subscription from "../models/Subscription";
import { RequestSubscriptionWithVoucherModel } from "../models/subscription/request.subscription.model";
import http from "./HttpService";

export const subscriptionService = {
  getSubscriptionWithVoucher,
};

async function getSubscriptionWithVoucher(
  requestModel: RequestSubscriptionWithVoucherModel
) {
  return await http.get<ResponseResultBase<Subscription[]>>(
    `/api/v1/subscriptions/GetSubscriptionsWithVoucher?&countryCode=${requestModel.countryCode}&language=${requestModel.language}&voucherCode=${requestModel.voucherCode}&subscriptionId=${requestModel.subscriptionId}`
  );
}
