import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Modal } from "semantic-ui-react";
import Boschicon from "../BoschIcon/Boschicon";
import Styles from "./TrialModeDisabledCDM3Modal.module.css";

interface ITrialModeDisabledCDM3ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}
export const TrialModeDisabledCDM3Modal: FC<
  ITrialModeDisabledCDM3ModalProps
> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={() => {
          closeModal();
        }}
        open={isOpen}
        dimmer={{
          className: `${Styles.trial_mode_disabled_cmd3_modal} ${Styles.warning}`,
        }}
        className={Styles.modal}
        size={"tiny"}
      >
        <Modal.Header className={Styles.header}>
          <Boschicon name="bosch-ic-car-connected" />
          {t("TrialModeDisabledModal_Title")}
        </Modal.Header>
        <Modal.Content className={Styles.content}>
          <Grid className={`${Styles.ui} ${Styles.grid}`}>
            <Grid.Row className={Styles.row}>
              <p className={Styles.description_text}>
                {t("TrialModeDisabledModal_Description_Paragraph1")}
              </p>
              <p className={Styles.description_text}>
                {t("TrialModeDisabledModal_Description_Paragraph2")}
              </p>
            </Grid.Row>
          </Grid>
        </Modal.Content>

        <Modal.Actions className={Styles.actions}>
          <Button
            primary
            onClick={() => {
              closeModal();
            }}
          >
            {t("TrialModeDisabledModal_SubscribeButton")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
