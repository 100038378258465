import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import ResponseResultBase from "../models/ResponseResultBase";
import Transaction from "../models/Transaction";
import { RequestCheckVoucherCodeModel } from "../models/transaction/request.CheckVoucherCode.model";
import { RequestTransactionFileModel } from "../models/transaction/request.transaction.file.model";
import http from "../services/HttpService";
import { transactionService } from "../services/TransactionService";
import { AppDispatch, RootState } from "./store";

export interface TransactionState {
  transaction: Transaction | null;
}

const initialState: TransactionState = {
  transaction: null,
};

export const getTransactionsAsync = createAsyncThunk<
  ResponseResultBase<Transaction[]> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("transaction/getTransactions", async (ciamid, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<Transaction[]>>(
      `/api/v1/transaction/GetTransactions?CiamId=${ciamid}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getTransactionFileAsync = createAsyncThunk<
  ResponseResultBase<Transaction> | null,
  RequestTransactionFileModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "transaction/getTransactionFile",
  async (model: RequestTransactionFileModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<Transaction>>(
        `/api/v1/transaction/getTransactionFile?CiamId=${model.ciamId}&transactionId=${model.transactionId}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const checkVoucherCodeAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  RequestCheckVoucherCodeModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "transaction/checkVoucherCode",
  async (requestModel: RequestCheckVoucherCodeModel, thunkApi) => {
    try {
      const response = await transactionService.checkVoucherCodeAsync(
        requestModel
      );
      console.log(response);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const selectTransaction = (rootState: RootState) =>
  rootState.transaction;

export const transactionSlice = createSlice({
  name: "transactionReducer",
  initialState,
  reducers: {
    getTransactionState: (state) => {
      return { ...state };
    },
  },
  extraReducers: (builder) => {},
});

export const { getTransactionState } = transactionSlice.actions;
export default transactionSlice.reducer;
