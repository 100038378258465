import { useSelector } from "react-redux";
import { RediConstant } from "../models/common/rediConstant";
import { PaymentFrequency } from "../models/paymentAdyen/paymentFrequency";
import {
  CartItemPrice,
  ShoppingCartItem,
} from "../models/ShoppingCart/ShoppingCartItem";
import { SubscriptionPrices } from "../models/subscription/SubscriptionV3.model";
import { selectCountryCode } from "../redux/auth";

export const convertToCartItemPrice = (
  price: SubscriptionPrices,
  quantity: number,
  countryCode: string = ""
): CartItemPrice => {
  const ret: CartItemPrice = {
    actualPrice: price.yearTotalPrice * quantity,
    unitPrice: price.yearNetPrice,
    netPrice: price.yearNetPrice * quantity,
    vatAmmout: price.yearTax * quantity,
    vatPercentage: price.vat,
    currency: {
      country: countryCode,
      currency: price.currency,
      symbol: price.currencySymbol,
    },
    quantity: quantity,
    voucherAppliedPrice: 0,
  };

  return ret;
};

export const calculateTotalPrice = (
  shoppingCartItems: ShoppingCartItem[],
  paymentFrequencySelected: PaymentFrequency | null
) => {
  if (!shoppingCartItems || shoppingCartItems.length <= 0) {
    return {
      currency: {},
      unitPrice: 0,
      vatPercentage: 0,
      voucherAppliedPrice: 0,
      netPrice: 0,
      vatAmmout: 0,
      actualPrice: 0,
    } as CartItemPrice;
  }
  const isMonthlyPayment =
    !!paymentFrequencySelected &&
    paymentFrequencySelected?.id === RediConstant.PaymentFrequency_Monthly_Id;
  return {
    currency: { ...shoppingCartItems[0].netPrice.yPrice.currency },
    unitPrice: 0,
    vatPercentage: shoppingCartItems[0].netPrice.yPrice.vatPercentage,
    voucherAppliedPrice: shoppingCartItems
      .map((x) => x.netPrice.yPrice.voucherAppliedPrice)
      .filter((x) => !!x)
      .reduce((accumulator, current) => {
        return (accumulator ? accumulator : 0) + (current ? current : 0);
      }, 0),
    netPrice: shoppingCartItems
      .map((x) => {
        if (
          !isMonthlyPayment ||
          (!!x.addOnItem && !x.addOnItem.allowMonthlyPayment)
        )
          return x.netPrice.yPrice.netPrice;
        return x.netPrice.mPrice.netPrice;
      })
      .reduce((accumulator, current) => {
        return accumulator + current;
      }, 0),
    vatAmmout: shoppingCartItems
      .map((x) => {
        if (
          !isMonthlyPayment ||
          (!!x.addOnItem && !x.addOnItem.allowMonthlyPayment)
        )
          return x.netPrice.yPrice.vatAmmout;
        return x.netPrice.mPrice.vatAmmout;
      })
      .reduce((accumulator, current) => {
        return accumulator + current;
      }, 0),
    actualPrice: shoppingCartItems
      .map((x) => {
        if (
          !isMonthlyPayment ||
          (!!x.addOnItem && !x.addOnItem.allowMonthlyPayment)
        )
          return x.netPrice.yPrice.actualPrice;

        return x.netPrice.mPrice.actualPrice;
      })
      .reduce((accumulator, current) => {
        return accumulator + current;
      }, 0),
  } as CartItemPrice;
};

export const calculateTotalOriginalPrice = (
  shoppingCartItems: ShoppingCartItem[],
  paymentFrequencySelected: PaymentFrequency | null
) => {
  if (!shoppingCartItems || shoppingCartItems.length <= 0) {
    return {
      currency: {},
      unitPrice: 0,
      vatPercentage: 0,
      voucherAppliedPrice: 0,
      netPrice: 0,
      vatAmmout: 0,
      actualPrice: 0,
    } as CartItemPrice;
  }

  const isMonthlyPayment =
    !!paymentFrequencySelected &&
    paymentFrequencySelected?.id === RediConstant.PaymentFrequency_Monthly_Id;

  let currency = shoppingCartItems[0].netPrice.yPrice.currency;
  let vatPercentage = shoppingCartItems[0].netPrice.yPrice.vatPercentage;
  if (!!shoppingCartItems[0].originalPrice) {
    currency = shoppingCartItems[0].originalPrice.yPrice.currency;
    vatPercentage = shoppingCartItems[0].originalPrice.yPrice.vatPercentage;
  }
  return {
    currency: { ...currency },
    unitPrice: 1,
    vatPercentage: vatPercentage,
    voucherAppliedPrice: shoppingCartItems
      .map((x) => {
        if (x.originalPrice) {
          return x.originalPrice.yPrice.voucherAppliedPrice;
        }
        return 0;
      })
      .filter((x) => !!x)
      .reduce((accumulator, current) => {
        return (accumulator ? accumulator : 0) + (current ? current : 0);
      }, 0),
    netPrice: shoppingCartItems
      .map((x) => {
        if (x.originalPrice) {
          if (
            !isMonthlyPayment ||
            (!!x.addOnItem && !x.addOnItem.allowMonthlyPayment)
          )
            return x.originalPrice.yPrice.netPrice;
          return x.originalPrice.mPrice.netPrice;
        }
        return 0;
      })
      .reduce((accumulator, current) => {
        return accumulator + current;
      }, 0),
    vatAmmout: shoppingCartItems
      .map((x) => {
        if (x.originalPrice) {
          if (
            !isMonthlyPayment ||
            (!!x.addOnItem && !x.addOnItem.allowMonthlyPayment)
          )
            return x.originalPrice.yPrice.vatAmmout;

          return x.originalPrice.mPrice.vatAmmout;
        }
        return 0;
      })
      .reduce((accumulator, current) => {
        return accumulator + current;
      }, 0),
    actualPrice: shoppingCartItems
      .map((x) => {
        if (x.originalPrice) {
          if (
            !isMonthlyPayment ||
            (!!x.addOnItem && !x.addOnItem.allowMonthlyPayment)
          )
            return x.originalPrice.yPrice.actualPrice;
          return x.originalPrice.mPrice.actualPrice;
        }
        return 0;
      })
      .reduce((accumulator, current) => {
        return accumulator + current;
      }, 0),
  } as CartItemPrice;
};

export const useConvertPriceToCartItemPrice = () => {
  const countryCode = useSelector(selectCountryCode);

  const convertPrice = (price: SubscriptionPrices, quantity: number) => {
    return convertToCartItemPrice(price, quantity, countryCode);
  };

  return convertPrice;
};
