import style from "./AdsComponentTitle.module.scss";

type Props = {
  title: string;
  className?: string;
};

const AdsComponentTitle = ({ title, className }: Props) => {
  return (
    <div className={`${style.container} ${className || ""}`}>
      <h4 className={style.title}>{title}</h4>
    </div>
  );
};

export default AdsComponentTitle;
