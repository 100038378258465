import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Modal } from "semantic-ui-react";
import Boschicon from "../BoschIcon/Boschicon";

import "./AccountInReviewCDM3Modal.css";
import RDButton from "../RDComponents/RDButton";
import RDText from "../RDComponents/RDText";

interface AccountInReviewCDM3ModalProps {
  isDisplayModal: boolean;
  setCloseModalTrigger: Function;
}

const AccountInReviewCDM3Modal: FC<AccountInReviewCDM3ModalProps> = (
  props: AccountInReviewCDM3ModalProps
) => {
  const { t } = useTranslation();
  const [openInformationModal, setOpenInformationModal] = useState(false);

  useEffect(() => {
    setOpenInformationModal(props.isDisplayModal);
  }, [props]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    props.setCloseModalTrigger(false);
  };

  return (
    <Fragment>
      <Modal
        dimmer={{ className: `account-in-review-cmd3-modal success` }}
        onClose={closeInformationModal}
        open={openInformationModal}
        size={"tiny"}
      >
        <Modal.Header>
          <Boschicon name="bosch-ic-settings-arrows" />
          {t("AccountInReviewCDM3Modal_Title")}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              {/* <p className="activation-link">
                <Link to="dashboard/editmyprofile/activate">
                  {t("AccountInReviewCDM3Modal_ActivationLink")}
                </Link>
                <small className="activation-link-notes">
                  {t("AccountInReviewCDM3Modal_ActivationLinkNotes")}
                </small>
              </p> */}
              <p className="description-text">
                {t("AccountInReviewCDM3Modal_Description_Line1")}
              </p>
              <p className="description-text">
                {t("AccountInReviewCDM3Modal_Description_Line2")}
              </p>
              {/* <p className="description-text">
                {t("AccountInReviewCDM3Modal_Description_Line3")}
              </p> */}
              <p
                className="description-text"
                dangerouslySetInnerHTML={{
                  __html: t("AccountInReviewCDM3Modal_Description_Line4")
                    .replace("[", "<a href='/contacts'>")
                    .replace("]", "</a>"),
                }}
              ></p>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <RDButton small minWidth="maxContent" onClick={closeInformationModal}>
            <RDText>{t("AccountInReviewCDM3Modal_LetMeInButton")}</RDText>
          </RDButton>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default AccountInReviewCDM3Modal;
