import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface DashboardState {
  activeModal: "none" | "success" | "error";
}

const initialState: DashboardState = {
  activeModal: "none",
};

export const joinCompanySlide = createSlice({
  name: "joinCompanyReducer",
  initialState,
  reducers: {
    setJoinCompanyActiveModal: (
      state,
      action: PayloadAction<"none" | "success" | "error">
    ) => {
      return {
        ...state,
        activeModal: action.payload,
      };
    },
  },
});

export const selectJoinCompanyActiveModal = (rootState: RootState) =>
  rootState.joinCompany.activeModal;
export const { setJoinCompanyActiveModal } = joinCompanySlide.actions;
export default joinCompanySlide.reducer;
