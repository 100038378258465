import { useSelector } from "react-redux";
import { selectUserAvailableCredits } from "../redux/newRequestSlice";
import {
  selectCountryCode,
  selectIsSignIn,
  selectLanguage,
} from "../redux/auth";
import { selectCiamId } from "../redux/account";
import { useAppDispatch } from "../redux/hooks";
import { getCreditPackagesAsync } from "../redux/creditSlide";
import { ShoppingCartItem } from "../models/ShoppingCart/ShoppingCartItem";
import { Constants } from "../utils/helpers/Constants";
import {
  ShoppingCartV3,
  clearCart,
  createOrUpdateCartAsync,
  deleteCartAsync,
  replaceCart,
  selectShoppingCart,
  setPrevPageBeforeBuyingCredits,
} from "../redux/shoppingCartV3Slice";
import {
  CheckoutStepPayment,
  setCurrentStepModal,
} from "../redux/checkoutSlide";
import { useHistory } from "react-router-dom";
import { CHECK_OUT_CDM_3_ROUTE } from "../constants/routes/cdm3v0Routes.constants";
import { clearAllCart } from "../redux/shoppingCartSlide";
import { useRouting } from "./useRouting";
import { NEGATIVE_MODAL_RESTRICTED_ROUTES } from "../constants/negativeBalanceModal.constants";
import ResponseResultBase from "../models/ResponseResultBase";

export const useProceedNegativeCreditBalance = () => {
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsSignIn);
  const dispatchReduxToolkit = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const countryCode = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);
  const currentCreditBalance = useSelector(selectUserAvailableCredits);
  const { isAbleToShowRedirectCountryModal, isAbleToShowSwitchCountryModal } =
    useRouting();
  const frontendCart = useSelector(selectShoppingCart);

  const isNegativeBalance = (creditBalance?: number) => {
    return (creditBalance || currentCreditBalance) < 0;
  };

  const isAbleToShowNegativeCreditBalanceModal = (creditBalance?: number) => {
    if (
      !isLoggedIn ||
      isAbleToShowRedirectCountryModal() ||
      isAbleToShowSwitchCountryModal() ||
      !isNegativeBalance(creditBalance)
    )
      return false;

    const currentPathName = window.location.pathname;

    if (currentPathName === "/") return true;

    if (NEGATIVE_MODAL_RESTRICTED_ROUTES.includes(currentPathName))
      return false;

    return true;
  };

  const preprocessToRefillCreditBalance = async () => {
    dispatchReduxToolkit(clearCart());
    dispatchReduxToolkit(clearAllCart());

    await dispatchReduxToolkit(
      deleteCartAsync({
        ciamId,
      })
    );
  };

  const proceedNegativeCreditBalance = async () => {
    await preprocessToRefillCreditBalance();

    const getCreditPackagesResponse = await dispatchReduxToolkit(
      getCreditPackagesAsync({
        ciamId,
        countryCode,
        language,
      })
    );

    if (!getCreditPackagesResponse || !getCreditPackagesResponse.payload)
      return;

    const selectedSingleCredit = (
      getCreditPackagesResponse.payload as ShoppingCartItem[]
    ).find(
      (item) =>
        item.addOnItem?.category.id === Constants.SINGLE_CREDIT_PACKAGE_ID
    );

    if (!selectedSingleCredit) return;

    const cartItems = [
      {
        ...selectedSingleCredit,
        quantity: Math.abs(currentCreditBalance),
      },
    ];

    const createCartResponse = await dispatchReduxToolkit(
      createOrUpdateCartAsync({
        ciamId,
        countryCode,
        frontendCart: {
          ...frontendCart,
          id: 0,
          shoppingItems: cartItems,
        },
        language,
      })
    );
    const createCartResult =
      createCartResponse.payload as ResponseResultBase<ShoppingCartV3>;

    if (createCartResult.success && createCartResult.dataObject) {
      dispatchReduxToolkit(replaceCart(createCartResult.dataObject));
      dispatchReduxToolkit(setCurrentStepModal(CheckoutStepPayment.id));
      dispatchReduxToolkit(setPrevPageBeforeBuyingCredits("/"));
      history.push(CHECK_OUT_CDM_3_ROUTE.path);
    }
  };

  return {
    isNegativeBalance,
    isAbleToShowNegativeCreditBalanceModal,
    proceedNegativeCreditBalance,
    preprocessToRefillCreditBalance,
  };
};
