import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserAvailableCredits } from "../../../../redux/newRequestSlice";
import Styles from "./WalletBalanceInfo.module.css";
import { AppDispatch, useAppDispatch } from "../../../../redux/store";
import { getCreditStatusAsync } from "../../../../redux/creditSlide";
import { selectCiamId } from "../../../../redux/account";

const WalletBalanceInfo: FC = () => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const userAvailableCredits = useSelector(selectUserAvailableCredits);

  useEffect(() => {
    dispatchReduxToolkit(getCreditStatusAsync(ciamId));
  }, []);

  return (
    <>
      <div className={Styles.wallet_balance_wrapper}>
        <span>{t("WalletBalanceInfo_WalletBalance_Label")}:</span>
        <span className={Styles.credit_value}>{userAvailableCredits}</span>
      </div>
    </>
  );
};

export default WalletBalanceInfo;
