import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "semantic-ui-react";
import Boschicon from "../../BoschIcon/Boschicon";
import ShoppingCartDetailCheckoutContent from "../../ShoppingCart/ShoppingCartCDM3/ShoppingCartDetails/ShoppingCartDetailRightPanel/ShoppingCartDetailCheckoutContent";

import "./AddToCartSuccessModal.scss";
import RDButton from "../../RDComponents/RDButton";
import RDText from "../../RDComponents/RDText";
import { useAppDispatch } from "../../../redux/store";
import { setPrevPageBeforeBuyingCredits } from "../../../redux/shoppingCartV3Slice";

interface AddToCartSuccessModalProps {
  // type?: "warning" | "success" | "error";
  // isOpen: boolean;
  // message: string;
  // additionMessage?: string;
  // moreMessages?: string[];
  // title: string;
  // icon?: string;
  // cancelButtonText: string;
  // acceptButtonText: string;
  // isAcceptLoading?: boolean;
  onCancel: Function;
  onAccept: Function;
}

const AddToCartSuccessModal: FC<AddToCartSuccessModalProps> = (
  props: AddToCartSuccessModalProps
) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();

  const onAccept = () => {
    if (props.onAccept) {
      dispatchReduxToolkit(setPrevPageBeforeBuyingCredits());
      props.onAccept();
    }
  };

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <Fragment>
      <Modal
        dimmer={{
          className: `add-to-cart-success-modal success`,
        }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={onCancel}
        open
        size={"tiny"}
      >
        <Modal.Header>
          <Boschicon name="bosch-ic-alert-success" />
          {t("AddToCartSuccessModal_Title")}
        </Modal.Header>
        <Modal.Content>
          <ShoppingCartDetailCheckoutContent
            isEditable
            showEditShoppingCartLink={false}
            hidePaymentMethods
            hideApplyVoucher
            hideRemoveButton
          />
        </Modal.Content>
        <Modal.Actions className="buy-credit-success-actions">
          <RDButton small minWidth="maxContent" onClick={onCancel}>
            <RDText>{t("AddToCartSuccessModal_ContinueButton")}</RDText>
          </RDButton>
          <RDButton
            small
            minWidth="maxContent"
            variant="secondary"
            onClick={onAccept}
          >
            <RDText>{t("AddToCartSuccessModal_MyShoppingCartButton")}</RDText>
          </RDButton>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default AddToCartSuccessModal;
