import { RouteConfigure } from "../models/Route";
import {
  CHECK_OUT_CDM_US_ROUTE,
  DASHBOARD_REQUESTS_CDM_US_ROUTE,
  DASHBOARD_STATISTICS_CDM_US_ROUTE,
  DASHBOARD_TEAM_CDM_US_ROUTE,
  HOME_CDM_US_ROUTE,
  MY_PROFILE_CDM_US_ROUTE,
  MY_REQUESTS_CDM_US_ROUTE,
  DASHBOARD_MY_SUBSCRIPTION_CDM_US_ROUTE,
  SHOPPING_CART_CDM_US_ROUTE,
  SUBSCRIPTION_CDM_US_ROUTE,
  SUBSCRIPTION_DETAIL_CDM_US_ROUTE,
  SUPPORT_CDM_US_ROUTE,
  DASHBOARD_TEAM_CREATE_CDM_US_ROUTE,
  DASHBOARD_TEAM_EDIT_CDM_US_ROUTE,
  DASHBOARD_WALLET_CDM_US_ROUTE,
  DASHBOARD_CDM_US_ROUTE,
  WALLET_CDM_US_ROUTE,
  CONTACTS_US_CDM_US_ROUTE,
  DASHBOARD_REQUEST_DETAILS_CDM_US_ROUTE,
} from "../constants/routes/cdmUS1v0Routes.constants";

export const CDM_US_ROUTERS_CONFIGS: RouteConfigure[] = [
  CONTACTS_US_CDM_US_ROUTE,
  HOME_CDM_US_ROUTE,
  SUBSCRIPTION_CDM_US_ROUTE,
  SUBSCRIPTION_DETAIL_CDM_US_ROUTE,
  SHOPPING_CART_CDM_US_ROUTE,
  CHECK_OUT_CDM_US_ROUTE,
  DASHBOARD_REQUESTS_CDM_US_ROUTE,
  DASHBOARD_STATISTICS_CDM_US_ROUTE,
  DASHBOARD_TEAM_EDIT_CDM_US_ROUTE,
  DASHBOARD_TEAM_CREATE_CDM_US_ROUTE,
  DASHBOARD_TEAM_CDM_US_ROUTE,
  DASHBOARD_WALLET_CDM_US_ROUTE,
  DASHBOARD_MY_SUBSCRIPTION_CDM_US_ROUTE,
  DASHBOARD_CDM_US_ROUTE,
  DASHBOARD_REQUEST_DETAILS_CDM_US_ROUTE,
  WALLET_CDM_US_ROUTE,
  MY_PROFILE_CDM_US_ROUTE,
  MY_REQUESTS_CDM_US_ROUTE,
  SUPPORT_CDM_US_ROUTE,
];
