import { FC } from "react";
import Styles from "./RDConversionBar.module.css";

interface RDConversionBarProps {
  header?: React.ReactNode;
  actions?: React.ReactNode;
}

const RDConversionBar: FC<RDConversionBarProps> = ({ header, actions }) => {
  return (
    <div className={Styles.wrapper}>
      {header && <div className={Styles.title_container}>{header}</div>}
      {actions && <div className={Styles.actions_container}>{actions}</div>}
    </div>
  );
};

export default RDConversionBar;
