import { useState } from "react";

interface FieldValidationOptions {
  isRequired?: boolean;
  requiredMessage?: string;
  isNumber?: boolean;
  numberMessage?: string;
  isPhone?: boolean;
  phoneMessage?: string;
  isRestrictSpecialChars?: boolean;
  restrictSpecialCharsMessage?: string;
  isEmail?: boolean;
  emailMessage?: string;
  maxLength?: number;
  maxLengthMessage?: string;
  minLength?: number;
  minLengthMessage?: string;
  exactLength?: number;
  exactLengthMessage?: string;
}

export interface ValidateMessages {
  isValid: boolean;
  required?: string;
  number?: string;
  phone?: string;
  restrictSpecialChars?: string;
  email?: string;
  maxLength?: string;
  minLength?: string;
  exactLength?: string;
}

export const useFieldValidation = (options?: FieldValidationOptions) => {
  const [validationResult, setValidationResult] = useState<ValidateMessages>();

  const numberCharRegex = RegExp(/^\+?\d*$/);
  const specialCharForPhoneRegex = RegExp(/[!$%^&*|~=`{}[:;<>?,/@#\]]/g);
  const specialCharRegex = RegExp(/[!$%^&*()+|~=`{}[:;<>?,@#\]]/g);
  const emailRegex = RegExp(/^[a-z0-9.+-]{1,64}@[a-z0-9.]{1,64}$/i);

  const validate = (validationValue?: string): boolean => {
    if (!options) return true;

    let validateResult: ValidateMessages = { isValid: true };

    if (options.isRequired && !validationValue) {
      validateResult = {
        ...validateResult,
        required: options.requiredMessage,
        isValid: false,
      };
    }

    if (
      validationValue &&
      options.isNumber &&
      !numberCharRegex.test(validationValue)
    ) {
      validateResult = {
        ...validateResult,
        number: options.numberMessage,
        isValid: false,
      };
    }

    if (
      validationValue &&
      options.isPhone &&
      specialCharForPhoneRegex.test(validationValue)
    ) {
      validateResult = {
        ...validateResult,
        phone: options.phoneMessage,
        isValid: false,
      };
    }

    if (
      validationValue &&
      options.isRestrictSpecialChars &&
      specialCharRegex.test(validationValue)
    ) {
      validateResult = {
        ...validateResult,
        restrictSpecialChars: options.restrictSpecialCharsMessage,
        isValid: false,
      };
    }

    if (
      validationValue &&
      options.isEmail &&
      !emailRegex.test(validationValue)
    ) {
      validateResult = {
        ...validateResult,
        email: options.emailMessage,
        isValid: false,
      };
    }

    if (
      validationValue &&
      options.maxLength &&
      validationValue.length > options.maxLength
    ) {
      validateResult = {
        ...validateResult,
        maxLength: options.maxLengthMessage,
        isValid: false,
      };
    }

    if (
      validationValue &&
      options.minLength &&
      validationValue.length < options.minLength
    ) {
      validateResult = {
        ...validateResult,
        minLength: options.minLengthMessage,
        isValid: false,
      };
    }

    if (
      validationValue &&
      options.exactLength &&
      validationValue.length !== options.exactLength
    ) {
      validateResult = {
        ...validateResult,
        exactLength: options.exactLengthMessage,
        isValid: false,
      };
    }

    setValidationResult(validateResult);
    return validateResult.isValid;
  };

  return {
    validationResult,
    validate,
    specialCharRegex,
    specialCharForPhoneRegex,
    numberCharRegex,
    emailRegex,
  };
};
