import Styles from "./styles/ModalGetQRCode.module.scss";
import { Dialog } from '@bosch/react-frok';
import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
interface ModalGetQRCodeProps {
    channelArn: String,
    countryCode: String,
    onClose: Function,
    onOpen: boolean
}

const ModalGetQRCode = ({ channelArn, countryCode, onClose, onOpen }: ModalGetQRCodeProps) => {
    const openModalRef = useRef<any>();
    const { t } = useTranslation();

    let qrLink = `${process.env.REACT_APP_PCS}privateChatchannel/?channelArn=${channelArn}${(countryCode && countryCode !== "") ? `&country=${countryCode}` : ""}`;

    useEffect(() => {
        const checkIfClickedOutside = (event: MouseEvent) => {
            if (
                onOpen &&
                openModalRef.current &&
                !openModalRef.current.contains(event.target)
            ) {
                onClose();
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [onOpen]);
    return (
        <Dialog
            open
            className={Styles["dialog-modal"]}
            title="qrcode"
            variant="success"
            onClose={() => onClose()}
            ref={openModalRef}
        >
            <div className={Styles['qr-container']}>
                <p>{t("ChannelQRCodeLable")}</p>
                <QRCode
                    size={150}
                    style={{ height: "150px", width: "150px", border: "1px solid black", padding: "1rem" }}
                    value={qrLink}
                    viewBox={`0 0 150 150`}
                />
            </div>

        </Dialog >
    );
};

export default ModalGetQRCode;
