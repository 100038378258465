import { useTranslation } from "react-i18next";
import { useAdsRedirect } from "../../hooks/useAdsRedirect";
import AdsDialog from "../adsDialog/AdsDialog";

const AdsInvalidParamModal = () => {
  const { t } = useTranslation();
  const { goBackToRDDHomePage } = useAdsRedirect();

  return (
    <AdsDialog
      modal
      open
      variant="info"
      title={t("Popup_Title_Info")}
      onConfirm={() => goBackToRDDHomePage()}
      confirmLabel={t("ContinueButton")}
    >
      <p>{t("ADS_InvalidParamModal_Message1")}</p>
      <p>{t("ADS_InvalidParamModal_Message2")}</p>
    </AdsDialog>
  );
};

export default AdsInvalidParamModal;
