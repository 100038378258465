import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import { CreditPriceModel } from "../models/credit/creditPrice.model";
import {
  RequestCreditIntervalNetPriceModel,
  RequestCreditIntervalPriceModel,
  RequestCreditPriceModel,
  RequestCreditPriceWithVoucherModel,
  RequestGetCreditPackagesModel,
} from "../models/credit/request.getCreditInterval.model";
import ResponseResultBase from "../models/ResponseResultBase";
import { ShoppingCartItem } from "../models/ShoppingCart/ShoppingCartItem";
import { CreditStatus } from "../redux/accountSlide";
import { creditService } from "../services/CreditService";
import http from "../services/HttpService";
import { AppDispatch, RootState } from "./store";

export interface CreditState {
  creditPrice: CreditPriceModel | null;
}

const initialState: CreditState = {
  creditPrice: null,
};

export const getCreditIntervalPricesAsync = createAsyncThunk<
  ResponseResultBase<CreditPriceModel> | null,
  RequestCreditIntervalPriceModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "credit/getCreditIntervalPrices",
  async (model: RequestCreditIntervalPriceModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<CreditPriceModel>>(
        `/api/v1/credit/getCreditIntervalPrices?countryCode=${model.country}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getCreditIntervalWithNetPricesAsync = createAsyncThunk<
  ResponseResultBase<CreditPriceModel> | null,
  RequestCreditIntervalNetPriceModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "credit/GetCreditIntervalWithNetPrices",
  async (model: RequestCreditIntervalNetPriceModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<CreditPriceModel>>(
        `/api/v1/credit/getCreditIntervalWithNetPrices?countryCode=${model.country}&ciamId=${model.ciamId}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getCreditPriceAsync = createAsyncThunk<
  ResponseResultBase<CreditPriceModel> | null,
  RequestCreditPriceModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("credit/getCreditPrice", async (model: RequestCreditPriceModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<CreditPriceModel>>(
      `/api/v1/credit/getCreditPrice?country=${model.country}&ciamId=${model.ciamId}&numberOfCreditsToPurchase=${model.numberOfCreditsToPurchase}&languageCode=${model.languageCode}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getCreditPriceWithVoucherAsync = createAsyncThunk<
  CreditPriceModel | null,
  RequestCreditPriceWithVoucherModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "credit/getCreditPriceWithVoucher",
  async (model: RequestCreditPriceWithVoucherModel, thunkApi) => {
    try {
      const response = await creditService.getCreditPriceWithVoucher(model);
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getCreditStatusAsync = createAsyncThunk<
  ResponseResultBase<CreditStatus> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("credit/getCreditStatus", async (ciamId, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<CreditStatus>>(
      `/api/v1/credit/getCreditStatus?ciamId=${ciamId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getCreditPackagesAsync = createAsyncThunk<
  ShoppingCartItem[] | null,
  RequestGetCreditPackagesModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("/api/v1/subscriptions/GetCreditPackages", async (model, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<ShoppingCartItem[]>>(
      `/api/v1/subscriptions/GetCreditPackages`,
      model
    );
    return response.data.dataObject as ShoppingCartItem[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const selectCredit = (rootState: RootState) => rootState.credit;

export const creditSlice = createSlice({
  name: "creditReducer",
  initialState,
  reducers: {
    getCreditIntervalPricesState: (state) => {
      return { ...state };
    },
    getCreditStatusState: (state) => {
      return { ...state };
    },
  },
  extraReducers: (builder) => {},
});

export const { getCreditIntervalPricesState, getCreditStatusState } =
  creditSlice.actions;
export default creditSlice.reducer;
