import { Route, Switch } from "react-router-dom";
import {
  ADS_ALL_PROTECTED_ROUTES,
  ADS_ALL_UNPROTECTED_ROUTES,
} from "./constants/adsRoutes.constant";
import { useSelector } from "react-redux";
import { selectIsSignIn } from "../redux/auth";
import AdsNewRequest from "./pages/adsNewRequest/AdsNewRequest";
import AdsAutoLogin from "./pages/adsAutoLogin/AdsAutoLogin";

const AdsRouter = () => {
  const isLoggedIn = useSelector(selectIsSignIn);

  return (
    <Switch>
      {ADS_ALL_UNPROTECTED_ROUTES.map((route) => (
        <Route exact path={route.path} component={route.component} />
      ))}
      {isLoggedIn ? (
        ADS_ALL_PROTECTED_ROUTES.map((route, index) => (
          <Route
            key={index}
            exact
            path={route.path}
            component={route.component}
          />
        ))
      ) : (
        <AdsAutoLogin />
      )}
      <Route component={AdsNewRequest} />
    </Switch>
  );
};

export default AdsRouter;
