import { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";

const Boschicon = (props: any) => {
  const [selctedIcon, setselectedicon] = useState(0);
  const { name, style, ...data } = props;
  //Add below bosch font icon unicode and give name
  const iconconfig = [
    { name: "shopping-cart", key: "\ue247" },
    { name: "bell-plus", key: "\ue1f7" },
    { name: "alert-info", key: "\ueb0f" },
    { name: "filter", key: "\ue16e" },
    { name: "grid-view", key: "\ue190" },
    { name: "table-view", key: "\ue1c0" },
    { name: "up-small", key: "\ue6d4" },
    { name: "down-small", key: "\ue6c3" },
    { name: "up", key: "\ue296" },
    { name: "down", key: "\ue147" },
    { name: "bosch-share", key: "\ue242" },
    { name: "bosch-print", key: "\ue213" },
    { name: "bosch-delete", key: "\ue118" },
    { name: "bosch-edit", key: "\ue152" },
    { name: "bosch-add", key: "\ue087" },
    { name: "bosch-minimize", key: "\ue1ba" },
    { name: "locked", key: "\ue1c8" },
    { name: "spark", key: "\ue25d" },
    { name: "bosch-login", key: "\ue1ca" },
    { name: "bosch-logout", key: "\ue1cc" },
    { name: "bosch-forward-right", key: "\ue181" },
    { name: "bosch-close", key: "\ue0f0" },
    { name: "bosch-upload-frame", key: "\ue89f" },
    { name: "bosch-download-frame", key: "\ue893" },
    { name: "bosch-pdf-doc", key: "\ue133" },
    { name: "bosch-calendar-add", key: "\ue0c9" },
    { name: "bosch-ic-alert-warning-filled", key: "\ueb0c" },
    { name: "bosch-ic-alert-warning", key: "\ueb0d" },
    { name: "bosch-ic-alert-error", key: "\ueb09" },
    { name: "bosch-ic-alert-success", key: "\ueb0b" },
    { name: "bosch-ic-translate", key: "\ue44b" },
    { name: "bosch-ic-microphone-classic", key: "\ue1df" },
    { name: "bosch-ic-desktop", key: "\ue11b" },
    { name: "bosch-ic-video", key: "\ue2a8" },
    { name: "bosch-ic-registration", key: "\ue220" },
    { name: "bosch-ic-garage", key: "\ue597" },
    // { name: "bosch-ic-ordernumber", key: "\ue4ef" },
    { name: "bosch-ic-find-id", key: "\ue38f" },
    { name: "bosch-ic-alert-info-filled", key: "\ueb0e" },
    { name: "bosch-ic-edit", key: "\ue152" },
    { name: "bosch-ic-car-connected", key: "\ue0d5" },
    { name: "bosch-ic-car-mechanic", key: "\ue0d7" },
    { name: "bosch-ic-alert-success-filled", key: "\ueb0a" },
    { name: "bosch-ic-checkmark", key: "\ue0e9" },
    { name: "bosch-ic-paperclip", key: "\ue200" },
    { name: "bosch-ic-back-left", key: "\ue0a0" },
    { name: "bosch-ic-lock-closed", key: "\ue1c8" },
    { name: "bosch-ic-upload", key: "\ue299" },
    { name: "bosch-ic-document-pdf", key: "\ue133" },
    { name: "bosch-ic-coin", key: "\ue50e" },
    { name: "bosch-ic-user-add", key: "\ue2f7" },
    { name: "bosch-ic-car", key: "\ue0d3" },
    { name: "bosch-ic-wide-angled-arrow", key: "\ue2b9" },
    { name: "bosch-ic-truck-delivery", key: "\ue5c4" },
    { name: "bosch-ic-ticket", key: "\ue27f" },
    { name: "bosch-ic-share", key: "\ue242" },
    { name: "bosch-ic-van-delivery", key: "\ue5c5" },
    { name: "bosch-ic-search", key: "\ue235" },
    { name: "bosch-ic-home", key: "\ue19d" },
    { name: "bosch-ic-team", key: "\ue276" },
    { name: "bosch-ic-vci", key: "\ue48b" },
    { name: "bosch-ic-service-agent", key: "\ue23d" },
    { name: "bosch-ic-coin-dollar", key: "\ue0fa" },
    { name: "bosch-ic-document-copy", key: "\ue12b" },
    { name: "bosch-ic-payment", key: "\ue204" },
    { name: "bosch-ic-hourglass", key: "\ue19f" },
    { name: "bosch-ic-settings-arrows", key: "\ue240" },
    { name: "bosch-ic-thumb-up", key: "\ue27e" },
    { name: "bosch-ic-abort-frame", key: "\ue886" },
    { name: "bosch-ic-download-frame", key: "\ue893" },
    { name: "bosch-ic-money-currency-coins", key: "\ueb10" },
    { name: "bosch-ic-arrow-up-frame", key: "\ue88e" },
    { name: "bosch-ic-paperplane", key: "\ue201" },
    { name: "bosch-ic-watch-on", key: "\ue2b3" },
    { name: "bosch-ic-question-frame", key: "\ue89b" },
    { name: "bosch-ic-refresh", key: "\ue21e" },
    { name: "bosch-ic-document-copy", key: "\ue12b" },
    { name: "desktop-dashboard", key: "\ue410" },
    { name: "bosch-ic-externallink", key: "\ue167" },
    { name: "bosch-ic-service-time", key: "\ue23e" },
    { name: "bosch-ic-save", key: "\ue232" },
    { name: "bosch-ic-arrow-left", key: "\ue090" },
    { name: "bosch-ic-team-5", key: "\ue66d" },
    { name: "bosch-ic-desktop-apps", key: "\ue469" },
    { name: "bosch-ic-bar-chart-search-detail", key: "\ue3f0" },
    { name: "bosch-ic-emoji-super-happy", key: "\ue15e" },
  ];

  useEffect(() => {
    setselectedicon(iconconfig.findIndex((val) => val.name === name));
  }, [props.name]);

  return (
    <Icon className="Boschicon" {...data} style={style}>
      {iconconfig[selctedIcon]?.key || iconconfig[0].key}{" "}
    </Icon>
  );
};
export default Boschicon;
