import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import {
  calculateTotalOriginalPrice,
  // calculateCartItemPrice,
  calculateTotalPrice,
} from "../hooks/useConvertPriceToCartItemPrice";
import { calculatePrice } from "../hooks/useShoppingCartItemPrice";
import Account from "../models/Account";
import { CountryPaymentMethodsDto } from "../models/paymentAdyen/countryPaymentMethodsDto";
import { PaymentFrequency } from "../models/paymentAdyen/paymentFrequency";
import ResponseResultBase from "../models/ResponseResultBase";
import { CheckCdm3FrontendCartWithVoucherCodeRequest } from "../models/ShoppingCart/CheckCdm3FrontendCartWithVoucherCodeRequest.model";
import { CreateOrUpdateCartRequest } from "../models/ShoppingCart/CreateOrUpdateCartRequest.model";
import { DeleteCartRequest } from "../models/ShoppingCart/DeleteCartRequest.model";
import { GetCartRequest } from "../models/ShoppingCart/GetCartRequest.model";
import {
  CartItemPrice,
  ShoppingCartItem,
} from "../models/ShoppingCart/ShoppingCartItem";
import { UpdateVoucherStatusRequest } from "../models/ShoppingCart/UpdateVoucherStatusRequest.model";
import { SubscriptionV3 } from "../models/subscription/SubscriptionV3.model";
import { ServiceRequestType } from "../models/support/serviceRequestType.model";
import TrialStatus from "../models/TrialStatus";
import WholesalerBranch from "../models/WholesalerBranch";
import WholesalerOrganization from "../models/WholesalerOrganization";
import http from "../services/HttpService";
import httpNoneSecure from "../services/HttpServiceNoneSecure";
import { getAccountSimpleAsync } from "./account";
import {
  GetPaymentMethodsForCountryAsync,
  GetPaymentFrequencyListAsync,
} from "./paymentAdyenSlide";
import { AppDispatch, RootState } from "./store";
import { getDefaultCartQuantity } from "../hooks/useGetDefaultCartQuantity";

interface UpdateWorkshopInfoModel {
  workshop: Account;
  index: number;
}

export interface VoucherV3 {
  id: number;
  isCashRebate: boolean;
  isMultipleRedeemable: boolean;
  isRedeemed: boolean;
  isValid: boolean;
  wholesalerGroup: WholesalerOrganization | null;
  wholesalerBranch: WholesalerBranch | null;
  voucherType: VoucherType | null;
}

export interface VoucherType {
  id: number;
  productType: string;
}
export interface ShoppingCartV3 {
  id: number;
  masterOrgAccount: Account | null;
  childOrgAccounts: Account[];
  shoppingItems: ShoppingCartItem[];
  transactionId: string;
  adyenPaymentDetailsId: number;
  voucherCode: string;
  validVoucher: VoucherV3 | null;
  hasEsiContract: boolean | undefined; //Empty, Y or N
  ktsVersion: string;
  cartPrice: CartItemPrice | null;
  originalCartPrice: CartItemPrice | null;
  paymentFrequency: PaymentFrequency | null;
  frontendSelectedNumberOfWorkshop: number;
}

export interface ShoppingCartV3State {
  frontendCart: ShoppingCartV3;
  //Field is using for frontend logic
  // quantity: number;
  paymentMethodSelected: CountryPaymentMethodsDto | null;
  availablePaymentMethods: CountryPaymentMethodsDto[];
  paymentFrequencies: PaymentFrequency[];
  isAcceptMonthlyTerms: boolean;
  isAcceptPaymentTerms: boolean;
  isPerformStepValidation: boolean;
  isSubmitTicket: boolean;
  isCheckoutClicked: boolean;
  performCheckVoucher: boolean;
  prevPageBeforeBuyingCredits: string;
  prevPageBeforeCheckout: string;
  isRefillingCredits: boolean;
}

const initialShoppingCart: ShoppingCartV3 = {
  id: 0,
  masterOrgAccount: null,
  shoppingItems: [],
  cartPrice: null,
  originalCartPrice: null,
  childOrgAccounts: [],
  transactionId: "",
  adyenPaymentDetailsId: 0,
  voucherCode: "",
  validVoucher: null,
  hasEsiContract: undefined,
  ktsVersion: "",
  paymentFrequency: null,
  frontendSelectedNumberOfWorkshop: 0,
};

const initialState: ShoppingCartV3State = {
  frontendCart: initialShoppingCart,
  // quantity: 0,
  availablePaymentMethods: [],
  paymentMethodSelected: null,
  paymentFrequencies: [],
  isAcceptMonthlyTerms: false,
  isAcceptPaymentTerms: false,
  isPerformStepValidation: false,
  isSubmitTicket: false,
  isCheckoutClicked: false,
  performCheckVoucher: false,
  prevPageBeforeBuyingCredits: "",
  prevPageBeforeCheckout: "",
  isRefillingCredits: false,
};

export const checkCdm3FrontendCartWithVoucherCodeAsync = createAsyncThunk<
  ResponseResultBase<ShoppingCartV3> | null,
  CheckCdm3FrontendCartWithVoucherCodeRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "cart/CheckCdm3FrontendCartWithVoucherCode",
  async (request: CheckCdm3FrontendCartWithVoucherCodeRequest, thunkApi) => {
    try {
      const response = await httpNoneSecure.post<
        ResponseResultBase<ShoppingCartV3>
      >(`/api/v1/cdm3/cart/CheckCdm3FrontendCartWithVoucherCode`, request);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getCartAsync = createAsyncThunk<
  ResponseResultBase<ShoppingCartV3> | null,
  GetCartRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("cart/GetCart", async (request: GetCartRequest, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<ShoppingCartV3>>(
      `/api/v1/cdm3/cart/GetCart`,
      request
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const createOrUpdateCartAsync = createAsyncThunk<
  ResponseResultBase<ShoppingCartV3> | null,
  CreateOrUpdateCartRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "cart/CreateOrUpdateCart",
  async (request: CreateOrUpdateCartRequest, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<ShoppingCartV3>>(
        `/api/v1/cdm3/cart/createOrUpdateCart`,
        request
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const deleteCartAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  DeleteCartRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("cart/DeleteCart", async (request: DeleteCartRequest, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<boolean>>(
      `/api/v1/cdm3/cart/DeleteCart`,
      request
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const updateVoucherStatusAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  UpdateVoucherStatusRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "cart/updateVoucherStatus",
  async (request: UpdateVoucherStatusRequest, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<boolean>>(
        `/api/v1/cdm3/cart/updateVoucherStatus`,
        request
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

// const calculatePrice = (
//   price: CartItemPrice,
//   quantity: number
// ): CartItemPrice => {
//   return {
//     ...price,
//     quantity: quantity,
//     netPrice: quantity * price.unitPrice,
//     vatAmmout: (quantity * price.unitPrice * price.vatPercentage) / 100,
//     actualPrice:
//       quantity * price.unitPrice +
//       (quantity * price.unitPrice * price.vatPercentage) / 100,
//   } as CartItemPrice;
// };

export const getAllCartItems = (
  subscription: SubscriptionV3
): ShoppingCartItem[] => {
  let allCartItems: ShoppingCartItem[] = [];

  allCartItems.push({
    subscriptionId: subscription.id,
    subscriptionItem: subscription,
    addOnId: null,
    serviceTypeId: null,
    quantity: getDefaultCartQuantity(subscription),
    netPrice: {
      // dPrice: convertToCartItemPrice(
      //   subscription.subscriptionPrices,
      //   getDefaultCartQuantity(subscription)
      // ),
      mPrice: calculatePrice(
        subscription.netPrice.mPrice,
        getDefaultCartQuantity(subscription)
      ),
      yPrice: calculatePrice(
        subscription.netPrice.yPrice,
        getDefaultCartQuantity(subscription)
      ),
    },
    originalPrice: {
      mPrice: calculatePrice(
        subscription.originalPrice.mPrice,
        getDefaultCartQuantity(subscription)
      ),
      yPrice: calculatePrice(
        subscription.originalPrice.yPrice,
        getDefaultCartQuantity(subscription)
      ),
    },
  });

  const mandatoryAddOnItems = subscription.addOn
    .filter((x) => x.isMandatory)
    .map((addOn) => {
      const quantity = addOn.isMultitplyByWorkShop
        ? getDefaultCartQuantity(subscription)
        : 1;
      const cartItem: ShoppingCartItem = {
        addOnId: addOn.id,
        addOnItem: addOn,
        netPrice: {
          mPrice: calculatePrice(addOn.netPrice.mPrice, quantity),
          yPrice: calculatePrice(addOn.netPrice.yPrice, quantity),
        },
        originalPrice: {
          // dPrice: convertToCartItemPrice(
          //   addOn.price,
          //   quantity
          // ),
          mPrice: calculatePrice(addOn.originalPrice.mPrice, quantity),
          yPrice: calculatePrice(addOn.originalPrice.yPrice, quantity),
        },
        quantity: quantity,
        subscriptionId: null,
        serviceTypeId: null,
      };
      return cartItem;
    });

  allCartItems = allCartItems.concat(mandatoryAddOnItems);

  return allCartItems;
};

export const shoppingCartV3Slice = createSlice({
  name: "shoppingCartV3",
  initialState,
  reducers: {
    addSubscriptionToCart: (
      state,
      action: PayloadAction<SubscriptionV3 | null | undefined>
    ) => {
      if (!!action.payload) {
        const subscription = action.payload;
        let cartItems: ShoppingCartItem[] = getAllCartItems(subscription);

        return {
          ...initialState,
          frontendCart: {
            ...initialState.frontendCart,
            frontendSelectedNumberOfWorkshop:
              getDefaultCartQuantity(subscription),
            shoppingItems: cartItems,
            cartPrice: calculateTotalPrice(
              cartItems,
              initialState.frontendCart.paymentFrequency
            ),
            originalCartPrice: calculateTotalOriginalPrice(
              cartItems,
              initialState.frontendCart.paymentFrequency
            ),
          },
          isSubmitTicket: false,
        };
      }
      return {
        ...initialState,
      };
    },
    addAddOnToCart: (state, action: PayloadAction<ShoppingCartItem>) => {
      if (!!action.payload) {
        let cartItems: ShoppingCartItem[] = [
          ...state.frontendCart.shoppingItems,
        ];
        const existingAddOn = cartItems.find(
          (x) => x.addOnId === action.payload.addOnId
        );
        if (!!existingAddOn) {
          const index = cartItems.indexOf(existingAddOn);
          cartItems[index] = action.payload;
        } else {
          cartItems.push(action.payload);
        }

        return {
          ...state,
          frontendCart: {
            ...state.frontendCart,
            shoppingItems: cartItems,
            cartPrice: calculateTotalPrice(
              cartItems,
              state.frontendCart.paymentFrequency
            ),
            originalCartPrice: calculateTotalOriginalPrice(
              cartItems,
              state.frontendCart.paymentFrequency
            ),
          },
          isSubmitTicket: false,
        };
      }
      return { ...state };
    },
    addMoreAddOnToCart: (state, action: PayloadAction<ShoppingCartItem>) => {
      if (!!action.payload) {
        let cartItems: ShoppingCartItem[] = [
          ...state.frontendCart.shoppingItems,
        ];
        const existingAddOn = cartItems.find(
          (x) => x.addOnId === action.payload.addOnId
        );
        if (!!existingAddOn) {
          const index = cartItems.indexOf(existingAddOn);
          let currentQuantity = cartItems[index].quantity;
          if (
            !!existingAddOn.addOnItem &&
            existingAddOn.addOnItem.isMultitplyByWorkShop
          ) {
            currentQuantity = 0;
          }
          cartItems[index] = {
            ...action.payload,
            quantity: currentQuantity + 1,
            netPrice: {
              // dPrice: calculateCartItemPrice(
              //   action.payload.netPrice.dPrice,
              //   currentQuantity + 1
              // ),
              mPrice: calculatePrice(
                action.payload.netPrice.mPrice,
                currentQuantity + 1
              ),
              yPrice: calculatePrice(
                action.payload.netPrice.yPrice,
                currentQuantity + 1
              ),
            },
            originalPrice: {
              mPrice: calculatePrice(
                action.payload.originalPrice.mPrice,
                currentQuantity + 1
              ),
              yPrice: calculatePrice(
                action.payload.originalPrice.yPrice,
                currentQuantity + 1
              ),
            },
          };
        } else {
          cartItems.push(action.payload);
        }

        return {
          ...state,
          frontendCart: {
            ...state.frontendCart,
            shoppingItems: cartItems,
            cartPrice: calculateTotalPrice(
              cartItems,
              state.frontendCart.paymentFrequency
            ),
            originalCartPrice: calculateTotalOriginalPrice(
              cartItems,
              state.frontendCart.paymentFrequency
            ),
          },
          isSubmitTicket: false,
        };
      }
      return { ...state };
    },
    removeAddOnFromCart: (state, action: PayloadAction<number>) => {
      if (!!action.payload) {
        let cartItems: ShoppingCartItem[] = [
          ...state.frontendCart.shoppingItems,
        ];
        cartItems = cartItems.filter((x) => x.addOnId !== action.payload);
        return {
          ...state,
          frontendCart: {
            ...state.frontendCart,
            shoppingItems: cartItems,
            cartPrice: calculateTotalPrice(
              cartItems,
              state.frontendCart.paymentFrequency
            ),
            originalCartPrice: calculateTotalOriginalPrice(
              cartItems,
              state.frontendCart.paymentFrequency
            ),
          },
        };
      }

      return { ...state };
    },
    addServiceTypeCartItem: (
      state,
      action: PayloadAction<ServiceRequestType>
    ) => {
      if (!!action.payload) {
        const quantity = 1;
        const cartItems: ShoppingCartItem[] = [
          {
            addOnId: null,
            subscriptionId: null,
            quantity: quantity,
            netPrice: {
              mPrice: calculatePrice(action.payload.payAsYouGoPrice, quantity),
              yPrice: calculatePrice(action.payload.payAsYouGoPrice, quantity),
            },
            originalPrice: {
              mPrice: calculatePrice(action.payload.payAsYouGoPrice, quantity),
              yPrice: calculatePrice(action.payload.payAsYouGoPrice, quantity),
            },
            serviceTypeId: action.payload.id,
            serviceTypeObject: action.payload,
            serviceTypePartNumber: action.payload.partNumber,
          },
        ];

        return {
          ...state,
          frontendCart: {
            ...state.frontendCart,
            shoppingItems: cartItems,
            cartPrice: calculateTotalPrice(
              cartItems,
              state.frontendCart.paymentFrequency
            ),
            originalCartPrice: calculateTotalOriginalPrice(
              cartItems,
              state.frontendCart.paymentFrequency
            ),
          },
          isSubmitTicket: true,
        };
      }
      return { ...state };
    },
    removeServiceTypeCartItem: (state) => {
      let cartItems: ShoppingCartItem[] = [...state.frontendCart.shoppingItems];
      cartItems = cartItems.filter((x) => !x.serviceTypeId);
      return {
        ...state,
        frontendCart: {
          ...state.frontendCart,
          shoppingItems: cartItems,
          cartPrice: calculateTotalPrice(
            cartItems,
            state.frontendCart.paymentFrequency
          ),
          originalCartPrice: calculateTotalOriginalPrice(
            cartItems,
            state.frontendCart.paymentFrequency
          ),
        },
      };
    },
    setHasEsiContract: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        frontendCart: {
          ...state.frontendCart,
          hasEsiContract: action.payload,
        },
      };
    },
    setKtsVersion: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        frontendCart: {
          ...state.frontendCart,
          ktsVersion: action.payload,
        },
      };
    },
    updateQuantity: (state, action: PayloadAction<number>) => {
      const shoppingCartItems: ShoppingCartItem[] = [
        ...state.frontendCart.shoppingItems,
      ];
      for (let i = 0; i < shoppingCartItems.length; i++) {
        if (
          !!shoppingCartItems[i].subscriptionItem ||
          // (!!shoppingCartItems[i]?.addOnItem?.isMultitplyByWorkShop &&
          //   !!shoppingCartItems[i]?.addOnItem?.isMandatory) ||
          !!shoppingCartItems[i]?.addOnItem?.isMultitplyByWorkShop
        ) {
          const mPrice = calculatePrice(
            shoppingCartItems[i].netPrice.mPrice,
            action.payload
          );
          const yPrice = calculatePrice(
            shoppingCartItems[i].netPrice.yPrice,
            action.payload
          );
          let originalPrice = shoppingCartItems[i].originalPrice;
          if (!!originalPrice) {
            originalPrice = {
              mPrice: calculatePrice(originalPrice.mPrice, action.payload),
              yPrice: calculatePrice(originalPrice.yPrice, action.payload),
            };
          }

          shoppingCartItems[i] = {
            ...shoppingCartItems[i],
            quantity: action.payload,
            netPrice: {
              // dPrice: dPrice,
              mPrice: mPrice,
              yPrice: yPrice,
            },
            originalPrice: originalPrice,
          };
        }
      }
      return {
        ...state,
        frontendCart: {
          ...state.frontendCart,
          frontendSelectedNumberOfWorkshop: action.payload,
          shoppingItems: shoppingCartItems,
          cartPrice: calculateTotalPrice(
            shoppingCartItems,
            state.frontendCart.paymentFrequency
          ),
          originalCartPrice: calculateTotalOriginalPrice(
            shoppingCartItems,
            state.frontendCart.paymentFrequency
          ),
        },
      };
    },
    updatePaymentMethodSelected: (
      state,
      action: PayloadAction<CountryPaymentMethodsDto | null>
    ) => {
      return {
        ...state,
        paymentMethodSelected: action.payload,
      };
    },
    updatePaymentFrequencySelected: (
      state,
      action: PayloadAction<PaymentFrequency | null>
    ) => {
      return {
        ...state,
        frontendCart: {
          ...state.frontendCart,
          paymentFrequency: action.payload,
        },
      };
    },
    updateIsAcceptMonthlyTerms: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAcceptMonthlyTerms: action.payload,
      };
    },
    updateIsAcceptPaymentTerms: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAcceptPaymentTerms: action.payload,
      };
    },
    addOrRemoveWorkshops: (state) => {
      const subscriptionItem = state.frontendCart.shoppingItems.find(
        (x) => !!x.subscriptionId
      );
      if (!!subscriptionItem) {
        const quantity = subscriptionItem.quantity || 0;
        let workshops: Account[] = [
          ...state.frontendCart.childOrgAccounts,
        ] as Account[];
        const adjustedQuantity = quantity - 1;
        if (workshops.length < adjustedQuantity) {
          const missingWorkshopNumber = adjustedQuantity - workshops.length;
          for (let i = 0; i < missingWorkshopNumber; i++) {
            workshops.push({
              id: 0,
              name1: "",
              address1: "",
              city: "",
              postCode: "",
              contactFirstName: "",
              contactEmail: "",
              companyPhone: "",
              countryCode: "",
              address2: "",
              availableCredits: null,
              cdbId: 0,
              ciamEmail: "",
              ciamId: "",
              companyCountry: "",
              companyEmail: "",
              contactLastName: "",
              employee: null,
              employees: [],
              hardware: [],
              isActive: true,
              mobliePhone: "",
              name2: "",
              privateHint: false,
              privateHintLastChanged: null,
              subscription: null,
              termAndConditions: false,
              termsAndConditionsLastChanged: null,
              transactions: [],
              trialStatus: TrialStatus.None,
              userRole: null,
              vat: "",
              allowPayViaAccount: false,
              wholesalerBranch: null,
              wholesalerBranchId: null,
              wholesalerGroup: null,
              wholesalerOrganizationId: null,
            });
          }
        } else if (workshops.length > adjustedQuantity) {
          const missingWorkshopNumber = workshops.length - adjustedQuantity;
          for (let i = 0; i < missingWorkshopNumber; i++) {
            workshops.splice(-1);
          }
        }

        return {
          ...state,
          frontendCart: {
            ...state.frontendCart,
            childOrgAccounts: workshops,
          },
        };
      }

      return {
        ...state,
      };
    },
    updateWorkshopInfo: (
      state,
      action: PayloadAction<UpdateWorkshopInfoModel>
    ) => {
      if (action.payload.index >= 0) {
        const workshops = [...state.frontendCart.childOrgAccounts];
        workshops[action.payload.index] = action.payload.workshop;
        return {
          ...state,
          frontendCart: {
            ...state.frontendCart,
            childOrgAccounts: workshops,
          },
        };
      }

      const masterOrgAccount: Account = {
        ...state.frontendCart.masterOrgAccount,
        sdiCode: action.payload.workshop.sdiCode,
        fiscalCode: action.payload.workshop.fiscalCode,
      } as Account;
      return {
        ...state,
        frontendCart: {
          ...state.frontendCart,
          masterOrgAccount: masterOrgAccount,
        },
      };
    },
    updateIsPerformStepValidation: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isPerformStepValidation: action.payload,
      };
    },
    updateIsSubmitTicket: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isSubmitTicket: action.payload,
      };
    },
    updateIsCheckoutClicked: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isCheckoutClicked: action.payload,
      };
    },
    replaceCart: (state, action: PayloadAction<ShoppingCartV3>) => {
      // const shoppingCart = action.payload as ShoppingCartV3State;
      // return {
      //   ...shoppingCart,
      //   quantity: state.quantity,
      //   paymentMethodSelected: state.paymentMethodSelected,
      //   availablePaymentMethods: state.availablePaymentMethods,
      //   paymentFrequencies: state.paymentFrequencies,
      //   paymentFrequencySelected: state.paymentFrequencySelected,
      //   isAcceptMonthlyTerms: state.isAcceptMonthlyTerms,
      //   isPerformStepValidation: state.isPerformStepValidation,
      // };
      return {
        ...state,
        frontendCart: action.payload,
      };
    },
    clearCart: (state) => {
      return {
        ...initialState,
        prevPageBeforeBuyingCredits: state.prevPageBeforeBuyingCredits,
        prevPageBeforeCheckout: state.prevPageBeforeCheckout,
      };
    },
    executeCheckVoucher: (state) => {
      return {
        ...state,
        performCheckVoucher: !state.performCheckVoucher,
      };
    },
    setPrevPageBeforeBuyingCredits: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      return {
        ...state,
        prevPageBeforeBuyingCredits: action.payload || window.location.pathname,
      };
    },
    setPrevPageBeforeCheckout: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      return {
        ...state,
        prevPageBeforeCheckout: action.payload || window.location.pathname,
      };
    },
    setRefillingCredits: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isRefillingCredits: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        GetPaymentMethodsForCountryAsync.fulfilled,
        (state, { payload }) => {
          if (
            payload != null &&
            payload.httpStatusCode === 200 &&
            payload.dataObject
          ) {
            return {
              ...state,
              availablePaymentMethods: [...payload.dataObject],
            };
          }
        }
      )
      .addCase(GetPaymentFrequencyListAsync.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          const paymentFrequencies = [...payload.dataObject].sort(
            (ob1, ob2) => {
              return ob1.id < ob2.id ? 1 : -1;
            }
          );
          return {
            ...state,
            paymentFrequencies: paymentFrequencies,
          };
        }
      })
      .addCase(getAccountSimpleAsync.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          const account: Account = payload.dataObject;
          let mainWorkshop: Account = {
            ...account,
            // sdiCode: account.sdiCode || state.frontendCart.masterOrgAccount?.sdiCode
            // id: 0,
            // name: account.name1,
            // address: account.address1,
            // postCode: account.postCode,
            // city: account.city,
            // country: account.countryCode,
            // contactName: `${account.contactFirstName} ${account.contactLastName}`,
            // contactEmail: account.contactEmail,
            // contactPhoneNumber: account.companyPhone,
          };

          return {
            ...state,
            frontendCart: {
              ...state.frontendCart,
              masterOrgAccount: mainWorkshop,
            },
          };
        }
      });
    // .addCase(createOrUpdateCartAsync.fulfilled, (state, { payload }) => {
    //   if (
    //     payload != null &&
    //     payload.httpStatusCode == 200 &&
    //     payload.dataObject
    //   ) {
    //     const shoppingCart = payload.dataObject as ShoppingCartV3State;
    //     return {
    //       ...shoppingCart,
    //       quantity: state.quantity,
    //       paymentMethodSelected: state.paymentMethodSelected,
    //       availablePaymentMethods: state.availablePaymentMethods,
    //       paymentFrequencies: state.paymentFrequencies,
    //       paymentFrequencySelected: state.paymentFrequencySelected,
    //       isAcceptMonthlyTerms: state.isAcceptMonthlyTerms,
    //       isPerformStepValidation: state.isPerformStepValidation,
    //     };
    //   }
    // });
  },
});
export const selectShoppingCart = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart;
export const selectShoppingCartItems = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.shoppingItems;
export const selectCartQuantity = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.shoppingItems.find(
    (x) => !!x.subscriptionId
  )?.quantity || 0;
export const selectHasEsiContract = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.hasEsiContract;
export const selectKtsVersion = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.ktsVersion;
export const selectAvailablePaymentMethods = (rootState: RootState) =>
  rootState.shoppingCartV3.availablePaymentMethods;
export const selectPaymentFrequencies = (rootState: RootState) =>
  rootState.shoppingCartV3.paymentFrequencies;
export const selectPaymentFrequencySelected = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.paymentFrequency;
export const selectPaymentMethodSelected = (rootState: RootState) =>
  rootState.shoppingCartV3.paymentMethodSelected;
export const selectIsAcceptMonthlyTerms = (rootState: RootState) =>
  rootState.shoppingCartV3.isAcceptMonthlyTerms;
export const selectIsAcceptPaymentTerms = (rootState: RootState) =>
  rootState.shoppingCartV3.isAcceptPaymentTerms;
export const selectShoppingCartWorkshops = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.childOrgAccounts;
export const selectIsPerformStepValidation = (rootState: RootState) =>
  rootState.shoppingCartV3.isPerformStepValidation;
export const selectShoppingCartVoucherCode = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.voucherCode;
export const selectShoppingCartTotalPrice = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.cartPrice;
export const selectMasterOrgAccount = (rootState: RootState) =>
  rootState.shoppingCartV3.frontendCart.masterOrgAccount as Account;
export const selectIsSubmitTicket = (rootState: RootState) =>
  rootState.shoppingCartV3.isSubmitTicket;
export const selectIsCheckoutClicked = (rootState: RootState) =>
  rootState.shoppingCartV3.isCheckoutClicked;
export const selectPerformCheckVoucher = (rootState: RootState) =>
  rootState.shoppingCartV3.performCheckVoucher;
export const selectPrevPageBeforeBuyingCredit = (rootState: RootState) =>
  rootState.shoppingCartV3.prevPageBeforeBuyingCredits;
export const selectPrevPageBeforeCheckout = (rootState: RootState) =>
  rootState.shoppingCartV3.prevPageBeforeCheckout;
export const selectIsRefillingCredits = (rootState: RootState) =>
  rootState.shoppingCartV3.isRefillingCredits;
export const {
  addSubscriptionToCart,
  setHasEsiContract,
  setKtsVersion,
  updateQuantity,
  updatePaymentMethodSelected,
  updatePaymentFrequencySelected,
  updateIsAcceptMonthlyTerms,
  updateIsAcceptPaymentTerms,
  addAddOnToCart,
  addMoreAddOnToCart,
  addServiceTypeCartItem,
  removeServiceTypeCartItem,
  addOrRemoveWorkshops,
  updateWorkshopInfo,
  removeAddOnFromCart,
  updateIsPerformStepValidation,
  updateIsSubmitTicket,
  updateIsCheckoutClicked,
  replaceCart,
  clearCart,
  executeCheckVoucher,
  setPrevPageBeforeBuyingCredits,
  setPrevPageBeforeCheckout,
  setRefillingCredits,
} = shoppingCartV3Slice.actions;
export default shoppingCartV3Slice.reducer;
