import React, { FC, Fragment, useState } from "react";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { ReactComponent as Profile } from "../../../assets/svg/profile.svg";
import { selectIsSignIn } from "../../../redux/auth";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./AccountButtonCDMUS.css";
import Boschicon from "../../BoschIcon/Boschicon";
import { ReactComponent as Coins } from "../../../assets/svg/coins.svg";
import { useUserSubscription } from "../../../hooks/useUserSubscription";
import { useUserRole } from "../../../hooks/useUserRole";
import RDAlert from "../../RDComponents/RDAlert";
import { useRouting } from "../../../hooks/useRouting";

interface AccountButtonCDMUSProps {
  isMobile: boolean;
  style?: Object;
  className: string;
  login: Function;
  logout: Function;
  changeCompany: Function;
}
const AccountButtonCDMUS: FC<AccountButtonCDMUSProps> = (props) => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsSignIn);
  const { isManager } = useUserRole();
  const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false);
  const [isLogoutExecuting, setIsLogoutExecuting] = useState(false);
  const { checkUserIsMapped, checkUserMultipleWorkshop } =
    useUserSubscription();
  const { isWalletAndMyProductsRouteAccessible } = useRouting();

  const options = [
    {
      key: "dashboard",
      text: t("MenuItem_MyRemote"),
      value: "dashboard",
      icon: <Boschicon name="desktop-dashboard" />,
      isCDM2: false,
      isCDM3Hidden: !checkUserIsMapped(),
      as: Link,
      to: "/dashboard",
    },
    {
      key: "Myprofile",
      text: t("MenuItem_MyBosch"),
      value: "Myprofile",
      icon: <Profile style={{ width: "1.5rem", marginRight: "0.8125rem" }} />,
      isCDM2: true,
      as: Link,
      to: "/myprofile",
    },
    {
      key: "company",
      text: t("ChangeCompanyModal_Title"),
      value: "company",
      icon: <Boschicon name="bosch-ic-garage" />,
      isCDM2: false,
      isCDM3Hidden: !checkUserMultipleWorkshop() || !isManager,
    },
    {
      key: "wallet",
      text: t("SettingsMyWallet_Headline"),
      value: "wallet",
      isCDM2: false,
      isCDM3Hidden: !isWalletAndMyProductsRouteAccessible(),
      icon: <Coins style={{ width: "1.5rem", marginRight: "0.8125rem" }} />,
      as: Link,
      to: "/wallet",
    },
    {
      key: "log_out",
      icon: <Boschicon name="bosch-logout" />,
      text: t("AccountButton_LogoutButton"),
      isCDM2: true,
      value: "log_out",
    },
  ];

  const handleSelection = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) => {
    if (data.value === "company") {
      props.changeCompany();
    }
    if (data.value === "log_out") {
      setIsOpenLogOutModal(true);
    }
  };

  if (isLoggedIn) {
    return (
      <Fragment>
        <Button basic className={props.className}>
          <Dropdown
            className="login-account-dropdown"
            text=" "
            options={options.filter((x: any) => !x.isCDM3Hidden)}
            icon={
              <Icon>
                <Profile />
              </Icon>
            }
            onChange={handleSelection}
            value=""
            data-testid="login-account-dropdown"
          />
        </Button>
        {isOpenLogOutModal ? (
          <RDAlert
            type="warning"
            isOpen={true}
            messages={[t("AccountButton_LogoutModal_Content")]}
            acceptButtonText={t("Header_YesButton")}
            onAccept={() => {
              setIsLogoutExecuting(true);
              props.logout();
            }}
            cancelButtonText={t("Header_NoButton")}
            onCancel={() => setIsOpenLogOutModal(false)}
            isAcceptLoading={isLogoutExecuting}
          />
        ) : (
          <></>
        )}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Button
          className={props.className}
          style={props.style}
          basic
          onClick={() => props.login()}
          icon={
            <Icon style={{ verticalAlign: "sub" }}>
              <Profile />
            </Icon>
          }
        ></Button>
      </Fragment>
    );
  }
};

export default AccountButtonCDMUS;
