export enum RequestType {
  None = 0,
  RemoteDiagnosticRequest = 1,
  TechnicalRequest = 2,
  VHCRequest = 3,
}

export enum RequestBookingType {
  _ = "",
  QUEUED_REQUEST = "QUEUED_REQUEST",
  SCHEDULED_REQUEST = "SCHEDULED_REQUEST",
}
