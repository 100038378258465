import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, HeaderProps, Icon, Input } from "semantic-ui-react";
import "./SearchField.scss";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete.svg";
import Interweave from "interweave";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useGlobalSearch } from "../../hooks/useGlobalSearch";
import { useGTMAnalyticTracker } from "../../hooks/useGTMAnalyticTracker";

const SearchField: FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { addAnalyticEvent } = useGTMAnalyticTracker();
  const { searchGlobalData, goToSearchResultsPage } = useGlobalSearch();
  const [searchKey, setSearchKey] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const searchInputRef = useRef<Input | null>(null);

  useEffect(() => {
    if (!props.showResults) return;

    searchInputRef.current?.focus();
  }, [props.showResults]);

  const handleSearchValueChange = (event: any) => {
    setSearchKey(event.target.value);
    getSearchResults(event.target.value);
  };

  const addInternalSearchGTMEvent = () => {
    const searchResults = searchGlobalData(searchKey);
    addAnalyticEvent("internal_search", {
      search_term: searchKey,
      search_results: searchResults.length,
    });
  };

  const handleSearch = () => {
    addInternalSearchGTMEvent();
    goToSearchResultsPage(searchKey);
    closeSearch();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") handleSearch();
  };

  const getFormattedResult = (key: string, result: string) => {
    const reg = new RegExp(key, "gi");

    const formattedResult = result.replace(reg, (str) => {
      return `<b>${str}</b>`;
    });

    return formattedResult;
  };

  const getSearchResults = (key: string) => {
    let filteredResults = searchGlobalData(key).map((element, index) => (
      <div
        className="search-suggestion-container"
        key={index}
        data-testid="global-search-result-suggestion"
      >
        <div
          className="search-suggestion"
          onClick={() => {
            goToPage(element);
          }}
        >
          <Interweave content={getFormattedResult(key, element.title)} />
        </div>
      </div>
    ));

    // Get maximum 3 results
    filteredResults = filteredResults.slice(0, 3);

    setSearchResults(filteredResults);
  };

  const goToPage = (routeElement: any) => {
    addInternalSearchGTMEvent();
    history.push(routeElement.path);
  };

  const closeSearch = () => {
    setSearchKey("");

    if (props.emitCloseEvent) {
      props.closeSearch();
    }
  };

  return (
    <Fragment>
      <div
        className={`
          ${
            props.footerMode
              ? "footer-input-container grid-responsive-width"
              : "input-container grid-responsive-width"
          } grid-responsive-width-CDM3-search`}
      >
        <Input
          placeholder={t("FooterSearch_SearchFieldPlaceholder")}
          onKeyPress={handleKeyPress}
          onChange={handleSearchValueChange}
          value={searchKey}
          className="search-input"
          data-testid="global-search-input"
          ref={searchInputRef}
        />
        <div className="search-field__buttons">
          <Button
            className="input-button search-field__clear-button"
            onClick={closeSearch}
          >
            <Icon>
              <DeleteIcon />
            </Icon>
          </Button>
          <Button
            className="input-button search-field__search-button"
            onClick={handleSearch}
          >
            <Icon className="search-icon">
              <SearchIcon />
            </Icon>
          </Button>
        </div>
      </div>

      {searchKey !== "" && props.showResults && (
        <div className="search-result-background">
          <div className="search-result-container">
            {searchResults}

            <div
              className="all-results-suggestion"
              onClick={() => {
                handleSearch();
                closeSearch();
              }}
            >
              {t("SearchField_AllResults")} {">"}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SearchField;
