import axios from "axios";
import { Log, SignoutResponse, User, UserManager } from "oidc-client-ts";
import RedirectHelper from "../utils/helpers/RedirectHelper";
import { AccountProfile } from "../redux/accountSlide";
import { AuthState, initialState } from "../redux/auth";
import { Region } from "../models/RegionState";
import { RBGeolocation } from "../models/geolocation/RBGeolocation";
import { Constants } from "../utils/helpers/Constants";
import { isAdsApp } from "../utils/routing.util";
import { ADS_SIGN_IN_CALLBACK_ROUTE } from "../ads/constants/adsRoutes.constant";

export interface CIAMCallbackData {
  redirectUrl: string;
  guid?: string;
}
export class AuthService {
  private static instance: AuthService;

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  public userManagerLogin: UserManager;
  public userManagerEditProfile: UserManager;
  public userDeleteUserProfile: UserManager;
  constructor() {
    const authState = AuthService.getAuthState();
    const clientRoot = RedirectHelper.getClientRoot(authState);
    const keycloakClientScope =
      "profile-dataprotectionnotice-" +
      authState.countryCode.trim().toLowerCase();
    const settings = {
      authority: String(process.env.REACT_APP_DEFAULT_AUTHORITY),
      client_id: String(process.env.REACT_APP_DEFAULT_CLIENTID),
      //client_id: process.env.REACT_APP_DEFAULT_CLIENTID_BOSCH_EMPLOYEE_LOGIN,
      //redirect_uri: `${clientRoot}signin-callback.html`,
      redirect_uri: `${clientRoot}${
        isAdsApp() ? ADS_SIGN_IN_CALLBACK_ROUTE.path : "/signincallback"
      }`,
      silent_redirect_uri: `${clientRoot}/silent-renew.html`,
      popup_redirect_uri: `${clientRoot}/signincallbackpopup`,
      // tslint:disable-next-line:object-literal-sort-keys
      automaticSilentRenew: true,
      post_logout_redirect_uri: `${clientRoot}/signoutcallback`,
      response_type: "code",
      scope: "openid profile " + keycloakClientScope,
      staleStateAgeInSeconds: 10000,
      extraQueryParams: {
        kc_idp_hint: String(process.env.REACT_APP_IDPHINT_LOGIN),
      },
    };
    this.userManagerLogin = new UserManager(settings);

    const settingsProfile = {
      authority: String(process.env.REACT_APP_DEFAULT_AUTHORITY),
      client_id: String(process.env.REACT_APP_CLIENTID_EDITPROFILE),
      //redirect_uri: `${clientRoot}signin-callback.html`,
      redirect_uri: `${clientRoot}/editprofilecallback`,
      // tslint:disable-next-line:object-literal-sort-keys
      automaticSilentRenew: false,
      post_logout_redirect_uri: `${clientRoot}/signoutcallback`,
      response_type: "code",
      scope: process.env.REACT_APP_DEFAULT_CLIENTSCOPE,
      staleStateAgeInSeconds: 10000,
      extraQueryParams: {
        kc_idp_hint: String(process.env.REACT_APP_IDPHINT_EDITPROFILE),
      },
    };
    this.userManagerEditProfile = new UserManager(settingsProfile);
    const settingsDeleteAccount = {
      authority: String(process.env.REACT_APP_DEFAULT_AUTHORITY),
      client_id: "account-console",
      //redirect_uri: `${clientRoot}signin-callback.html`,
      redirect_uri: `${clientRoot}/signincallback`,
      // tslint:disable-next-line:object-literal-sort-keys
      automaticSilentRenew: false,
      post_logout_redirect_uri: `${clientRoot}/signoutcallback`,
      response_type: "code",
      scope: process.env.REACT_APP_DEFAULT_CLIENTSCOPE,
      staleStateAge: 10000,
      extraQueryParams: {
        kc_idp_hint: "ciam-stage",
        kc_action: "delete_account",
      },
    };
    this.userDeleteUserProfile = new UserManager(settingsDeleteAccount);

    Log.setLogger(console);
    Log.setLevel(Log.INFO);
  }

  public getUser(): Promise<User | null> {
    return this.userManagerLogin.getUser();
  }

  public getUserEditProfile(): Promise<User | null> {
    return this.userManagerEditProfile.getUser();
  }

  public async loginAsync(
    dataRedirect?: CIAMCallbackData | undefined
  ): Promise<void> {
    this.userManagerLogin.clearStaleState();
    return !!dataRedirect
      ? await this.userManagerLogin.signinRedirect({ state: dataRedirect })
      : await this.userManagerLogin.signinRedirect();
  }

  public async loginPopupAsync(
    dataRedirect?: CIAMCallbackData | undefined
  ): Promise<User> {
    return this.userManagerLogin.signinPopup();
  }

  // public async loginAsync(redirectPath: string = ""): Promise<void> {
  //   return await this.userManagerLogin.signinRedirect({ data: redirectPath });
  // }

  // public loginCallback(): Promise<User> {
  //   return this.userManager.signinRedirectCallback();
  // }

  public async loginCallbackAsync(): Promise<User> {
    return await this.userManagerLogin.signinRedirectCallback();
  }

  public async loginPopupCallbackAsync(): Promise<void> {
    return await this.userManagerLogin.signinPopupCallback().catch(() => {
      localStorage.setItem(Constants.EXECUTE_LOGIN_POPUP, "1");
      window.close();
    });
  }

  public renewToken(): Promise<User | null> {
    return this.userManagerLogin.signinSilent();
  }

  public renewTokenEditProfile(): Promise<User | null> {
    return this.userManagerEditProfile.signinSilent();
  }

  public logoutAsync(): Promise<void> {
    return this.userManagerLogin.signoutRedirect();
  }

  public async logoutCallbackAsync(): Promise<SignoutResponse> {
    return await this.userManagerLogin.signoutRedirectCallback();
  }

  public async editProfileAsync(redirectPath: string = ""): Promise<void> {
    return await this.userManagerEditProfile.signinRedirect({
      state: redirectPath,
    });
  }

  public async editProfileCallbackAsync(): Promise<User> {
    return await this.userManagerEditProfile.signinRedirectCallback();
  }

  public async clearLoginUserState(): Promise<void> {
    await this.userManagerLogin.clearStaleState();
    await this.userManagerLogin.removeUser();
    await this.userManagerEditProfile.clearStaleState();
    return this.userManagerEditProfile.removeUser();
  }

  public getSimpleAccount(ciamid: string) {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API_KEY}/api/v1/account/GetAccountSimple/${ciamid}`,
      responseType: "stream",
    });
  }

  public createAccount(account: AccountProfile) {
    return axios.post(
      `${process.env.REACT_APP_API_KEY}/api/v1/account`,
      account
    );
  }

  public static getAuthState(): AuthState {
    const regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : "REGIONS_STORE";
    let auth = initialState;
    if (!!localStorage.getItem(regionStorageKey))
      auth = JSON.parse(localStorage.getItem(regionStorageKey) as string);
    return auth;
  }

  public static setAuthState(auth: AuthState) {
    let regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : "REGIONS_STORE";
    localStorage.setItem(regionStorageKey, JSON.stringify(auth));
  }

  public static removeAuthState() {
    let regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : "REGIONS_STORE";
    localStorage.removeItem(regionStorageKey);
  }
  public static getRegionsLanguages(): Region[] {
    const regionLanguagesStorageKey = process.env.REACT_APP_REGIONLANGUAGES
      ? process.env.REACT_APP_REGIONLANGUAGES
      : "REGIONSLANGUAGES_STORE";
    let regionLanguages: Region[] = [];
    if (!!localStorage.getItem(regionLanguagesStorageKey))
      regionLanguages = JSON.parse(
        localStorage.getItem(regionLanguagesStorageKey) as string
      );
    return regionLanguages;
  }

  public static setRegionsLanguages(regionlangues: Region[]) {
    let regionLanguagesStorageKey = process.env.REACT_APP_REGIONLANGUAGES
      ? process.env.REACT_APP_REGIONLANGUAGES
      : "REGIONS_STORE";
    localStorage.setItem(
      regionLanguagesStorageKey,
      JSON.stringify(regionlangues)
    );
  }

  public static removeRegionsLanguages() {
    let regionLanguagesStorageKey = process.env.REACT_APP_REGIONLANGUAGES
      ? process.env.REACT_APP_REGIONLANGUAGES
      : "REGIONS_STORE";
    localStorage.removeItem(regionLanguagesStorageKey);
  }

  public static setGeolocation(geolocation: RBGeolocation) {
    let geolocationKey = process.env.REACT_APP_GEOLOCATION
      ? process.env.REACT_APP_GEOLOCATION
      : "REDI_GEOLOCATION_EMPTY";
    localStorage.setItem(geolocationKey, JSON.stringify(geolocation));
  }

  public static getGeolocation(): RBGeolocation | null {
    const geolocationKey = process.env.REACT_APP_GEOLOCATION
      ? process.env.REACT_APP_GEOLOCATION
      : "REDI_GEOLOCATION_EMPTY";
    let geolocation: RBGeolocation | null = null;
    if (!!localStorage.getItem(geolocationKey))
      geolocation = JSON.parse(localStorage.getItem(geolocationKey) as string);
    return geolocation;
  }

  public static removeGeolocation() {
    let geolocationKey = process.env.REACT_APP_GEOLOCATION
      ? process.env.REACT_APP_GEOLOCATION
      : "REDI_GEOLOCATION_EMPTY";
    localStorage.removeItem(geolocationKey);
  }
}
