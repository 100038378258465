import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Image } from "semantic-ui-react";
import JourneyImage from "../../assets/CDM-Journey-Regions.png";
import style from "./LandingPageServiceWork.module.css";
import { ReactComponent as RBPortrait } from "../../assets/svg/robert-bosch-portrait-brille.svg";

const LandingPageServiceWork = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Grid className="computer tablet only">
        <Grid.Column className={`${style.service_work}`}>
          <Grid.Row className={`${style.journey_image}`}>
            <Image src={JourneyImage} />
          </Grid.Row>
          <Grid.Row className={`${style.service_work_title}`}>
            <Grid.Column>
              <p>
                <span className={`${style.step_title}`}>
                  {t("LandingServiceWorkCDM3_Title_RDS500")}
                </span>{" "}
                {t("LandingServiceWorkCDM3_Title_RDS500Desc")}{" "}
              </p>
            </Grid.Column>
            <Grid.Column> </Grid.Column>
            <Grid.Column>
              <p>
                <span className={`${style.step_title}`}>
                  {t("LandingServiceWorkCDM3_Title_OpenRequest")}
                </span>{" "}
                {t("LandingServiceWorkCDM3_Title_OpenRequestDesc")}{" "}
              </p>
            </Grid.Column>
            <Grid.Column> </Grid.Column>
            <Grid.Column>
              <p>
                <span className={`${style.step_title}`}>
                  {t("LandingServiceWorkCDM3_Title_Expert")}
                </span>{" "}
                {t("LandingServiceWorkCDM3_Title_ExpertDesc")}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column className={`${style.portrait} computer tablet only`}>
          <RBPortrait />
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default LandingPageServiceWork;
