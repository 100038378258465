import { FC, Fragment } from "react";
import { Button, Icon } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as ShoppingCart } from "../../assets/svg/shoppingCart.svg";
import "../../translations/i18n";
import { Constants } from "../../utils/helpers/Constants";
import RDBadge from "../RDComponents/RDBadge";
import style from "./HeaderCartButton.module.css";
import { useCartCount } from "../../hooks/useCartCount";
import { useAppDispatch } from "../../redux/store";
import { setPrevPageBeforeBuyingCredits } from "../../redux/shoppingCartV3Slice";

interface HeaderCartButtonProps {
  isMobile: boolean;
  style?: Object;
  className: string;
}
const HeaderCartButton: FC<HeaderCartButtonProps> = (props) => {
  const history = useHistory();
  const { cartCount } = useCartCount();
  const dispatchReduxToolkit = useAppDispatch();

  const goToShoppingCart = () => {
    dispatchReduxToolkit(setPrevPageBeforeBuyingCredits());
    history.push("/v3/shopping-cart");
  };

  const renderCartQuantity = () => {
    if (cartCount > Constants.CART_BADGE_MAX_QUANTITY) {
      return Constants.CART_OVER_MAX_QUANTITY_LABEL;
    }

    return cartCount;
  };

  const renderCartBadge = () => {
    if (cartCount === 0) return <></>;

    return (
      <RDBadge
        isAbsolute
        className={style.cart_badge}
        content={renderCartQuantity()}
      />
    );
  };

  return (
    <Fragment>
      <Link
        to="#"
        onClick={goToShoppingCart}
        className={style.cart_button_container}
      >
        <Button
          className={props.className}
          style={props.style}
          basic
          content=""
          icon={
            <Icon style={{ verticalAlign: "sub" }}>
              <ShoppingCart />
            </Icon>
          }
        ></Button>
        {renderCartBadge()}
      </Link>
    </Fragment>
  );
};

export default HeaderCartButton;
