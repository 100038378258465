import { useEffect } from "react";
import "./CustomLoader.css";

interface Props {
  enable?: boolean;
}

const CustomLoader = ({ enable }: Props) => {
  useEffect(() => {
    !!enable ? showLoadPanel() : hideLoadPanel();
  }, [enable]);

  function showLoadPanel() {
    const myTarget: any = document.getElementById("loadingPanel");
    myTarget.classList.add("show");
  }

  function hideLoadPanel() {
    const myTarget: any = document.getElementById("loadingPanel");
    myTarget.classList.remove("show");
  }

  return (
    <div id="loadingPanel">
      <div className="a-activity-indicator" aria-live="off">
        <div className="a-activity-indicator__top-box"></div>
        <div className="a-activity-indicator__bottom-box"></div>
      </div>
    </div>
  );
};

export default CustomLoader;
