import { FC, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import {
  createMemberArn,
  describeChannel,
  listChannelMembershipsForAppInstanceUser,
  MessageType,
  Persistence,
} from "../../utils/helpers/ChimeAPI";
import { selectOriginComp } from "../../redux/account";
import { selectIsSignIn } from "../../redux/auth";
import {
  channelClearState,
  channelSetChannel,
  channelSetChannelArn,
  channelSetChimeUserId,
  selectApiKey,
  selectChannelArn,
  selectChimeUserId,
} from "../../redux/chatWidget";
import MessagingService from "../../services/MessagingService";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import LauncherIcon from "./LauncherIcon";
import { useCloseLiveBoxContext } from "./context/CloseLiveBoxContext";
import AWS from "aws-sdk";
import { getApiKey, getChimeSecret } from "../../services/Chime";
import { RSADecrypt } from "../../services/RSA_Encrypt";
import { ChannelMembershipForAppInstanceUserSummary } from "aws-sdk/clients/chimesdkmessaging";
import addNotification from "react-push-notification";
import { selectSelectedSupportRequest } from "../../redux/dashboardSlide";
import { useLocation } from "react-router-dom";

const NewChatWidget: FC = () => {
  const messagingService = MessagingService.getInstance();
  const [connected, setConnected] = useState<boolean>(false);
  const chimeUserId = useAppSelector(selectChimeUserId);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const isSignIn = useAppSelector(selectIsSignIn);
  const acc = useAppSelector(selectOriginComp);
  const member = {
    username: acc ? acc.contactFirstName + " " + acc.contactLastName : "Workshop Technician",
    userId: acc ? acc.ciamId : "Workshop Technician",
  };
  const chimeUserIdRef = useRef(chimeUserId);
  const [hasActiveChannel, setHasActiveChannel] = useState<boolean>(false);
  const [hasNewMessagesCome, setHasNewMessagesCome] = useState<boolean>(false);
  const { setChannelActiveId, isLiveChatOpen, channelActiveId, setNewMessageCome, setDeletedChannel } = useCloseLiveBoxContext();
  const apiKey = useAppSelector(selectApiKey);
  const appConfig = { appInstanceArn: process.env.REACT_APP_AWS_ARN };
  let tabHasFocusNotState = true;
  const currentRequest = useAppSelector(selectSelectedSupportRequest);
  const channelArn = useAppSelector(selectChannelArn);
  const location = useLocation();
  const channelArnRef = useRef(channelArn);
  const currentRequestRef = useRef(currentRequest);
  const locationRef = useRef(location);
  channelArnRef.current = channelArn;
  currentRequestRef.current = currentRequest;
  locationRef.current = location;

  const messagesProcessor = async (message: any) => {
    const messageType = message?.headers["x-amz-chime-event-type"];
    const record = JSON.parse(message?.payload);
    switch (messageType) {
      case "CHANNEL_DETAILS":
        if (!hasActiveChannel) {
          setHasActiveChannel(true)
        }
        break;
      // Channel Messages
      case "CREATE_CHANNEL_MESSAGE":
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
      case "DENIED_CREATE_CHANNEL_MESSAGE":
      case "FAILED_CREATE_CHANNEL_MESSAGE":
      case "DENIED_UPDATE_CHANNEL_MESSAGE":
      case "FAILED_UPDATE_CHANNEL_MESSAGE":
      case "PENDING_CREATE_CHANNEL_MESSAGE":
      case "PENDING_UPDATE_CHANNEL_MESSAGE":
        processChannelMessage(record);
        break;
      // Channels actions
      case "CREATE_CHANNEL":
      case "UPDATE_CHANNEL":
        break;
      case "DELETE_CHANNEL":
        await startNewestActiveChannel();
        setDeletedChannel(record.Name);
        break;
      case "CREATE_CHANNEL_MEMBERSHIP":
        break;
      case "UPDATE_CHANNEL_MEMBERSHIP":
        break;
      case "DELETE_CHANNEL_MEMBERSHIP":
        break;
      default:
        console.log(`Unexpected message type! ${messageType}`);
        await startNewestActiveChannel();
    }
  };

  const notifiOnclick = () => {
    window.focus();
  };

  const checkMatchingChannel = (ticketIncomeID: string) => {

    if (channelArnRef.current) {
      // hasn't open request detail yet
      if (!currentRequestRef.current) return false;
      // had open request detail
      let currentRequestId = String(currentRequestRef.current.ticketId) || "";
      let checkMatchingChannelWithRequestDetail = channelArnRef.current.toLowerCase().includes(currentRequestId);
      let checkMatchingChannelWithIncomeTicket = currentRequestId == ticketIncomeID;
      let checkMatchingRoutePath = locationRef.current.pathname.toLowerCase().includes("/dashboard/requests/request-detail");

      if (!checkMatchingChannelWithRequestDetail && checkMatchingRoutePath && checkMatchingChannelWithIncomeTicket) {
        return true
      }
    }
    return false
  };

  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);
    let ticketIncomeID = getTicketIDFromARN(message.ChannelArn);
    let ticketCurrentID = getTicketIDFromARN(channelArnRef.current);

    let isMatching = checkMatchingChannel(ticketIncomeID);

    if (ticketIncomeID !== ticketCurrentID && !isMatching) {
      setChannelActiveId(ticketIncomeID);
    }

    if (newMessage.Persistence !== Persistence.NON_PERSISTENT && newMessage.Type !== MessageType.CONTROL) {
      if (newMessage.Sender.Arn !== createMemberArn(chimeUserId)) {
        if (!tabHasFocusNotState) {
          addNotification({
            title: newMessage.Sender.Name + " sent you a message",
            message: newMessage.Content,
            theme: "darkblue",
            duration: 5000,
            native: true,
            onClick: notifiOnclick,
          });
          if (ticketIncomeID !== ticketCurrentID) {
            if (!isMatching) {
              setHasNewMessagesCome(true);
            }
          } else {
            if (
              !isLiveChatOpen
            )
              setHasNewMessagesCome(true);
          }
        }
      }
      setNewMessageCome(newMessage.Content || "");
    }
  };

  const getTicketIDFromARN = (arn: string) => {
    let lastSlashIndex: number = arn.lastIndexOf('/');
    let extractedString: string = arn.substring(lastSlashIndex + 1);
    return extractedString;
  }

  const startNewestActiveChannel = async () => {
    const channelList = await listChannelMembershipsForAppInstanceUser(
      member.userId
    );
    if (channelList.length === 0) return;
    let channelHasMessage = channelList.filter((channel: ChannelMembershipForAppInstanceUserSummary) => channel.ChannelSummary?.LastMessageTimestamp != null);
    channelHasMessage.sort((firstChannel: ChannelMembershipForAppInstanceUserSummary, nextChannel: ChannelMembershipForAppInstanceUserSummary) => {
      const timestampA = new Date(firstChannel.ChannelSummary!.LastMessageTimestamp!).getTime();
      const timestampB = new Date(nextChannel.ChannelSummary!.LastMessageTimestamp!).getTime();
      return timestampB - timestampA;
    });
    if (!chimeUserIdRef.current) {
      dispatchReduxToolkit(channelSetChimeUserId(member.userId));
    }
    if (channelHasMessage.length === 0) {
      setChannelActiveId(channelList[0].ChannelSummary?.Name || "");
      return;
    }
    const newestChannel: ChannelMembershipForAppInstanceUserSummary = channelHasMessage[0];
    if (newestChannel && newestChannel.ChannelSummary?.ChannelArn) {
      setChannelActiveId(newestChannel.ChannelSummary?.Name || "");
    }
  };

  const storeChannelInfo = async (ChannelArn: string) => {
    const newChannel = await describeChannel(ChannelArn, member.userId);
    if (newChannel) {
      let newObject = {
        ...newChannel,
        CreatedTimestamp: newChannel?.CreatedTimestamp?.toString() || "",
        LastUpdatedTimestamp: newChannel?.LastUpdatedTimestamp?.toString() || "",
        LastMessageTimestamp: newChannel?.LastMessageTimestamp?.toString() || "",
      }
      dispatchReduxToolkit(channelSetChannel(newObject));
    }
  };

  const handleFocus = () => {
    tabHasFocusNotState = true;
  };

  const handleBlur = () => {
    tabHasFocusNotState = false
  };

  //useEffect
  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);


  useEffect(() => {
    if (isLiveChatOpen && channelActiveId === "") {
      startNewestActiveChannel();
    }
  }, [isLiveChatOpen, channelActiveId])

  useEffect(() => {
    if (channelActiveId != "") {
      let channelArnStr = `${appConfig.appInstanceArn}/channel/${channelActiveId}`;
      dispatchReduxToolkit(channelSetChannelArn(channelArnStr));
      storeChannelInfo(channelArnStr);
      setNewMessageCome("New");
    }
  }, [channelActiveId])



  //Connect to AWS and ChimeSDK
  useEffect(() => {
    const connectingToChimeSDK = async () => {
      let headerKey = apiKey ? apiKey : await getApiKey();
      const awsKeys = await getChimeSecret(headerKey);
      AWS.config.region = process.env.REACT_APP_REACT_REGION;
      if (awsKeys) {
        const awsKeysDecrypt = await RSADecrypt.RSADecrypt(awsKeys);
        if (awsKeysDecrypt) {
          let awsKeysDecryptData = JSON.parse(awsKeysDecrypt);
          AWS.config.credentials = {
            accessKeyId: awsKeysDecryptData.AccessKey,
            secretAccessKey: awsKeysDecryptData.SecretKey,
          };
          if (AWS.config.credentials && AWS.config.credentials.accessKeyId && AWS.config.credentials.secretAccessKey) {
            console.log("Subscribe to MessagingService ...");
            messagingService.setMember(member);
            messagingService.connect();
            messagingService.subscribeToMessageUpdate(messagesProcessor);
            setConnected(true);
          }
        }
      } else {
        console.log("Connecting to ChimeSDK failed ...");
      }
    };

    if (isSignIn && !connected) {
      connectingToChimeSDK();
    }
  }, [isSignIn, connected]);

  useEffect(() => {
    return () => {
      console.log("Unsubscribe to MessagingService ...");
      messagingService.close();
      messagingService.unsubscribeFromMessageUpdate(messagesProcessor);
      dispatchReduxToolkit(channelClearState());
    };
  }, []);

  useEffect(() => {
    if (isLiveChatOpen) {
      setHasNewMessagesCome(false);
    }
  }, [isLiveChatOpen]);


  return (
    <>
      {hasActiveChannel && (
        <LauncherIcon newMessagesCome={hasNewMessagesCome} />
      )}

    </>
  );
};

export default NewChatWidget;
