import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./NewMenuHeaderMobile.module.css";
import { Button, Icon, Image } from "semantic-ui-react";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import { ReactComponent as RegionIcon } from "../../../assets/svg/locator.svg";
import { useAppDispatch } from "../../../redux/hooks";
import { loginAsync } from "../../../redux/account";
import HeaderCartButton from "../HeaderCartButton";
import { useSelector } from "react-redux";
import {
  authSetLanguage,
  selectAuthState,
  selectCountryName,
} from "../../../redux/auth";
import { LanguageOption } from "../../../models/pageConfiguration/LanguageModel";
import { useMenuHeaderConfiguration } from "../../../hooks/pageConfiguration/useMenuHeaderConfiguration";
import { MinimalMenuHeaderItem } from "../../../models/pageConfiguration/MenuHeaderModel";
import BoschIcon from "../../BoschIcon/Boschicon";
import { useHistory } from "react-router-dom";
import { useHeaderConfiguration } from "../../../hooks/pageConfiguration/useHeaderConfiguration";
import RDLanguageSelection from "../../RDComponents/RDLanguageSelection/RDLanguageSelection";
import BoschSuperGraphic from "../../../assets/Bosch-Supergraphic_.png";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  openSearchBar: Function;
  openChangeRegionModal: Function;
  openChangeCompanyModal: Function;
  openConfirmLogoutModal: Function;
};

const NewMenuHeaderMobile = (props: Props) => {
  const {
    onClose,
    openSearchBar,
    openChangeRegionModal,
    openChangeCompanyModal,
    openConfirmLogoutModal,
  } = props;
  const { t, i18n } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();
  const { headerConfig } = useHeaderConfiguration();
  const authState = useSelector(selectAuthState);
  const { menuHeaderConfig } = useMenuHeaderConfiguration({
    openConfirmLogoutModal,
  });
  const history = useHistory();
  const countryName = useSelector(selectCountryName);

  const [currentLanguage, setCurrentLanguage] = useState(authState.language);
  const [selectedLv1Item, setSelectedLv1Item] =
    useState<MinimalMenuHeaderItem | null>(null);

  const handleCloseMenuHeaderMobile = () => {
    onClose();
    setSelectedLv1Item(null);
  };

  const handleChangeRegionButtonClicked = () => {
    openChangeRegionModal();
    handleCloseMenuHeaderMobile();
  };

  const handleChangeCompanyButtonClicked = () => {
    openChangeCompanyModal();
    handleCloseMenuHeaderMobile();
  };

  const handleSearchButtonClicked = () => {
    openSearchBar();
    handleCloseMenuHeaderMobile();
  };

  const login = () => {
    dispatchReduxToolkit(loginAsync({ redirectUrl: window.location.pathname }));
  };

  const logout = () => {
    openConfirmLogoutModal();
  };

  const getLanguageOptions = (): LanguageOption[] => {
    if (authState.languages && authState.languages.length > 0)
      return authState.languages.map((lang) => {
        return {
          key: lang.localized,
          text: lang.localized,
          value: lang.lcid,
        };
      });

    return [
      {
        key: "English",
        text: "English",
        value: "en-US",
      },
    ];
  };

  const changeLanguage = (newLanguage: string) => {
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage.trim());
    dispatchReduxToolkit(authSetLanguage(newLanguage));
  };

  const renderMenuMobileHeaderIconButton = (
    icon: React.JSX.Element,
    onClick: Function,
    className?: string,
    content?: ReactNode
  ): ReactNode => {
    return (
      <Button
        basic
        className={className ? className : style.menu_mobile_icon_button}
        onClick={() => onClick()}
        icon={<Icon className={style.menu_mobile_icon}>{icon}</Icon>}
        content={content ? content : <></>}
      />
    );
  };

  const isChildrenOfMenuItemValid = (menuItem: MinimalMenuHeaderItem) => {
    return menuItem.childrenItems && menuItem.childrenItems.length > 0;
  };

  const handleMenuItemClicked = (
    menuItem: MinimalMenuHeaderItem,
    currentLevel: 1 | 2
  ) => {
    if (isChildrenOfMenuItemValid(menuItem) && currentLevel === 1) {
      setSelectedLv1Item(menuItem);
    }

    if (menuItem.onClick) {
      menuItem.onClick();
      handleCloseMenuHeaderMobile();
      return;
    }

    if (menuItem.redirectUrl) {
      history.push(menuItem.redirectUrl);
      handleCloseMenuHeaderMobile();
    }
  };

  const renderAllMenuItems = (
    menuItems: MinimalMenuHeaderItem[],
    isShowArrowIcon: boolean,
    currentLevel: 1 | 2,
    onMenuItemClick: (item: MinimalMenuHeaderItem, currentLevel: 1 | 2) => void
  ) => {
    return menuItems.map((menuItem) => {
      const isChildrenValid = isChildrenOfMenuItemValid(menuItem);

      return renderMenuMobileHeaderIconButton(
        (isChildrenValid || !menuItem.isShowOnMinimalMenu) &&
          isShowArrowIcon ? (
          <BoschIcon name="bosch-forward-right" />
        ) : (
          <></>
        ),
        () => onMenuItemClick(menuItem, currentLevel),
        isChildrenValid
          ? style.menu_mobile_item_button_with_arrow_end
          : style.menu_mobile_item_button,
        <span>{t(menuItem.label)}</span>
      );
    });
  };

  const renderAllMenuItemsLv1 = () => {
    return (
      <div className={style.menu_mobile_lv1}>
        {renderAllMenuItems(menuHeaderConfig, true, 1, handleMenuItemClicked)}
        {renderMenuMobileHeaderIconButton(
          <RegionIcon />,
          () => openChangeRegionModal(),
          `${style.menu_mobile_item_button} ${style.menu_mobile_region_button}`,
          <span>{countryName}</span>
        )}
      </div>
    );
  };

  const renderAllMenuItemsLv2 = () => {
    if (!selectedLv1Item) return <></>;

    if (
      !selectedLv1Item.childrenItems ||
      selectedLv1Item.childrenItems.length === 0
    )
      return <></>;

    return (
      <div className={style.menu_mobile_lv2}>
        {renderMenuMobileHeaderIconButton(
          <BoschIcon name="bosch-ic-arrow-left" />,
          () => setSelectedLv1Item(null),
          `${style.menu_mobile_item_button} ${style.menu_mobile_level_back_button}`,
          <span>{t(selectedLv1Item.label)}</span>
        )}
        {renderAllMenuItems(
          selectedLv1Item.childrenItems,
          false,
          2,
          handleMenuItemClicked
        )}
      </div>
    );
  };

  return (
    <>
      <div className={style.menu_mobile}>
        <div className={style.super_graphic_wrapper}>
          <Image src={BoschSuperGraphic} fluid></Image>
        </div>
        <div className={style.main_content}>
          <div className={style.menu_mobile_header}>
            <div className={style.menu_mobile_header_left}>
              {renderMenuMobileHeaderIconButton(
                <CloseIcon />,
                handleCloseMenuHeaderMobile,
                `${style.menu_mobile_icon_button} ${style.menu_mobile_close_button}`
              )}
            </div>
            <div className={style.menu_mobile_header_right}>
              <headerConfig.AccountButton
                className={style.menu_mobile_icon_button}
                isMobile={true}
                login={login}
                logout={logout}
                changeCompany={handleChangeCompanyButtonClicked}
              />
              {renderMenuMobileHeaderIconButton(
                <RegionIcon />,
                handleChangeRegionButtonClicked
              )}
              {renderMenuMobileHeaderIconButton(
                <SearchIcon />,
                handleSearchButtonClicked
              )}
              <HeaderCartButton
                className={style.menu_mobile_icon_button}
                isMobile={false}
              />
              <RDLanguageSelection
                currentLanguage={currentLanguage}
                languages={getLanguageOptions()}
                onChangeLanguage={(newLang) => changeLanguage(newLang)}
              />
            </div>
          </div>
          <div className={style.menu_mobile_main}>
            {renderAllMenuItemsLv1()}
            {renderAllMenuItemsLv2()}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewMenuHeaderMobile;
