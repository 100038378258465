import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import Hardware, { Product } from "../models/Hardware";
import {
  RequestFaultCodesModel,
  RequestUserHardwaresModel,
  RequestVciProductModel,
  DeleteUserHardwareModel,
} from "../models/hardware/request.hardware.model";
import { RequestHardwareClaimFormModel } from "../models/hardware/request.hardware.model copy";
import ResponseResultBase from "../models/ResponseResultBase";
import { RequestAddVci } from "../models/vci/request.createVciFavourite.model";
import VciFaultCode from "../models/VciFaultCode";
import http from "../services/HttpService";
import { AppDispatch, RootState } from "./store";
import { getVciListAsync } from "./vciSlide";

export const getReasonListAsync = createAsyncThunk<
  VciFaultCode[] | null,
  RequestFaultCodesModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("vci/getVciFaultCodes", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<VciFaultCode[]>>(
      `/api/v1/vci/getVciFaultCodes?countryCode=${requestModel.countryCode}&language=${requestModel.language}`
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getUserHardwaresAsync = createAsyncThunk<
  Hardware[] | null,
  RequestUserHardwaresModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("vci/getVciList", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<Hardware[]>>(
      `/api/v1/vci/getVciList?ciamId=${requestModel.ciamId}&countryCode=${requestModel.countryCode}&language=${requestModel.language}`
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const deleteUserHardwaresAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  DeleteUserHardwareModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("", async (requestModel, thunkApi) => {
  try {
    const response = await http.delete<ResponseResultBase<boolean>>(
      `/api/v1/vci/Delete?ciamId=${requestModel.ciamId}&HardwareId=${requestModel.hardwareId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const submitHardwareClaim = createAsyncThunk<
  Hardware | null,
  RequestHardwareClaimFormModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("vci/requestVciRepair", async (requestModel, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<Hardware>>(
      `/api/v1/vci/requestVciRepair`,
      requestModel
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const submitHardwareAdd = createAsyncThunk<
  Hardware | null,
  RequestAddVci,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("/api/v1/vci/AddVCI", async (requestModel, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<Hardware>>(
      `/api/v1/vci/AddVCI`,
      requestModel
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getVciProductsList = createAsyncThunk<
  Product[] | null,
  RequestVciProductModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("/api/v1/vci/GetVciProducts", async (requestModel, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<Product[]>>(
      `/api/v1/vci/GetVciProducts`,
      requestModel
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

interface HardwareState {
  hardwareList: Hardware[];
}

const initialState: HardwareState = {
  hardwareList: [],
};

export const hardwareSlice = createSlice({
  name: "hardware",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVciListAsync.fulfilled, (state, { payload }) => {
      if (!!payload) {
        state.hardwareList = payload;
      }
    });
    builder.addCase(submitHardwareAdd.fulfilled, (state, { payload }) => {
      if (!!payload) {
        state.hardwareList.push(payload);
      }
    });
  },
});

export const selectHardwareList = (rootState: RootState) =>
  rootState.hardware.hardwareList;
export default hardwareSlice.reducer;
