import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";
import { GetCdm3SubscriptionsRequest } from "../models/subscription/GetCdm3SubscriptionsRequest.model";
import { SubscriptionV3 } from "../models/subscription/SubscriptionV3.model";
import httpNoneSecure from "../services/HttpServiceNoneSecure";
import { AppDispatch, RootState } from "./store";

export interface ServicePlansState {
  subscriptions: SubscriptionV3[];
  selectedSubscriptionId: number | null;
}

const initialState: ServicePlansState = {
  subscriptions: [],
  selectedSubscriptionId: null,
};

export const getCdm3SubscriptionsAsync = createAsyncThunk<
  ResponseResultBase<SubscriptionV3[]> | null,
  GetCdm3SubscriptionsRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Subscriptions/GetCdm3Subscriptions",
  async (model: GetCdm3SubscriptionsRequest, thunkApi) => {
    try {
      const response = await httpNoneSecure.get<
        ResponseResultBase<SubscriptionV3[]>
      >(
        `/api/v1/Subscriptions/GetCdm3Subscriptions?countryCode=${model.countryCode}&language=${model.language}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const servicePlansSlice = createSlice({
  name: "servicePlans",
  initialState,
  reducers: {
    setSelectedSubscription: (state, action: PayloadAction<SubscriptionV3>) => {
      return {
        ...state,
        selectedSubscriptionId: action.payload.id,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCdm3SubscriptionsAsync.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          return {
            ...state,
            subscriptions: [...payload.dataObject],
          };
        }

        return {
          ...state,
          subscriptions: [],
        };
      })
      .addCase(getCdm3SubscriptionsAsync.rejected, (state, { payload }) => {
        return {
          ...state,
          subscriptions: [],
        };
      });
  },
});

export const selectSubscriptions = (rootState: RootState) =>
  rootState.servicePlans.subscriptions;
export const selectSelectedSubscriptionId = (rootState: RootState) =>
  rootState.servicePlans.selectedSubscriptionId;
export const { setSelectedSubscription } = servicePlansSlice.actions;
export default servicePlansSlice.reducer;
