import { RediConstant } from "../models/common/rediConstant";
import { PaymentFrequency } from "../models/paymentAdyen/paymentFrequency";

export const useIsMonthlyPayment = () => {
  const isMonthlyPayment = (
    paymentFrequencySelected: PaymentFrequency | null | undefined
  ) => {
    if (!paymentFrequencySelected) return false;
    return (
      paymentFrequencySelected.id === RediConstant.PaymentFrequency_Monthly_Id
    );
  };

  return isMonthlyPayment;
};
