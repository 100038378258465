import { ForwardedRef, forwardRef } from "react";
import { Input, InputProps } from "semantic-ui-react";
import Styles from "./RDInput.module.css";
import Boschicon from "../BoschIcon/Boschicon";
import { useTranslation } from "react-i18next";

interface RDInputProps extends InputProps {
  title: string;
  successMessage?: string;
  errorMessage?: string;
  testId?: string;
  maxLength?: number;
  containerClassName?: string;
}

const RDInput = forwardRef<Input, RDInputProps>(
  (props: RDInputProps, ref: ForwardedRef<Input>) => {
    const { t } = useTranslation();
    const {
      title,
      successMessage,
      errorMessage,
      testId,
      maxLength,
      containerClassName,
      ...restProps
    } = props;

    const renderErrorComponent = () => {
      if (
        !errorMessage ||
        (!!maxLength && props.value.toString().length < maxLength)
      )
        return <></>;

      return (
        <span className={Styles.error}>
          <Boschicon name="bosch-ic-alert-error" />
          <span data-testid="rd-search-input-error-message">
            {errorMessage}
          </span>
        </span>
      );
    };

    const renderInformationComponent = () => {
      if (!maxLength || props.value.length >= maxLength) return <></>;

      return (
        <span className={Styles.information}>
          {t("RDInput_InformationMessage")
            .replace("{currentLength}", props.value.length)
            .replace("{maxLength}", maxLength.toString())}
        </span>
      );
    };

    return (
      <div className={`${Styles.rd_input_group} ${containerClassName}`}>
        <span className={`text_oversize_handler ${Styles.title}`}>{title}</span>
        <Input
          {...restProps}
          ref={ref}
          className={`${Styles.rd_input} ${!!title ? Styles.with_title : ""} ${
            props.className ?? ""
          } ${props.disabled && Styles.disabled}`}
          error={!!errorMessage}
          data-testid={testId}
        />
        {renderErrorComponent()}
        {renderInformationComponent()}
      </div>
    );
  }
);

export default RDInput;
