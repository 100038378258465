import { useEffect } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { useSelector } from "react-redux";
import { selectCountryCode } from "../../../redux/auth";
import style from "./AdsNewRequest.module.scss";
import AdsNewRequestBreadcrumb from "../../features/adsNewRequest/adsNewRequestBreadcrumb/AdsNewRequestBreadcrumb";
import {
  NewRequestStep,
  selectCountrySearchMethods,
  selectCurrentStep,
  selectFormValues,
  updateFormValues,
} from "../../../redux/newRequestSlice";
import AdsVehicleIdentification from "../../features/adsNewRequest/adsVehicleIdentification/AdsVehicleIdentification";
import AdsRequestDetails from "../../features/adsNewRequest/adsRequestDetails/AdsRequestDetails";
import AdsNewRequestSummary from "../../features/adsNewRequest/adsNewRequestSummary/AdsNewRequestSummary";
import AdsRequestCreated from "../../features/adsNewRequest/adsRequestCreated/AdsRequestCreated";
import { CountrySearchMethodModel } from "../../../models/NewRequest/CountrySearchMethod.model";
import { SEARCH_BY_VIN_METHOD_ID } from "../../../constants/automaticSearch.constants";

const AdsNewRequest = () => {
  const dispatch = useAppDispatch();
  const formValues = useSelector(selectFormValues);
  const currentStep = useSelector(selectCurrentStep);
  const countrySearchMethods = useSelector(selectCountrySearchMethods);
  const countryCode = useSelector(selectCountryCode);

  useEffect(() => {
    let searchCountrySelected: CountrySearchMethodModel | null = null;

    if (!!formValues?.searchCountry) return;
    if (countrySearchMethods.length <= 0) return;

    const searchCountry = countrySearchMethods.find(
      (x) => x.countryCode?.toLowerCase() === countryCode?.toLowerCase()
    );
    if (searchCountry) {
      searchCountrySelected = searchCountry;
    } else {
      if (countrySearchMethods.length > 0) {
        searchCountrySelected = countrySearchMethods[0];
      }
    }
    dispatch(
      updateFormValues({
        searchCountry: searchCountrySelected,
        searchCountryCode:
          searchCountrySelected?.countryCode || countryCode || "",
        searchMethodId: SEARCH_BY_VIN_METHOD_ID,
      })
    );
  }, [formValues.searchCountry, countryCode, countrySearchMethods]);

  const renderComponentBasedOnStep = () => {
    switch (currentStep) {
      case NewRequestStep.Vehicle_Identification:
        return <AdsVehicleIdentification />;
      case NewRequestStep.Request_Detail:
        return <AdsRequestDetails />;
      case NewRequestStep.New_Request_Summary:
        return <AdsNewRequestSummary />;
      case NewRequestStep.Request_Created_Successfully:
        return <AdsRequestCreated />;
      default:
        return <></>;
    }
  };

  return (
    <div className={style.container}>
      <AdsNewRequestBreadcrumb />
      {renderComponentBasedOnStep()}
    </div>
  );
};

export default AdsNewRequest;
