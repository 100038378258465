import { useSelector } from "react-redux";
import { selectCdmVersion, selectIsSignIn } from "../redux/auth";
import { GlobalSearchData } from "../models/globalSearch/globalSearch";
import { useTranslation } from "react-i18next";
import { CdmVersion } from "../models/pageConfiguration/CdmVersion";
import { SearchDataGlobal } from "../utils/searchData";
import { selectAccount } from "../redux/account";
import { useUserSubscription } from "./useUserSubscription";
import {
  DASHBOARD_STATISTICS_SHARED_ROUTE,
  MY_SUBSCRIPTION_SHARED_ROUTE,
  WALLET_SHARED_ROUTE,
} from "../constants/routes/sharedRoutes.constants";
import { useRouting } from "./useRouting";
import { useHistory } from "react-router-dom";
import { SEARCH_RESULTS_ROUTE } from "../constants/routes/defaultRoutes.constants";

export const useGlobalSearch = () => {
  const { t } = useTranslation();
  const cdmVersion = useSelector(selectCdmVersion);
  const isLoggedIn = useSelector(selectIsSignIn);
  const currentAccount = useSelector(selectAccount);
  const { checkSubscriptionIsTrial } = useUserSubscription();
  const { isWalletAndMyProductsRouteAccessible } = useRouting();
  const history = useHistory();

  const isGlobalSearchDataItemValid = (item: GlobalSearchData) => {
    const { ignoreVersions, isLoginRequired, ignoreRoles } = item;

    if (!!ignoreVersions && ignoreVersions.includes(cdmVersion as CdmVersion))
      return false;

    if (!!isLoginRequired && isLoginRequired === true && !isLoggedIn)
      return false;

    if (
      !!ignoreRoles &&
      currentAccount?.userRole?.id &&
      ignoreRoles.includes(currentAccount.userRole.id)
    )
      return false;

    if (item.path.includes(WALLET_SHARED_ROUTE.path))
      return isWalletAndMyProductsRouteAccessible();

    if (item.path.includes(MY_SUBSCRIPTION_SHARED_ROUTE.path))
      return isWalletAndMyProductsRouteAccessible();

    if (
      item.path.includes(DASHBOARD_STATISTICS_SHARED_ROUTE.path) &&
      checkSubscriptionIsTrial()
    )
      return false;

    return true;
  };

  const isGlobalSearchDataItemMatchWithKeyword = (
    nameTranslated: string,
    titleTranslated: string,
    keywordLowercase: string
  ) => {
    const itemNameLowercase = nameTranslated.toLowerCase();
    const itemTitleLowercase = titleTranslated.toLowerCase();

    return (
      itemNameLowercase.includes(keywordLowercase) ||
      itemTitleLowercase.includes(keywordLowercase)
    );
  };

  const searchGlobalData = (keyword: string): GlobalSearchData[] => {
    let targetKeyword = keyword.trim();

    if (targetKeyword === "") return [];

    targetKeyword = targetKeyword.toLowerCase();

    const globalSearchResult: GlobalSearchData[] = [];

    for (const currentItem of SearchDataGlobal) {
      if (!isGlobalSearchDataItemValid(currentItem)) continue;

      const itemNameTranslated = t(currentItem.name);
      const itemTitleTranslated = t(currentItem.title);

      if (
        !isGlobalSearchDataItemMatchWithKeyword(
          itemNameTranslated,
          itemTitleTranslated,
          targetKeyword
        )
      )
        continue;

      globalSearchResult.push({
        ...currentItem,
        name: itemNameTranslated,
        title: itemTitleTranslated,
        parent: t(currentItem.parent),
      });
    }

    return globalSearchResult;
  };

  const goToSearchResultsPage = (searchKey: string) => {
    history.push({
      pathname: SEARCH_RESULTS_ROUTE.path,
      search: `q=${searchKey}`,
    });
  };

  const getSearchKey = (): string => {
    return new URLSearchParams(window.location.search).get("q") || "";
  };

  return { searchGlobalData, goToSearchResultsPage, getSearchKey };
};
