import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCiamId } from "../../redux/account";
import { selectIsSignIn } from "../../redux/auth";
import { useAppDispatch } from "../../redux/hooks";
import { getAccountSettingsAsync } from "../../services/AccountSettingsService";

export const useGetAccountSettings = () => {
  const dispatchReduxToolkit = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const [showLoader, setShowLoader] = useState(false);
  const isLoggedIn = useSelector(selectIsSignIn);

  useEffect(() => {
    (async () => {
      if (
        isLoggedIn &&
        !window.location.pathname.startsWith("/signoutcallback")
      ) {
        setShowLoader(true);
        await dispatchReduxToolkit(getAccountSettingsAsync(ciamId));
        setShowLoader(false);
      }
    })();
  }, [isLoggedIn]);

  return [showLoader];
};
