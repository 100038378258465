import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { UserSubscriptionInfo } from "../models/paymentAdyen/userSubscriptionInfo";

import ResponseResultBase from "../models/ResponseResultBase";
import Subscription from "../models/Subscription";
import { RequestCancelSubscriptionModel } from "../models/subscription/request.cancelSubscription";
import {
  RequestSubscriptionModel,
  RequestSubscriptionWithVoucherModel,
} from "../models/subscription/request.subscription.model";
import http from "../services/HttpService";
import httpNoneSecure from "../services/HttpServiceNoneSecure";
import { subscriptionService } from "../services/SubscriptionService";
import { AppDispatch, RootState } from "./store";

export const getSubscriptionsAsync = createAsyncThunk<
  Subscription[] | null,
  RequestSubscriptionModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("subscriptions/getSubscriptions", async (requestModel, thunkApi) => {
  try {
    const response = await httpNoneSecure.get<
      ResponseResultBase<Subscription[]>
    >(
      `/api/v1/subscriptions/getSubscriptions?countryCode=${requestModel.countryCode}&language=${requestModel.language}`
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getSubscriptionsWithVoucherAsync = createAsyncThunk<
  Subscription[] | null,
  RequestSubscriptionWithVoucherModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "subscriptions/getSubscriptionsWithVoucher",
  async (requestModel, thunkApi) => {
    try {
      const response = await subscriptionService.getSubscriptionWithVoucher(
        requestModel
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getSubscriptionStatusAsync = createAsyncThunk<
  UserSubscriptionInfo | null,
  RequestSubscriptionModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("subscriptions/getSubscriptionStatus", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<UserSubscriptionInfo>>(
      `/api/v1/subscriptions/getSubscriptionStatus?ciamId=${requestModel.ciamId}&countryCode=${requestModel.countryCode}&language=${requestModel.language}`
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const cancelSubscriptionAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  RequestCancelSubscriptionModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("subscriptions/cancelSubscription", async (requestModel, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<boolean>>(
      `/api/v1/subscriptions/${requestModel.ciamId}/cancelSubscription`,
      requestModel
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

// export const getCdm3SubscriptionAsync = createAsyncThunk<
//     SubscriptionsCDM3[] | null,
//     RequestGetCdm3Subscriptions,
//     {
//         dispatch: AppDispatch,
//         state: RootState,
//         rejectValue: AxiosError
//     }>(
//         "/api/v1/subscriptions/GetCdm3Subscriptions",
//         async (model:RequestGetCdm3Subscriptions, thunkApi) => {
//             try {
//                 const response = await http.get<ResponseResultBase<SubscriptionsCDM3[]>>(`/api/v1/subscriptions/GetCdm3Subscriptions?CountryCode=${model.countryCode}&Language=${model.language}k&CiamId=${model.ciamId}`);
//                 return response.data.dataObject as SubscriptionsCDM3[];
//             } catch (error) {
//                 if (axios.isAxiosError(error)) {
//                     return thunkApi.rejectWithValue(error);
//                 }
//             }
//             return null;
//         }
//     )
