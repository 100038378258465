import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import Styles from "./ShoppingCartDetailCheckoutInfo.module.css";
import { useSelector } from "react-redux";
import {
  checkCdm3FrontendCartWithVoucherCodeAsync,
  replaceCart,
  selectShoppingCart,
  ShoppingCartV3,
} from "../../../../../redux/shoppingCartV3Slice";
import { selectCountryCode, selectLanguage } from "../../../../../redux/auth";
import { showRedeemModal } from "../../../../../redux/productsSlice";
import { useAppDispatch } from "../../../../../redux/hooks";
import Boschicon from "../../../../BoschIcon/Boschicon";
import CustomLoader from "../../../../loading/CustomLoader";
import { selectCiamId } from "../../../../../redux/account";
import { CheckCdm3FrontendCartWithVoucherCodeRequest } from "../../../../../models/ShoppingCart/CheckCdm3FrontendCartWithVoucherCodeRequest.model";
import ResponseResultBase from "../../../../../models/ResponseResultBase";
import { useCartSubscription } from "../../../../../hooks/useCartSubscription";

interface CheckoutContentVoucherInfoProps {
  isEditable: boolean;
  hideApplyVoucher?: boolean;
}

const CheckoutContentVoucherInfo: FC<CheckoutContentVoucherInfoProps> = ({
  isEditable,
  hideApplyVoucher,
}) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();
  const countryCode = useSelector(selectCountryCode);
  const ciamId = useSelector(selectCiamId);
  const language = useSelector(selectLanguage);
  const shoppingCart = useSelector(selectShoppingCart);
  const [showLoader, setShowLoader] = useState(false);
  const { isPrepaidVoucher } = useCartSubscription();
  const onShowRedeemVoucher = () => {
    dispatchReduxToolkit(showRedeemModal());
  };

  const onRemoveVoucher = (e: any) => {
    e.preventDefault();

    setShowLoader(true);
    const request: CheckCdm3FrontendCartWithVoucherCodeRequest = {
      ciamId,
      language,
      countryCode,
      frontendCart: {
        ...shoppingCart,
        voucherCode: "",
      },
    };

    dispatchReduxToolkit(checkCdm3FrontendCartWithVoucherCodeAsync(request))
      .then((res) => {
        if (res) {
          const payload = res.payload as ResponseResultBase<ShoppingCartV3>;
          if (payload && payload.httpStatusCode === 200 && payload.dataObject) {
            const newCart: ShoppingCartV3 = {
              ...payload.dataObject,
              hasEsiContract: shoppingCart.hasEsiContract,
            };
            dispatchReduxToolkit(replaceCart(newCart));
            return;
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <Fragment>
      {isEditable && !hideApplyVoucher && !shoppingCart.voucherCode && (
        <Grid.Row className={Styles.row}>
          <Grid.Column className={Styles.column} width={16}>
            <div
              className={`${Styles.order_summary_contents} ${Styles.voucher_row}`}
            >
              <Link to="#" onClick={onShowRedeemVoucher}>
                {t("ShoppingCartDetailCheckoutInfo_EnterVoucher_Link")}
              </Link>
            </div>
          </Grid.Column>
        </Grid.Row>
      )}
      {!hideApplyVoucher && !!shoppingCart.voucherCode && (
        <Grid.Row
          className={`${Styles.row} ${Styles.voucher_wrapper} ${
            isPrepaidVoucher && Styles.no_border_bottom
          }`}
        >
          <Grid.Column
            className={`${Styles.column} ${Styles.voucher_applied}`}
            width={16}
          >
            <div className={Styles.field_label}>
              {t("ShoppingCartDetailCheckoutInfo_VoucherApplied_Label")}
            </div>
            <div
              className={`${Styles.order_summary_contents} ${
                Styles.voucher_row
              } ${isEditable && Styles.editable}`}
            >
              <span className={Styles.voucher_code}>
                {shoppingCart.voucherCode}
              </span>
              {isEditable && (
                <>
                  <Link to="#" onClick={onShowRedeemVoucher}>
                    <Boschicon name="bosch-ic-edit" />
                  </Link>
                  <Link
                    to="#"
                    onClick={onRemoveVoucher}
                    className={Styles.remove_voucher}
                  >
                    <Boschicon name="bosch-delete" />
                  </Link>
                </>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      )}
      {showLoader && <CustomLoader enable />}
    </Fragment>
  );
};

export default CheckoutContentVoucherInfo;
