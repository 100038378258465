import LoadableComponent from "../../components/loading/LoadableComponent";
import { RouteConfigure } from "../../models/Route";
import { Constants } from "../../utils/helpers/Constants";
import {
  MY_SUBSCRIPTION_SHARED_ROUTE,
  DASHBOARD_REQUESTS_SHARED_ROUTE,
  DASHBOARD_SHARED_ROUTE,
  DASHBOARD_STATISTICS_SHARED_ROUTE,
  DASHBOARD_TEAM_CREATE_SHARED_ROUTE,
  DASHBOARD_TEAM_EDIT_SHARED_ROUTE,
  DASHBOARD_TEAM_SHARED_ROUTE,
  WALLET_SHARED_ROUTE,
  DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE,
  SHOPPING_CART_SHARED_ROUTE,
  CHECKOUT_SHARED_ROUTE,
  NEW_REQUEST_SHARED_ROUTE,
  MY_BOSCH_SHARED_ROUTE,
  SUPPORT_SHARED_ROUTE,
  CONTACT_US_SHARED_ROUTE,
  HOME_SHARED_ROUTE,
  SUBSCRIPTIONS_SHARED_ROUTE,
  SUBSCRIPTION_DETAILS_SHARED_ROUTE,
} from "./sharedRoutes.constants";

export const HOME_CDM_3_ROUTE_PL_PATH =
  "/?utm_source=zdalnadiagnozabosch&utm_medium=redirect&utm_campaign=redirect_tracking";

/**
 * CDM_3 ONLY ROUTES
 */
export const CONTACTS_US_CDM_3_ROUTE: RouteConfigure = {
  ...CONTACT_US_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/ContactUs")),
};
export const HOME_CDM_3_ROUTE: RouteConfigure = {
  ...HOME_SHARED_ROUTE,
  component: LoadableComponent(
    () => import(`../../pages/CDM3.0/homePage/HomePage`)
  ),
};

export const HOME_CDM_3_ROUTE_PL: RouteConfigure = {
  ...HOME_SHARED_ROUTE,
  path: HOME_CDM_3_ROUTE_PL_PATH,
  component: LoadableComponent(
    () => import(`../../pages/CDM3.0/homePage/HomePage`)
  ),
};

export const SUBSCRIPTIONS_CDM_3_ROUTE: RouteConfigure = {
  ...SUBSCRIPTIONS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import(`../../pages/CDM3.0/Subscriptions`)
  ),
};

export const SUBSCRIPTION_DETAIL_CDM_3_ROUTE: RouteConfigure = {
  ...SUBSCRIPTION_DETAILS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import(`../../pages/CDM3.0/SubscriptionDetail`)
  ),
  parentRoute: SUBSCRIPTIONS_CDM_3_ROUTE,
};

export const SHOPPING_CART_CDM_3_ROUTE: RouteConfigure = {
  ...SHOPPING_CART_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/ShoppingCart")),
};

// export const PAYMENT_DETAIL_CDM_3_ROUTE: RouteConfigure = {
//   path: "/v3/payment-detail",
//   name: "Payment detail",
//   title: "Payment detail",
//   //TODO: double check
//   crumb: "MenuHeaderPopup_Subscriptions_Headline",
//   icon: "Payments",
//   exact: true,
//   isProtected: false,
//   permission: "",
//   component: LoadableComponent(
//     () => import(`../../pages/CDM3.0/PaymentDetail`)
//   ),
// };

export const CHECK_OUT_CDM_3_ROUTE: RouteConfigure = {
  ...CHECKOUT_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/CheckOut")),
  parentRoute: SHOPPING_CART_CDM_3_ROUTE,
};

export const DASHBOARD_REQUESTS_CDM_3_ROUTE: RouteConfigure = {
  ...DASHBOARD_REQUESTS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/DashboardRequestCDM3Wrapper")
  ),
};

export const DASHBOARD_REQUEST_DETAILS_CDM_3_ROUTE: RouteConfigure = {
  ...DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/RequestDetails")
  ),
  parentRoute: DASHBOARD_REQUESTS_CDM_3_ROUTE,
};

export const DASHBOARD_TEAM_CDM_3_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/DashboardTeamCDM3")
  ),
};

export const DASHBOARD_TEAM_EDIT_CDM_3_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_EDIT_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/TeamEdit")),
  parentRoute: DASHBOARD_TEAM_CDM_3_ROUTE,
};

export const DASHBOARD_TEAM_CREATE_CDM_3_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_CREATE_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/TeamCreate")),
  parentRoute: DASHBOARD_TEAM_CDM_3_ROUTE,
};

export const DASHBOARD_STATISTICS_CDM_3_ROUTE: RouteConfigure = {
  ...DASHBOARD_STATISTICS_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/Statistics")),
};

export const DASHBOARD_WALLET_CDM_3_ROUTE: RouteConfigure = {
  ...WALLET_SHARED_ROUTE,
  path: "/dashboard/wallet",
  role: [Constants.USER_ROLE_MANAGER_ID],
  component: LoadableComponent(() => import("../../pages/CDM3.0/Wallet")),
};

export const DASHBOARD_MY_SUBSCRIPTION_CDM_3_ROUTE: RouteConfigure = {
  ...MY_SUBSCRIPTION_SHARED_ROUTE,
  path: "/dashboard/my-sub",
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/MySubscription")
  ),
};

export const NEW_REQUEST_CDM_3_ROUTE: RouteConfigure = {
  ...NEW_REQUEST_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/newRequest/NewRequest")
  ),
};

export const NEW_REQUEST_REDI_CDM_3_ROUTE: RouteConfigure = {
  ...NEW_REQUEST_SHARED_ROUTE,
  path: "/new-request/redi",
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/newRequestRedi/NewRequestRedi")
  ),
  breadcrumbs: [
    {
      label: "NewRequestCDM3_Breadcrumb_NewRequest",
      path: NEW_REQUEST_CDM_3_ROUTE.path,
    },
    {
      label: "SubSystem_Id_6",
    },
  ],
  parentRoute: NEW_REQUEST_CDM_3_ROUTE,
};

export const NEW_REQUEST_THL_CDM_3_ROUTE: RouteConfigure = {
  ...NEW_REQUEST_SHARED_ROUTE,
  path: "/new-request/thl",
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/newRequestTHL/NewRequestTHL")
  ),
  breadcrumbs: [
    {
      label: "NewRequestCDM3_Breadcrumb_NewRequest",
      path: NEW_REQUEST_CDM_3_ROUTE.path,
    },
    {
      label: "RequestDetailRequestType_CarRepairSupport",
    },
  ],
  parentRoute: NEW_REQUEST_CDM_3_ROUTE,
};

export const NEW_REQUEST_VHC_CDM_3_ROUTE: RouteConfigure = {
  ...NEW_REQUEST_SHARED_ROUTE,
  path: "/new-request/vhc",
  breadcrumbs: [
    {
      label: "NewRequestCDM3_Breadcrumb_NewRequest",
      path: NEW_REQUEST_CDM_3_ROUTE.path,
    },
    {
      label: "VHC_BoschVehicleHealthCheck",
    },
  ],
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/newRequestVHC/NewRequestVHC")
  ),
  parentRoute: NEW_REQUEST_CDM_3_ROUTE,
};

export const DASHBOARD_CDM_3_ROUTE: RouteConfigure = {
  ...DASHBOARD_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/Dashboard")),
};

export const MY_SUBSCRIPTION_CDM_3_ROUTE: RouteConfigure = {
  ...MY_SUBSCRIPTION_SHARED_ROUTE,
  role: [Constants.USER_ROLE_MANAGER_ID],
  component: LoadableComponent(
    () => import("../../pages/CDM3.0/MySubscription")
  ),
};

export const MY_PROFILE_CDM_3_ROUTE: RouteConfigure = {
  ...MY_BOSCH_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/MyProfile")),
};

export const SUPPORT_CDM_3_ROUTE: RouteConfigure = {
  ...SUPPORT_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/Support")),
};

export const WALLET_CDM_3_ROUTE: RouteConfigure = {
  ...WALLET_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../pages/CDM3.0/Wallet")),
  parentRoute: DASHBOARD_WALLET_CDM_3_ROUTE,
};
