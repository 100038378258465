import { AutomaticSearchFormatRule } from "../models/automaticSearch";

export const getLicensePlateWithoutDelimiters = (
  input: string,
  formatRules?: AutomaticSearchFormatRule[]
): string => {
  if (!formatRules || input === "") return input;

  let result = input;

  for (const rule of formatRules) {
    result = result.replace(rule.delimiter, "");
  }

  return result;
};

export const autoFormatLicensePlate = (
  input: string,
  originalRules?: AutomaticSearchFormatRule[]
): string => {
  if (!originalRules) return input;

  let result = "";
  let newRules: AutomaticSearchFormatRule[] = JSON.parse(
    JSON.stringify(originalRules)
  ); // Deep copy
  const formatRuleLength = newRules.length;

  if (formatRuleLength === 0 || input === "") return input;

  for (let i = 0; i < formatRuleLength; ++i) {
    if (i === 0) newRules[0].pos -= 1;
    else if (i === 1) newRules[1].pos += newRules[0].delimiter.length - 1;
    else {
      let totalDelimiterLength = 0;

      for (let j = 0; j < i; ++j) {
        totalDelimiterLength += newRules[j].delimiter.length;
      }

      newRules[i].pos += totalDelimiterLength - 1;
    }
  }

  for (let i = 0; i < input.length; ++i) {
    result += input[i];

    for (let j = 0; j < formatRuleLength; ++j) {
      if (
        i === newRules[j].pos &&
        input.substring(i + 1, newRules[j].delimiter.length + i + 1) !==
          newRules[j].delimiter
      ) {
        result += newRules[j].delimiter;

        for (let k = j; k < formatRuleLength; ++k) {
          newRules[k].pos -= newRules[j].delimiter.length;
        }
      }
    }
  }

  return result;
};
