import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import Account from "../models/Account";
import {
  getAccountSimpleAsync,
  loginCallbackAsync,
  logoutCallbackAsync,
} from "../redux/account";

export enum Status {
  Paid,
  Outstanding,
}

export interface Transaction {
  id: string;
  displayName: string;
  invoiceNumber: number;
  fileName: string;
  fileExt: string;
  dateCreated: Date | null;
  dueDate: Date | null;
  totalPrice: number;
  status: Status;
}

export enum SubscriptionType {
  Basic = 1,
  Pro = 2,
  ProPlus = 3,
}

export interface SubscriptionFeature {
  feature: string;
}

export interface SubscriptionPrice {
  subscriptionType: SubscriptionType;
  yearNetPrice: number;
  yearTax: number;
  yearTotalPrice: number;
  vat: number;
  country: string;
  currency: string;
}

export interface Subscription {
  id: number;
  subscriptionType: SubscriptionType;
  title: string;
  partNumber: string;
  subscriptionFeatures: SubscriptionFeature[];
  subscriptionPrices: SubscriptionPrice;
  numberOfTicketsPerYear: number;
  country: string;
  language: string;
}

export interface CustomerSubscription {
  id: number;
  subscription: Subscription;
  validFrom: Date | null;
  validTill: Date | null;
  isCancelled: boolean;
  cancellationDate: Date | null;
  isMonthlyPayment: boolean;
  isRecurringPayment: boolean;
}

export interface WorkshopRole {
  id: number;
  role: string;
}

export interface ContactPerson {
  id: string;
  cdbId: number;
  customerId: number;
  title: string;
  firstName: string;
  lastName: string;
  language: string;
  mobilePhone: string;
  role: WorkshopRole;
  email: string;
  isActive: boolean;
}

export interface Hardware {
  id: number;
  name: string;
  serialNumber: string;
  isActivated: boolean;
}

export interface CreditStatus {
  credits: number;
}

export interface AccountProfile {
  isActive: boolean;
  cdbId: number;
  ciamId: string;
  name1: string;
  name2: string;
  address1: string;
  address2: string;
  city: string;
  postCode: string;
  countryCode: string;
  companyPhone: string;
  companyEmail: string;
  vat: string;
  employees: ContactPerson[];
  subscription: CustomerSubscription | null;
  transactions: Transaction[];
  availableCredits: CreditStatus | null;
  hardware: Hardware[];
}

export interface AccountState {
  isLoggedIn: boolean;
  accountCreditBalance: number;
  access_token: string;
  refresh_token?: string;
  session_state: string | null;
  scope?: string;
  expires_at?: number;
  profile: Account | null;
  timeZoneId: string;
}

const initialState: AccountState = {
  isLoggedIn: false,
  accountCreditBalance: 0,
  access_token: "",
  refresh_token: "",
  session_state: "",
  scope: "",
  expires_at: -1,
  profile: null,
  timeZoneId: "",
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    // login: (state, action: PayloadAction<any>) => {
    //   return {
    //     ...state,
    //     isLoggedIn: false,
    //     accountCreditBalance: 0,
    //     access_token: action.payload.access_token,
    //     refresh_token: action.payload.refresh_token,
    //     session_state: action.payload.session_state,
    //     scope: action.payload.scope,
    //     expires_at: action.payload.expires_at
    //   }
    // },
    // loginSuccess: (state, action: PayloadAction<Account>) => {
    //   return {
    //     ...state,
    //     isLoggedIn: true,
    //     profile: { ...action.payload }
    //   }
    // },
    // logout: (state) => {
    //   return {
    //     ...state,
    //     isLoggedIn: false,
    //     accountCreditBalance: 0,
    //     access_token: "",
    //     refresh_token: "",
    //     session_state: "",
    //     scope: "",
    //     expires_at: null,
    //     profile: null
    //   }
    // },
    setAccountCreditBalance: (state, action: PayloadAction<number>) => {
      state.accountCreditBalance = action.payload;
    },
    // setTimeZoneId: (state, action: PayloadAction<string>) => {
    //   state.timeZoneId = action.payload;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountSimpleAsync.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          return {
            ...state,
            isLoggedIn: true,
            profile: { ...payload.dataObject },
          };
        }
      })
      .addCase(loginCallbackAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            isLoggedIn: false,
            accountCreditBalance: 0,
            access_token: payload.access_token,
            refresh_token: payload.refresh_token,
            session_state: payload.session_state,
            scope: payload.scope,
            expires_at: payload.expires_at,
          };
        }
      })
      .addCase(logoutCallbackAsync.fulfilled, (state) => {
        return {
          ...state,
          isLoggedIn: false,
          accountCreditBalance: 0,
          access_token: "",
          refresh_token: "",
          session_state: "",
          scope: "",
          expires_at: -1,
          profile: null,
        };
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAccount = (state: RootState) => state.account;
export const selectAccountCreditBalance = (state: RootState) =>
  state.account.accountCreditBalance;
export const selectTimeZoneId = (state: RootState) => state.account.timeZoneId;

export const { setAccountCreditBalance } = accountSlice.actions;

export default accountSlice.reducer;
