import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import SupportRequestModel from "../models/support/supportRequest.model";
import { getServerTimeAsync, getSupportRequestsAsync } from "./supportSlide";
import {
  ContactLanguage,
  ContactLanguageQueryFields,
  TitulationQueryFields,
  Titulations,
} from "../models/dashboard/DashboardModel";
import ResponseResultBase from "../models/ResponseResultBase";
import { AppDispatch, RootState } from "../redux/store";
import axios, { AxiosError } from "axios";
import http from "../services/HttpService";
import {
  VHCRequestDetailsStep,
  VhcRequestStatusChangeType,
} from "../types/requestDetailsVHC.type";
import { Constants } from "../utils/helpers/Constants";

export const getTitulationsAsync = createAsyncThunk<
  ResponseResultBase<Titulations[]> | null,
  TitulationQueryFields,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/GetTitulations",
  async (titleInfo: TitulationQueryFields, thunkApi) => {
    try {
      const model = {
        // ciamId: accountInfo.ciamId,
        // customerId: accountInfo.customerId,
        // country: accountInfo.countryCode,
        language: titleInfo.language,
      };

      const response = await http.post<ResponseResultBase<Titulations[]>>(
        `/api/v1/contactpersons/GetTitulations`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getContactLanguagesAsync = createAsyncThunk<
  ResponseResultBase<ContactLanguage[]> | null,
  ContactLanguageQueryFields,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/GetLanguages", async (req: ContactLanguageQueryFields, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<ContactLanguage[]>>(
      `/api/v1/support/GetLanguages?CountryCode=${req.countryCode}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export interface DashboardState {
  supportRequests: SupportRequestModel[];
  selectedSupportRequest: SupportRequestModel | null;
  vhcRequestDetailsCurrentStep: VHCRequestDetailsStep;
  isSelectedRequestChanged: boolean;
  isAbleToGetCertificateImmediately: boolean;
  serverTime: string;
  isRechargedCreditBalance: boolean;
  priceToUnlockVhcCert: number | null; // credits price
}

const initialState: DashboardState = {
  supportRequests: [],
  selectedSupportRequest: null,
  vhcRequestDetailsCurrentStep: VHCRequestDetailsStep.IN_PROGRESS,
  isSelectedRequestChanged: false,
  isAbleToGetCertificateImmediately: false,
  serverTime: "",
  isRechargedCreditBalance: false,
  priceToUnlockVhcCert: null,
};

export const dashboardSlice = createSlice({
  name: "dashboardReducer",
  initialState,
  reducers: {
    setSelectedSupportRequest: (
      state,
      action: PayloadAction<SupportRequestModel | null>
    ) => {
      return {
        ...state,
        selectedSupportRequest: action.payload,
      };
    },
    setVHCRequestDetailsCurrentStep: (
      state,
      action: PayloadAction<VHCRequestDetailsStep>
    ) => {
      return {
        ...state,
        vhcRequestDetailsCurrentStep: action.payload,
      };
    },
    setSelectedRequestChanged: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isSelectedRequestChanged: action.payload,
      };
    },
    setAbleToGetCertificateImmediately: (
      state,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        isAbleToGetCertificateImmediately: action.payload,
      };
    },
    setServerTime: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        serverTime: action.payload,
      };
    },
    resetVHCRequestState: (state) => {
      return {
        ...state,
        selectedSupportRequest: null,
        vhcRequestDetailsCurrentStep: VHCRequestDetailsStep.IN_PROGRESS,
        isPurchasedVHCRequest: false,
      };
    },
    setRechargedCreditBalance: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isRechargedCreditBalance: action.payload,
      };
    },
    setPriceToUnlockVhcCert: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        priceToUnlockVhcCert: action.payload,
      };
    },
    handleRejectVHCCertificate: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        selectedSupportRequest: !state.selectedSupportRequest
          ? null
          : {
              ...state.selectedSupportRequest,
              supportRequestStatus: {
                ...state.selectedSupportRequest.supportRequestStatus,
                id: Constants.REQUEST_STATUS_ID_CANCELLED,
                ticketStatusLocalized: action.payload,
                ticketStatus: action.payload,
              },
            },
        vhcRequestDetailsCurrentStep:
          VHCRequestDetailsStep.DOWNLOAD_CERTIFICATE,
      };
    },
    handleRequestStatusChange: (
      state,
      action: PayloadAction<VhcRequestStatusChangeType>
    ) => {
      return {
        ...state,
        selectedSupportRequest: !state.selectedSupportRequest
          ? null
          : {
              ...state.selectedSupportRequest,
              supportRequestStatus: {
                ...state.selectedSupportRequest.supportRequestStatus,
                id: action.payload.statusId,
                ticketStatusLocalized: action.payload.statusLocalized,
                ticketStatus: action.payload.statusLocalized,
              },
              supportRequestSubStatus: !action.payload.subStatusId
                ? state.selectedSupportRequest.supportRequestSubStatus
                : !!state.selectedSupportRequest.supportRequestSubStatus
                ? {
                    ...state.selectedSupportRequest.supportRequestSubStatus,
                    id: action.payload.subStatusId,
                    ticketStatus: action.payload.subStatusLocalized || "",
                    ticketStatusLocalized:
                      action.payload.subStatusLocalized || "",
                  }
                : {
                    id: action.payload.subStatusId,
                    ticketStatus: action.payload.subStatusLocalized || "",
                    ticketStatusLocalized:
                      action.payload.subStatusLocalized || "",
                    language:
                      state.selectedSupportRequest.supportRequestStatus
                        .language,
                  },
            },
      };
    },
    updateRequestInfo: (state, action: PayloadAction<SupportRequestModel>) => {
      const newRequestList = state.supportRequests.map((request) => {
        if (request.ticketId !== action.payload.ticketId) return request;

        return {
          ...request,
          ...action.payload,
        };
      });

      return {
        ...state,
        supportRequests: newRequestList,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupportRequestsAsync.fulfilled, (state, { payload }) => {
        return {
          ...state,
          supportRequests: payload?.dataObject || [],
        };
      })
      .addCase(getServerTimeAsync.fulfilled, (state, { payload }) => {
        return {
          ...state,
          serverTime: payload?.serverTime || "",
        };
      });
  },
});

export const selectSupportRequests = (rootState: RootState) =>
  rootState.dashboard.supportRequests;
export const selectSelectedSupportRequest = (rootState: RootState) =>
  rootState.dashboard.selectedSupportRequest;
export const selectVHCRequestDetailsCurrentStep = (rootState: RootState) =>
  rootState.dashboard.vhcRequestDetailsCurrentStep;
export const selectIsSelectedRequestChanged = (rootState: RootState) =>
  rootState.dashboard.isSelectedRequestChanged;
export const selectAbleToGetCertificateImmediately = (rootState: RootState) =>
  rootState.dashboard.isAbleToGetCertificateImmediately;
export const selectServerTime = (rootState: RootState) =>
  rootState.dashboard.serverTime;
export const selectRechargedCreditBalance = (rootState: RootState) =>
  rootState.dashboard.isRechargedCreditBalance;
export const selectPriceToUnlockVhcCert = (rootState: RootState) =>
  rootState.dashboard.priceToUnlockVhcCert;
export const {
  setSelectedSupportRequest,
  setVHCRequestDetailsCurrentStep,
  setSelectedRequestChanged,
  setAbleToGetCertificateImmediately,
  setServerTime,
  setPriceToUnlockVhcCert,
  resetVHCRequestState,
  setRechargedCreditBalance,
  handleRejectVHCCertificate,
  handleRequestStatusChange,
  updateRequestInfo,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const getVehicleNameOfSupportRequestCDMUS = (
  request: SupportRequestModel
) => {
  return `${request.year} ${request.make} ${request.model}`;
};
