import { useTranslation } from "react-i18next";
import { useAdsRedirect } from "../../hooks/useAdsRedirect";
import AdsDialog from "../adsDialog/AdsDialog";
import React from "react";
interface AdsNegativeCreditWarningModalProps {
  onCancel?: () => void;
}

const AdsNegativeCreditWarningModal: React.FC<
  AdsNegativeCreditWarningModalProps
> = ({ onCancel }) => {
  const { t } = useTranslation();
  const { goBackToRDDHomePage } = useAdsRedirect();

  const getMessage = () => {
    const url = process.env.REACT_APP_DEFAULT_CLIENTROOT || "";
    const link = `<a href="${url}">${url}</a>`;

    return t("AdsNegativeCreditWarningModal_Message").replace(
      "{rediLink}",
      link
    );
  };

  return (
    <AdsDialog
      modal
      open
      variant="info"
      title={t("Popup_Title_Info")}
      onConfirm={() => goBackToRDDHomePage()}
      confirmLabel={t("ReturnToPreviousPageButton")}
      onCancel={onCancel}
      cancelLabel={t("CloseButton")}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: getMessage(),
        }}
      ></p>
    </AdsDialog>
  );
};

export default AdsNegativeCreditWarningModal;
