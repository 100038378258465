import style from "./AdsBlockTitle.module.scss";

type Props = {
  title: string;
};

const AdsBlockTitle = ({ title }: Props) => {
  return (
    <div className={style.container}>
      <h5 className={style.title}>{title}</h5>
    </div>
  );
};

export default AdsBlockTitle;
