import { FC, Fragment } from "react";
import { Grid } from "semantic-ui-react";
import style from "./HeaderBar.module.scss";
import RDConversionBar from "../../RDComponents/RDConversionBar";
import AddNewEmployeeButton from "./headerBarButtons/AddNewEmployeeButton";
import AddDeviceButton from "./headerBarButtons/AddDeviceButton";
import NewRequestButton from "./headerBarButtons/NewRequestButton";
import {
  DASHBOARD_REQUESTS_SHARED_ROUTE,
  DASHBOARD_TEAM_SHARED_ROUTE,
} from "../../../constants/routes/sharedRoutes.constants";
import { DASHBOARD_HARDWARE_ROUTE } from "../../../constants/routes/defaultRoutes.constants";
import {
  DASHBOARD_WALLET_CDM_3_ROUTE,
  WALLET_CDM_3_ROUTE,
} from "../../../constants/routes/cdm3v0Routes.constants";
import WalletBalanceInfoCdm3 from "./headerBarButtons/WalletBalanceInfoCdm3";

interface HeaderBarProps {
  content: string;
}

const HeaderBar: FC<any> = (props: HeaderBarProps) => {
  const currentRoute = window.location.pathname;

  const getAdditionalClassName = (): string => {
    switch (currentRoute) {
      case DASHBOARD_WALLET_CDM_3_ROUTE.path:
        return style.wallet_inner_header_bar;
      case WALLET_CDM_3_ROUTE.path:
        return style.wallet_inner_header_bar;
      default:
        return "";
    }
  };

  const renderButton = () => {
    switch (currentRoute) {
      case DASHBOARD_REQUESTS_SHARED_ROUTE.path:
        return <NewRequestButton />;
      case DASHBOARD_TEAM_SHARED_ROUTE.path:
        return <AddNewEmployeeButton />;
      case DASHBOARD_HARDWARE_ROUTE.path:
        return <AddDeviceButton />;
      case DASHBOARD_WALLET_CDM_3_ROUTE.path:
        return <WalletBalanceInfoCdm3 />;

      case WALLET_CDM_3_ROUTE.path:
        return <WalletBalanceInfoCdm3 />;

      default:
        return <></>;
    }
  };

  return (
    <Fragment>
      <div className={`${style.header_bar}`}>
        <Grid>
          <Grid
            className={`${style.inner_header_bar} ${getAdditionalClassName()}`}
          >
            <RDConversionBar
              header={<span>{props.content}</span>}
              actions={<>{renderButton()}</>}
            />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default HeaderBar;
