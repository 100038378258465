import { MenuHeaderItem } from "../../../models/pageConfiguration/MenuHeaderModel";
import { Constants } from "../../../utils/helpers/Constants";

const CdmUs3v0MyRemoteItemChildren: MenuHeaderItem[] = [
  Constants.MenuItemRequest,
  Constants.MenuItemHardware,
  Constants.MenuItemTeam,
  Constants.MenuItemWallet,
  Constants.MenuItemMyProducts,
  Constants.MenuItemStatistic,
];

export const Cdm3v0MenuConfiguration: MenuHeaderItem[] = [
  Constants.MenuItemNewRequest,
  {
    ...Constants.MenuItemMyRemote,
    childrenItems: CdmUs3v0MyRemoteItemChildren,
  },
  Constants.MenuItemProducts,
  Constants.MenuItemSupport,
  Constants.MenuItemMyBosch,
  Constants.MenuItemShoppingCart,
  Constants.MenuItemLogin,
  Constants.MenuItemLogout,
  Constants.MenuItemContactUs,
];
