import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import {
  getAccountSimpleAsync,
  selectAccount,
  selectCiamId,
  selectImList,
  selectOriginComp,
} from "../../redux/account";
import style from "./ChangeCompanyModal.module.css";
import { useAppDispatch, AppDispatch } from "../../redux/store";
import { selectCountryCode, selectLanguage } from "../../redux/auth";
import { Impersonation } from "../../models/Account";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import RDButton from "../RDComponents/RDButton";
import RDDropdown from "../RDComponents/RDDropdown";

interface ChangeCompanyModalProps {
  setCloseModalTrigger: Function;
}

const ChangeCompanyModal: FC<ChangeCompanyModalProps> = ({
  setCloseModalTrigger,
}) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const [switching, setSwitching] = useState<boolean>(false);
  const ciamId = useSelector(selectCiamId);
  const origin = useSelector(selectOriginComp);
  const account = useSelector(selectAccount);
  const workshops = useSelector(selectImList);
  const [managerList, setManagerList] = useState<[] | Impersonation[]>([]);
  const [disabledChange, setDisabledChange] = useState<boolean>(false);
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [companyCiamId, setCompanyCiamId] = useState<string>(ciamId);
  const [customerId, setCustomerId] = useState<number | undefined>();

  const onHandleConfirmChangeCompanyModal = () => {
    if (companyCiamId !== ciamId) {
      setSwitching(true);
      const loadNewBranchPromise = dispatchReduxToolkit(
        getAccountSimpleAsync({
          ciamId: companyCiamId || "",
          language: language,
          countryCode: countryCode,
        })
      );
      loadNewBranchPromise
        .then((res) => {})
        .finally(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  useEffect(() => {
    if (managerList.length === 1) {
      setCompanyCiamId(managerList[0].ciamId);
      setDisabledChange(false);
    } else {
      setDisabledChange(true);
    }
  }, [customerId]);

  useEffect(() => {
    if (!!workshops) {
      setManagerList(
        workshops.filter((x: Impersonation) => x.customerId === account?.cdbId)
      );
    }
  }, [workshops]);

  useEffect(() => {
    setCustomerId(account?.cdbId);
  }, [account]);

  useEffect(() => {
    if (!!workshops) {
      setCompanyOptions(
        workshops
          .filter(
            (value: Impersonation, index: any, self: any) =>
              index ===
              self.findIndex((t: any) => t.customerId === value.customerId)
          )
          .map((x: Impersonation) => {
            return {
              key: x.customerId,
              text: genCompanyName(x),
              value: x.customerId,
            };
          })
      );
    }
  }, [workshops, origin]);

  const onHandleCancelChangeCompanyModal = () => {
    setCloseModalTrigger();
  };

  const genCompanyName = (company: Impersonation) => {
    const { customerId, workshopName1, workshopName2, workshopName4, city } =
      company;
    const companyIdentifier = workshopName4 || city || "";

    const baseName = `${workshopName1} ${workshopName2} ${
      !!companyIdentifier ? `(${companyIdentifier})` : ""
    }`;

    return !origin || origin.cdbId !== customerId
      ? baseName
      : `${baseName} ${t("ChangeCompanyModal_HeadOffice")}`;
  };

  return (
    <Fragment>
      <RDCustomAlert
        type="info"
        isOpen
        title={t("SelectCompanyModal_Title")}
        CancelButton={
          <RDButton
            variant="secondary"
            className={style.cancel_button}
            onClick={onHandleCancelChangeCompanyModal}
            disabled={switching}
          >
            {t("CancelButton")}
          </RDButton>
        }
        AcceptButton={
          <RDButton
            onClick={onHandleConfirmChangeCompanyModal}
            loading={switching}
            disabled={switching || disabledChange}
          >
            {t("Change_Button")}
          </RDButton>
        }
      >
        <Grid className={style.modal_content_grid}>
          <Grid.Row className={style.row}>
            <RDDropdown
              title={t("SelectCompanyModal_Title")}
              placeholder="-"
              options={companyOptions}
              value={customerId}
              search
              onChange={(event: any, data: any) => {
                if (!!workshops) {
                  setCustomerId(data.value);
                  setManagerList(
                    workshops.filter(
                      (x: Impersonation) => x.customerId === data.value
                    )
                  );
                }
              }}
              testId="select-company-dropdown"
            />
          </Grid.Row>
          <Grid.Row className={style.row}>
            <RDDropdown
              title={t("ChangeManagerModal_Content")}
              placeholder="-"
              options={managerList.map((x: Impersonation) => {
                return {
                  key: x.customerId,
                  text: `${x.contactFirstName} ${x.contactLastName}`,
                  value: x.ciamId,
                };
              })}
              value={companyCiamId}
              defaultValue={companyCiamId}
              search
              onChange={(event: any, data: any) => {
                setCompanyCiamId(data.value);
                setDisabledChange(false);
              }}
            />
          </Grid.Row>
        </Grid>
      </RDCustomAlert>
    </Fragment>
  );
};

export default ChangeCompanyModal;
