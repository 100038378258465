import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SupportRequestModel from "../models/support/supportRequest.model";
import { getCreditStatusAsync } from "./creditSlide";
import { RootState } from "./store";
import { getSupportRequestsAsync } from "./supportSlide";

export interface DashboardState {
  supportRequests: SupportRequestModel[];
  pendingRequests: SupportRequestModel[];
  closedRequests: SupportRequestModel[];
  selectedSupportRequest: SupportRequestModel | null;
  creditStatus: number | null;
  isOpenTopUpModalDirectly: boolean;
}

const initialState: DashboardState = {
  supportRequests: [],
  pendingRequests: [],
  closedRequests: [],
  selectedSupportRequest: null,
  creditStatus: null,
  isOpenTopUpModalDirectly: false,
};

export const dashboardCDM3Slice = createSlice({
  name: "dashboardCDM3Reducer",
  initialState,
  reducers: {
    setSelectedSupportRequest: (
      state,
      action: PayloadAction<SupportRequestModel | null>
    ) => {
      return {
        ...state,
        selectedSupportRequest: action.payload,
      };
    },
    updateIsOpenTopUpModalDirectly: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isOpenTopUpModalDirectly: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupportRequestsAsync.fulfilled, (state, { payload }) => {
        return {
          ...state,
          supportRequests: payload?.dataObject || [],
        };
      })
      .addCase(getCreditStatusAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            creditStatus: payload?.dataObject?.credits || 0,
          };
        }
      });
  },
});

export const selectSupportRequests = (rootState: RootState) =>
  rootState.dashboardCMD3.supportRequests;
export const selectSelectedSupportRequest = (rootState: RootState) =>
  rootState.dashboardCMD3.selectedSupportRequest;
export const selectCreditStatus = (rootState: RootState) =>
  rootState.dashboardCMD3.creditStatus;
export const selectIsOpenTopUpModalDirectly = (rootState: RootState) =>
  rootState.dashboardCMD3.isOpenTopUpModalDirectly;
export const { setSelectedSupportRequest, updateIsOpenTopUpModalDirectly } =
  dashboardCDM3Slice.actions;
export default dashboardCDM3Slice.reducer;
