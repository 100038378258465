import Styles from './styles/launcher.module.scss';
import LiveChatBox from './liveChatBox/LiveChatBox';
import { useCloseLiveBoxContext } from './context/CloseLiveBoxContext';
import { Icon } from '@bosch/react-frok';

interface LauncherProps {
    newMessagesCome: boolean;
}

const LauncherIcon: React.FC<LauncherProps> = ({ newMessagesCome }) => {
    const { isLiveChatOpen, setIsLiveChatOpen } = useCloseLiveBoxContext();
    function handleClick() {
        setIsLiveChatOpen(!isLiveChatOpen)
    }
    return (
        <div id="sc-launcher">
            <div
                className={isLiveChatOpen ? `${Styles['sc-launcher']} ${Styles["opened"]}` : `${Styles['sc-launcher']}`}
                onClick={handleClick}
            >
                {(newMessagesCome) ? (
                    <div className={`${Styles['sc-count-unseen']}`}>
                        <Icon iconName='notification-active'></Icon>
                    </div>
                ) : null}
                <Icon iconName="message"></Icon>
            </div>
            {
                isLiveChatOpen && (<LiveChatBox />)
            }
        </div>
    );
};

export default LauncherIcon;