import { AppDispatch, useAppDispatch } from "../redux/store";
import { getUtcTimeAsync } from "../services/GeneralService";
import moment from "moment";

export const useUTCTime = () => {
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();

  const getUtcTimeWithAdjustment = async () => {
    const startTime = Date.now();

    try {
      // Make a GET request to WorldTimeAPI to get the current time
      const response = await dispatchReduxToolkit(getUtcTimeAsync());
      if (
        getUtcTimeAsync.fulfilled.match(response) &&
        response.payload?.dataObject?.utc_datetime
      ) {
        // Parse the response to extract the server time
        const serverTime = moment
          .utc(response.payload?.dataObject?.utc_datetime)
          .toDate();
        const endTime = Date.now(); // Record the end time
        const elapsedTime = endTime - startTime; // Calculate elapsed time
        serverTime.setTime(serverTime.getTime() + elapsedTime);
        return serverTime;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  return { getUtcTimeWithAdjustment };
};
