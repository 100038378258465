import {
  SideNavigationProps,
  SideNavigation as FrokSideNavigation,
  SideNavigationItem,
  Icon,
} from "@bosch/react-frok";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { triggerSelectedSideNavGroupItem } from "../../utils/frok.util";
import SideNavigationBottom from "../sideNavigationBottom/SideNavigationBottom";
import style from "./SideNavigation.module.scss";
import { useSideNavConfig } from "../../hooks/pageConfiguration/useSideNavConfig";
import { MinimalSideNavItem } from "../../types/sideNavigation.type";
import {
  selectCdmVersion,
  selectMainContentPinned,
  selectOpenSideNav,
  setMainContentPinned,
  setOpenSideNav,
} from "../../redux/auth";
import { useSelector } from "react-redux";
import { CdmVersion } from "../../models/pageConfiguration/CdmVersion";
import { applicationRouters } from "../../router/router.config";
import {
  getCurrentRoutePath,
  getParentSideNavItem,
} from "../../utils/routing.util";
import { useAppDispatch } from "../../redux/hooks";
import { STATIC_SIDE_NAV_ITEM } from "../../constants/sideNav.constant";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../../constants/frok.constant";
import RDSideNavigationGroup from "@components/RDComponents/RDSideNavigationGroup/RDSideNavigationGroup";

type Props = SideNavigationProps & {};

const SideNavigation = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isOpenSideNav = useSelector(selectOpenSideNav);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const cdmVersion = useSelector(selectCdmVersion);
  const { sideNavItems } = useSideNavConfig();
  const [currentRoutePath, setCurrentRoutePath] = useState<string | undefined>(
    getCurrentRoutePath(applicationRouters[cdmVersion as CdmVersion] || [])
  );

  useEffect(() => {
    setCurrentRoutePath(
      getCurrentRoutePath(applicationRouters[cdmVersion as CdmVersion] || [])
    );
  }, [window.location.pathname, cdmVersion]);

  useEffect(() => {
    if (sideNavItems.length === 0) return;

    setCurrentRoutePath(
      !!isOpenSideNav
        ? getCurrentRoutePath(
            applicationRouters[cdmVersion as CdmVersion] || []
          )
        : getParentSideNavItem(sideNavItems)?.path
    );
  }, [isOpenSideNav, cdmVersion, sideNavItems]);

  useEffect(() => {
    if (sideNavItems.length === 0) return;

    const currentRoutePath = getParentSideNavItem(sideNavItems)?.path;

    if (!currentRoutePath) return;

    const timeoutId = window.setTimeout(() => {
      triggerSelectedSideNavGroupItem(
        currentRoutePath,
        !!isOpenSideNav ? false : true
      );
      window.clearTimeout(timeoutId);
    }, 25);
  }, [isOpenSideNav, cdmVersion, sideNavItems]);

  const onChangePage = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    data: any
  ) => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));

    if (
      event.currentTarget.parentElement?.attributes.getNamedItem("aria-label")
        ?.value === STATIC_SIDE_NAV_ITEM
    )
      return;

    history.push(data.value);
  };

  const renderSingleMenuItem = (
    route: MinimalSideNavItem,
    isSubItem: boolean
  ) => {
    return (
      <SideNavigationItem
        icon={!!isSubItem ? "" : route.icon}
        value={route.path}
        label={t(route.label)}
      />
    );
  };

  const renderMultiLevelMenuItem = (route: MinimalSideNavItem) => {
    if (!!route.isHideOnSideNavigation) return <></>;

    return (
      <RDSideNavigationGroup
        id={route.path}
        icon={route.icon}
        value={route.path}
        label={t(route.label)}
        isDefaultSelected={
          getParentSideNavItem(sideNavItems)?.path === route.path
        }
        subItemsCount={route.childItems ? route.childItems.length : 0}
      >
        {route.childItems?.map((childItem) =>
          renderSingleMenuItem(childItem, true)
        )}
      </RDSideNavigationGroup>
    );
  };

  const renderMenuItem = (route: MinimalSideNavItem) => {
    if (
      !route.childItems ||
      route.childItems.length === 0 ||
      route.childItems.every((item) => !!item.isHideOnSideNavigation)
    )
      return renderSingleMenuItem(route, false);

    return renderMultiLevelMenuItem(route);
  };

  const onOpenChange = (open: boolean, _?: React.SyntheticEvent) => {
    dispatch(setOpenSideNav(open));
  };

  const autoTriggerSideNav = (value: boolean) => {
    if (isMainContentPinned) return;

    if (
      window.screen.availWidth >= SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT &&
      isOpenSideNav !== value
    )
      dispatch(setOpenSideNav(value));
  };

  return (
    <FrokSideNavigation
      {...props}
      header={{
        label: t("MyRequests_RequestType_Remote"),
      }}
      open={!!isOpenSideNav}
      onOpenChange={onOpenChange}
      onSelectedItemChange={onChangePage}
      defaultSelectedItem={currentRoutePath}
      selectedItem={currentRoutePath}
      className={`${style.container} ${!!isOpenSideNav ? style.open : ""}`}
      onMouseOver={() => autoTriggerSideNav(true)}
      onMouseLeave={() => autoTriggerSideNav(false)}
    >
      <button
        className={`a-button a-button--integrated -without-label m-side-navigation__header__trigger ${
          style.pin_button
        } ${isOpenSideNav ? style.pin_button_shown : ""}`}
        onClick={() => dispatch(setMainContentPinned())}
      >
        <Icon
          iconName={
            isMainContentPinned ? "unpin-classic-disabled-light" : "pin-classic"
          }
          className="a-button__icon"
        />
      </button>
      <div className={style.wrapper}>
        {sideNavItems.map((item, index) => (
          <Fragment key={index}>{renderMenuItem(item)}</Fragment>
        ))}
      </div>
      <SideNavigationBottom />
    </FrokSideNavigation>
  );
};

export default SideNavigation;
