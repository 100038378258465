import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface ProductsState {
  isShowRedeemVoucherModal: boolean;
}

const initialState: ProductsState = {
  isShowRedeemVoucherModal: false,
};

export const productsSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    showRedeemModal: (state) => {
      return {
        ...initialState,
        isShowRedeemVoucherModal: true,
      };
    },
    hideRedeemModal: (state) => {
      return {
        ...initialState,
        isShowRedeemVoucherModal: false,
      };
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(searchMethodsByCountryAsync.fulfilled, (state, { payload }) => {
    //     if (payload != null && payload.httpStatusCode == 200 && payload.dataObject) {
    //       return {
    //         ...state,
    //         countrySearchMethods: [...(payload.dataObject as any)]
    //       }
    //     }
    //   });
  },
});
export const selectIsShowRedeemVoucherModal = (rootState: RootState) =>
  rootState.products.isShowRedeemVoucherModal;
export const { showRedeemModal, hideRedeemModal } = productsSlice.actions;
export default productsSlice.reducer;
