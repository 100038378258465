import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ResponseResultBase from "../../models/ResponseResultBase";
import { selectCiamId } from "../../redux/account";
import { selectCountryCode, selectLanguage } from "../../redux/auth";
import { useAppDispatch } from "../../redux/hooks";
import {
  getCartAsync,
  replaceCart,
  selectShoppingCart,
  ShoppingCartV3,
} from "../../redux/shoppingCartV3Slice";

export const useGetCart = () => {
  const dispatchReduxToolkit = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const shoppingCart = useSelector(selectShoppingCart);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!ciamId) return;

    //If there is nothing in shopping cart local => Dispatch getCart action to get cart from database
    if (
      !shoppingCart ||
      !shoppingCart.shoppingItems ||
      shoppingCart.shoppingItems.length <= 0
    ) {
      setShowLoader(true);
      const response = dispatchReduxToolkit(
        getCartAsync({
          ciamId: ciamId,
          language: language,
          countryCode: countryCode,
        })
      );
      response
        .then((response) => {
          if (response && response.payload) {
            const shoppingCart =
              response.payload as ResponseResultBase<ShoppingCartV3>;
            if (
              !!shoppingCart &&
              shoppingCart.dataObject &&
              shoppingCart.dataObject.id > 0
            ) {
              dispatchReduxToolkit(replaceCart(shoppingCart.dataObject));
            }
          }
        })
        .finally(() => setShowLoader(false));
    }
    // else if (shoppingCart.id == 0) {
    //   //Remove cart in database if user process for new cart
    //   dispatchReduxToolkit(
    //     deleteCartAsync({
    //       ciamId: ciamId,
    //     })
    //   );
    // }
  }, [ciamId]);

  return [showLoader];
};
