import "./semantic/dist/semantic.min.css";
import "./App.css";
import "@bosch/frontend.kit-npm/dist/frontend-kit.css";
import Router from "./router";
import { findWindows } from "windows-iana";
import ct from "countries-and-timezones";
import { Fragment, useEffect, useState } from "react";
import { Region } from "./models/RegionState";
import { AppDispatch, useAppDispatch } from "./redux/store";
import {
  getGeolocationAsync,
  getRegionsAsync,
  setRediRegions,
} from "./redux/region";
import { AuthService } from "./services/AuthService";
import {
  authFetchState,
  authOverrideInfo,
  authSetNewState,
  AuthState,
  selectAuthState,
  selectIsSignIn,
} from "./redux/auth";
import { useSelector } from "react-redux";
import RedirectHelper from "./utils/helpers/RedirectHelper";
import {
  logoutAsync,
  selectAccount,
  selectAccountSubscription,
} from "./redux/account";
import BrowserVersionNotSupport from "./components/BrowserVersionNotSupport/BrowserVersionNotSupport";
import CustomLoader from "./components/loading/CustomLoader";
import { useHistory } from "react-router-dom";
import SelectWholesalerModalCDM3 from "./components/ShoppingCart/ShoppingCartCDM3/ShoppingCartDetails/ShoppingCartDetailLeftPanel/SelectWholesalerModalCDM3";
import { Constants } from "./utils/helpers/Constants";
import TrialStatus from "./models/TrialStatus";
import { useGetAccountSimple } from "./hooks/httpHook/useGetAccountSimple";
import MaintenancePage from "./components/Maintaining/MaintenancePage";
import { useGetAccountSettings } from "./hooks/httpHook/useGetAccountSettings";
import ForbiddenPage from "./components/ErrorPages/ForbiddenPage";
import TagManager from "react-gtm-module";
import { useGetAssignedApplication } from "./hooks/httpHook/useGetAssignedApplication";

const App = () => {
  const isIE = !!(document as any).documentMode;
  const isMaintainingMode =
    !!process.env.REACT_APP_ISMAINTAINING &&
    process.env.REACT_APP_ISMAINTAINING.toLowerCase() === "on";
  const [regions, setRegions] = useState<Region[]>([]);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsSignIn);
  const auth = useSelector(selectAuthState);
  const account = useSelector(selectAccount);
  const userSubscription = useSelector(selectAccountSubscription);
  const history = useHistory();
  const [isShowWholesalerModal, setIsShowWholesalerModal] = useState(false);
  const [showLoader] = useGetAccountSimple();
  const [executingGetAccountSettings] = useGetAccountSettings();
  const [executingGetAssignedApplication] = useGetAssignedApplication();
  const [isRestrictedGeoLocation, setIsRestrictedGeoLocation] =
    useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const country = window.location.search !== "" ? urlParams.get("c") : "";
  const lang = window.location.search !== "" ? urlParams.get("l") : "";
  const redirectUrl =
    window.location.search !== "" ? urlParams.get("redirect") : "";

  const onSetAuthState = (region: Region | undefined) => {
    if (!!country && country !== "" && !!region) {
      let timeZoneId = "";
      if (country.trim().toLowerCase() === "uk") {
        const countryName = ct.getCountry("GB");
        const timeZoneName = countryName?.timezones[0];
        if (timeZoneName !== undefined) {
          timeZoneId = findWindows(timeZoneName)[0];
        }
      } else {
        const countryName = ct.getCountry(country);
        const timeZoneName = countryName?.timezones[0];
        if (timeZoneName !== undefined) {
          timeZoneId = findWindows(timeZoneName)[0];
        }
      }
      let authLanguage = lang;
      if (!authLanguage) {
        const defaultLanguage = region.languages.find(
          (lan) => lan.defaultLanguage
        );
        authLanguage = defaultLanguage ? defaultLanguage.lcid : lang;
      }
      const newAuthState = {
        //domain: region.url,
        language: authLanguage,
        countryCode: country,
        countryName: region.countryName,
        //clientId: region.clientId,
        timeZoneId: timeZoneId,
        languages: region.languages,
        cdmVersion: region.cdmVersion,
        hasWholesalerConfig: region.hasWholesalerConfig,
      } as AuthState;
      AuthService.setAuthState(newAuthState);
      dispatchReduxToolkit(authSetNewState(newAuthState));

      if (!!redirectUrl) {
        history.push(redirectUrl);
      }
    } else {
      dispatchReduxToolkit(authFetchState());
    }
    dispatchReduxToolkit(authOverrideInfo(region));
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-WL6NRVJ",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (!isMaintainingMode && !isIE) {
      const fetchRegions = async () => {
        //Get Regions Languages
        let regions = AuthService.getRegionsLanguages();
        let regionSelection = null;
        const resGetRegions = await dispatchReduxToolkit(getRegionsAsync());
        if (getRegionsAsync.fulfilled.match(resGetRegions)) {
          if (!!resGetRegions.payload && !!resGetRegions.payload.dataObject) {
            AuthService.setRegionsLanguages(
              !!resGetRegions.payload.dataObject
                ? resGetRegions.payload.dataObject
                : []
            );
            regions = resGetRegions.payload.dataObject;
            dispatchReduxToolkit(
              setRediRegions(resGetRegions.payload.dataObject)
            );
          }
        }
        //}

        const authState = AuthService.getAuthState();
        //First Time Access The Website
        if (
          authState.countryCode === "" &&
          country?.trim().toLowerCase().length === 0 &&
          !window.location.href.toLowerCase().includes("/regions") &&
          !window.location.href
            .toLowerCase()
            .includes("/registerpersonalaccount") &&
          !window.location.href.toLowerCase().includes("/term-condition") &&
          !window.location.href.toLowerCase().includes("/privacy-policy/") &&
          !window.location.href
            .toLowerCase()
            .includes("/corporate-information/") &&
          !window.location.href
            .toLowerCase()
            .includes("/data-protection-notice/") &&
          !window.location.href.toLowerCase().includes("/legal-notice/")
        ) {
          const resGeoLocation = await dispatchReduxToolkit(
            getGeolocationAsync()
          );
          if (getGeolocationAsync.fulfilled.match(resGeoLocation)) {
            if (
              !!resGeoLocation.payload &&
              resGeoLocation.payload.success &&
              !!resGeoLocation.payload.dataObject
            ) {
              AuthService.setGeolocation(resGeoLocation.payload.dataObject);
              let countryCodeGeolocation =
                resGeoLocation.payload.dataObject.countryCode;
              regionSelection = regions.find(
                (x) =>
                  x.isActive &&
                  x.country.trim().toLowerCase() ===
                    countryCodeGeolocation.trim().toLowerCase()
              );
              if (!!regionSelection) {
                const countryCodeCheck = regionSelection.country
                  .trim()
                  .toUpperCase();
                const language = regionSelection.languages.find(
                  (l) => l.defaultLanguage
                );
                if (countryCodeCheck.length > 0) {
                  switch (countryCodeCheck) {
                    case "BA":
                      RedirectHelper.redirectBasedOnGeolocation("HR", "hr");
                      break;
                    case "SL":
                      RedirectHelper.redirectBasedOnGeolocation("HR", "sl");
                      break;
                    default:
                      RedirectHelper.redirectBasedOnGeolocation(
                        countryCodeCheck,
                        !!language ? language.lcid : ""
                      );
                      break;
                  }
                }
              } else {
                window.location.href = "/regions";
              }
            }
          }
        }

        //Setup based on country in url
        if (
          country?.trim().toLowerCase().length !== 0 ||
          authState.countryCode.trim().toLowerCase().length !== 0
        ) {
          let selectedCountry = country?.trim().toLowerCase();
          if (
            selectedCountry?.length === 0 &&
            authState.countryCode.trim().toLowerCase().length > 0
          )
            selectedCountry = authState.countryCode.trim().toLowerCase();
          regionSelection = regions.find(
            (x) => x.country.trim().toLowerCase() === selectedCountry
          );
          onSetAuthState(regionSelection);
        }

        setRegions(regions);
        window.history.scrollRestoration = "manual";
      };

      fetchRegions();

      if (!isLoggedIn && !!account && !!account.ciamId) {
        logout();
      } else {
        fetchRegions();
      }
    }
  }, []);

  const logout = async () => {
    await dispatchReduxToolkit(logoutAsync());
  };

  const detectCountry = async () => {
    let resGeoLocation = await dispatchReduxToolkit(getGeolocationAsync());
    if (getGeolocationAsync.fulfilled.match(resGeoLocation)) {
      if (
        !!resGeoLocation.payload &&
        resGeoLocation.payload.success &&
        !!resGeoLocation.payload.dataObject
      ) {
        AuthService.setGeolocation(resGeoLocation.payload.dataObject);
        let countryCodeGeolocation =
          resGeoLocation.payload.dataObject.countryCode;

        if (countryCodeGeolocation) {
          if (
            Constants.RESTRICTED_GEO_COUNTRIES.includes(countryCodeGeolocation)
          ) {
            setIsRestrictedGeoLocation(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    detectCountry();
  }, []);

  useEffect(() => {
    if (
      auth?.countryCode === account?.companyCountry &&
      !!account?.cdbId &&
      !!auth.hasWholesalerConfig &&
      !account.wholesalerBranch &&
      !!userSubscription &&
      userSubscription?.id > 0 &&
      userSubscription?.trialStatus === TrialStatus.None &&
      userSubscription?.subscription?.cdm3SubscriptionType.id !==
        Constants.SUBSCRIPTION_PAY_AS_YOU_GO_SUBSCRIPTION_TYPE_ID &&
      account?.userRole?.id === Constants.USER_ROLE_MANAGER_ID
    ) {
      setIsShowWholesalerModal(true);
    } else {
      setIsShowWholesalerModal(false);
    }
  }, [account, auth, userSubscription]);

  return (
    <Fragment>
      {isRestrictedGeoLocation ? (
        <ForbiddenPage />
      ) : isIE ? (
        <BrowserVersionNotSupport />
      ) : isMaintainingMode ? (
        <MaintenancePage />
      ) : !!regions && regions.length > 0 ? (
        <>
          <Router />
          {isShowWholesalerModal && <SelectWholesalerModalCDM3 />}
          {(showLoader ||
            executingGetAccountSettings ||
            executingGetAssignedApplication) && <CustomLoader enable />}
        </>
      ) : (
        <CustomLoader enable />
      )}
    </Fragment>
  );
};
export default App;
