import { FC, Fragment } from "react";
import NewRequestButton from "./headerBarButtons/NewRequestButton";
import AddNewEmployeeButton from "./headerBarButtons/AddNewEmployeeButton";
import WalletBalanceInfo from "./headerBarButtons/WalletBalanceInfo";
import RDConversionBar from "../../RDComponents/RDConversionBar";
import style from "./HeaderBarCdmUs1v0.module.scss";
import {
  DASHBOARD_REQUESTS_SHARED_ROUTE,
  DASHBOARD_TEAM_SHARED_ROUTE,
  MY_SUBSCRIPTION_SHARED_ROUTE,
} from "../../../constants/routes/sharedRoutes.constants";
import {
  DASHBOARD_MY_SUBSCRIPTION_CDM_US_ROUTE,
  DASHBOARD_WALLET_CDM_US_ROUTE,
  WALLET_CDM_US_ROUTE,
} from "../../../constants/routes/cdmUS1v0Routes.constants";

interface HeaderBarProps {
  content: string;
}

const HeaderBarCdmUs1v0: FC<HeaderBarProps> = (props: HeaderBarProps) => {
  const renderButton = (route: string) => {
    switch (route) {
      case DASHBOARD_REQUESTS_SHARED_ROUTE.path:
        return <NewRequestButton />;
      case DASHBOARD_TEAM_SHARED_ROUTE.path:
        return <AddNewEmployeeButton />;
      case DASHBOARD_WALLET_CDM_US_ROUTE.path:
      case WALLET_CDM_US_ROUTE.path:
      case DASHBOARD_MY_SUBSCRIPTION_CDM_US_ROUTE.path:
      case MY_SUBSCRIPTION_SHARED_ROUTE.path:
        return <WalletBalanceInfo />;
      default:
        return <></>;
    }
  };

  return (
    <Fragment>
      <div className={`${style.header_bar}`}>
        <div className={`${style.inner_header_bar}`}>
          {/* <Grid.Row className={style.inner_header_bar_row}>
            <Grid.Column
              computer={12}
              tablet={8}
              mobile={16}
              className={`${style.header_content}`}
            >
              <span>{props.content}</span>
            </Grid.Column>
            <Grid.Column
              computer={4}
              tablet={8}
              mobile={16}
              className={`${style.connecter_status}`}
            >
              {renderButton(window.location.pathname)}
            </Grid.Column>
          </Grid.Row> */}
          <RDConversionBar
            header={<span>{props.content}</span>}
            actions={<>{renderButton(window.location.pathname)}</>}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderBarCdmUs1v0;
