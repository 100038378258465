import { DropdownItemProps } from "semantic-ui-react";
import style from "./AdsUsStateOption.module.scss";
import { useSelector } from "react-redux";
import { selectFormValues } from "../../../../redux/newRequestSlice";
import { useEffect, useRef } from "react";
import { scrollIntoCurrentView } from "../../../../utils/dom.util";

type Props = {
  option: DropdownItemProps;
  onClick: (stateCode: string) => void;
};

const AdsUsStateOption = ({ option, onClick }: Props) => {
  const formValues = useSelector(selectFormValues);
  const optionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (formValues.searchCountryState === option.value) {
      scrollIntoCurrentView(optionRef);
    }
  }, [formValues.searchCountryState]);

  return (
    <div
      ref={optionRef}
      className={`${style.state_option} ${
        option.value === formValues.searchCountryState
          ? style.state_option_selected
          : ""
      }`}
      onClick={() => onClick(option.value as string)}
    >
      {option.text}
    </div>
  );
};

export default AdsUsStateOption;
