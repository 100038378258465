import { ReactNode } from "react";
import style from "./RDText.module.css";

type Props = {
  maxLine?: number;
  size?: "s" | "m" | "l" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl";
  className?: string;
  children?: ReactNode;
  content?: string;
  isFitContent?: boolean;
  isBold?: boolean;
  align?: "center" | "left" | "right";
};

const RDText = ({
  maxLine,
  size,
  className,
  children,
  content,
  isFitContent,
  isBold,
  align,
}: Props) => {
  const getAllClassNames = () => {
    const allClassNames: string[] = ["text_oversize_handler", style.rd_text];

    allClassNames.push(style["_" + (size || "m")]);
    if (!!isFitContent) allClassNames.push(style.fit_content);
    if (!!className) allClassNames.push(className);

    return allClassNames.join(" ");
  };

  return (
    <p
      style={{
        WebkitLineClamp: maxLine ? maxLine : "unset",
        lineClamp: maxLine ? maxLine : "unset",
        fontWeight: !!isBold ? "bold" : "normal",
        textAlign: align || "left",
      }}
      className={getAllClassNames()}
    >
      {content || children}
    </p>
  );
};

export default RDText;
