const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ".",
  decimalSeparator: ",",
  //symbol: '$'
};

export const useCurrencyFormat = () => {
  const format = (
    value: number | null,
    symbol: string,
    country: string | null
  ) => {
    if (!value && value !== 0) {
      return "-";
    }
    let preSymbol = "";
    let suffixSymbol = "";
    let options = { ...defaultOptions };
    if (!!country) {
      switch (country.toUpperCase()) {
        case "US":
        case "UK":
        case "AU":
        case "NZ":
          options = {
            ...defaultOptions,
            thousandsSeparator: ",",
            decimalSeparator: ".",
          };
          preSymbol = symbol;
          break;
        case "BE":
        case "NL":
        case "LU":
        case "FR":
          preSymbol = symbol;
          break;
        default:
          suffixSymbol = symbol;
          break;
      }
    }

    let ret = value.toFixed(options.significantDigits);

    const [currency, decimal] = ret.split(".");
    return `${preSymbol} ${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.thousandsSeparator
    )}${options.decimalSeparator}${decimal} ${suffixSymbol || ""}`;
  };

  return format;
};
