import {
  AdsRequestDetailsFields,
  AdsVehicleIdentificationErrors,
  AdsVehicleLoadingFields,
} from "../types/adsNewRequest.type";

export const ADS_COUNTRY_CODE_SEARCH_QUERY_KEY = "COUNTRY_CODE";
export const ADS_LANGUAGE_SEARCH_QUERY_KEY = "LANG";
export const ADS_VIN_SEARCH_QUERY_KEY = "VIN";
export const ADS_YEAR_SEARCH_QUERY_KEY = "YEAR";
export const ADS_MAKE_SEARCH_QUERY_KEY = "MAKE";
export const ADS_MODEL_SEARCH_QUERY_KEY = "MODEL";
export const ADS_ENGINE_SEARCH_QUERY_KEY = "ENGINE";
export const ADS_ACES_ID_SEARCH_QUERY_KEY = "ACESID";
export const ADS_VCI_SERIAL_SEARCH_QUERY_KEY = "ticket_vciSerial";
export const ADS_DIAGNOSTIC_TOOL_SERIAL_SEARCH_QUERY_KEY =
  "ticket_diagnosticToolSerial";

export const ADS_MILEAGE_FIELD_MAX_LENGTH = 9;
export const ADS_REPAIR_ORDER_FIELD_MAX_LENGTH = 45;

export const ADS_VEHICLE_LOADING_FIELDS: AdsVehicleLoadingFields = {
  make: false,
  model: false,
  engine: false,
  year: false,
  variant: false,
};

export const ADS_VEHICLE_IDENTIFICATION_ERROR: AdsVehicleIdentificationErrors =
  {
    vin: "",
    plate: "",
    make: "",
    model: "",
    engine: "",
    variant: "",
    year: "",
    mileage: "",
  };

export const ADS_REQUEST_DETAILS_FIELDS_INIT: AdsRequestDetailsFields = {
  repairOrder: "",
  diagnosticToolSerial: "",
  vciSerial: "",
};
