import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { CheckoutWithFullVoucherModel } from "../models/paymentAdyen/checkoutWithFullVoucher.model";
import ResponseResultBase from "../models/ResponseResultBase";
import http from "../services/HttpService";
import { AppDispatch, RootState } from "./store";
import { CheckoutByBoschAccountModel } from "../models/paymentAdyen/checkoutByBoschAccount.model";

export type CheckoutStep =
  | "Delivery"
  | "Payment"
  | "Review"
  | "PlaceOrder"
  | "Complete"
  | "Error";

interface ListStepState {
  id: CheckoutStep;
  label: string;
  iconName: string;
  index: number;
  type?: "error" | "";
}

interface CheckOutState {
  listStepState: ListStepState[];
  currentStep: ListStepState;
  isBuySubscription: boolean;
  adyenErrorCode: number | null;
}

export const CheckoutStepDelivery: ListStepState = {
  id: "Delivery",
  label: `CheckOutCDM3Header_Step_Delivery`,
  iconName: "bosch-ic-van-delivery",
  index: 1,
};

export const CheckoutStepPayment: ListStepState = {
  id: "Payment",
  label: `CheckOutCDM3Header_Step_Payment`,
  iconName: "bosch-ic-payment",
  index: 2,
};

export const CheckoutStepReview: ListStepState = {
  id: "Review",
  label: `CheckOutCDM3Header_Step_Review`,
  iconName: "bosch-ic-search",
  index: 3,
};

export const CheckoutStepPlaceOrder: ListStepState = {
  id: "PlaceOrder",
  label: `CheckOutCDM3Header_Step_Place_Order`,
  iconName: "bosch-ic-paperplane",
  index: 4,
};

export const CheckoutStepComplete: ListStepState = {
  id: "Complete",
  label: `CheckOutCDM3Header_Step_Place_Order`,
  iconName: "bosch-ic-paperplane",
  index: 5,
};

export const CheckoutStepError: ListStepState = {
  id: "Error",
  label: `Popup_Title_Error`,
  iconName: "bosch-close",
  type: "error",
  index: 6,
};

const listStep: ListStepState[] = [
  CheckoutStepDelivery,
  CheckoutStepPayment,
  CheckoutStepReview,
  CheckoutStepPlaceOrder,
  CheckoutStepComplete,
];

const allListStep: ListStepState[] = [
  ...listStep,
  CheckoutStepError
];

const initialState: CheckOutState = {
  listStepState: listStep,
  currentStep: listStep[0],
  isBuySubscription: false,
  adyenErrorCode: null,
};

export const submitPaymentCdm3Async = createAsyncThunk<
  string | null,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("cdm3/payment/submitPayment", async (submitPaymentData: any, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<string>>(
      `/api/v1/cdm3/payment/submitPayment`,
      submitPaymentData
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const submitSepaPaymentCdm3Async = createAsyncThunk<
  string | null,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "cdm3/payment/SubmitSepaPayment",
  async (submitPaymentData: any, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<string>>(
        `/api/v1/cdm3/payment/SubmitSepaPayment`,
        submitPaymentData
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const checkoutForPayAsYouGoAsync = createAsyncThunk<
  string | null,
  CheckoutWithFullVoucherModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "cdm3/payment/CheckoutForPayAsYouGo",
  async (submitPaymentData: CheckoutWithFullVoucherModel, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<string>>(
        `/api/v1/cdm3/payment/CheckoutForPayAsYouGo`,
        submitPaymentData
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const submitPaymentDetailCdm3Async = createAsyncThunk<
  string | null,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "cdm3/payment/processDetails",
  async (submitPaymentDetailData: any, thunkApi) => {
    try {
      let submitDetail = JSON.stringify(submitPaymentDetailData)
        .replace("threeds2.fingerprint", "threeds2fingerprint")
        .replace("threeds2.challengeResult", "threeds2challengeResult");
      let submitObjDetail = JSON.parse(submitDetail);
      const response = await http.post<ResponseResultBase<string>>(
        `/api/v1/cdm3/payment/processDetails`,
        submitObjDetail
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const checkoutCartByBoschAccount = createAsyncThunk<
  boolean | null,
  CheckoutByBoschAccountModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("cdm3/payment/SubmitBoschAccountPayment", async (requestModel, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<string>>(
      `/api/v1/cdm3/payment/SubmitBoschAccountPayment`,
      requestModel
    );
    return response.data.success;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const checkoutSlide = createSlice({
  name: "checkoutReducer",
  initialState,
  reducers: {
    setListStepModal: (state, action: PayloadAction<ListStepState[]>) => {
      return {
        ...state,
        listStepState: action.payload,
      };
    },
    updateListStepModal: (state, action: PayloadAction<ListStepState[]>) => {
      return {
        ...state,
        listStepState: action.payload,
      };
    },
    resetListStepModal: (state) => {
      return {
        ...state,
        listStepState: listStep,
      };
    },
    setCurrentStepModal: (state, action: PayloadAction<CheckoutStep>) => {
      const step = allListStep.find((x) => x.id === action.payload);
      if (step) {
        return {
          ...state,
          currentStep: step,
        };
      }

      return {
        ...state,
      };
    },
    updateIsBuySubscription: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isSubmitTicket: false,
        isBuySubscription: action.payload,
      };
    },
    updateAdyenErrorCode: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        adyenErrorCode: action.payload,
      };
    },
  },
});

export const selectCheckoutState = (rootState: RootState) => rootState.checkout;
export const selectListStep = (rootState: RootState) =>
  rootState.checkout.listStepState;
export const selectCheckoutCurrentStep = (rootState: RootState) =>
  rootState.checkout.currentStep;
export const selectCheckoutFirstStep = (rootState: RootState) =>
  rootState.checkout.listStepState &&
  rootState.checkout.listStepState.length > 0
    ? rootState.checkout.listStepState[0]
    : null;
export const selectIsCheckoutLastStep = (rootState: RootState) =>
  rootState.checkout.currentStep.index ===
  rootState.checkout.listStepState[rootState.checkout.listStepState.length - 1]
    .index;
export const selectAdyenErrorCode = (rootState: RootState) =>
  rootState.checkout.adyenErrorCode;
export const {
  setListStepModal,
  updateListStepModal,
  resetListStepModal,
  setCurrentStepModal,
  updateIsBuySubscription,
  updateAdyenErrorCode,
} = checkoutSlide.actions;
export default checkoutSlide.reducer;
