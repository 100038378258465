import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountSimpleAsync, selectCiamId } from "../../redux/account";
import {
  selectCountryCode,
  selectIsSignIn,
  selectLanguage,
} from "../../redux/auth";
import { useAppDispatch } from "../../redux/hooks";
import { getCreditStatusAsync } from "../../redux/creditSlide";

export const useGetAccountSimple = () => {
  const dispatchReduxToolkit = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const [showLoader, setShowLoader] = useState(false);
  const isLoggedIn = useSelector(selectIsSignIn);

  useEffect(() => {
    if (
      isLoggedIn &&
      !window.location.pathname.startsWith("/signoutcallback")
    ) {
      setShowLoader(true);
      Promise.all([
        dispatchReduxToolkit(
          getAccountSimpleAsync({
            ciamId,
            language,
            countryCode,
          })
        ),
        dispatchReduxToolkit(getCreditStatusAsync(ciamId)),
      ]).finally(() => setShowLoader(false));
    }
  }, [isLoggedIn]);

  return [showLoader];
};
