import {
  SIDE_NAV_ALL_REQUESTS_ITEM,
  SIDE_NAV_CHECKOUT_ITEM,
  SIDE_NAV_HARDWARE_ITEM,
  SIDE_NAV_HOME_ITEM,
  SIDE_NAV_MY_PRODUCTS_ITEM,
  SIDE_NAV_MY_REMOTE_ITEM,
  SIDE_NAV_NEW_REQUEST_ITEM,
  SIDE_NAV_NEW_REQUEST_REDI_ITEM,
  SIDE_NAV_NEW_REQUEST_VHC_ITEM,
  SIDE_NAV_PRODUCTS_ITEM,
  SIDE_NAV_PRODUCT_DETAILS_ITEM,
  SIDE_NAV_REQUEST_DETAILS_ITEM,
  SIDE_NAV_SHOPPING_CART_ITEM,
  SIDE_NAV_STATISTIC_ITEM,
  SIDE_NAV_SUPPORT_ITEM,
  SIDE_NAV_TEAM_CREATE_ITEM,
  SIDE_NAV_TEAM_EDIT_ITEM,
  SIDE_NAV_TEAM_ITEM,
  SIDE_NAV_WALLET_ITEM,
} from "../../../constants/sideNav.constant";
import { SideNavItem } from "../../../types/sideNavigation.type";

const CDM_3_MY_REMOTE_CHILD_ITEMS: SideNavItem[] = [
  {
    ...SIDE_NAV_ALL_REQUESTS_ITEM,
    childItems: [SIDE_NAV_REQUEST_DETAILS_ITEM],
  },
  SIDE_NAV_HARDWARE_ITEM,
  {
    ...SIDE_NAV_TEAM_ITEM,
    childItems: [SIDE_NAV_TEAM_CREATE_ITEM, SIDE_NAV_TEAM_EDIT_ITEM],
  },
  SIDE_NAV_WALLET_ITEM,
  SIDE_NAV_MY_PRODUCTS_ITEM,
  SIDE_NAV_STATISTIC_ITEM,
];

export const CDM_3_SIDE_NAV_CONFIG: SideNavItem[] = [
  SIDE_NAV_HOME_ITEM,
  {
    ...SIDE_NAV_NEW_REQUEST_ITEM,
    childItems: [SIDE_NAV_NEW_REQUEST_REDI_ITEM, SIDE_NAV_NEW_REQUEST_VHC_ITEM],
  },
  {
    ...SIDE_NAV_MY_REMOTE_ITEM,
    childItems: CDM_3_MY_REMOTE_CHILD_ITEMS,
  },
  {
    ...SIDE_NAV_PRODUCTS_ITEM,
    childItems: [SIDE_NAV_PRODUCT_DETAILS_ITEM],
  },
  SIDE_NAV_SUPPORT_ITEM,
  {
    ...SIDE_NAV_SHOPPING_CART_ITEM,
    childItems: [SIDE_NAV_CHECKOUT_ITEM],
  },
];
