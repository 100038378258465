import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Constants } from "../utils/helpers/Constants";
import { ShoppingCartItem } from "../models/ShoppingCart/ShoppingCartItem";
import {
  AddOn,
  SubscriptionV3,
} from "../models/subscription/SubscriptionV3.model";
import { useAppDispatch } from "../redux/hooks";
import { selectSubscriptions } from "../redux/servicePlansSlice";
import {
  // selectCartSubscriptionId,
  addSubscriptionToCart as addSubscriptionToCartRedux,
  selectShoppingCart,
  selectShoppingCartItems,
} from "../redux/shoppingCartV3Slice";

export const useCartSubscription = () => {
  const dispatchReduxToolkit = useAppDispatch();
  const history = useHistory();
  const shoppingCart = useSelector(selectShoppingCart);
  const shoppingCartItems = useSelector(selectShoppingCartItems);
  const subscriptions = useSelector(selectSubscriptions);
  const [mandatoryAddOns, setMandatoryAddOns] = useState<AddOn[]>([]);
  const [optionalAddOns, setOptionalAddOns] = useState<AddOn[]>([]);
  const [cartSubscription, setCartSubscription] = useState<SubscriptionV3>();
  const [isExistingItemInCart, setIsExistingItemInCart] = useState(false);
  const [isPrepaidVoucher, setIsPrepaidVoucher] = useState(false);
  const [isSubscriptionVoucher, setIsSubscriptionVoucher] = useState(false);
  const [subscriptionShoppingCartItem, setSubscriptionShoppingCartItem] =
    useState<ShoppingCartItem | null>();

  const addSubscriptionToCart = (
    subscription: SubscriptionV3,
    isValidate = true
  ) => {
    if (
      isValidate &&
      !!subscriptionShoppingCartItem &&
      !!subscriptionShoppingCartItem.subscriptionId &&
      subscriptionShoppingCartItem.subscriptionId > 0 &&
      subscription.id !== subscriptionShoppingCartItem.subscriptionId
    ) {
      setIsExistingItemInCart(true);
    } else {
      setIsExistingItemInCart(false);
      dispatchReduxToolkit(addSubscriptionToCartRedux(subscription));
      goToShoppingCart();
    }
  };

  const resetIsExistingItemInCart = () => {
    setIsExistingItemInCart(false);
  };

  const isPayAsYouGo = () => {
    if (!cartSubscription) return false;
    return (
      cartSubscription?.cdm3SubscriptionType.id ===
      Constants.SUBSCRIPTION_PAY_AS_YOU_GO_SUBSCRIPTION_TYPE_ID
    );
  };

  const goToShoppingCart = () => {
    setIsExistingItemInCart(false);
    history.push("/v3/shopping-cart");
  };

  const isCartFree = () => {
    return (shoppingCart?.cartPrice?.actualPrice || 0) === 0;
  };

  useEffect(() => {
    if (
      !!subscriptions &&
      subscriptions.length > 0 &&
      !!subscriptionShoppingCartItem &&
      !!subscriptionShoppingCartItem.subscriptionId &&
      subscriptionShoppingCartItem.subscriptionId > 0
    ) {
      const subscription = subscriptions.find(
        (x) => x.id === subscriptionShoppingCartItem.subscriptionId
      );
      setCartSubscription(subscription);
      if (!!subscription && !!subscription.addOn) {
        setMandatoryAddOns(subscription.addOn.filter((x) => x.isMandatory));
        setOptionalAddOns(subscription.addOn.filter((x) => !x.isMandatory));
      } else {
        setMandatoryAddOns([]);
        setOptionalAddOns([]);
      }
      return;
    }
    setCartSubscription(undefined);
    setMandatoryAddOns([]);
    setOptionalAddOns([]);
  }, [subscriptions, subscriptionShoppingCartItem]);

  useEffect(() => {
    setSubscriptionShoppingCartItem(
      shoppingCartItems?.find((x) => !!x.subscriptionId)
    );
  }, [shoppingCartItems]);

  useEffect(() => {
    if (
      shoppingCart?.validVoucher?.voucherType?.id ===
      Constants.PREPAID_VOUCHER_TYPE_ID
    ) {
      setIsPrepaidVoucher(true);
      return;
    }

    setIsPrepaidVoucher(false);
  }, [shoppingCart?.validVoucher]);

  useEffect(() => {
    if (
      shoppingCart?.validVoucher?.voucherType?.id ===
      Constants.SUBSCRIPTION_VOUCHER_TYPE_ID
    ) {
      setIsSubscriptionVoucher(true);
      return;
    }

    setIsSubscriptionVoucher(false);
  }, [shoppingCart?.validVoucher]);

  return {
    cartSubscription,
    mandatoryAddOns,
    optionalAddOns,
    shoppingCartItems,
    subscriptionShoppingCartItem,
    isExistingItemInCart,
    isPrepaidVoucher,
    isSubscriptionVoucher,
    addSubscriptionToCart,
    resetIsExistingItemInCart,
    goToShoppingCart,
    isPayAsYouGo,
    isCartFree,
  } as const;
};
