import { Icon, MinimalHeader } from "@bosch/react-frok";
import style from "./AppWrapper.module.scss";
import SideNavigation from "../sideNavigation/SideNavigation";
import Footer from "../footer/Footer";
import { useSelector } from "react-redux";
import {
  selectCountryCode,
  selectLanguage,
  selectMainContentPinned,
  selectOpenSideNav,
} from "../../redux/auth";
import RDFrokBreacrumbs from "../../components/RDComponents/RDFrokBreacrumbs/RDFrokBreacrumbs";
import { useEffect, useMemo, useRef, useState } from "react";
import { RouteConfigure } from "../../models/Route";
import { useRouting } from "../../hooks/useRouting";
import HeaderActions from "../headerActions/HeaderActions";
import {
  selectIsForcedToHideNotificationBanner,
  updateForcedToHideNotificationBanner,
} from "@redux/maintenanceSlice";
import { useAppDispatch } from "@redux/hooks";
import { getHeightOfComponent } from "@utils/dom.util";
import {
  CHRISTMAS_BANNER_CONFIGS,
  DEFAULT_CHRISTMAS_BANNER_MESSAGE,
} from "@constants/app.constants";
import { Message } from "semantic-ui-react";

type Props = {
  route: JSX.Element;
};

const AppWrapper = ({ route }: Props) => {
  const dispatch = useAppDispatch();
  const { getCurrentRoute } = useRouting();
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const isOpenSideNav = useSelector(selectOpenSideNav);
  const isMainContentPinned = useSelector(selectMainContentPinned);
  const isForceToHideNotificationBanner = useSelector(
    selectIsForcedToHideNotificationBanner
  );
  const [currentRoute, setCurrentRoute] = useState<RouteConfigure | undefined>(
    undefined
  );

  const maintenanceBlockRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [christmasBannerMessage, setChristmasBannerMessage] = useState("");
  const [maintenanceBannerHeight, setMaintenanceBannerHeight] = useState(0);
  const params = new URLSearchParams(window.location.search);
  const isMobile = useMemo(
    () => !!params.get("ismobile") && params.get("ismobile") === "1",
    [params]
  );

  useEffect(() => {
    setCurrentRoute(getCurrentRoute());
  }, [window.location.pathname]);

  useEffect(() => {
    if (!christmasBannerMessage) {
      setMaintenanceBannerHeight(0);
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setMaintenanceBannerHeight(getHeightOfComponent(maintenanceBlockRef));
      window.clearTimeout(timeoutId);
    }, 50);
  }, [christmasBannerMessage]);

  useEffect(() => {
    if (!countryCode) return;

    const normalizedCountryCode = countryCode.trim().toLowerCase();

    const currentDate = new Date().getTime();
    const matchedChristmasConfig = CHRISTMAS_BANNER_CONFIGS.find((config) => {
      if (config.countryCode !== normalizedCountryCode) return false;

      const startDate = new Date(config.startDate).getTime();
      const dueDate = new Date(config.dueDate).getTime();

      return currentDate >= startDate && currentDate <= dueDate;
    });

    if (!matchedChristmasConfig) {
      setChristmasBannerMessage("");
      return;
    }

    const normalizedLanguage = language.trim().toLowerCase();

    if (!normalizedLanguage || normalizedLanguage.includes("en")) {
      setChristmasBannerMessage(DEFAULT_CHRISTMAS_BANNER_MESSAGE);
      return;
    }

    setChristmasBannerMessage(matchedChristmasConfig.message);
  }, [countryCode, language]);

  useEffect(() => {
    if (!wrapperRef.current || !maintenanceBlockRef.current) return;

    if (christmasBannerMessage) {
      const maintenanceBannerHeightAsString = maintenanceBannerHeight + "px";
      wrapperRef.current.style.marginTop = maintenanceBannerHeightAsString;

      setHeightForSideNavigation(
        `calc(100vh - ${maintenanceBannerHeightAsString})`
      );
    } else {
      wrapperRef.current.style.marginTop = "0";
      setHeightForSideNavigation("100vh");
    }
  }, [christmasBannerMessage, maintenanceBannerHeight]);

  useEffect(() => {
    window.addEventListener("scroll", toggleMaintenanceNotificationBar);

    return () => {
      window.removeEventListener("scroll", toggleMaintenanceNotificationBar);
    };
  }, [
    maintenanceBannerHeight,
    window.scrollY,
    window.screen.availWidth,
    window.screen.availHeight,
  ]);

  const toggleMaintenanceNotificationBar = () => {
    if (!wrapperRef.current || !maintenanceBlockRef.current) return;

    const screenHeight = window.screen.height;
    const documentHeight = document.body.scrollHeight;

    if (documentHeight - screenHeight <= maintenanceBannerHeight) return;

    if (window.scrollY > maintenanceBannerHeight) {
      maintenanceBlockRef.current.style.display = "none";
      wrapperRef.current.style.marginTop = "0px";
      setHeightForSideNavigation("100vh");
    } else {
      const maintenanceBannerHeightAsString = maintenanceBannerHeight + "px";

      maintenanceBlockRef.current.style.display = "block";
      wrapperRef.current.style.marginTop = maintenanceBannerHeightAsString;
      setHeightForSideNavigation(
        `calc(100vh - ${maintenanceBannerHeightAsString})`
      );
    }
  };

  const setHeightForSideNavigation = (maintenanceBannerHeight: string) => {
    if (!wrapperRef.current) return;

    const sideNavigation =
      wrapperRef.current.querySelector(".m-side-navigation");

    if (!sideNavigation) return;

    (sideNavigation as HTMLElement).style.height = maintenanceBannerHeight;
  };

  const isAbleToShowFooter = () => {
    return !(
      window.location.href.includes("/signincallback") ||
      window.location.href.includes("/signoutcallback") ||
      window.location.href.includes("/sessiontimeout") ||
      window.location.href.includes("/editprofilecallback") ||
      window.location.href.includes("/launchlinkredirect") ||
      window.location.href.includes("/meeting")
    );
  };

  const handleCloseMaintenanceBanner = () => {
    setChristmasBannerMessage("");
    dispatch(updateForcedToHideNotificationBanner(true));

    if (!wrapperRef.current) return;

    wrapperRef.current.style.marginTop = "0px";
    setHeightForSideNavigation("100vh");
  };

  return (
    <div className={style.container}>
      {!!christmasBannerMessage && !isForceToHideNotificationBanner ? (
        <div
          ref={maintenanceBlockRef}
          className={`maintenance_block ${style.maintenance_block}`}
        >
          <Message className="maintenance_message" info size="small">
            <div className="maintenance_detail">
              <Message.Content>
                <Icon iconName="service-time" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: christmasBannerMessage,
                  }}
                />
                <Icon iconName="close" onClick={handleCloseMaintenanceBanner} />
              </Message.Content>
            </div>
          </Message>
        </div>
      ) : (
        <></>
      )}
      <div ref={wrapperRef} className={style.wrapper}>
        {!isMobile ? (
          <MinimalHeader
            actions={<HeaderActions />}
            sideNavigation={<SideNavigation />}
            className={`${style.minimal_header} ${
              !!isOpenSideNav ? style.open : ""
            } ${
              !isMainContentPinned ? style.header_with_main_content_pinned : ""
            }`}
          >
            {!!currentRoute?.breadcrumbs ? (
              <RDFrokBreacrumbs crumbs={currentRoute.breadcrumbs} />
            ) : (
              <></>
            )}
          </MinimalHeader>
        ) : (
          <></>
        )}
        <div
          className={`${style.main_content} ${
            !!isMainContentPinned && !!isOpenSideNav ? style.minimal : ""
          }`}
        >
          <div className={style.route_content}>{route}</div>
        </div>
        <div
          className={`${style.footer_wrapper} ${
            !!isMainContentPinned && !!isOpenSideNav ? style.minimal : ""
          }`}
        >
          {isAbleToShowFooter() ? <Footer /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default AppWrapper;
