import { FC, useEffect, useState } from "react";
import Styles from "./MainContent.module.scss";
import HeaderContent from "./HeaderContent";
import { useCloseLiveBoxContext } from "../context/CloseLiveBoxContext";
import Messages from "../../../utils/chat-widget/MessageList";
import UserInput from "../../../utils/chat-widget/UserInput";
import { AppDispatch, useAppDispatch, useAppSelector } from "../../../redux/store";
import { channelSetMessage, selectApiKey, selectChannelArn, selectChannelMessage, selectChimeUserId, selectIsTranslate } from "../../../redux/chatWidget";
import { createMemberArn, listChannelMessages, MessageType, Persistence, sendChannelMessage, updateChannelReadMarker } from "../../../utils/helpers/ChimeAPI";
import { liveTranslateMessage } from "../../../services/Chime";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "amazon-chime-sdk-component-library-react";
import { selectOriginComp } from "../../../redux/account";
import { ActivityIndicator } from "@bosch/react-frok";
import ModalTranlationOptions from "../../../utils/chat-widget/ModalTranlationOptions";
export interface IMainContentProps {

}

const MainContent: FC<IMainContentProps> = () => {
    const { channelActiveId, newMessageCome } = useCloseLiveBoxContext();
    let [sessionid, setSessionId] = useState<string>("");
    let [onLoading, setOnLoading] = useState<boolean>(true);
    const chimeUserId = useAppSelector(selectChimeUserId);
    const channelArn = useAppSelector(selectChannelArn);
    const isTranslate = useAppSelector(selectIsTranslate);
    const dispatchReduxToolkit: AppDispatch = useAppDispatch();
    const apiKey = useAppSelector(selectApiKey);
    const messages = useAppSelector(selectChannelMessage);
    const acc = useAppSelector(selectOriginComp);
    const member = {
        username: acc ? acc.contactFirstName + " " + acc.contactLastName : "Workshop Technician",
        userId: acc ? acc.ciamId : "Workshop Technician",
    };
    const browserLanguage = window.navigator.language;

    const onMessageWasSent = async (
        message: { author: string; timestamp: string; data: { text: string } },
        options?: any
    ) => {
        const newOptions = options ? JSON.parse(options) : {};
        await sendChannelMessage(
            channelArn,
            message.data.text,
            Persistence.PERSISTENT,
            MessageType.STANDARD,
            member,
            JSON.stringify({ ...newOptions, language: browserLanguage })
        );
    };

    useEffect(() => {
        const updateMessage = async () => {
            if (channelArn && channelArn !== "" && chimeUserId) {
                const newMessages = await listChannelMessages(channelArn, chimeUserId);
                if (newMessages && newMessages.Messages) {
                    const message_translate = await Promise.all(
                        newMessages.Messages.map(async (message: any) => {
                            message.CreatedTimestamp = message.CreatedTimestamp.toISOString();
                            message.LastUpdatedTimestamp = message.LastUpdatedTimestamp.toISOString();
                            if (
                                createMemberArn(chimeUserId) !== message.Sender.Arn &&
                                isTranslate.status
                            ) {
                                const translateMessage = await liveTranslateMessage(
                                    message.Content,
                                    isTranslate.langSource,
                                    isTranslate.langTarget,
                                    apiKey
                                );
                                return { ...message, Content: translateMessage };
                            }
                            return message;
                        })
                    );
                    dispatchReduxToolkit(
                        channelSetMessage({
                            channelMessages: message_translate,
                            nextToken: newMessages.NextToken,
                        })
                    );
                }
            }
        };

        updateMessage();
    }, [newMessageCome, isTranslate, chimeUserId, channelArn]);

    useEffect(() => {
        if (channelActiveId) {
            setSessionId(channelActiveId)
            setOnLoading(true)
        }
    }, [channelActiveId])

    useEffect(() => {
        if (channelArn) {
            if (channelArn.includes(channelActiveId)) {
                setTimeout(() => {
                    setOnLoading(false)
                }, 2000)

            }
        }
    }, [channelArn])




    return (
        <div className={Styles["container"]}>
            <HeaderContent />
            <div className={Styles["chat-area"]}>
                {
                    sessionid && (
                        <>
                            <ThemeProvider theme={lightTheme}>
                                <Messages
                                    channelArn={channelArn}
                                    messages={messages}
                                    userId={chimeUserId}
                                    translateModeData={isTranslate}
                                />
                            </ThemeProvider>
                            <UserInput
                                onSubmit={onMessageWasSent}
                                updateMarker={() => updateChannelReadMarker(channelArn, chimeUserId)}
                                activeChannelArn={channelArn}
                                newMessage={newMessageCome}
                            />
                        </>
                    )
                }

                {isTranslate.languagebox && (
                    <ModalTranlationOptions
                        translateModeData={isTranslate}
                        isOpen={isTranslate.languagebox}
                    />
                )}

                {
                    onLoading && (
                        <div className={Styles["loadingPanel"]}>
                            <ActivityIndicator />
                        </div>
                    )

                }
            </div >
        </div>
    );
};

export default MainContent;
