import { FC, Fragment } from "react";
import { Image } from "semantic-ui-react";
import boschSupergraphic from "../../assets/Bosch-Supergraphic_.png";
import style from "./ForbiddenPage.module.css";

const ForbiddenPage: FC<any> = () => {
  return (
    <Fragment>
      <div className="supergraphic">
        <Image src={boschSupergraphic} fluid></Image>
      </div>

      <div className="grid-responsive-width">
        <div className={`${style.maintenance_message}`}>
          <p className={`${style.maintenance_message_title}`}>
            <h1>Forbidden</h1>
          </p>
          <p className={`${style.info_message}`}>
            <span>
              You don't have permission to access Bosch Remote Diagnostics.
            </span>
          </p>
          <div className={`${style.maintenance_image}`}></div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForbiddenPage;
