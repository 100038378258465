import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "../utils/helpers/Constants";
import { selectAccount } from "../redux/account";

export const useUserRole = () => {
  const account = useSelector(selectAccount);
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    setIsManager(account?.userRole?.id === Constants.USER_ROLE_MANAGER_ID);
  }, [account]);

  return { isManager };
};
