import { useEffect } from "react";
import CustomLoader from "../../../components/loading/CustomLoader";
import { useAppDispatch } from "../../../redux/hooks";
import { loginAsync } from "../../../redux/account";
import { selectCountryCode, selectIsSignIn } from "../../../redux/auth";
import { useSelector } from "react-redux";

const AdsAutoLogin = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsSignIn);
  const countryCode = useSelector(selectCountryCode);

  useEffect(() => {
    if (isLoggedIn || !countryCode) return;

    dispatch(
      loginAsync({
        redirectUrl: window.location.pathname + window.location.search,
      })
    );
  }, [isLoggedIn, countryCode]);

  return <CustomLoader enable />;
};

export default AdsAutoLogin;
