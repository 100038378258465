import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import Hardware from "../models/Hardware";

import ResponseResultBase from "../models/ResponseResultBase";
import { RequestCreateVciFavoriteModel } from "../models/vci/request.createVciFavourite.model";
import { RequestVciModel } from "../models/vci/request.vci.model";
import { VciFavouriteModel } from "../models/vci/vciFavourite.model";
import http from "../services/HttpService";
import { AppDispatch, RootState } from "./store";

export const getVciListAsync = createAsyncThunk<
  Hardware[] | null,
  RequestVciModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("vci/getVciList", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<Hardware[]>>(
      `/api/v1/vci/getVciList?ciamId=${requestModel.ciamId}&countryCode=${requestModel.countryCode}&language=${requestModel.language}`
    );
    return response.data.dataObject as Hardware[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getVciFavouriteAsync = createAsyncThunk<
  ResponseResultBase<VciFavouriteModel> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("vci/GetVciFavourite", async (ciamId: string, thunkApi) => {
  let result: ResponseResultBase<VciFavouriteModel> | null = null;
  try {
    const response = await http.get<ResponseResultBase<VciFavouriteModel>>(
      `/api/v1/vci/GetVciFavourite?ciamId=${ciamId}`
    );
    result = response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return result;
});

export const createVciFavouriteAsync = createAsyncThunk<
  ResponseResultBase<VciFavouriteModel> | null,
  RequestCreateVciFavoriteModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "vci/CreateVciFavourite",
  async (data: RequestCreateVciFavoriteModel, thunkApi) => {
    let result: ResponseResultBase<VciFavouriteModel> | null = null;
    try {
      const response = await http.post<ResponseResultBase<VciFavouriteModel>>(
        `/api/v1/vci/CreateVciFavourite`,
        data
      );
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const deleteVciFavouriteAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("vci/DeleteVciFavourite", async (id: number, thunkApi) => {
  let result: ResponseResultBase<boolean> | null = null;
  try {
    const response = await http.post<ResponseResultBase<boolean>>(
      `/api/v1/vci/DeleteVciFavourite?id=${id}`
    );
    result = response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return result;
});
