import { createAsyncThunk } from "@reduxjs/toolkit";
import ResponseResultBase from "../models/ResponseResultBase";
import {
  ExportShopReportRequest,
  GetStatisticDataRequest,
  StatisticData,
} from "../models/statistic/StatisticModel";
import { AppDispatch, RootState } from "../redux/store";
import axios, { AxiosError } from "axios";
import http from "./HttpService";

export const getStatisticDataByYTD = createAsyncThunk<
  ResponseResultBase<StatisticData> | null,
  GetStatisticDataRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "statistic/getSummaryDataByYTD",
  async (request: GetStatisticDataRequest, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<StatisticData>>(
        `api/v1/Statistics/GetStatisticsYTD`,
        request
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getStatisticDataByWeek = createAsyncThunk<
  ResponseResultBase<StatisticData> | null,
  GetStatisticDataRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "statistic/getSummaryDataByWeek",
  async (request: GetStatisticDataRequest, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<StatisticData>>(
        `api/v1/Statistics/GetStatisticsByWeek`,
        request
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getStatisticDataByMonth = createAsyncThunk<
  ResponseResultBase<StatisticData> | null,
  GetStatisticDataRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "statistic/getSummaryDataByMonth",
  async (request: GetStatisticDataRequest, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<StatisticData>>(
        `api/v1/Statistics/GetStatisticsByMonth`,
        request
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getStatisticDataByYear = createAsyncThunk<
  ResponseResultBase<StatisticData> | null,
  GetStatisticDataRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "statistic/getSummaryDataByYear",
  async (request: GetStatisticDataRequest, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<StatisticData>>(
        `api/v1/Statistics/GetStatisticsByYear`,
        request
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const exportSupportRequestReport = (
  request: ExportShopReportRequest
) => {
  return http.post(`/api/v1/Statistics/ExportSupportRequests`, request);
};
