import { useSelector } from "react-redux";
import { selectCdmVersion } from "../../redux/auth";
import { useEffect, useState } from "react";
import { CdmVersion } from "../../models/pageConfiguration/CdmVersion";
import { HeaderConfigurationModel } from "../../models/pageConfiguration/HeaderConfigurationModel";
import { HeaderConfiguration } from "../../pages/Configuration/HeaderConfiguration/HeaderConfiguration";

export const useHeaderConfiguration = () => {
  const cdmVersion = useSelector(selectCdmVersion);
  const [headerConfig, setHeaderConfig] = useState<HeaderConfigurationModel>(
    HeaderConfiguration[CdmVersion.CDM3v0]
  );

  useEffect(() => {
    const targetConfig = HeaderConfiguration[cdmVersion as CdmVersion];

    if (targetConfig) setHeaderConfig(targetConfig);
  }, [cdmVersion]);

  return { headerConfig };
};
