import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Grid, Image } from "semantic-ui-react";
import Boschicon from "../../BoschIcon/Boschicon";
import { Constants } from "../../../utils/helpers/Constants";
import { useCurrencyFormat } from "../../../hooks/useCurrencyFormat";
import CustomLoader from "../../loading/CustomLoader";
import { RequestGetCreditPackagesModel } from "../../../models/credit/request.getCreditInterval.model";
import { ShoppingCartItem } from "../../../models/ShoppingCart/ShoppingCartItem";
import { selectCiamId } from "../../../redux/account";
import { selectCountryCode, selectLanguage } from "../../../redux/auth";
import { getCreditPackagesAsync } from "../../../redux/creditSlide";
import { useAppDispatch } from "../../../redux/hooks";
import { addMoreAddOnToCart } from "../../../redux/shoppingCartV3Slice";
import { AppDispatch } from "../../../redux/store";
import AddToCartSuccessModal from "./AddToCartSuccessModal";
import style from "./DashboardCDM3WalletTopup.module.css";
import RDAlert from "../../RDComponents/RDAlert";
import RDButton from "../../RDComponents/RDButton";

const DashboardCDM3WalletTopup: FC<any> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const ciamId = useSelector(selectCiamId);
  const [showAddToCartSuccessModal, setShowAddToCartSuccessModal] =
    useState(false);

  const [packageList, setPackageList] = useState<ShoppingCartItem[]>([]);
  const [itemList, setItemList] = useState<ShoppingCartItem[]>([]);
  const [size, setSize] = useState<number>(0);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const format = useCurrencyFormat();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getPackageList();
  }, []);

  useEffect(() => {
    setSize(Math.max(packageList.length, itemList.length));
  }, [packageList, itemList]);

  const getPackageList = () => {
    let getPackageListModel: RequestGetCreditPackagesModel = {
      ciamId: ciamId,
      countryCode: countryCode,
      language: language,
    };
    dispatchReduxToolkit(getCreditPackagesAsync(getPackageListModel))
      .then((result: any) => {
        if (!!result.payload) {
          setPackageList(
            result.payload.filter(
              (x: ShoppingCartItem) =>
                x.addOnItem?.category?.id === Constants.CREDIT_PACKAGE_ID &&
                x.isTopUp
            )
          );
          setItemList(
            result.payload.filter(
              (x: ShoppingCartItem) =>
                x.addOnItem?.category?.id === Constants.SINGLE_CREDIT_PACKAGE_ID
            )
          );
          setShowLoader(false);
        } else {
          setError(true);
        }
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const addItemToCart = (cartItem: ShoppingCartItem) => {
    dispatchReduxToolkit(addMoreAddOnToCart(cartItem));
    setShowAddToCartSuccessModal(true);
  };

  return (
    <Fragment>
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={props.onClose}
        open={props.open}
        closeIcon={true}
        className={`${
          (size > 2 && style.wallet_large) ||
          (size === 2 && style.wallet_tiny) ||
          style.wallet_small
        }`}
      >
        <Modal.Header className={`${style.header}`}>
          <Boschicon name="bosch-ic-arrow-up-frame" />
          {t("Wallet_Topup")}
        </Modal.Header>
        <Modal.Content className={`${style.content}`}>
          <Grid className={`${style.content_inner}`}>
            {!!packageList && packageList.length > 0 && (
              <Grid.Row className={`${style.service_header}`}>
                <span>{t("Wallet_Packages")}</span>
              </Grid.Row>
            )}
            <Grid.Row
              className={`${size === 1 && style.package_row_1_col} ${
                size === 2 && style.package_row_2_col
              } ${style.package_row}`}
            >
              {!!packageList &&
                packageList.map((x: ShoppingCartItem) => {
                  return (
                    <Grid.Column
                      computer={(size > 2 && 5) || (size === 2 && 8) || 16}
                      className={`${style.package_col} ${style.package_item}`}
                    >
                      <Grid className={`${style.package_grid}`}>
                        <Grid.Row className={`${style.package_data}`}>
                          {/* <Coins className={`${style.coins}`}></Coins> */}
                          {!!x.addOnItem?.image && (
                            <Image
                              src={
                                require(`../../../assets/icon/subscription_addon_icons/${x.addOnItem.image}`)
                                  .default
                              }
                            />
                          )}
                        </Grid.Row>
                        <Grid.Row
                          className={`${style.package_data} ${style.package_data_title}`}
                        >
                          <span>{x.addOnItem?.title}</span>
                        </Grid.Row>
                        <Grid.Row
                          className={`${style.package_data} ${style.package_data_price}`}
                        >
                          <span>
                            {t("Checkout_YourPrice")}{" "}
                            {format(
                              x.netPrice.yPrice.netPrice,
                              x.netPrice.yPrice.currency.symbol,
                              countryCode
                            )}
                          </span>
                          {!!(
                            x?.originalPrice?.yPrice?.netPrice &&
                            x?.netPrice?.yPrice?.netPrice
                          ) &&
                            x?.originalPrice?.yPrice?.netPrice >
                              x?.netPrice?.yPrice?.netPrice && (
                              <span className={`${style.unitPrice}`}>
                                {t("Checkout_OriginalPrice")}{" "}
                                {format(
                                  x.netPrice.yPrice.netPrice,
                                  x.netPrice.yPrice.currency.symbol,
                                  countryCode
                                )}
                              </span>
                            )}
                        </Grid.Row>
                        <Grid.Row className={`${style.package_add_btn}`}>
                          <RDButton
                            minWidth="fluid"
                            onClick={() => addItemToCart(x)}
                          >
                            {t("SettingsMyBilling_AddToCart")}
                          </RDButton>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  );
                })}
            </Grid.Row>
            {!!itemList && itemList.length > 0 && (
              <Grid.Row className={`${style.service_header}`}>
                <span>{t("Wallet_Credits")}</span>
              </Grid.Row>
            )}
            <Grid.Row
              className={`${size === 1 && style.package_row_1_col} ${
                size === 2 && style.package_row_2_col
              } ${style.package_row}`}
            >
              {!!itemList &&
                itemList.map((x: ShoppingCartItem) => {
                  return (
                    <Grid.Column
                      computer={(size > 2 && 5) || (size === 2 && 8) || 16}
                      className={` ${style.package_col} ${style.package_item}`}
                    >
                      <Grid className={`${style.package_grid}`}>
                        <Grid.Row className={`${style.package_data}`}>
                          <Boschicon name="bosch-ic-coin" />
                        </Grid.Row>
                        <Grid.Row
                          className={`${style.package_data} ${style.package_data_title}`}
                        >
                          <span>{x.addOnItem?.title}</span>
                        </Grid.Row>
                        <Grid.Row
                          className={`${style.package_data} ${style.package_data_price}`}
                        >
                          <span>
                            {t("Checkout_YourPrice")}{" "}
                            {format(
                              x.netPrice.yPrice.netPrice,
                              x.netPrice.yPrice.currency.symbol,
                              countryCode
                            )}
                          </span>
                          {!!(
                            x?.originalPrice?.yPrice?.netPrice &&
                            x?.netPrice?.yPrice?.netPrice
                          ) &&
                            x?.originalPrice?.yPrice?.netPrice >
                              x?.netPrice?.yPrice?.netPrice && (
                              <span className={`${style.unitPrice}`}>
                                {t("Checkout_OriginalPrice")}{" "}
                                {format(
                                  x.originalPrice.yPrice.netPrice,
                                  x.originalPrice.yPrice.currency.symbol,
                                  countryCode
                                )}
                              </span>
                            )}
                        </Grid.Row>
                        <Grid.Row className={`${style.package_add_btn}`}>
                          <RDButton
                            minWidth="fluid"
                            onClick={() => addItemToCart(x)}
                          >
                            {t("SettingsMyBilling_AddToCart")}
                          </RDButton>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  );
                })}
            </Grid.Row>
          </Grid>
        </Modal.Content>
        {showLoader && <CustomLoader enable={true} />}
      </Modal>
      {showAddToCartSuccessModal && (
        <AddToCartSuccessModal
          onAccept={() => {
            setShowAddToCartSuccessModal(false);
            history.push("/v3/shopping-cart");
          }}
          onCancel={() => setShowAddToCartSuccessModal(false)}
        />
      )}
      {error && props.open && (
        <RDAlert
          type="error"
          isOpen={error}
          messages={[t("Error_Common")]}
          acceptButtonText={t("CloseButton")}
          onAccept={() => {
            props.onClose();
          }}
        />
      )}
    </Fragment>
  );
};

export default DashboardCDM3WalletTopup;
