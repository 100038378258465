import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import ResponseResultBase from "../models/ResponseResultBase";
import { RequestGetWholesalerBranches } from "../models/wholesaler/request.getWholesalerBranches.model";
import { RequestGetWholesalerGroups } from "../models/wholesaler/request.getWholesalerGroups.model";
import WholesalerBranch from "../models/WholesalerBranch";
import WholesalerOrganization from "../models/WholesalerOrganization";
import http from "../services/HttpService";
import { AppDispatch, RootState } from "./store";

export const getWholesalerGroupsAsync = createAsyncThunk<
  WholesalerOrganization[] | null,
  RequestGetWholesalerGroups,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("transaction/getWholesalerGroups", async (requestModel, thunkApi) => {
  try {
    //const response = await http.get<ResponseResultBase<Hardware[]>>(`/api/v1/vci/getVciList?ciamId=${requestModel.ciamId}&countryCode=${requestModel.countryCode}&language=${requestModel.language}`);
    const response = await http.get<
      ResponseResultBase<WholesalerOrganization[]>
    >(
      `/api/v1/transaction/GetWholesalerGroups?ciamId=${requestModel.ciamId}&countryCode=${requestModel.countryCode}&languageCode=${requestModel.languageCode}&timeZoneId=${requestModel.timeZoneId}`
    );
    return response.data.dataObject as WholesalerOrganization[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getWholesalerBranchesAsync = createAsyncThunk<
  WholesalerBranch[] | null,
  RequestGetWholesalerBranches,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("transaction/getWholesalerGroups", async (requestModel, thunkApi) => {
  try {
    //const response = await http.get<ResponseResultBase<Hardware[]>>(`/api/v1/vci/getVciList?ciamId=${requestModel.ciamId}&countryCode=${requestModel.countryCode}&language=${requestModel.language}`);
    const response = await http.get<ResponseResultBase<WholesalerBranch[]>>(
      `/api/v1/transaction/GetWholesalerBranches?ciamId=${requestModel.ciamId}&countryCode=${requestModel.countryCode}&languageCode=${requestModel.languageCode}&timeZoneId=${requestModel.timeZoneId}&wholesalerGroupId=${requestModel.wholesalerGroupId}`
    );
    return response.data.dataObject as WholesalerBranch[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});
