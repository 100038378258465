import { useSelector } from "react-redux";
import {
  authSetSignIn,
  selectCountryCode,
  selectLanguage,
} from "../../redux/auth";
import { useHistory } from "react-router-dom";
import {
  ADS_COUNTRY_CODE_SEARCH_QUERY_KEY,
  ADS_LANGUAGE_SEARCH_QUERY_KEY,
} from "../constants/adsNewRequest.constant";
import { useAppDispatch } from "../../redux/hooks";

export const useAdsRedirect = () => {
  const history = useHistory();
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(window.location.search);
  const langParam = params.get(ADS_LANGUAGE_SEARCH_QUERY_KEY);

  const redirectToAdsPage = (link: string) => {
    const fullLink = !!langParam
      ? `${link}?${ADS_COUNTRY_CODE_SEARCH_QUERY_KEY}=${countryCode}&${ADS_LANGUAGE_SEARCH_QUERY_KEY}=${language}`
      : `${link}?${ADS_COUNTRY_CODE_SEARCH_QUERY_KEY}=${countryCode}`;

    history.push(fullLink);
  };

  const goBackToRDDHomePage = (
    mode?: "_self" | "_blank",
    isResetSignInState: boolean = true
  ) => {
    if (
      isResetSignInState &&
      window.location.pathname.startsWith("/ads/sign-in-callback")
    ) {
      dispatch(authSetSignIn(false));
    }
    window.open(process.env.REACT_APP_RDD_HOME_PAGE, mode || "_self");
  };

  const goToStartSessionPage = (
    sessionId: number,
    mode?: "_self" | "_blank"
  ) => {
    const baseUrl = process.env.REACT_APP_RDD_SESSION_PAGE;

    if (!baseUrl) return;

    window.open(baseUrl + sessionId, mode || "_self");
  };

  return {
    redirectToAdsPage,
    goBackToRDDHomePage,
    goToStartSessionPage,
  };
};
