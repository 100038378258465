import { FC } from "react";
import { Breadcrumb, BreadcrumbProps, Icon } from "semantic-ui-react";
import Styles from "./RDBreadcrumb.module.css";
import { ReactComponent as WideAngledArrow } from "../../assets/svg/wide-angled-arrow.svg";

const RDBreadcrumb: FC<BreadcrumbProps> = (props) => {
  return (
    <Breadcrumb
      icon={
        <Icon>
          <WideAngledArrow fill="#000" style={{ height: "0.625rem" }} />
        </Icon>
      }
      {...props}
      className={`${Styles.breadcrumb}  ${props.className ?? ""}`}
    />
  );
};

export default RDBreadcrumb;
