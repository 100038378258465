import { MinimalMenuHeaderItem } from "../models/pageConfiguration/MenuHeaderModel";

export const useMenuItemSelected = () => {
  const isMenuItemSelected = (menuItem: MinimalMenuHeaderItem) => {
    if (window.location.pathname === "/") return false;
    if (menuItem.redirectUrl === "/dashboard") {
      return window.location.pathname === menuItem.redirectUrl;
    }

    return (
      (menuItem?.redirectUrl &&
        menuItem.redirectUrl.includes(window.location.pathname)) ||
      menuItem.childrenItems?.find((childItem) => {
        return childItem.redirectUrl?.includes(window.location.pathname);
      })
    );
  };

  return isMenuItemSelected;
};
