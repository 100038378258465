import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectShoppingCartItems } from "../redux/shoppingCartV3Slice";

export const useCartCount = () => {
  const shoppingCartItems = useSelector(selectShoppingCartItems);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (shoppingCartItems && shoppingCartItems.length > 0) {
      count = shoppingCartItems.filter((x) => !x.addOnItem?.isMandatory).length;
    }
    setCartCount(count);
  }, [shoppingCartItems]);

  return {
    cartCount,
  } as const;
};
