import { CdmVersion } from "../../../models/pageConfiguration/CdmVersion";
import { HeaderConfigurationModel } from "../../../models/pageConfiguration/HeaderConfigurationModel";
import { Cdm3v0HeaderConfiguration } from "./Cdm3v0HeaderConfiguration";
import { CdmUs1v0HeaderConfiguration } from "./CdmUs1v0HeaderConfiguration";

/**
 * Homepage Parent config
 */
export const HeaderConfiguration: Record<CdmVersion, HeaderConfigurationModel> =
  {
    [CdmVersion.CDM3v0]: Cdm3v0HeaderConfiguration,
    [CdmVersion.US1v0]: CdmUs1v0HeaderConfiguration,
  };
