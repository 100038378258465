import { DEFAULT_LANGUAGE } from "../translations/i18n";

/**
 * Structure: [lang]-[countryCode]
 * Example: en-US
 */
export const getBrowserLanguage = () => {
  const browserLang = navigator.language;

  return !!browserLang ? browserLang : DEFAULT_LANGUAGE;
};
