import { CdmVersion } from "../models/pageConfiguration/CdmVersion";
import { RouteConfigure } from "../models/Route";
import { CDM_3_ROUTERS_CONFIG } from "./cdm3v0Router.config";
import { CDM_US_ROUTERS_CONFIGS } from "./cdmUS1v0Router.config";
import { defaultRouterConfig } from "./defaultRouter.config";

const mixRouters = (
  routerConfigBasedOnCdmVersion: RouteConfigure[]
): RouteConfigure[] => {
  return [...defaultRouterConfig, ...routerConfigBasedOnCdmVersion];
};

export const applicationRouters: Record<CdmVersion, RouteConfigure[]> = {
  [CdmVersion.CDM3v0]: mixRouters(CDM_3_ROUTERS_CONFIG),
  [CdmVersion.US1v0]: mixRouters(CDM_US_ROUTERS_CONFIGS),
  // [CdmVersion.CDM3v0]: mixRouters(cdmUS1v0RouterConfig),
  // [CdmVersion.US1v0]: mixRouters(cdm3v0RouterConfig),
};
