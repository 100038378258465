import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";
import { ReOpenReason } from "../models/support/reOpenReason.model";
import { RequestCreateSupportRequestModel } from "../models/support/request.createSupportRequest.model";
import { RequestSupportRequests } from "../models/support/request.getSupportRequests.model";
import {
  GetAssignedSupportRequestTypesRequestModel,
  GetSupportRequestTypeRequestModel,
  RequestCountryLanguageTimeZoneModel,
} from "../models/support/request.countryLanguageTimeZone.model";
import { ServiceRequestType } from "../models/support/serviceRequestType.model";
import { SupportHours } from "../models/support/supportHours.model";
import SupportRequestModel, {
  DownloadAttachmentModel,
  GetSupportRequestBookingTypesModel,
  UpdateSupportRequestModel,
} from "../models/support/supportRequest.model";
import { Vehicle } from "../models/support/vehicle.model";
import http from "../services/HttpService";
import { AppDispatch, RootState } from "./store";
import {
  RequestGetCarModelNamesByBrand,
  RequestGetCarModelsByBrand,
} from "../models/support/request.getCarModelsByBrand.model";
import { OpenTimeSlotFull } from "../models/support/openTimeSlotFull.model";
import {
  CarBrandModel,
  CarModelsByBrandModel,
} from "../models/support/carModelsByBrand.model";
import { RequestReOpenSupportRequestModel } from "../models/support/request.reOpenSupportRequest.model";
import { RequestSupportRequest } from "../models/support/request.getSupportRequest.model";
import { ServerTime } from "../models/support/serverTime.model";
import { RequestCreateTechnicalSupportRequestModel } from "../models/support/request.createTechnicalSupportRequest.model";
import httpNoneSecure from "../services/HttpServiceNoneSecure";
import { GetOpenTimeSlotsRequestModel } from "../models/NewRequest/GetOpenTimeSlotsRequest.model";

export const getSupportRequestTypesAsync = createAsyncThunk<
  ServiceRequestType[] | null,
  GetSupportRequestTypeRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/getSupportRequestTypes",
  async (requestModel: GetSupportRequestTypeRequestModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<ServiceRequestType[]>>(
        `/api/v1/support/GetSupportRequestTypes?countryCode=${requestModel.countryCode}&language=${requestModel.language}&ciamId=${requestModel.ciamId}&isTHLTicket=${requestModel.isTHLTicket}`
      );
      return response.data.dataObject as ServiceRequestType[];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getReOpenReasonsAsync = createAsyncThunk<
  ReOpenReason[] | null,
  RequestCountryLanguageTimeZoneModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/getReOpenReasons", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<ReOpenReason[]>>(
      `/api/v1/support/GetReOpenReasons?countryCode=${requestModel.countryCode}&language=${requestModel.language}`
    );
    return response.data.dataObject as ReOpenReason[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getSupportHoursAsync = createAsyncThunk<
  SupportHours[] | null,
  RequestCountryLanguageTimeZoneModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/getSupportHours", async (requestModel, thunkApi) => {
  try {
    const response = await httpNoneSecure.get<
      ResponseResultBase<SupportHours[]>
    >(
      `/api/v1/support/GetSupportHours?countryCode=${requestModel.countryCode}&timeZoneId=${requestModel.timeZoneId}`
    );
    return response.data.dataObject as SupportHours[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getCarBrandsAsync = createAsyncThunk<
  Vehicle[] | null,
  RequestCountryLanguageTimeZoneModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/getCarBrands", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<Vehicle[]>>(
      `/api/v1/support/GetCarBrands?countryCode=${requestModel.countryCode}&language=${requestModel.language}`
    );
    return response.data.dataObject as Vehicle[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getSupportRequestsAsync = createAsyncThunk<
  ResponseResultBase<SupportRequestModel[]> | null,
  RequestSupportRequests,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/getSupportRequests",
  async (requestSupportRequests: RequestSupportRequests, thunkApi) => {
    try {
      const response = await http.get<
        ResponseResultBase<SupportRequestModel[]>
      >(
        `/api/v1/support/GetSupportRequests?CiamId=${requestSupportRequests.ciamId}&CountryCode=${requestSupportRequests.countryCode}&Language=${requestSupportRequests.language}&TimeZoneId=${requestSupportRequests.timeZoneId}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getSupportRequestAsync = createAsyncThunk<
  ResponseResultBase<SupportRequestModel> | null,
  RequestSupportRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/getSupportRequest",
  async (requestModel: RequestSupportRequest, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<SupportRequestModel>>(
        `/api/v1/support/GetSupportRequest?CiamId=${requestModel.ciamId}&CountryCode=${requestModel.countryCode}&Language=${requestModel.language}&SupportRequestId=${requestModel.supportRequestId}&TimeZoneId=${requestModel.timeZoneId}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const createSupportRequestAsync = createAsyncThunk<
  ResponseResultBase<RequestCreateSupportRequestModel> | null,
  RequestCreateSupportRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/createSupportRequest",
  async (data: RequestCreateSupportRequestModel, thunkApi) => {
    let result: ResponseResultBase<RequestCreateSupportRequestModel> | null =
      null;
    try {
      const response = await http.post<
        ResponseResultBase<RequestCreateSupportRequestModel>
      >(`/api/v1/support/CreateSupportRequest`, data);
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const createTechnicalSupportRequestAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  RequestCreateTechnicalSupportRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/createTechnicalSupportRequest",
  async (data: RequestCreateTechnicalSupportRequestModel, thunkApi) => {
    let result: ResponseResultBase<boolean> | null = null;
    try {
      const response = await http.post<ResponseResultBase<boolean>>(
        `/api/v1/support/CreateTechnicalSupportRequest`,
        data
      );
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const getCarModelNamesByBrandAsync = createAsyncThunk<
  CarBrandModel[] | null,
  RequestGetCarModelNamesByBrand,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/getCarModelNamesByBrand",
  async (requestModel: RequestGetCarModelNamesByBrand, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<CarBrandModel[]>>(
        `/api/v1/support/GetCarModelNamesByBrand?countryCode=${requestModel.countryCode}&language=${requestModel.language}&carBrand=${requestModel.carBrand}`
      );
      return response.data.dataObject as CarBrandModel[];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getCarModelsByBrandAsync = createAsyncThunk<
  CarModelsByBrandModel | null,
  RequestGetCarModelsByBrand,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/getCarModelsByBrand",
  async (requestModel: RequestGetCarModelsByBrand, thunkApi) => {
    try {
      const response = await http.get<
        ResponseResultBase<CarModelsByBrandModel>
      >(
        `/api/v1/support/GetCarModelsByBrand?countryCode=${requestModel.countryCode}&language=${requestModel.language}&carBrand=${requestModel.carBrand}&carModel=${requestModel.carModel}`
      );
      return response.data.dataObject as CarModelsByBrandModel;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getOpenTimeSlotsAsync = createAsyncThunk<
  OpenTimeSlotFull | null,
  GetOpenTimeSlotsRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/getOpenTimeSlots",
  async (requestModel: GetOpenTimeSlotsRequestModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<OpenTimeSlotFull>>(
        `/api/v1/timeslots/GetOpenTimeSlots?countryCode=${
          requestModel.countryCode
        }&TimeZoneId=${requestModel.timeZoneId}&CarBrand=${
          requestModel.carBrand ?? ""
        }`
      );
      return response.data.dataObject as OpenTimeSlotFull;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const reOpenSupportRequestAsync = createAsyncThunk<
  ResponseResultBase<null> | null,
  RequestReOpenSupportRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/reOpenSupportRequest",
  async (requestModel: RequestReOpenSupportRequestModel, thunkApi) => {
    let result: ResponseResultBase<null> | null = null;
    try {
      const response = await http.put<ResponseResultBase<null>>(
        `/api/v1/support/ReOpenSupportRequest/${requestModel.supportRequestId}`,
        requestModel
      );
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const getServerTimeAsync = createAsyncThunk<
  ServerTime | null,
  RequestCountryLanguageTimeZoneModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/getServerTime", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<ServerTime>>(
      `/api/v1/support/GetServerTime?countryCode=${requestModel.countryCode}&timeZoneId=${requestModel.timeZoneId}`
    );
    return response.data.dataObject as ServerTime;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const cancelServiceRequestAsync = createAsyncThunk<
  ResponseResultBase<null> | null,
  RequestSupportRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/cancelServiceRequest", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<null>>(
      `/api/v1/support/CancelServiceRequest?countryCode=${requestModel.countryCode}&language=${requestModel.language}&timeZoneId=${requestModel.timeZoneId}&ciamId=${requestModel.ciamId}&supportRequestId=${requestModel.supportRequestId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const cancelHotlineTicketAsync = createAsyncThunk<
  ResponseResultBase<null> | null,
  RequestSupportRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/cancelHotlineTicket", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<null>>(
      `/api/v1/support/CancelHotlineTicket?countryCode=${requestModel.countryCode}&language=${requestModel.language}&timeZoneId=${requestModel.timeZoneId}&ciamId=${requestModel.ciamId}&hotlineTicketId=${requestModel.supportRequestId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const downloadSupportRequestAttachment = createAsyncThunk<
  Blob | null,
  DownloadAttachmentModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/DownloadAttachment",
  async (requestModel: DownloadAttachmentModel, thunkApi) => {
    try {
      const response = await http.get<Blob>(`/api/v1/Tcs/DownloadAttachment`, {
        params: requestModel,
        responseType: "blob",
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const updateSupportRequest = createAsyncThunk<
  ResponseResultBase<SupportRequestModel> | null,
  UpdateSupportRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/UpdateSupportRequest",
  async (requestModel: UpdateSupportRequestModel, thunkApi) => {
    try {
      const response = await http.post(
        "/api/v1/support/UpdateSupportRequest",
        requestModel
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getSupportRequestBookingTypes = createAsyncThunk<
  ResponseResultBase<GetSupportRequestBookingTypesModel> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/GetSupportRequestBookingTypes", async (_, thunkApi) => {
  try {
    const response = await http.get(
      "/api/v1/support/GetCreateRequestHybridSettings"
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getAssignedSupportRequestTypesAsync = createAsyncThunk<
  ServiceRequestType[] | null,
  GetAssignedSupportRequestTypesRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "support/GetAssignedSupportRequestTypes",
  async (
    requestModel: GetAssignedSupportRequestTypesRequestModel,
    thunkApi
  ) => {
    let url = `/api/v1/support/GetAssignedSupportRequestTypes?SelectedSubSystemId=${requestModel.subsystemId}&Language=${requestModel.language}`;

    if (!!requestModel.solutionGroupId)
      url += `&SolutionGroupId=${requestModel.solutionGroupId}`;

    try {
      const response = await http.get<ResponseResultBase<ServiceRequestType[]>>(
        url
      );
      return response.data.dataObject as ServiceRequestType[];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);
