import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface MyRequestForm {
  formData: string;
}

const initialState: MyRequestForm = {
  formData: "",
  // status: 'idle',
};

export const myRequestFormSlice = createSlice({
  name: "myRequest",
  initialState,
  reducers: {
    addFormData: (state, action: PayloadAction<string>) => {
      state.formData = action.payload;
    },
    removeFormData: (state) => {
      state.formData = "";
    },
  },
});

export const { addFormData, removeFormData } = myRequestFormSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFormData = (state: RootState) =>
  state.myRequestForm.formData;

export default myRequestFormSlice.reducer;
