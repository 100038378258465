import React from "react";
import { useSelector } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { useUserSubscription } from "../hooks/useUserSubscription";
import { loginAsync, selectAccount } from "../redux/account";
import { CommonService } from "../services/Common";
import {
  MY_SUBSCRIPTION_SHARED_ROUTE,
  WALLET_SHARED_ROUTE,
} from "../constants/routes/sharedRoutes.constants";
import { useRouting } from "../hooks/useRouting";
import { useAppDispatch } from "../redux/hooks";
import CustomLoader from "../components/loading/CustomLoader";

interface IProtectRouteProps extends RouteProps {
  isSignIn: boolean | undefined;
  countryCode: string;
  permission?: string;
  role?: number[];
  isMapingRequired: boolean | undefined;
  isTrialHidden: boolean | undefined;
}

const ProtectedRoute: React.FC<IProtectRouteProps> = ({
  component: Component,
  isSignIn,
  countryCode,
  permission,
  role,
  isMapingRequired,
  isTrialHidden,
  ...rest
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isMobile =
    window.location.search !== "" &&
    !!urlParams.get("ismobile") &&
    urlParams.get("ismobile") === "1";
  const isSelectedRegion = !!countryCode && countryCode !== "";
  const account = useSelector(selectAccount);
  const dispatch = useAppDispatch();
  const { checkSubscriptionIsTrial } = useUserSubscription();
  const {
    isWalletAndMyProductsRouteAccessible,
    redirectTo401Page,
    redirectToRoute,
  } = useRouting();

  return (
    <Route
      {...rest}
      render={(insideProps) => {
        if (isMapingRequired && !account?.cdbId && !isMobile) {
          return redirectTo401Page(insideProps.location);
        }

        if (isTrialHidden && checkSubscriptionIsTrial()) {
          return redirectTo401Page(insideProps.location);
        }

        if (
          !(
            window.location.href.includes("/regions") ||
            window.location.href.includes("/redirect-subdomain") ||
            window.location.href.includes("/exception") ||
            window.location.href.includes("/clear-state") ||
            window.location.href.includes("/privacy-policy") ||
            window.location.href.includes("/term-condition")
          )
        ) {
          if (!isSelectedRegion)
            return redirectToRoute("/regions", {
              from: insideProps.location,
            });
        }

        if (countryCode !== "" && !isSignIn) {
          let redirectUrl = insideProps.location.pathname;

          if (!!insideProps.location.search)
            redirectUrl += insideProps.location.search;

          if (!!redirectUrl) {
            dispatch(
              loginAsync({
                redirectUrl,
              })
            );
          } else dispatch(loginAsync());

          return <CustomLoader enable />;
          // console.log(insideProps.location);
          // return redirectToRoute("/login-redirect", {
          //   from: insideProps.location,
          //   search: isMobile ? `?ismobile=${isMobile}` : "",
          // });
        }

        if (permission && !CommonService.isGrantPermission(permission)) {
          return redirectTo401Page(insideProps.location);
        }

        if (
          (!account?.cdbId || account?.cdbId <= 0) &&
          window.location.href.includes("/my-requests")
        ) {
          return redirectTo401Page(insideProps.location);
        }

        if (!!role && !role.includes(account?.userRole?.id || 0)) {
          return redirectTo401Page(insideProps.location);
        }

        if (window.location.href.includes(WALLET_SHARED_ROUTE.path)) {
          if (!isWalletAndMyProductsRouteAccessible())
            return redirectTo401Page(insideProps.location);
        }

        if (window.location.href.includes(MY_SUBSCRIPTION_SHARED_ROUTE.path)) {
          if (!isWalletAndMyProductsRouteAccessible())
            return redirectTo401Page(insideProps.location);
        }

        return Component ? (
          <Component {...insideProps} />
        ) : (
          redirectTo401Page(insideProps.location)
        );
      }}
    />
  );
};

export default ProtectedRoute;
