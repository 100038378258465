import TagManager from "react-gtm-module";

export type GTMEventName =
  | "virtualPageview"
  | "account_registration"
  | "login"
  | "internal_search"
  | "faq"
  | "form_start"
  | "form_sent"
  | "identify_vehicle_click_error"
  | "identify_vehicle_click_success"
  | "request_details_click"
  | "select_time_slot_impression"
  | "select_timeslot_click"
  | "remote_diagnostics_submission";

interface VirtualPageViewParam {
  page_path: string;
  step_name: string;
}

interface InternalSearchParam {
  search_term: string;
  search_results: number;
}

interface FAQParam {
  faq_category: string;
  faq_question: string;
}

interface FormStartParam {
  form_name: string;
}

interface FormSentParam {
  form_name: string;
}

interface IdentifyVehicleParam {
  search_type: "Automatic" | "Manual";
}

interface RequestDetailsClickParam {
  hardware_type: string;
  service_type: string;
}

interface SelectTimeslotImpressionParam {
  available_slots_today: number;
  available_slots_tomorrow: number;
  available_slots_next_5_days: number;
  next_available_slot_weekday: string;
  next_available_slot_time: string;
}

interface SelectTimeslotClickParam {
  available_slots_today: number;
  available_slots_tomorrow: number;
  available_slots_next_5_days: number;
  selected_slot_weekday: string;
  selected_slot_time: string;
  booked_today: boolean;
  days_from_booking: number;
}

export type GTMEventParam =
  | VirtualPageViewParam
  | InternalSearchParam
  | FAQParam
  | FormStartParam
  | FormSentParam
  | IdentifyVehicleParam
  | RequestDetailsClickParam
  | SelectTimeslotImpressionParam
  | SelectTimeslotClickParam;

export const useGTMAnalyticTracker = () => {
  const addAnalyticEvent = (name: GTMEventName, params?: GTMEventParam) => {
    TagManager.dataLayer({
      dataLayer: {
        ...(params || {}),
        event: name,
      },
    });
  };

  return {
    addAnalyticEvent,
  };
};
