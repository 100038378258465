import { useTranslation } from "react-i18next";
import style from "./AdsSearchByPlateOnUS.module.scss";
import RDInput from "../../../../components/RDComponents/RDInput";
import { useSelector } from "react-redux";
import {
  selectFormValues,
  updateFormValues,
} from "../../../../redux/newRequestSlice";
import { useAppDispatch } from "../../../../redux/hooks";
import { ReactComponent as Search } from "../../../../assets/svg/search.svg";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Boschicon from "../../../../components/BoschIcon/Boschicon";
import { capitalizeString } from "../../../../utils/text.util";
import { DropdownItemProps, Input } from "semantic-ui-react";
import { CountryState } from "../../../../models/NewRequest/CountrySearchMethod.model";
import { handleClickedOutOfComponent } from "../../../../utils/dom.util";
import AdsUsStateOption from "../adsUsStateOption/AdsUsStateOption";
import { SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID } from "../../../../constants/automaticSearch.constants";

type Props = {
  onSearch: () => void;
  error?: string;
  setError?: Dispatch<SetStateAction<string>>;
};

const AdsSearchByPlateOnUS = ({ onSearch, error, setError }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formValues = useSelector(selectFormValues);
  const [stateOptions, setStateOptions] = useState<DropdownItemProps[]>([]);
  const [foundStateOptions, setFoundStateOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [isShowStatesList, setShowStatesList] = useState(false);
  const [selectedState, setSelectedState] = useState<CountryState | null>(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const statesListRef = useRef<HTMLDivElement | null>(null);
  const searchStateInputRef = useRef<Input | null>(null);

  useEffect(() => {
    const checkIfClickOutStateList = (e: MouseEvent) =>
      handleClickedOutOfComponent(e, statesListRef, () =>
        setShowStatesList(false)
      );

    document.addEventListener("mousedown", checkIfClickOutStateList);

    return () => {
      document.removeEventListener("mousedown", checkIfClickOutStateList);
    };
  }, []);

  useEffect(() => {
    if (!isShowStatesList || !searchStateInputRef.current) return;

    searchStateInputRef.current.focus();
  }, [isShowStatesList]);

  useEffect(() => {
    if (!formValues.searchCountry?.states) return;

    setStateOptions(
      formValues.searchCountry.states.map((state) => ({
        text: `${state.state} (${state.code})`,
        value: state.code,
      }))
    );
  }, [formValues.searchCountry]);

  useEffect(() => {
    if (!formValues.searchCountryState || !formValues.searchCountry) return;

    const matchedState = formValues.searchCountry.states?.find(
      (state) => state.code === formValues.searchCountryState
    );

    if (!matchedState) return;

    setSelectedState(matchedState);
  }, [formValues.searchCountryState]);

  useEffect(() => {
    if (
      !!formValues.searchCountryState &&
      errorMessage === t("ADS_YouHaveToSelectStateFirst")
    )
      setErrorMessage("");
  }, [formValues.searchCountryState]);

  const toggleStatesList = () => {
    setShowStatesList((prev) => !prev);
  };

  const onLicensePlateInputChange = (value: string) => {
    dispatch(
      updateFormValues({
        licensePlate: value,
        searchMethodId: SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID,
      })
    );

    if (!!setError) setError("");
  };

  const onChangeState = (stateCode: string) => {
    dispatch(
      updateFormValues({
        searchCountryState: stateCode,
      })
    );
    setShowStatesList(false);
  };

  const getTargetStatesListToRender = () => {
    if (foundStateOptions.length !== 0) return foundStateOptions;

    return stateOptions;
  };

  const onSearchState = (keyword: string) => {
    setSearchKeyword(keyword);
    setFoundStateOptions(
      stateOptions.filter((option) =>
        (option.text as string).toLowerCase().includes(keyword.toLowerCase())
      )
    );
  };

  const validateBeforeSearch = () => {
    const message = !formValues.searchCountryState
      ? t("ADS_YouHaveToSelectStateFirst")
      : "";

    setErrorMessage(message);
    return !message;
  };

  const onSearchInput = (event: any) => {
    if (event.charCode === 13) {
      onSearchVehicleByLicensePlate();
    }
  };

  const onSearchVehicleByLicensePlate = () => {
    if (!validateBeforeSearch()) return;

    onSearch();
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.left}>
          <div
            className={style.search_button}
            onClick={onSearchVehicleByLicensePlate}
          >
            <Search className={style.search_icon} />
          </div>
          <div className={style.states_list_toggle} onClick={toggleStatesList}>
            <Boschicon name="down" />
          </div>
        </div>
        <RDInput
          type="search"
          title={selectedState?.state || capitalizeString(t("ADS_ChooseState"))}
          containerClassName={style.search_input_wrapper}
          className={style.search_input}
          value={formValues.licensePlate}
          onChange={(_: any, { value }: any) =>
            onLicensePlateInputChange(value)
          }
          onKeyPress={onSearchInput}
        />
      </div>
      {!!error || !!errorMessage ? (
        <div className={style.error_message}>
          <Boschicon name="bosch-ic-alert-error" />
          {error || errorMessage}
        </div>
      ) : (
        <></>
      )}
      {isShowStatesList ? (
        <div ref={statesListRef} className={style.states_list_wrapper}>
          <RDInput
            ref={searchStateInputRef}
            title={t("ADS_ChooseState")}
            className={style.search_state_input}
            value={searchKeyword}
            onChange={(_: any, { value }: any) => onSearchState(value)}
          />
          <div className={style.state_list}>
            {getTargetStatesListToRender().map((option, index) => (
              <AdsUsStateOption
                key={index}
                option={option}
                onClick={onChangeState}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdsSearchByPlateOnUS;
