import { Fragment, useEffect } from "react";
import { AuthService } from "../../services/AuthService";

const SignInCallbackPopup = () => {
  useEffect(() => {
    async function performLogin() {
      const accountService = AuthService.getInstance();
      await accountService.loginPopupCallbackAsync();
    }

    performLogin();
  }, []);

  return <Fragment></Fragment>;
};

export default SignInCallbackPopup;
