import { useSelector } from "react-redux";
import { selectCdmVersion, selectIsSignIn } from "../../redux/auth";
import { selectAccount, selectAccountSubscription } from "../../redux/account";
import { useUserSubscription } from "../useUserSubscription";
import { useRouting } from "../useRouting";
import { useEffect, useState } from "react";
import {
  MinimalSideNavItem,
  SideNavItem,
  SideNavItemKey,
} from "../../types/sideNavigation.type";
import { SIDE_NAV_CONFIG } from "../../pages/Configuration/SideNavigationConfig/SideNavConfig";
import { CdmVersion } from "../../models/pageConfiguration/CdmVersion";

export const useSideNavConfig = () => {
  const cdmVersion = useSelector(selectCdmVersion);
  const isLoggedIn = useSelector(selectIsSignIn);
  const account = useSelector(selectAccount);
  const userSubscription = useSelector(selectAccountSubscription);
  const { checkUserIsMapped, checkSubscriptionIsTrial } = useUserSubscription();
  const { isWalletAndMyProductsRouteAccessible } = useRouting();
  const [sideNavItems, setSideNavItems] = useState<MinimalSideNavItem[]>([]);

  useEffect(() => {
    const menuHeaderConfig = SIDE_NAV_CONFIG[cdmVersion as CdmVersion];

    setSideNavItems(
      minifySideNavItemList(
        menuHeaderConfig ? menuHeaderConfig : SIDE_NAV_CONFIG[CdmVersion.CDM3v0]
      )
    );
  }, [
    cdmVersion,
    isLoggedIn,
    account?.cdbId,
    userSubscription,
    account?.userRole,
  ]);

  const minifySideNavItemList = (
    items: SideNavItem[]
  ): MinimalSideNavItem[] => {
    if (!items || items.length === 0) return [];

    const result: MinimalSideNavItem[] = [];

    for (const currentItem of items) {
      let minifiedItem = minifySideNavItem(currentItem) as MinimalSideNavItem;

      if (!!minifiedItem.key) {
        if (currentItem.childItems && currentItem.childItems.length > 0) {
          const tempChildren = minifySideNavItemList(currentItem.childItems);

          minifiedItem = {
            ...minifiedItem,
            childItems: tempChildren as SideNavItem[],
          };
        }

        result.push(minifiedItem);
      }
    }

    return result;
  };

  const minifySideNavItem = (item: SideNavItem): MinimalSideNavItem | {} => {
    if (!validateSideNavItem(item)) return {};

    const {
      isLoginRequired,
      supportedRoles,
      isActive,
      isAccountMappedRequired,
      ...rest
    } = item;

    if (!item.childItems || item.childItems.length === 0)
      return rest as MinimalSideNavItem;

    const itemChildren = item.childItems.map((childItem) =>
      minifySideNavItem(childItem)
    );

    return {
      ...rest,
      childItems: itemChildren,
    };
  };

  const validateSideNavItem = (item: SideNavItem): boolean => {
    const {
      isLoginRequired,
      isActive,
      supportedRoles,
      isAccountMappedRequired,
    } = item;

    if (!isActive) return false;

    if (!verifySideNavByAdditionalCondition(item)) return false;

    if (isLoginRequired && !isLoggedIn) return false;

    if (isAccountMappedRequired && !(account && account.cdbId > 0))
      return false;

    const currentUserRoleId = account?.userRole?.id;

    if (isLoginRequired && !currentUserRoleId) return false;

    if (isLoggedIn && !supportedRoles.includes(currentUserRoleId as number))
      return false;

    return true;
  };

  const verifySideNavByAdditionalCondition = (
    menuItem: SideNavItem
  ): boolean => {
    if (menuItem.key === SideNavItemKey.WALLET)
      return isWalletAndMyProductsRouteAccessible();

    if (menuItem.key === SideNavItemKey.NEW_REQUEST && !checkUserIsMapped())
      return false;

    if (
      menuItem.key === SideNavItemKey.STATISTICS &&
      checkSubscriptionIsTrial()
    )
      return false;

    if (menuItem.key === SideNavItemKey.MY_PRODUCTS)
      return isWalletAndMyProductsRouteAccessible();

    return true;
  };

  return {
    sideNavItems,
  };
};
