import { FC } from "react";
import Styles from "./HeaderContent.module.scss";
import { Icon } from "@bosch/react-frok";
import { useCloseLiveBoxContext } from "../context/CloseLiveBoxContext";
import { useTranslation } from "react-i18next";

export interface IHeader {}

const HeaderContent: FC<IHeader> = () => {
  const { setIsLiveChatOpen, channelActiveId } = useCloseLiveBoxContext();
  const { t } = useTranslation();
  return (
    <div className={Styles["container"]}>
      <div className={Styles["sc-header"]}>
        {/* <Toggle
          id="translation"
          rightLabel={t("LiveChat_Header_Translation")}
          checked={true}
        /> */}
        <p>{`${t("ReportFile_TicketId")}: ${channelActiveId}`}</p>
        <div className={Styles["icon-function"]}>
          {/* <Icon iconName="window-new" /> */}
          <Icon iconName="close" onClick={() => setIsLiveChatOpen(false)} />
        </div>
      </div>
    </div>
  );
};

export default HeaderContent;
