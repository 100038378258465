import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RBGeolocation } from "../models/geolocation/RBGeolocation";
import { Region, RegionState } from "../models/RegionState";
import ResponseResultBase from "../models/ResponseResultBase";
import { CommonService } from "../services/Common";
import { AppDispatch, RootState } from "./store";

const initialState: RegionState = {
  regions: null,
};

export const getRegionsAsync = createAsyncThunk<
  ResponseResultBase<Region[]> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("region/getRegions", async (_, thunkApi) => {
  try {
    const response = await CommonService.getAllLanguages();
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getGeolocationAsync = createAsyncThunk<
  ResponseResultBase<RBGeolocation> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("region/getGeolocation", async (_, thunkApi) => {
  try {
    const response = await CommonService.getGeolocation();
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const regionSlice = createSlice({
  name: "regionReducer",
  initialState,
  reducers: {
    setRediRegions: (state, action: PayloadAction<Region[]>) => {
      return { regions: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRegionsAsync.fulfilled, (state, { payload }) => {
      if (
        payload != null &&
        payload.httpStatusCode === 200 &&
        payload.dataObject
      ) {
        return {
          ...state,
          regions: [...payload.dataObject.filter((x) => !!x.isActive)],
        };
      }
    });
  },
});

export const selectRegions = (state: RootState) => state.region;
export const { setRediRegions } = regionSlice.actions;
export const selectRegionState = (state: RootState) => state.regionRedi;
export const selectAllRegions = (state: RootState) => state.regionRedi.regions;
export default regionSlice.reducer;
