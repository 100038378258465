import {
  CheckoutStepPayment,
  CheckoutStepComplete,
} from "../redux/checkoutSlide";
import { CHECK_OUT_CDM_3_ROUTE } from "./routes/cdm3v0Routes.constants";

export const NEGATIVE_MODAL_RESTRICTED_ROUTES: string[] = [
  "/signoutcallback",
  "/signincallbackpopup",
  CHECK_OUT_CDM_3_ROUTE.path,
];

export const NEGATIVE_MODAL_RESTRICTED_SHOPPING_CART_STEP: string[] = [
  CheckoutStepPayment.id,
  CheckoutStepComplete.id,
];
