import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RequestType } from "../../models/enums/requestType";
import {
  selectFormValues,
  selectUserAvailableCredits,
} from "../../redux/newRequestSlice";

export const useCreditValidation = () => {
  const userAvailableCredits = useSelector(selectUserAvailableCredits);
  const formValues = useSelector(selectFormValues);
  const [isInsufficientCredit, setIsInsufficientCredit] = useState(false);

  useEffect(() => {
    let servicePrice = getSelectedServicePrice();

    if (servicePrice > 0)
      setIsInsufficientCredit(userAvailableCredits < servicePrice);
    else setIsInsufficientCredit(false);
  }, [
    formValues.requestType,
    formValues?.serviceTypeSelectedModel,
    formValues?.subjectSelectedModel,
    userAvailableCredits,
  ]);

  const getSelectedServicePrice = (): number => {
    let servicePrice = 0;
    if (
      formValues.requestType === RequestType.RemoteDiagnosticRequest &&
      !!formValues?.serviceTypeSelectedModel
    ) {
      servicePrice = formValues?.serviceTypeSelectedModel?.creditValue || 0;
    } else if (
      formValues.requestType === RequestType.TechnicalRequest &&
      !!formValues?.subjectSelectedModel
    ) {
      servicePrice = formValues?.subjectSelectedModel?.creditValue || 0;
    } else {
      servicePrice = formValues?.serviceTypeSelectedModel?.creditValue || 0;
    }

    return servicePrice;
  };

  return {
    isInsufficientCredit,
    getSelectedServicePrice,
  } as const;
};
