import forge from "node-forge";
import { getPrivateKeySecret } from "./Chime";

export class RSADecrypt {
  public static async RSADecrypt(awsEncryptedKey: any) {
    try {
      const encryptedData = awsEncryptedKey.value; // the encrypted data as a string
      const privateKeysFromApi = await getPrivateKeySecret();
      const privateKey = forge.pki.privateKeyFromPem(privateKeysFromApi.value);
      const encryptedBytes = forge.util.decode64(encryptedData);
      const decryptedBytes = privateKey.decrypt(encryptedBytes);

      return decryptedBytes;
    } catch (error) {
      console.log("Cannot get AWS keys");
      return null;
    }
  }
}
