import { User } from "oidc-client-ts";
import { Fragment, useEffect } from "react";
import Account from "../models/Account";
import {
  updateAccount,
  createAccountForEditProfile,
  getAccountSimpleForEditProfileAsync,
} from "../redux/account";
import { AppDispatch, useAppDispatch } from "../redux/store";
import { AuthService } from "../services/AuthService";
import "../translations/i18n";
import { useSelector } from "react-redux";
import { selectAuthState } from "../redux/auth";
import CustomLoader from "../components/loading/CustomLoader";
import TrialStatus from "../models/TrialStatus";

const EditProfileCallback = () => {
  const accountService = AuthService.getInstance();
  const authState = useSelector(selectAuthState);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const createNewAccount = async (resp: User, ciamid: string) => {
    const profile = resp.profile;
    const account: Account = {
      ciamId: profile.ciam_id as string,
      contactFirstName: !!profile.given_name ? profile.given_name : "",
      contactLastName: !!profile.family_name ? profile.family_name : "",
      contactEmail: !!profile.email ? profile.email : "",
      mobliePhone: !!profile.mobile ? (profile.mobile as string) : "",
      ciamEmail: !!profile.email ? profile.email : "",
      name1: (profile.company as any).companyName
        ? (profile.company as any).companyName
        : "",
      name2: "",
      address1: (profile.company as any).address1
        ? (profile.company as any).address1
        : "",
      address2: (profile.company as any).address2
        ? (profile.company as any).address2
        : "",
      city: (profile.company as any).city,
      postCode: (profile.company as any).zipCode,
      vat: (profile.company as any).taxNumber,
      companyCountry: !!(profile.company as any).country
        ? (profile.company as any).country
        : "",
      countryCode: authState.countryCode,
      companyEmail: !!profile.email ? profile.email : "",
      companyPhone: (profile.company as any).phone,
      availableCredits: null,
      cdbId: 0,
      employees: [],
      hardware: [],
      isActive: false,
      subscription: null,
      transactions: [],
      privateHint: false,
      privateHintLastChanged: null,
      termAndConditions: false,
      termsAndConditionsLastChanged: null,
      wholesalerOrganizationId: 0,
      wholesalerBranchId: 0,
      wholesalerGroup: null,
      wholesalerBranch: null,
      employee: null,
      trialStatus: TrialStatus.None,
      userRole: null,
      allowPayViaAccount: false,
    };

    const response = await dispatchReduxToolkit(
      createAccountForEditProfile(account)
    );

    if (createAccountForEditProfile.fulfilled.match(response)) {
      await getSimpleAccount(resp, ciamid);
    }
  };

  const getSimpleAccount = async (resp: User, ciamid: string) => {
    const response = await dispatchReduxToolkit(
      getAccountSimpleForEditProfileAsync(ciamid)
    );
    if (getAccountSimpleForEditProfileAsync.fulfilled.match(response)) {
      if (!!response.payload && response.payload.success) {
        if (response.payload.dataObject) {
          setTimeout(() => {
            if (!!resp.state) window.location.href = resp.state as string;
            else window.location.href = "/";
          }, 2000);
        } else {
          await createNewAccount(resp, ciamid);
        }
      } else {
        await createNewAccount(resp, ciamid);
      }
    }
  };

  const updateAccountAsync = async (resp: User, ciamId: string) => {
    const response = await dispatchReduxToolkit(
      getAccountSimpleForEditProfileAsync(ciamId)
    );
    if (
      getAccountSimpleForEditProfileAsync.fulfilled.match(response) &&
      !!response.payload &&
      response.payload.success &&
      response.payload.dataObject
    ) {
      if (resp.profile) {
        const profile = resp.profile;
        const account = Object.assign({}, response.payload.dataObject);
        account.contactFirstName = !!profile.given_name
          ? profile.given_name
          : "";
        account.contactLastName = !!profile.family_name
          ? profile.family_name
          : "";
        account.mobliePhone = !!profile.mobile
          ? (profile.mobile as string)
          : "";
        account.contactEmail = !!profile.email ? profile.email : "";
        account.name1 = !!(profile.company as any).companyName
          ? (profile.company as any).companyName
          : "";
        account.name2 = "";
        account.city = !!(profile.company as any).city
          ? (profile.company as any).city
          : "";
        account.postCode = !!(profile.company as any).zipCode
          ? (profile.company as any).zipCode
          : "";
        account.vat = !!(profile.company as any).taxNumber
          ? (profile.company as any).taxNumber
          : "";
        account.wholesalerOrganizationId = account.wholesalerGroup?.id;
        account.wholesalerBranchId = account.wholesalerBranch?.id;
        //account.companyCountry = !!profile.company.country ? profile.company.country : "";
        if (authState.countryCode) {
          account.countryCode = authState.countryCode;
        }
        account.companyPhone = !!(profile.company as any).phone
          ? (profile.company as any).phone
          : "";
        account.address1 = !!(profile.company as any).address1
          ? (profile.company as any).address1
          : "";
        account.address2 = !!(profile.company as any).address2
          ? (profile.company as any).address2
          : "";
        await dispatchReduxToolkit(
          updateAccount({
            ...account,
            mobilePhone: account.mobliePhone,
          } as any)
        );
      }
    }
  };

  useEffect(() => {
    accountService.editProfileCallbackAsync().then(async (resp) => {
      if (!!resp && !!!!resp.profile && !!resp.profile.ciam_id) {
        await updateAccountAsync(resp, resp.profile.ciam_id as string);
      }
      setTimeout(() => {
        if (!!resp.state) window.location.href = resp.state as string;
        else window.location.href = "/";
      }, 2000);
    });
  }, []);

  return (
    <Fragment>
      <CustomLoader enable={true}></CustomLoader>
    </Fragment>
  );
};

export default EditProfileCallback;
