import { AuthState } from "../../redux/auth";

export default class RedirectHelper {
  static redirectToSubDomain(
    url: string,
    countryCode: string,
    lang: string,
    originCountryCode: string
  ) {
    if (url.endsWith("/"))
      window.location.href = `${url}?c=${countryCode}&l=${lang}&oc=${originCountryCode}`;
    else
      window.location.href = `${url}/?c=${countryCode}&l=${lang}&oc=${originCountryCode}`;
  }

  static redirectBasedOnGeolocation(countryCode: string, lang: string) {
    const url = !!process.env.REACT_APP_DEFAULT_CLIENTROOT
      ? process.env.REACT_APP_DEFAULT_CLIENTROOT
      : "";
    if (url.endsWith("/"))
      window.location.href = `${url}?c=${countryCode}&l=${lang}&isGeo=1`;
    else window.location.href = `${url}/?c=${countryCode}&l=${lang}&isGeo=1`;
  }

  static redirectToRightCountry(countryCode: string, lang: string) {
    const url = !!process.env.REACT_APP_DEFAULT_CLIENTROOT
      ? process.env.REACT_APP_DEFAULT_CLIENTROOT
      : "";
    if (url.endsWith("/"))
      window.location.href = `${url}?c=${countryCode}&l=${lang}&ischangecountry=1`;
    else
      window.location.href = `${url}/?c=${countryCode}&l=${lang}&ischangecountry=1`;
  }

  static redirectToMainSite() {
    window.location.href = this.getDefaultClientRoot();
  }

  static getDefaultClientRoot(): string {
    if (!!process.env.REACT_APP_DEFAULT_CLIENTROOT)
      return process.env.REACT_APP_DEFAULT_CLIENTROOT;
    return "";
  }

  static getClientRoot(authState: AuthState) {
    return this.getDefaultClientRoot();
  }

  static getClientId(authState: AuthState) {
    if (!!process.env.REACT_APP_DEFAULT_CLIENTID)
      return process.env.REACT_APP_DEFAULT_CLIENTID;
    return "";
  }

  static extractDomainName(domain: string | undefined) {
    if (!domain || domain === "") return domain;
    let startIndex = domain.indexOf("//") >= 0 ? domain.indexOf("//") + 2 : 0;
    let endIndex =
      domain.indexOf("/", startIndex) > 0
        ? domain.indexOf("/", startIndex)
        : domain.length;
    return domain.substring(startIndex, endIndex);
  }
}
