import { useSelector } from "react-redux";
import { selectCdmVersion } from "../../redux/auth";
import { useEffect, useState } from "react";
import { CdmVersion } from "../../models/pageConfiguration/CdmVersion";
import { DashboardConfigurationModel } from "../../models/pageConfiguration/DashboardConfigurationModel";
import { DashboardConfiguration } from "../../pages/Configuration/DashboardConfiguration/DashboardConfiguration";

export const useDashboardConfiguration = () => {
  const cdmVersion = useSelector(selectCdmVersion);
  const [dashboardConfig, setDashboardConfig] =
    useState<DashboardConfigurationModel>(
      DashboardConfiguration[CdmVersion.CDM3v0]
    );

  useEffect(() => {
    const targetConfig = DashboardConfiguration[cdmVersion as CdmVersion];

    if (targetConfig) setDashboardConfig(targetConfig);
  }, [cdmVersion]);

  return { dashboardConfig };
};
