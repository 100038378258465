import { FC } from "react";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import RDBreadcrumb from "../RDComponents/RDBreadcrumb";

export interface CrumbProps {
  name: string;
  crumb: string;
  Component: FC<RouteComponentProps>;
  path: string;
}
interface BreadcrumbsProps {
  crumbs?: CrumbProps[];
}
interface SectionsProps {
  content: string;
  href: string;
}
const Breadcrumbs: FC<BreadcrumbsProps> = ({ crumbs }) => {
  const { t } = useTranslation();
  let sections: SectionsProps[] = [];
  if (!(crumbs?.length === 1 && crumbs?.[0].name === "Home")) {
    crumbs?.map((item) => {
      sections.push({
        content: t(item.crumb),
        href: item.path,
      });
    });
  }
  if (window.location.pathname.startsWith("/dashboard")) {
    if (window.location.pathname === "/dashboard") {
      sections.push({
        content: t("Dashboard_Headline"),
        href: "/dashboard",
      });
    }
    sections = sections.filter((x) => x.href !== "/");
  }

  if (window.location.pathname.startsWith("/dashboard/wallet")) {
    sections = sections.filter((x) => x.href !== "/wallet");
  }

  if (window.location.pathname.startsWith("/dashboard/my-sub")) {
    sections = sections.filter((x) => x.href !== "/my-sub");
  }

  return <RDBreadcrumb sections={sections} />;
};

export default Breadcrumbs;
