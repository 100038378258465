import { useEffect, useState } from "react";
import { MessageAttachment } from "amazon-chime-sdk-component-library-react";
import { AttachmentService } from "../../services/AttachmentService";
import defaultImage from "../../assets/default-img.jpg";
import "./styles/message.css";

interface AttachmentProps {
  fileKey: string;
  name: string;
  size: string;
  isImage: boolean;
}

const Attachment = ({
  fileKey,
  name,
  size = "0",
  isImage,
}: AttachmentProps) => {
  const [url, setUrl] = useState(defaultImage);
  const [downUrl, setDownUrl] = useState("");

  const attachmentService = AttachmentService.getInstance();

  useEffect(() => {
    setDownUrl(
      `${process.env.REACT_APP_API_KEY_PCS}/api/Document/DownloadFile/` +
      fileKey +
      `/${name}`
    );
    async function getUrl() {
      const data = await attachmentService.getPresignURL(fileKey);
      setUrl(data);
    }
    getUrl();
  }, [fileKey]);

  return (
    <MessageAttachment
      renderImg={isImage}
      name={name}
      downloadUrl={downUrl}
      size={size}
      imgUrl={url}
    />
  );
};

export default Attachment;