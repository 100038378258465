import { CheckoutWithFullVoucherModel } from "../models/paymentAdyen/checkoutWithFullVoucher.model";
import ResponseResultBase from "../models/ResponseResultBase";
import http from "./HttpService";

export const paymentService = {
  checkoutWithFullVoucherAsync,
};

async function checkoutWithFullVoucherAsync(
  requestModel: CheckoutWithFullVoucherModel
) {
  return await http.post<ResponseResultBase<string>>(
    `/api/v1/payment/CheckoutWithFullVoucher`,
    requestModel
  );
}
