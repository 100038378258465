import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentFrequency } from "../models/paymentAdyen/paymentFrequency";
import Subscription from "../models/Subscription";
import { RootState } from "./store";

export interface ShoppingCartState {
  item: Subscription | null;
  itemQuantity: number;
  creditQuantity: number;
  isRenewSubscription: boolean;
  paymentFrequencySelection: PaymentFrequency | null;
  redirectUrl: string;
}

const initialState: ShoppingCartState = {
  item: null,
  itemQuantity: 0,
  creditQuantity: 0,
  isRenewSubscription: false,
  paymentFrequencySelection: null,
  redirectUrl: "",
  // status: 'idle',
};

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Subscription>) => {
      state.creditQuantity = 0;
      state.paymentFrequencySelection = null;
      state.item = action.payload;
    },
    addItemQuantity: (state, action: PayloadAction<number>) => {
      state.itemQuantity = action.payload;
    },
    setIsRenewSubscription: (state, action: PayloadAction<boolean>) => {
      state.isRenewSubscription = action.payload;
    },
    remove: (state) => {
      state.item = null;
      state.itemQuantity = 0;
      state.paymentFrequencySelection = null;
    },
    addCredits: (state, action: PayloadAction<number>) => {
      state.item = null;
      state.paymentFrequencySelection = null;
      if (action.payload > 0) {
        state.creditQuantity += action.payload;
      } else {
        state.creditQuantity = 0;
      }
    },
    removeAllCredits: (state) => {
      state.creditQuantity = 0;
    },
    clearAllCart: (state) => {
      state.item = null;
      state.itemQuantity = 0;
      state.creditQuantity = 0;
      state.paymentFrequencySelection = null;
    },
    updatePaymentFrequency: (
      state,
      action: PayloadAction<PaymentFrequency | null>
    ) => {
      state.paymentFrequencySelection = action.payload;
    },
    addWalletCredits: (state, action: PayloadAction<number>) => {
      state.item = null;
      state.paymentFrequencySelection = null;
      if (action.payload > 0) {
        state.creditQuantity = 0;
        state.creditQuantity += action.payload;
      } else {
        state.creditQuantity = 0;
      }
    },
    updateRedirectUrl: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        redirectUrl: payload,
      };
    },
  },
});

export const {
  add,
  addItemQuantity,
  remove,
  addCredits,
  removeAllCredits,
  clearAllCart,
  setIsRenewSubscription,
  updatePaymentFrequency,
  addWalletCredits,
  updateRedirectUrl,
} = shoppingCartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectItem = (state: RootState) => state.shoppingCartItem.item;
export const selectIsRenewSubscription = (state: RootState) =>
  state.shoppingCartItem.isRenewSubscription;
export const selectItemQuantity = (state: RootState) =>
  state.shoppingCartItem.itemQuantity;
export const selectCreditQuantity = (state: RootState) =>
  state.shoppingCartItem.creditQuantity;
export const selectRedirectUrl = (state: RootState) =>
  state.shoppingCartItem.redirectUrl;
export const selectItemPaymentFrequency = (state: RootState) =>
  state.shoppingCartItem.paymentFrequencySelection;

export default shoppingCartSlice.reducer;
