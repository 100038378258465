import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Button, Form, Progress, Image, TextArea } from "semantic-ui-react";
import "./styles/user-input.css";
import { AttachmentService } from "../../services/AttachmentService";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { markMessageSeen } from "../helpers/ChimeAPI";
import { channelSetIsTranslate, selectChannelArn, selectChannelName, selectIsTranslate } from "../../redux/chatWidget";
import FileSizeWarningModal from "../../components/modals/FileSizeWarningModal";
import autosize from 'autosize';
import { Icon } from "@bosch/react-frok";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import { useTranslation } from "react-i18next";
import ModalGetEmoji from "./ModalGetEmoji";
import { EmojiClickData } from "emoji-picker-react";
import ModalGetQRCode from "./ModalGetQRCode";
import { selectCountryCode } from "../../redux/auth";
interface UserInputProps {
  onSubmit: any;
  updateMarker: () => void;
  activeChannelArn: string;
  newMessage: any;
}

const UserInput = (props: UserInputProps) => {
  const [inputActive, setInputActive] = useState(false);
  const [userInput, setUserInput] = useState<string>("");
  const [file, setFile] = useState<any>(null);
  const [isUploadFail, setIsUploadFail] = useState<boolean>(false);
  const [progressCurrent, setProgressCurrent] = useState<number>(0);
  const [popFileSizeWarn, setPopFileSizeWarn] = useState<boolean>(false);
  const uploadRef = useRef<any>();
  const inputRef = useRef<any>();
  const channelArn = useSelector(selectChannelArn);
  const channelName = useSelector(selectChannelName);
  const countryCode = useSelector(selectCountryCode);
  const maxSizeMessageInWord = 4000;
  const [countWordMesssage, setCountWordMesssage] = useState<number>(0)
  let flagOnUpload = false;
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const isTranslate = useSelector(selectIsTranslate);
  const { t } = useTranslation();
  const [openEmoji, setOpenEmoji] = useState<boolean>(false);
  const [openQR, setOpenQR] = useState<boolean>(false);


  const getEmoji = (data: EmojiClickData) => {
    setUserInput(userInput + data.emoji)
    setOpenEmoji(false)
  }

  const handleOpenTranslate = () => {
    dispatchReduxToolkit(channelSetIsTranslate({
      ...isTranslate,
      languagebox: true,
    }))
  }

  const userInputChangeHandler = (e: any) => {
    let text = e.target.value || "";
    let countWord = text.length;
    if (countWord <= maxSizeMessageInWord) {
      setUserInput(text);
      setCountWordMesssage(countWord)
    }

    if (countWord > maxSizeMessageInWord) {
      let maxSizeMessageInWordInText = text.substring(0, maxSizeMessageInWord);
      setUserInput(maxSizeMessageInWordInText);
      setCountWordMesssage(maxSizeMessageInWordInText.length)
    }
  };

  const maxSize = parseInt(`${process.env.REACT_APP_AWS_S3_MAX_SIZE}`);
  const onSubmit = async (event?: SyntheticEvent) => {
    event?.preventDefault();
    if (file && !flagOnUpload) {
      flagOnUpload = true;
      const fileName = uuidv4();
      const attachmentService = AttachmentService.getInstance();
      await attachmentService
        .uploadObject(
          file,
          props.activeChannelArn.substring(
            props.activeChannelArn.lastIndexOf("/") + 1
          ),
          fileName
        )
        .on("httpUploadProgress", (evt) => {
          setProgressCurrent(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err: any, data: any) => {
          if (err) {
            setIsUploadFail(true);
          } else {
            const options = JSON.stringify({
              attachment: {
                fileKey: data.Key,
                location: data.Location,
                name: file.name,
                size: file.size,
                type: file.type,
              },
            });

            props.onSubmit(
              {
                author: "me",
                data: { text: userInput || " " },
              },
              options
            );
            setFile(null);
            setUserInput("");
            setCountWordMesssage(0)
          }
        });
    } else {
      if (userInput && userInput.length > 0)
        props.onSubmit({
          author: "me",
          data: { text: userInput },
        });
      setUserInput("");
      setCountWordMesssage(0)
    }
  };

  const setPopFileSizeWarnFalse = () => {
    setPopFileSizeWarn(false);
  };

  useEffect(() => {
    if (inputActive) {
      if (!props.newMessage.MessageId) return;
      markMessageSeen(channelArn, props.newMessage);
    }
  }, [props.newMessage]);

  const handleOnPaste = (e: any) => {
    setIsUploadFail(false);
    const file = e.files[0];
    if (file) {
      if (file.size <= maxSize) {
        setFile(file);
      } else {
        setPopFileSizeWarn(true);
      }
    }
  };

  const handleImport = (e: any) => {
    setIsUploadFail(false);
    const file = e.target.files[0];

    if (file) {
      if (file.size <= maxSize) {
        setFile(file);
      } else {
        setPopFileSizeWarn(true);
      }
      e.target.value = null;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && inputRef.current) {
        inputRef.current.focus();
      }
    });
  });

  useEffect(() => {
    if (inputRef.current && inputRef.current.ref?.current) {
      autosize(inputRef.current.ref.current);

      return () => {
        if (inputRef.current && inputRef.current.ref) {
          autosize.destroy(inputRef.current.ref?.current!);
        }
      };
    }
  }, [userInput, inputRef]);

  const insertLineBreak = () => {
    const textarea = inputRef.current.ref.current;

    if (!textarea) {
      return;
    }

    const { selectionStart, selectionEnd } = textarea;
    const value = textarea.value;
    const newText = `${value.substring(0, selectionStart)}\n${value.substring(selectionEnd)}`;

    setUserInput(newText);

    // Move the cursor to the position after the inserted line break
    const newPosition = selectionStart + 1;
    textarea.setSelectionRange(newPosition, newPosition);
  };


  return (
    <>
      {file && (
        <>
          <div className="upload-object-preview">
            <div className="preview--text">{file.name}</div>
            {file.type.includes("image") && (
              <Image
                style={{
                  width: "200px",
                  maxHeight: "150px",
                  display: "flex",
                  flexDirection: "column",
                }}
                src={URL.createObjectURL(file)}
              />
            )}
            <Button
              type="button"
              icon="delete"
              onClick={() => {
                setFile(null);
              }}
            />
          </div>
          <div>
            {progressCurrent !== 0 && (
              <Progress
                error={isUploadFail}
                value={progressCurrent}
                total={100}
                progress="percent"
                size="tiny"
                className="progress-bar"
              />
            )}
          </div>
        </>
      )}

      <Form
        className="sc-user-input"
        style={{ display: "flex" }}
        onSubmit={onSubmit}
      >
        <div className="input-component">
          <TextArea
            className="text_area"
            placeholder={t("ChatWidgetInputLable")}
            onFocus={() => {
              setInputActive(true);
              props.updateMarker();
            }}
            onBlur={() => {
              setInputActive(false);
            }}
            onChange={userInputChangeHandler}
            value={userInput}
            onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === 'Enter' && e.altKey) {
                e.preventDefault();
                insertLineBreak();
              } else if (e.key === 'Enter') {
                onSubmit(e);
              }
            }}
            onPaste={(e: React.ClipboardEvent<HTMLTextAreaElement>) => {
              handleOnPaste(e.clipboardData);
            }}
            ref={inputRef}
          />
          <Button type="submit" className="userInput--button">
            <Icon
              iconName="paperplane"
            />
          </Button>
        </div>

        <div className={'input-container'}>
          <div className={"actions-left"}>
            <Icon
              iconName="translate"
              onClick={handleOpenTranslate}
            />

            <Icon
              iconName="emoji-happy"
              onClick={() => setOpenEmoji(!openEmoji)}
            />
            <Icon
              iconName="paperclip"
              onClick={() => {
                uploadRef.current.value = null;
                uploadRef.current.click();
              }}
            />
            <Icon
              iconName="qr-code"
              onClick={() =>
                setOpenQR(true)
              }
            />
            <input
              type="file"
              accept="file_extension|audio/*|video/*|image/*|media_type"
              style={{ display: "none" }}
              ref={uploadRef}
              multiple={false}
              hidden
              onChange={handleImport}
            />
            {openEmoji && (
              <div className={"emojiModalWidget"}>
                <ModalGetEmoji
                  getEmoji={(e: EmojiClickData) => getEmoji(e)}
                  onClose={() => setOpenEmoji(false)}
                  onOpen={openEmoji}
                ></ModalGetEmoji>
              </div>
            )}
            {openQR && (
              <div className={"emojiModalWidget"}>
                <ModalGetQRCode
                  onClose={() => setOpenQR(false)}
                  onOpen={openQR}
                  channelArn={props.activeChannelArn}
                  countryCode={countryCode} />
              </div>
            )}
          </div>
          <div className={(countWordMesssage >= maxSizeMessageInWord) ? 'input-text-count limited' : 'input-text-count'}>
            <p>{`${countWordMesssage}/${maxSizeMessageInWord}`}</p>
          </div>
        </div>
      </Form>

      <FileSizeWarningModal
        enabled={popFileSizeWarn}
        title={channelName}
        setErrorTrigger={setPopFileSizeWarnFalse}
      />
    </>
  );
};

export default UserInput;
