import { MenuHeaderItem } from "../../../models/pageConfiguration/MenuHeaderModel";
import { Constants } from "../../../utils/helpers/Constants";

const CdmUs1v0MyRemoteItemChildren: MenuHeaderItem[] = [
  Constants.MenuItemRequest,
  Constants.MenuItemTeam,
  Constants.MenuItemWallet,
  Constants.MenuItemMyProducts,
  Constants.MenuItemStatistic,
];

export const CdmUs1v0MenuConfiguration: MenuHeaderItem[] = [
  Constants.MenuItemNewRequest,
  {
    ...Constants.MenuItemMyRemote,
    childrenItems: CdmUs1v0MyRemoteItemChildren,
    isTheEndOfBlock: true,
  },
  {
    ...Constants.MenuItemSupport,
    isActive: false,
  },
  Constants.MenuItemMyBosch,
  Constants.MenuItemShoppingCart,
  Constants.MenuItemLogin,
  Constants.MenuItemLogout,
  {
    ...Constants.MenuItemContactUs,
    isActive: false,
  },
];
