import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Button, Modal, Image } from "semantic-ui-react";
import BoschCoins from "../../assets/svg/coins.svg";
import Styles from "./LowCreditBalanceCDM3Modal.module.css";
import {
  selectCreditStatus,
  updateIsOpenTopUpModalDirectly,
} from "../../redux/dashboardCDM3Slice";
import { useUserRole } from "../../hooks/useUserRole";
import { AppDispatch } from "../../redux/store";
import { useAppDispatch } from "../../redux/hooks";

interface LowCreditBalanceCDM3ModalProps {
  isDisplayModal: boolean;
  setCloseModalTrigger: Function;
}

const LowCreditBalanceCDM3Modal: FC<LowCreditBalanceCDM3ModalProps> = (
  props: LowCreditBalanceCDM3ModalProps
) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const userCredit = useSelector(selectCreditStatus);
  const { isManager } = useUserRole();
  const [openInformationModal, setOpenInformationModal] = useState(false);

  useEffect(() => {
    setOpenInformationModal(props.isDisplayModal);
  }, [props]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    props.setCloseModalTrigger(false);
  };

  const onBuyCredits = () => {
    dispatchReduxToolkit(updateIsOpenTopUpModalDirectly(true));
    setOpenInformationModal(false);
    history.push("/wallet");
  };

  return (
    <Fragment>
      <Modal
        dimmer={{
          className: `${Styles.inactive_subscription_cmd3_modal} ${Styles.warning}`,
        }}
        className={Styles.modal}
        onClose={closeInformationModal}
        open={openInformationModal}
        size={"tiny"}
      >
        <Modal.Header className={Styles.header}>
          {/* <Boschicon name="bosch-ic-coin-dollar" /> */}
          <Image src={BoschCoins} />
          {t("LowCreditBalanceCDM3Modal_Title")}
        </Modal.Header>
        <Modal.Content className={Styles.content}>
          <Grid className={`${Styles.ui} ${Styles.grid}`}>
            <Grid.Row className={Styles.row}>
              <p
                className={Styles.description_text}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "LowCreditBalanceCDM3Modal_Description_Line1"
                  ).replace("[credit_value]", `<strong>${userCredit}</strong>`),
                }}
              ></p>
              <p className={Styles.description_text}>
                {isManager
                  ? t("LowCreditBalanceCDM3Modal_Description_Line2")
                  : t("LowCreditBalanceCDM3Modal_Description_Line2_Individual")}
              </p>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions className={Styles.actions}>
          {isManager ? (
            <>
              <Button basic color="blue" onClick={closeInformationModal}>
                {t("CloseButton")}
              </Button>
              <Button primary onClick={onBuyCredits}>
                {t("DashboardCDM3_WalletInfo_BuyCreditsButton")}
              </Button>
            </>
          ) : (
            <Button primary onClick={closeInformationModal}>
              {t("CloseButton")}
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default LowCreditBalanceCDM3Modal;
