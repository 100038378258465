import { Label, LabelProps } from "semantic-ui-react";
import style from "./RDBadge.module.css";

interface Props extends LabelProps {
  content?: string | number;
  type?: "default" | "success" | "warning" | "error";
  isAbsolute?: boolean;
}

const RDBadge = (props: Props) => {
  const { content, isAbsolute, type, ...restProps } = props;
  const { circular, ...restrictedProps } = restProps;

  const generateRDBadgeClassName = () => {
    const classNames: string[] = [style.rd_badge];

    classNames.push(type ? style[type] : style.default);

    if (isAbsolute && isAbsolute === true)
      classNames.push(style.absolute_badge);

    if (restrictedProps.className) classNames.push(restrictedProps.className);

    return classNames.join(" ");
  };

  return (
    <Label {...restrictedProps} className={generateRDBadgeClassName()}>
      <p className={style.badge_content}>{content}</p>
    </Label>
  );
};

export default RDBadge;
