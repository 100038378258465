import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Dropdown, Grid, Modal } from "semantic-ui-react";
import { useAppDispatch } from "../../../../../redux/hooks";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import Account from "../../../../../models/Account";
import { RequestGetWholesalerBranches } from "../../../../../models/wholesaler/request.getWholesalerBranches.model";
import { RequestGetWholesalerGroups } from "../../../../../models/wholesaler/request.getWholesalerGroups.model";
import WholesalerBranch from "../../../../../models/WholesalerBranch";
import WholesalerOrganization from "../../../../../models/WholesalerOrganization";
import {
  getAccountSimpleAsync,
  updateSignInAccount,
  selectCiamId,
} from "../../../../../redux/account";
import {
  selectCountryCode,
  selectLanguage,
  selectTimeZoneId,
} from "../../../../../redux/auth";
import { AppDispatch } from "../../../../../redux/store";
import {
  getWholesalerBranchesAsync,
  getWholesalerGroupsAsync,
} from "../../../../../redux/wholesalerSlide";
import "./SelectWholesalerModalCDM3.css";

interface ObjectList {
  key: string;
  text: string;
  value: any;
}

interface SelectWholesalerModalCDM3Props {
  onClose?: Function;
}

const SelectWholesalerModalCDM3: FC<SelectWholesalerModalCDM3Props> = ({
  onClose,
}) => {
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const { t } = useTranslation();
  const countryCode = useSelector(selectCountryCode); // country code
  const language = useSelector(selectLanguage);
  const timeZoneId = useSelector(selectTimeZoneId); // time zone
  const ciamId = useSelector(selectCiamId);
  const [isLoadingWholesalerGroup, setIsLoadingWholesalerGroup] =
    useState(true);
  const [wholesalerGroups, setWholesalerGroups] =
    useState<WholesalerOrganization[]>();
  const [wholesalerGroupListSelect, setWholesalerGroupListSelect] = useState<
    ObjectList[]
  >([]);
  const [wholesalerGroupSelected, setWholesalerGroupSelected] =
    useState<WholesalerOrganization | null>();
  const [isLoadingWholesalerBranch, setIsLoadingWholesalerBranch] =
    useState(false);
  const [wholesalerBranchListSelect, setWholesalerBranchListSelect] = useState<
    ObjectList[]
  >([]);
  const [wholesalerBranches, setWholesalerBranches] =
    useState<WholesalerBranch[]>();
  const [wholesalerBranchSelected, setWholesalerBranchSelected] =
    useState<WholesalerBranch | null>();
  const [isApplyHandling, setIsApplyHandling] = useState(false);

  useEffect(() => {
    let model: RequestGetWholesalerGroups = {
      ciamId: ciamId,
      countryCode: countryCode,
      languageCode: language,
      timeZoneId: timeZoneId,
    };

    const getWholesalerGroupsPromise = dispatchReduxToolkit(
      getWholesalerGroupsAsync(model)
    );
    getWholesalerGroupsPromise.then((result) => {
      let data = result.payload as WholesalerOrganization[];
      setWholesalerGroups(data);
      if (!!data && data.length > 0) {
        const wholesalerGroups: any[] = data.map((x) => {
          return {
            key: x.id,
            text: x.name,
            value: x.id,
          };
        });
        setWholesalerGroupListSelect(wholesalerGroups);
      } else {
        setWholesalerGroupSelected(null);
      }
      setIsLoadingWholesalerGroup(false);
    });
  }, []);

  useEffect(() => {
    if (wholesalerGroups && wholesalerGroups.length === 1) {
      setWholesalerGroupSelected(wholesalerGroups[0]);
      wholesalerGroupChanged(null, { value: wholesalerGroups[0].id });
    }
  }, [wholesalerGroups]);

  const closeModal = () => {
    if (!!onClose) {
      onClose();
    }
  };

  const wholesalerGroupChanged = (event: any, data: any) => {
    const selectedValue = data.value;
    setWholesalerGroupSelected(
      wholesalerGroups?.find((x) => x.id === +selectedValue)
    );
    setWholesalerBranchSelected(null);
    setWholesalerBranchListSelect([]);
    loadWholesalerBranches(selectedValue);
  };

  const loadWholesalerBranches = (wholesalerGroupId: number) => {
    if (!!wholesalerGroupId) {
      setIsLoadingWholesalerBranch(true);
      let model: RequestGetWholesalerBranches = {
        ciamId: ciamId,
        countryCode: countryCode,
        languageCode: language,
        timeZoneId: timeZoneId,
        wholesalerGroupId: wholesalerGroupId,
      };

      const getWholesalerBranchesPromise = dispatchReduxToolkit(
        getWholesalerBranchesAsync(model)
      );
      getWholesalerBranchesPromise.then((result) => {
        let data = result.payload as WholesalerBranch[];
        setWholesalerBranches(data);
        if (!!data) {
          const dataMapped: any[] = data.map((x) => {
            return {
              key: x.id,
              text: x.name,
              value: x.id,
            };
          });
          setWholesalerBranchListSelect(dataMapped);
          if (data.length === 1) {
            setWholesalerBranchSelected(data[0]);
          }
        } else {
          setWholesalerBranchSelected(null);
        }

        setIsLoadingWholesalerBranch(false);
      });
    }
  };

  const updateAccountAsync = async (existingUser: Account | null) => {
    if (!!existingUser) {
      const account = Object.assign({}, existingUser);
      account.wholesalerOrganizationId = wholesalerGroupSelected?.id;
      account.wholesalerGroup = wholesalerGroupSelected;
      account.wholesalerBranchId = wholesalerBranchSelected?.id;
      account.wholesalerBranch = wholesalerBranchSelected;

      const resUpdateAccount = await dispatchReduxToolkit(
        updateSignInAccount(account)
      );
      if (updateSignInAccount.fulfilled.match(resUpdateAccount)) {
        return true;
      }
    }

    return false;
  };

  const onApply = async () => {
    setIsApplyHandling(true);
    const response = await dispatchReduxToolkit(
      getAccountSimpleAsync({
        ciamId: ciamId,
        language: language,
        countryCode: countryCode,
      })
    );
    if (getAccountSimpleAsync.fulfilled.match(response)) {
      if (!!response.payload && response.payload.success) {
        const isUpdateSuccess = await updateAccountAsync(
          response.payload.dataObject
        );
        if (isUpdateSuccess) {
          await dispatchReduxToolkit(
            getAccountSimpleAsync({
              ciamId: ciamId,
              language: language,
              countryCode: countryCode,
            })
          );
          setIsApplyHandling(false);
        }
      }
    }
  };

  return (
    <Fragment>
      <Modal onClose={closeModal} open size={"mini"} closeOnDimmerClick={false}>
        <div className="common-modal-header">
          <div className="common-modal-header-text">
            {t("SelectWholesalerModal_Title")}
          </div>
          {!!onClose && (
            <div className="close-button-container">
              <Close className="close-button" onClick={closeModal} />
            </div>
          )}
        </div>
        <div className="common-modal-content select-wholesaler-modal-content">
          <Grid.Row className="modal-grid-row1">
            <label>
              <span style={{ color: "var(--boschBlue50)" }}>*</span>{" "}
              {t("SelectWholesalerModal_WholesalerGroupLabel")}
            </label>
          </Grid.Row>
          <Grid.Row className="modal-grid-row2">
            <Dropdown
              style={{ width: "100%" }}
              placeholder={t(
                "MyRequests_First_SelectServiceModal_Choose_PlaceHolder"
              )}
              scrolling
              selection
              search
              selectOnBlur={false}
              loading={isLoadingWholesalerGroup}
              // //clearable
              options={wholesalerGroupListSelect}
              onChange={wholesalerGroupChanged}
              value={wholesalerGroupSelected?.id}
              // error={!makeValidation}
            ></Dropdown>
          </Grid.Row>
          <Grid.Row className="modal-grid-row1">
            <label>
              <span style={{ color: "var(--boschBlue50)" }}>*</span>{" "}
              {t("SelectWholesalerModal_WholesalerBranchLabel")}
            </label>
          </Grid.Row>
          <Grid.Row className="modal-grid-row2">
            <Dropdown
              style={{ width: "100%" }}
              placeholder={t(
                "MyRequests_First_SelectServiceModal_Choose_PlaceHolder"
              )}
              scrolling
              selection
              search
              selectOnBlur={false}
              loading={isLoadingWholesalerBranch}
              // //clearable
              options={wholesalerBranchListSelect}
              onChange={(event: any, data: any) => {
                setWholesalerBranchSelected(
                  wholesalerBranches?.find((x) => x.id === +data.value)
                );
              }}
              value={wholesalerBranchSelected?.id}
              // error={!makeValidation}
            ></Dropdown>
          </Grid.Row>
        </div>
        <div className="common-modal-multiple-actions">
          <Button
            className="primary-button button-block"
            onClick={onApply}
            disabled={
              !(wholesalerGroupSelected && wholesalerBranchSelected) ||
              isApplyHandling
            }
            loading={isApplyHandling}
          >
            {t("ApplyButton")}
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default SelectWholesalerModalCDM3;
