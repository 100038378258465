import { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppDispatch, useAppDispatch } from "../redux/store";
import { logoutCallbackAsync } from "../redux/account";
import { AuthService } from "../services/AuthService";
import CustomLoader from "../components/loading/CustomLoader";
import { clearAllCart } from "../redux/shoppingCartSlide";
import { setJoinCompanyActiveModal } from "../redux/joinCompanySlide";
import { resetNewRequestState } from "../redux/newRequestSlice";
import { clearCart } from "../redux/shoppingCartV3Slice";
import { channelClearState } from "../redux/chatWidget";
import { useSelector } from "react-redux";
import { selectMainContentPinned, setOpenSideNav } from "@redux/auth";

const SignOutCallback = () => {
  const history = useHistory();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const isMainContentPinned = useSelector(selectMainContentPinned);

  useEffect(() => {
    async function performLogout() {
      const response = await dispatchReduxToolkit(logoutCallbackAsync());
      if (logoutCallbackAsync.fulfilled.match(response)) {
        const authService = AuthService.getInstance();
        await authService.clearLoginUserState();
        dispatchReduxToolkit(clearAllCart());
        dispatchReduxToolkit(clearCart());
        dispatchReduxToolkit(setJoinCompanyActiveModal("none"));
        dispatchReduxToolkit(resetNewRequestState());
        dispatchReduxToolkit(channelClearState());
        setTimeout(() => {
          if (!isMainContentPinned) dispatchReduxToolkit(setOpenSideNav(false));
          history.push("/");
        }, 2000);
      }
    }

    performLogout();
  }, []);

  return (
    // <div>
    //     <h1>Loading...</h1>
    // </div>
    <Fragment>
      <CustomLoader enable={true}></CustomLoader>
    </Fragment>
  );
};

export default SignOutCallback;
