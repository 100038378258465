import { RBGeolocation } from "../models/geolocation/RBGeolocation";
import { Region } from "../models/RegionState";
import ResponseResultBase from "../models/ResponseResultBase";
import httpNoneSecure from "./HttpServiceNoneSecure";
import httpPCS from "./HttpServicePCS";

export class CommonService {
  public static isGrantPermission(permission: string): boolean {
    return false;
  }

  public static async getAllLanguages(): Promise<ResponseResultBase<Region[]>> {
    const response = await httpNoneSecure.get<ResponseResultBase<Region[]>>(
      `/api/v1/support/GetAllLanguagesWithArea`
    );
    let isDev = CommonService.isDev();
    if (isDev) {
      if (!!response.data && !!response.data.dataObject) {
        response.data.dataObject.forEach((element) => {
          element.url = "http://localhost:3000/";
        });
      }
    }
    return response.data;
  }

  public static async getGeolocation(): Promise<
    ResponseResultBase<RBGeolocation>
  > {
    const response = await httpNoneSecure.get<
      ResponseResultBase<RBGeolocation>
    >(`/api/v1/Geolocation/GetGeolocation`);
    return response.data;
  }

  public static isDev() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  }

  public static getCIAMEditProfileLInk(): string {
    return process.env.REACT_APP_CIAM_EDIT_PROFILE_URL !== undefined
      ? process.env.REACT_APP_CIAM_EDIT_PROFILE_URL
      : "";
  }

  public static scrollTo = (tag: string) => {
    const section = document.querySelector(tag);
    if (section) {
      window.scroll({
        top: (section as HTMLElement).offsetTop,
        behavior: "smooth",
      });
    }
  };

  public static async detectLanguage(data: any, apiKey: string) {
    try {
      const headers = {
        ApiKey: apiKey,
      };
      const response = await httpPCS.post<any>(
        `/api/chime/DetectLanguage/`,
        data,
        { headers }
      );
      return response.data;
    } catch (error) {}

    return null;
  }

  public static readonly getUserProfileAttribute= (
    userAttribute: string,
    countryCodeLower: string,
    profile: any
  ) => {
    let ret;
    let phObject = profile[userAttribute + countryCodeLower];

    if (phObject) {
      const propertyNames = Object.keys(phObject);
      const propertyValues = Object.values(phObject);

      if (propertyNames?.length >= 1) {
        ret = (propertyValues[propertyNames?.length - 1] as boolean) ?? false;
      } else {
        ret = false;
      }
    } else {
      ret = false;
    }

    return ret;
  };
}
