import { Dropdown, DropdownProps } from "semantic-ui-react";
import Boschicon from "../BoschIcon/Boschicon";
import Styles from "./RDDropdown.module.css";
import { Icon } from "@bosch/react-frok";

interface RDDropdownProps extends DropdownProps {
  title?: string;
  errorMessage?: string;
  testId?: string;
  downIcon?: string;
  id?: string;
}

const RDDropdown = (props: RDDropdownProps) => {
  const { title, errorMessage, testId, downIcon, id, ...restProps } = props;

  return (
    <div className={Styles.rd_input_group} id={id}>
      {!!title ? <span className={Styles.title}>{title}</span> : <></>}
      <Dropdown
        {...restProps}
        selectOnBlur={false}
        header={
          props.header && <div className={Styles.header}>{props.header}</div>
        }
        className={`${Styles.dropdown} ${
          !!title ? Styles.dropdown_with_title : ""
        } ${props.className ?? ""}`}
        icon={
          <div className={Styles.rd_icon}>
            <Icon iconName={downIcon || "down"} />
          </div>
        }
        data-testid={testId || ""}
      />
      {!!errorMessage && (
        <span className={Styles.error}>
          <Boschicon name="bosch-ic-alert-error" />
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default RDDropdown;
