import { useTranslation } from "react-i18next";
import RDAlert from "../../RDComponents/RDAlert";
import { useSelector } from "react-redux";
import {
  authSetSignIn,
  selectCountryCode,
  selectCountryName,
} from "../../../redux/auth";
import { useAppDispatch } from "../../../redux/hooks";
import { clearCart } from "../../../redux/shoppingCartV3Slice";
import { clearAllCart } from "../../../redux/shoppingCartSlide";
import { AuthService } from "../../../services/AuthService";
import RedirectHelper from "../../../utils/helpers/RedirectHelper";
import { selectAccount, updateAccount } from "../../../redux/account";
import { useState } from "react";
import CustomLoader from "../../loading/CustomLoader";

const SwitchCountryModal = () => {
  const { t } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();
  const countryName = useSelector(selectCountryName);
  const countryCode = useSelector(selectCountryCode);
  const currentAccount = useSelector(selectAccount);

  const [isLoading, setLoading] = useState(false);
  const [isShowErrorModal, setShowErrorModal] = useState(false);

  const getMessage = (originalMessage: string) => {
    const accountCountry = AuthService.getRegionsLanguages().find(
      (region) => region.country === currentAccount?.countryCode
    );

    return originalMessage
      .replace("{selectedCountry}", countryName)
      .replace("{countryName}", accountCountry?.countryName || "");
  };

  const switchCountry = (targetCountryCode: string) => {
    const regions = AuthService.getRegionsLanguages();
    const regionSelection = regions.find(
      (region) =>
        region.country.trim().toLowerCase() ===
        targetCountryCode.trim().toLowerCase()
    );

    if (!regionSelection) {
      return;
    }

    const language = regionSelection.languages.find((l) => l.defaultLanguage);

    RedirectHelper.redirectToRightCountry(
      regionSelection.country,
      !!language ? language.lcid : ""
    );
  };

  const onAcceptSwitchCountry = async () => {
    if (!currentAccount) return;

    try {
      setLoading(true);
      await dispatchReduxToolkit(
        updateAccount({
          ...currentAccount,
          countryCode,
        })
      );

      setLoading(false);
    } catch {
      setLoading(false);
      setShowErrorModal(true);
    }
  };

  const onCancelSwitchCountry = () => {
    if (!currentAccount) return;

    dispatchReduxToolkit(authSetSignIn(true));
    dispatchReduxToolkit(clearAllCart());
    dispatchReduxToolkit(clearCart());

    const timeoutId = window.setTimeout(() => {
      switchCountry(currentAccount.countryCode);
      window.clearTimeout(timeoutId);
    }, 1000);
  };

  return (
    <>
      <RDAlert
        isOpen
        type="warning"
        messages={[
          getMessage(t("SwitchCountryModal_FirstMessage")),
          getMessage(t("SwitchCountryModal_SecondMessage")),
          getMessage(t("SwitchCountryModal_ThirdMessage")),
        ]}
        onAccept={onAcceptSwitchCountry}
        acceptButtonText={t("YesButton")}
        onCancel={onCancelSwitchCountry}
        cancelButtonText={t("CancelButton")}
      />
      {isShowErrorModal ? (
        <RDAlert
          isOpen
          type="error"
          messages={[t("Error_Common")]}
          acceptButtonText={t("CloseButton")}
          onAccept={() => {
            onCancelSwitchCountry();
            setShowErrorModal(false);
          }}
        />
      ) : (
        <></>
      )}
      {isLoading ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default SwitchCountryModal;
