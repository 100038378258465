import { useSelector } from "react-redux";
import { selectAccountDateFormat } from "../redux/account";
import {
  formatCDMDateTime,
  getBrowserDateFormat,
} from "../utils/dateFormat.util";
import { DateType, TimeType } from "../models/dateFormat";

export const useDateFormat = () => {
  const dateFormat = useSelector(selectAccountDateFormat);

  const formatDate = (
    date: string,
    dateSeparator?: string,
    timeType?: TimeType
  ) => {
    return formatCDMDateTime({
      input: date,
      dateSeparator,
      dateType: !!dateFormat
        ? (dateFormat as DateType)
        : getBrowserDateFormat(),
      timeType,
    });
  };

  return formatDate;
};
