import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import { AppDispatch, RootState } from "./store";
import http from "../services/HttpService";
import ResponseResultBase from "../models/ResponseResultBase";
import { ClientConfigModel } from "../models/paymentAdyen/clientConfig.model";
import { RequestPaymentMethods } from "../models/paymentAdyen/request.getPaymentMethods.model";
import { CheckoutWithFullVoucherModel } from "../models/paymentAdyen/checkoutWithFullVoucher.model";
import { paymentService } from "../services/PaymentService";
import { CountryPaymentMethodsDto } from "../models/paymentAdyen/countryPaymentMethodsDto";
import { PaymentFrequency } from "../models/paymentAdyen/paymentFrequency";
import {
  RequestGetPaymentFrequencyListInput,
  RequestUpdateCustomerPaymentFrequencyInput,
} from "../models/paymentAdyen/Dtos/apiRequestResponse";
import httpNoneSecure from "../services/HttpServiceNoneSecure";

export interface PaymentAdyenState {
  paymentAdyen: any | null;
  paymentMethodForCountries: CountryPaymentMethodsDto[] | [];
  paymentFrequencyList: PaymentFrequency[] | [];
}

const initialState: PaymentAdyenState = {
  paymentAdyen: null,
  paymentMethodForCountries: [],
  paymentFrequencyList: [],
};

const baseSubRoute = "/api/v1/payment";

export const getclientConfigAsync = createAsyncThunk<
  ClientConfigModel | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("payment/getClientConfig", async (_: void, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<ClientConfigModel>>(
      `${baseSubRoute}/getClientConfig`
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getPaymentMethodsAsync = createAsyncThunk<
  ResponseResultBase<string> | null,
  RequestPaymentMethods,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "payment/getPaymentMethods",
  async (requestModel: RequestPaymentMethods, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<string>>(
        `${baseSubRoute}/getPaymentMethods`,
        requestModel
      );
      console.log(response);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const GetPaymentMethodsForCountryAsync = createAsyncThunk<
  ResponseResultBase<CountryPaymentMethodsDto[]> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("payment/getPaymentMethodsForCountry", async (_, thunkApi) => {
  try {
    const response = await httpNoneSecure.get<
      ResponseResultBase<CountryPaymentMethodsDto[]>
    >(`${baseSubRoute}/getPaymentMethodsForCountry`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const GetPaymentFrequencyListAsync = createAsyncThunk<
  ResponseResultBase<PaymentFrequency[]> | null,
  RequestGetPaymentFrequencyListInput,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "payment/GetPaymentFrequencies",
  async (input: RequestGetPaymentFrequencyListInput, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<PaymentFrequency[]>>(
        `${baseSubRoute}/GetPaymentFrequencies?CountryCode=${input.countryCode}&LanguageCode=${input.languageCode}&subscriptionId=${input.subscriptionId}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const submitPaymentAsync = createAsyncThunk<
  string | null,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("payment/submitPayment", async (submitPaymentData: any, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<string>>(
      `${baseSubRoute}/submitPayment`,
      submitPaymentData
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const submitSepaPaymentAsync = createAsyncThunk<
  string | null,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("payment/submitSepaPayment", async (submitPaymentData: any, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<string>>(
      `${baseSubRoute}/submitSepaPayment`,
      submitPaymentData
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const submitUpdatePaymentMethodAsync = createAsyncThunk<
  string | null,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "payment/submitUpdatePaymentMethod",
  async (submitPaymentData: any, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<string>>(
        `${baseSubRoute}/submitUpdatePaymentMethod`,
        submitPaymentData
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const submitUpdateSepaPaymentMethodAsync = createAsyncThunk<
  string | null,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "payment/submitUpdateSepaPaymentMethod",
  async (submitPaymentData: any, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<string>>(
        `${baseSubRoute}/submitUpdateSepaPaymentMethod`,
        submitPaymentData
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const submitPaymentDetailAsync = createAsyncThunk<
  string | null,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("payment/processDetails", async (submitPaymentDetailData: any, thunkApi) => {
  try {
    let submitDetail = JSON.stringify(submitPaymentDetailData)
      .replace("threeds2.fingerprint", "threeds2fingerprint")
      .replace("threeds2.challengeResult", "threeds2challengeResult");
    let submitObjDetail = JSON.parse(submitDetail);
    const response = await http.post<ResponseResultBase<string>>(
      `${baseSubRoute}/processDetails`,
      submitObjDetail
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const handlingPaymentRedirectionAsync = createAsyncThunk<
  string | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("payment/handlingRedirection", async (refOrder, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<string>>(
      `${baseSubRoute}/HandlingRedirection?orderId=${refOrder}`
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getApplyVoucherAsync = createAsyncThunk<
  ClientConfigModel | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("payment/getApplyVoucher", async (_: void, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<ClientConfigModel>>(
      `${baseSubRoute}/getApplyVoucher`
    );
    return response.data.dataObject;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

//export const paymentAdyenTransaction = (rootState: RootState) => rootState.paymentAdyen;

export const checkoutWithFullVoucherAsync = createAsyncThunk<
  string | null,
  CheckoutWithFullVoucherModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "payment/checkoutWithFullVoucher",
  async (model: CheckoutWithFullVoucherModel, thunkApi) => {
    try {
      const response = await paymentService.checkoutWithFullVoucherAsync(model);
      return response.data.success.toString();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const updateCustomerPaymentFrequencyAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  RequestUpdateCustomerPaymentFrequencyInput,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "payment/updateCustomerPaymentFrequency",
  async (input: RequestUpdateCustomerPaymentFrequencyInput, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<boolean>>(
        `${baseSubRoute}/UpdateCustomerPaymentFrequency`,
        input
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const paymentAdyenReducerSlice = createSlice({
  name: "paymentAdyenReducer",
  initialState,
  reducers: {
    //getTransactionState: state => { return { ...state } }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        GetPaymentMethodsForCountryAsync.fulfilled,
        (state, { payload }) => {
          if (payload !== null && payload.httpStatusCode === 200) {
            state.paymentMethodForCountries =
              payload.dataObject !== null ? payload.dataObject : [];
          }
        }
      )
      .addCase(GetPaymentFrequencyListAsync.fulfilled, (state, { payload }) => {
        if (payload !== null && payload.httpStatusCode === 200) {
          state.paymentFrequencyList =
            payload.dataObject !== null ? payload.dataObject : [];
        }
      });
  },
});

export const selectPaymentAdyenState = (rootState: RootState) =>
  rootState.paymentAdyen;
export const selectPaymentMethodForCountries = (rootState: RootState) =>
  selectPaymentAdyenState(rootState).paymentMethodForCountries;
export const selectPaymentFrequencyList = (rootState: RootState) =>
  selectPaymentAdyenState(rootState).paymentFrequencyList;
export default paymentAdyenReducerSlice.reducer;
