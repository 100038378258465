import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import Styles from "./styles/ModalGetEmoji.module.scss";
import { Dialog } from '@bosch/react-frok';
import { useEffect, useRef } from 'react';
interface ModalGetEmojiProps {
    getEmoji: Function,
    onClose: Function,
    onOpen: boolean
}

const ModalGetEmoji = ({ getEmoji, onClose, onOpen }: ModalGetEmojiProps) => {
    const openModalRef = useRef<any>();
    useEffect(() => {
        const checkIfClickedOutside = (event: MouseEvent) => {
            if (
                onOpen &&
                openModalRef.current &&
                !openModalRef.current.contains(event.target)
            ) {
                onClose();
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [onOpen]);
    return (
        <Dialog
            open
            className={Styles["dialog-modal"]}
            title="Emoji"
            variant="success"
            onClose={() => onClose()}
            ref={openModalRef}
        >
            <div className={Styles['emoji-container']}>
                <EmojiPicker
                    className={Styles['emoji-block']}
                    onEmojiClick={(emojiData: EmojiClickData) => getEmoji(emojiData)}
                    searchDisabled={true}
                    lazyLoadEmojis={true}
                    autoFocusSearch={false}></EmojiPicker>

            </div>

        </Dialog >
    );
};

export default ModalGetEmoji;
