export enum DateType {
  DMY = "dd-mm-yyyy",
  MDY = "mm-dd-yyyy",
  YMD = "yyyy-mm-dd",
}

export type TimeType = "hm" | "hms" | "hmp" | "hmsp"; // p = AM | PM

export interface DateFormat {
  input: string;
  dateType?: DateType;
  dateSeparator?: string;
  timeType?: TimeType;
}
