import axios from "axios";
import { AuthService } from "./AuthService";
const qs = require("qs");

const httpEditProfile = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

httpEditProfile.interceptors.request.use(
  async (config) => {
    const authService = AuthService.getInstance();
    const user = await authService.getUserEditProfile();
    if (user && user.access_token && !user.expired) {
      config.headers.common["Authorization"] = "Bearer " + user.access_token;
    } else if (user && user.expired) {
      try {
        const newUser = await authService.renewTokenEditProfile();
        config.headers.common["Authorization"] =
          "Bearer " + newUser?.access_token;
      } catch (e) {}
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpEditProfile.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const authService = AuthService.getInstance();
    const originalRequest = error.config;
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const user = await authService.getUserEditProfile();
      if (user && user.expired) {
        try {
          const newuser = await authService.renewTokenEditProfile();
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + newuser?.access_token;
          return httpEditProfile(originalRequest);
        } catch (e) {
          window.location.href = "/sessiontimeout";
        }
      } else {
        await authService.loginAsync();
      }
    }
    return Promise.reject(error);
  }
);

export default httpEditProfile;
