export interface VHCScanResultModalState {
  isShowScanResultModal: boolean;
  isShowUnlockCertificateModal: boolean;
  isShowRejectCertificateModal: boolean;
  isShowErrorModal: boolean;
}

export enum VHCRequestDetailsStep {
  _, // Do not use this option
  IN_PROGRESS,
  SCAN_RESULT,
  RATE_ERROR_CODES,
  DOWNLOAD_CERTIFICATE,
}

export interface VhcRateErrorCodesModalState {
  isShowErrorModal: boolean;
  isShowCertificateReady: boolean;
  isShowUnratedErrorCodesModal: boolean;
  isShowGeneratingCertificateModal: boolean;
}

export interface VhcStatusNotificationType {
  ticketId: number;
  sessionId: number;
  statusId: number;
  customerId: number;
}

export interface VhcRequestStatusChangeType {
  statusId: number;
  statusLocalized: string;
  subStatusId?: number;
  subStatusLocalized?: string;
}
