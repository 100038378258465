export enum MenuHeaderKey {
  DEFAULT,
  NEW_REQUEST,
  MY_REMOTE,
  SUPPORT,
  MY_BOSCH,
  SHOPPING_CART,
  REQUESTS,
  TEAM,
  WALLET,
  MY_PRODUCTS,
  STATISTICS,
  COURSE_CATALOG,
  EVENT_CALENDAR,
  TRAINING_PROGRAM,
  LOG_IN,
  LOG_OUT,
  CONTACT_US,
  PRODUCTS,
  DASHBOARD,
  HARDWARE,
}

export type MenuHeaderItem = {
  key: MenuHeaderKey;
  /**
   * Let's use translation key
   */
  label: string;
  /**
   * If isShowOnMinimalMenu === true, this item label will be shown on minimal menu that user click item to expand full menu header
   */
  isShowOnMinimalMenu: boolean;
  /**
   * If isTheEndOfBlock === true, a divider line will be render below this item
   */
  isTheEndOfBlock: boolean;
  supportedRoles: number[];
  /**
   * If isActive === false, it mean this feature is not available for currently -> Do not show it
   */
  isActive: boolean;
  /**
   * If isLoginRequired === true, this item children only show in case user is logged in
   */
  isLoginRequired: boolean;
  childrenItems?: MenuHeaderItem[];
  isHideIfLoggedIn?: boolean;
  isAccountMappedRequired?: boolean;
  /**
   * To handle click on menu item -> High priority to check than redirectUrl
   * Assign onClick in useMenuHeaderConfiguration file
   */
  onClick?: Function;
  /**
   * Only add this field if childrenItems = undefined
   */
  redirectUrl?: string;
};

export type MinimalMenuHeaderItem = Omit<
  MenuHeaderItem,
  | "isLoginRequired"
  | "supportedRoles"
  | "isActive"
  | "isHideIfLoggedIn"
  | "isAccountMappedRequired"
>;
