import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { MaintenanceInfo } from "../models/maintenance/MaintenanceInfo.model";
import ResponseResultBase from "../models/ResponseResultBase";
import http from "../services/HttpService";
import { AppDispatch, RootState } from "./store";

export interface MaintenanceState {
  maintenanceInfo: string | null;
  maintenanceInformation: MaintenanceInfo | null;
  isForcedToHideNotificationBanner: boolean;
}

const initialState: MaintenanceState = {
  maintenanceInfo: "",
  maintenanceInformation: null,
  isForcedToHideNotificationBanner: false,
};

export const getMaintenanceInfoAsync = createAsyncThunk<
  ResponseResultBase<string> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("general/GetMaintenanceInfo", async (_, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<string>>(
      `/api/v1/general/GetMaintenanceInfo`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const maintenanceSlice = createSlice({
  name: "maintenanceReducer",
  initialState,
  reducers: {
    updateForcedToHideNotificationBanner: (
      state,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        isForcedToHideNotificationBanner: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMaintenanceInfoAsync.fulfilled, (state, { payload }) => {
      if (payload != null && payload.httpStatusCode === 200) {
        return {
          ...state,
          maintenanceInfo: payload.dataObject,
          maintenanceInformation: null,
        };
      }
    });
    /* .addCase(getMaintenanceInformationAsync.fulfilled, (state, { payload }) => {     
      if (payload != null && payload.httpStatusCode == 200) {
        return {
          ...state,
          maintenanceInfo: "",
          maintenanceInformation: payload.dataObject
        }
      }
    }) */
  },
});

export const selectMaintenanceState = (state: RootState) => state.maintenance;
export const selectIsForcedToHideNotificationBanner = (state: RootState) =>
  state.maintenance.isForcedToHideNotificationBanner;

export const { updateForcedToHideNotificationBanner } =
  maintenanceSlice.actions;

export default maintenanceSlice.reducer;
