import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RequestContactFormModel } from "../models/contactUs/request.contactForm.model";
import { RequestDeleteAccountModel } from "../models/contactUs/request.deleteAccount.model";
import ResponseResultBase from "../models/ResponseResultBase";
import http from "../services/HttpService";
import httpNoneSecure from "../services/HttpServiceNoneSecure";
import { AppDispatch, RootState } from "../redux/store";
import { SupportTimezoneModel } from "../models/general/SupportTimezone.model";
import { UtcTimeModel } from "../models/general/UtcTimeModel";

export const submitContactUsAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  RequestContactFormModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("general/contactUs", async (requestModel, thunkApi) => {
  try {
    const response = await httpNoneSecure.post<ResponseResultBase<boolean>>(
      `/api/v1/general/contactUs`,
      requestModel
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const requestDeleteAccount = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  RequestDeleteAccountModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("general/requestDeleteAccount", async (requestModel, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<boolean>>(
      `/api/v1/general/requestDeleteAccount`,
      requestModel
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getSupportTimezonesAsync = createAsyncThunk<
  ResponseResultBase<SupportTimezoneModel[]> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("general/GetSupportTimezones", async (_, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<SupportTimezoneModel[]>>(
      `/api/v1/general/GetSupportTimezones`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getUtcTimeAsync = createAsyncThunk<
  ResponseResultBase<UtcTimeModel> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("general/GetSupportTimezones", async (_, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<UtcTimeModel>>(
      `/api/v1/general/GetUTCTime`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});
