import React, {
  createContext,
  useContext,
  useState,
} from "react";

interface CloseLiveBoxContextProviderProps {
  children: React.ReactNode;
}


interface CloseLiveBoxContextType {
  isLiveChatOpen: boolean;
  setIsLiveChatOpen: (data: boolean) => void;
  channelActiveId: string;
  setChannelActiveId: (data: string) => void;
  newMessageCome: string;
  setNewMessageCome: (data: string) => void;
  deletedChannel: string;
  setDeletedChannel: (data: string) => void;
}

export const CloseLiveBoxContext = createContext<
  CloseLiveBoxContextType | undefined
>(undefined);

export const CloseLiveBoxContextProvider: React.FC<
  CloseLiveBoxContextProviderProps
> = ({ children }) => {
  const [isLiveChatOpen, setIsLiveChatOpen] = useState<boolean>(false);
  const [channelActiveId, setChannelActiveId] = useState<string>("");
  const [newMessageCome, setNewMessageCome] = useState<string>("");
  const [deletedChannel, setDeletedChannel] = useState<string>("");
  return (
    <CloseLiveBoxContext.Provider value={{ isLiveChatOpen, setIsLiveChatOpen, channelActiveId, setChannelActiveId, newMessageCome, setNewMessageCome, deletedChannel, setDeletedChannel }}>
      {children}
    </CloseLiveBoxContext.Provider>
  );
};

export const useCloseLiveBoxContext = () => {
  const context = useContext(CloseLiveBoxContext);
  if (!context) {
    throw new Error(
      "use CloseLiveBoxContext must be used within a  CloseLiveBoxContextProvider"
    );
  }
  return context;
};
