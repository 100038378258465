import {
  MenuHeaderItem,
  MenuHeaderKey,
} from "../../models/pageConfiguration/MenuHeaderModel";

export class Constants {
  public static VOUCHER_TYPE_CREDIT = "CREDIT";
  public static VOUCHER_TYPE_SUBSCRIPTION = "SUBSCRIPTION";

  public static DASHBOARD_SUBSCRIPTIONSTAB = "mysubscriptions";
  public static DASHBOARD_REQUESTSTAB = "myrequests";
  public static DASHBOARD_HARDWARETAB = "myhardware";
  public static DASHBOARD_BILLINGTAB = "mybilling";
  public static DASHBOARD_TEAMTAB = "myteam";
  public static DASHBOARD_EDITMYPROFILE = "editmyprofile";
  public static DASHBOARD_WALLETTAB = "mywallet";
  public static DASHBOARD_STATISTICS_TAB = "statistics";

  public static USER_ROLE_INDIVIDUAL = "Individual";
  public static USER_ROLE_MANAGER = "Manager";
  public static USER_ROLE_WHOLESALER = "Wholesaler";

  public static USER_ROLE_INDIVIDUAL_ID = 20;
  public static USER_ROLE_MANAGER_ID = 10;

  public static ACCOUNT_BALANCE_MINIMUM_VALUE = 0;

  public static SUPPORT_REQUEST_COMPLETED_STATUSES = [100, 500];

  public static JOIN_COMPANY_ACTIVATION_CODE_LENGTH = 6;

  public static VEHICLE_VIN_LENGTH = 17;
  public static NEW_REQUEST_SEARCH_METHOD_REGISTRATION_NUMBER_ID = 10;
  public static NEW_REQUEST_SEARCH_METHOD_VIN_ID = 20;
  public static NEW_REQUEST_SEARCH_METHOD_KBA_ID = 30;
  public static NEW_REQUEST_MAX_FILES_ATTACHMENTS = 3;

  public static SERVICE_REQUEST_UPLOADER_MAX_FILE_SIZE = 5242880; //5MB to byte

  public static THL_TICKET_QUEUED_REQUEST = 20;
  public static THL_TICKET_IN_PROGRESS_REQUEST = 40;

  public static VOUCHER_CODE_LENGTH = 6;
  public static PREPAID_VOUCHER_TYPE_ID = 41;
  public static SUBSCRIPTION_VOUCHER_TYPE_ID = 31;

  public static SUBSCRIPTION_PAY_AS_YOU_GO_SUBSCRIPTION_TYPE_ID = 10;
  public static SUBSCRIPTION_BUSINESS_SUBSCRIPTION_TYPE_ID = 11;
  public static SUBSCRIPTION_ENTERPRISE_SUBSCRIPTION_TYPE_ID = 12;
  public static SUBSCRIPTION_MIN_WORKSHOPS = 2;
  public static SUBSCRIPTION_MAXIMUM_WORKSHOPS = 50;
  public static SUBSCRIPTION_ADDON_RDS500_CATEGORY_ID = 11;
  public static SUBSCRIPTION_ADDON_CAR_REPAIR_CATEGORY_ID = 6;

  public static REQUEST_STATUS_ID_NEW = 10;
  public static REQUEST_STATUS_ID_QUEUED = 20;
  public static REQUEST_STATUS_ID_SCHEDULED = 30;
  public static REQUEST_STATUS_ID_IN_PROGRESS = 40;
  public static REQUEST_STATUS_ID_FEEDBACK_REQUIRED = 50;
  public static REQUEST_STATUS_ID_CLOSED = 100;
  public static REQUEST_STATUS_ID_REOPENED = 200;
  public static REQUEST_STATUS_ID_UNRESOLVED = 400;
  public static REQUEST_STATUS_ID_CANCELLED = 500;
  public static REQUEST_STATUS_ID_SCAN_COMPLETED = 600;
  public static REQUEST_STATUS_ID_RATE_CODES = 700;

  public static REQUEST_SUB_STATUS_ID_RESOLVED = 46;
  public static REQUEST_SUB_STATUS_ID_UNRESOLVED = 51;

  public static SUBSCRIPTION_CDM3_PAY_AS_YOU_GO_TYPE = 10;
  public static SUBSCRIPTION_CDM3_BUSINESS_TYPE = 11;
  public static SUBSCRIPTION_CDM3_ENTERPRISE_TYPE = 12;

  public static APPLICATION_CAR_REPAIR_SUPPORT_ID = 11;

  public static CREDIT_PACKAGE_ID = 1;
  public static SINGLE_CREDIT_PACKAGE_ID = 16;

  public static SEPA_PAYMENT_LIMIT_AMOUNT = 500;

  public static ACCOUNT_CREDIT_LIMIT_WARNING = 20;

  public static CDM3_RDS_500_HIDDEN = ["fr"];
  public static CDM3_SDICODE_REQUIRED_COUNTRIES = ["IT"];
  public static CDM3_FISCAL_CODE_REQUIRED_COUNTRIES = ["IT"];
  public static WORKSHOP_MIN_INDEX = -2;

  public static SUPPORT_INCIDENT_IDS = [3];
  public static ELECTRICAL_VEHICLE_ENGINE_ID = 99;

  public static CDM_VERSION_3v0 = "3.0";

  public static EXECUTE_LOGIN_POPUP = "EXECUTE_LOGIN_POPUP";

  public static RESTRICTED_GEO_COUNTRIES = ["RU", "SY", "IR", "KP", "BY"];

  public static REMOTE_DIAGNOSTICS_APPLICATION_ID = 6;
  public static REDI_SUBSYSTEM_ID = 6;
  public static THL_SUBSYSTEM_ID = 11;
  public static VHC_SUBSYSTEM_ID = 16;

  public static headerDashboard = [
    ["/training-course-catalogue", "TrainingCourse_TrainingCourse"],
    ["/dashboard/requests", "BreadScrumb_Dashboard_Requests"],
    ["/dashboard/hardware", "DashboardCDM3_MainMenu_InstalledBase_Item"],
    ["/dashboard/team/member-creation", "Dashboard_Team_Create"],
    ["/dashboard/team/member", "Dashboard_Team_Edit"],
    ["/dashboard/team", "DashboardCDM3_MainMenu_Team_Item"],
    ["/myprofile", "MenuItem_MyBosch"],
    ["/wallet", "WalletHeader_Headline"],
    ["/dashboard/wallet", "WalletHeader_Headline"],
    ["/my-sub", "OurServices_MyAccountCard_Products"],
    ["/dashboard/my-sub", "OurServices_MyAccountCard_Products"],
    ["/dashboard/statistics", "MenuItem_Statistics"],
  ];

  public static LEGALINFO_MAPPINGCOUNTRIES = [
    {
      country: "AT",
      languageFile: "de-at.html",
    },
    {
      country: "BE",
      languageFile: "nl-be.html",
    },
    {
      country: "CH",
      languageFile: "de-ch.html",
    },
    {
      country: "DE",
      languageFile: "de-de.html",
    },
    {
      country: "DK",
      languageFile: "da-dk.html",
    },
    {
      country: "ES",
      languageFile: "es-es.html",
    },
    {
      country: "FI",
      languageFile: "fi-fi.html",
    },
    {
      country: "LU",
      languageFile: "fr-lu.html",
    },
    {
      country: "NL",
      languageFile: "nl-nl.html",
    },
    {
      country: "NO",
      languageFile: "nb-no.html",
    },
    {
      country: "SE",
      languageFile: "sv-se.html",
    },
    {
      country: "FR",
      languageFile: "fr-fr.html",
    },
    {
      country: "UK",
      languageFile: "en-gb.html",
    },
    {
      country: "CZ",
      languageFile: "cs-cz.html",
    },
  ];

  public static PRIVACYPOLICY_MAPPINGLANGUAGES = [
    {
      language: "da",
      languageFile: "da-dk.html",
    },
    {
      language: "fi",
      languageFile: "fi-fi.html",
    },
    {
      language: "cz-cz",
      languageFile: "cs-cz.html",
    },
  ];

  public static DEFAULT_YOUTUBE_VIDEO_ID = "kvxwTMPM11o";

  public static LanguageAndYoutubeVideoId = [
    { language: "de-AT", youtubeVideoId: "hDFFZQNVBk8" },
    { language: "de-CH", youtubeVideoId: "hDFFZQNVBk8" },
    { language: "de-DE", youtubeVideoId: "hDFFZQNVBk8" },
    { language: "pl-PL", youtubeVideoId: "DGQEfaxq3LE" },
    { language: "es-ES", youtubeVideoId: "IaulXv0s6jQ" },
    { language: "it-IT", youtubeVideoId: "fLaTlf5MNtQ" },
    { language: "it-CH", youtubeVideoId: "fLaTlf5MNtQ" },
    { language: "nl-NL", youtubeVideoId: "sSp26L16vUQ" },
    { language: "nl-BE", youtubeVideoId: "sSp26L16vUQ" },
    { language: "fr-BE", youtubeVideoId: "6Yk-G-iybZA" },
    { language: "fr-LU", youtubeVideoId: "6Yk-G-iybZA" },
    { language: "fr-FR", youtubeVideoId: "iQWq44-vnQE" },
  ];

  public static NoTelephoneNumberCountries: string[] = [
    "DE",
    "AT",
    "CH",
    "IT",
    "UK",
    "IE",
    "PL",
    "CZ",
    "SK",
  ];

  /**
   * START MENU HEADER CONSTANTS
   */
  public static FullRolesSupported: number[] = [
    Constants.USER_ROLE_INDIVIDUAL_ID,
    Constants.USER_ROLE_MANAGER_ID,
  ];

  public static MenuItemRequest: MenuHeaderItem = {
    key: MenuHeaderKey.REQUESTS,
    label: "DashboardCDM3_MainMenu_Requests_Item",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/dashboard/requests",
  };

  public static MenuItemTeam: MenuHeaderItem = {
    key: MenuHeaderKey.TEAM,
    label: "DashboardCDM3_MainMenu_Team_Item",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
    isActive: true,
    redirectUrl: "/dashboard/team",
  };

  public static MenuItemWallet: MenuHeaderItem = {
    key: MenuHeaderKey.WALLET,
    label: "DashboardCDM3_WalletInfo_Wallet_Title",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
    isActive: true,
    redirectUrl: "/dashboard/wallet",
  };

  public static MenuItemMyProducts: MenuHeaderItem = {
    key: MenuHeaderKey.MY_PRODUCTS,
    label: "MenuHeaderPopup_MyAccount_Products",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
    isActive: true,
    redirectUrl: "/dashboard/my-sub",
  };

  public static MenuItemStatistic: MenuHeaderItem = {
    key: MenuHeaderKey.STATISTICS,
    label: "MenuItem_Statistics",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
    isActive: true,
    redirectUrl: "/dashboard/statistics",
  };

  public static MenuItemNewRequest: MenuHeaderItem = {
    key: MenuHeaderKey.NEW_REQUEST,
    label: "NewRequestCDM3_Breadcrumb_NewRequest",
    isLoginRequired: true,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/my-requests",
  };

  public static MenuItemMyRemote: MenuHeaderItem = {
    key: MenuHeaderKey.MY_REMOTE,
    label: "MenuItem_MyRemote",
    isLoginRequired: true,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    isAccountMappedRequired: true,
  };

  public static MenuItemProducts: MenuHeaderItem = {
    key: MenuHeaderKey.PRODUCTS,
    label: "Header_Products",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/subscriptions",
  };

  public static MenuItemSupport: MenuHeaderItem = {
    key: MenuHeaderKey.SUPPORT,
    label: "MenuHeaderPopup_Support_Headline",
    isLoginRequired: false,
    isShowOnMinimalMenu: true,
    isTheEndOfBlock: true,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/support",
  };

  public static MenuItemMyBosch: MenuHeaderItem = {
    key: MenuHeaderKey.MY_BOSCH,
    label: "MenuItem_MyBosch",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/myprofile",
  };

  public static MenuItemShoppingCart: MenuHeaderItem = {
    key: MenuHeaderKey.SHOPPING_CART,
    label: "Breadcrumb_ShoppingCart",
    isLoginRequired: false,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/v3/shopping-cart",
  };

  public static MenuItemLogin: MenuHeaderItem = {
    key: MenuHeaderKey.LOG_IN,
    label: "HomeLayoutCDM3_Login_ButtonLink",
    isLoginRequired: false,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    isHideIfLoggedIn: true,
    redirectUrl: "/login-redirect",
  };

  public static MenuItemLogout: MenuHeaderItem = {
    key: MenuHeaderKey.LOG_OUT,
    label: "AccountButton_LogoutButton",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/logout",
  };

  public static MenuItemContactUs: MenuHeaderItem = {
    key: MenuHeaderKey.CONTACT_US,
    label: "Header_ContactUs",
    isLoginRequired: false,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: Constants.FullRolesSupported,
    isActive: true,
    redirectUrl: "/contacts",
  };

  public static MenuItemHardware: MenuHeaderItem = {
    key: MenuHeaderKey.HARDWARE,
    label: "SettingsMyHardware_Headline",
    isLoginRequired: true,
    isShowOnMinimalMenu: false,
    isTheEndOfBlock: false,
    supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
    isActive: true,
    redirectUrl: "/dashboard/hardware",
  };

  /**
   * END MENU HEADER CONSTANTS
   */

  /**
   * STATISTIC PAGE
   */
  public static StatisticSelectViewThisYear = "Statistic_SelectView_YearToDate";
  public static StatisticSelectViewThisWeek = "Statistic_SelectView_Weekly";
  public static StatisticSelectViewLastWeek = "Statistic_SelectView_LastWeek";
  public static StatisticSelectViewThisMonth = "Statistic_SelectView_Monthly";
  public static StatisticSelectViewLastMonth = "Statistic_SelectView_LastMonth";
  public static StatisticSelectViewLastYear = "Statistic_SelectView_Yearly";

  public static StatisticSelectViewOptions = [
    this.StatisticSelectViewThisYear,
    this.StatisticSelectViewThisWeek,
    this.StatisticSelectViewLastWeek,
    this.StatisticSelectViewThisMonth,
    this.StatisticSelectViewLastMonth,
    this.StatisticSelectViewLastYear,
  ];

  public static ShopOverviewMaximumChildOrgsPerPage = 5;

  public static TotalTicketInfo = {
    label: "Statistic_TotalTicket",
    color: "#007bc0",
  };
  public static SuccessfulTicketInfo = {
    label: "Statistic_SuccessfulTicket",
    color: "#00884a",
  };
  public static InProcessTicketInfo = {
    label: "Statistic_InProcessTicket",
    color: "#8a9097",
  };
  public static UnresolvedTicketInfo = {
    label: "Statistic_UnresolvedTicket",
    color: "#ffcf00",
    textColor: "#000",
  };
  public static CancelledByShopTicketInfo = {
    label: "Statistic_CancelledByShopTicket",
    color: "#ed0007",
  };
  public static CreditUsedInfo = {
    label: "Statistic_TotalCreditsUsedTicket",
    color: "#007bc0",
  };
  public static ServiceTypesChartInfo = [
    this.SuccessfulTicketInfo,
    this.InProcessTicketInfo,
    this.UnresolvedTicketInfo,
    this.CancelledByShopTicketInfo,
  ];

  public static MakesChartColor = Constants.TotalTicketInfo.color;

  public static StatisticChartBarHeight = 1.5 * 16 * 2;
  public static BarColumnWidth = 1.5 * 16;
  public static ChartLabelFontSize = "0.75rem";

  /**
   * STICKY HEADER
   */
  public static DISPLAY_WIDTH_ABLE_TO_SET_LEFT_FOR_STICKY_HEADER = 1300;

  /**
   * Statistic report file
   */
  public static REPORT_FILE_NAME_DELIMITER = "_";
  public static REPORT_FILE_NAME_POST_FIX = "report";
  public static REPORT_FILE_CELL_MIN_WIDTH = 12;
  public static REPORT_FILE_TRIPLE_THAN_DEFAULT_WIDTH_COLS = [
    "comment",
    "solution",
  ];

  public static CART_BADGE_MAX_QUANTITY = 999;
  public static CART_OVER_MAX_QUANTITY_LABEL = "999+";

  public static HEADER_STICKY_MODE_CLASSNAME = "sticky-mode-on";
}
