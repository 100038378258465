import { createAsyncThunk } from "@reduxjs/toolkit";
import ResponseResultBase from "../models/ResponseResultBase";
import { AppDispatch, RootState } from "../redux/store";
import axios, { AxiosError } from "axios";
import {
  CreateQueuedSupportRequestResponse,
  NewQueuedSupportRequestModel,
} from "../models/NewRequest/UsNewRequest.model";
import http from "./HttpService";

export const createQueuedSupportRequest = createAsyncThunk<
  ResponseResultBase<CreateQueuedSupportRequestResponse> | null,
  NewQueuedSupportRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("us/newRequest", async (req, thunkApi) => {
  try {
    const response = await http.post<ResponseResultBase<any>>(
      "/api/v1/Tcs/CreateQueuedSupportRequest",
      req
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});
