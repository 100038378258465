import { useTranslation } from "react-i18next";
import style from "./AdsNewRequestBreadcrumb.module.scss";
import { useSelector } from "react-redux";
import {
  NewRequestStep,
  selectCurrentStep,
  setCurrentStep,
} from "../../../../redux/newRequestSlice";
import { Icon } from "semantic-ui-react";
import { ReactComponent as WideAngledArrow } from "../../../../assets/svg/wide-angled-arrow.svg";
import { useAppDispatch } from "../../../../redux/hooks";

const AdsNewRequestBreadcrumb = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentStep = useSelector(selectCurrentStep);

  const getBreadcrumbInfo = () => {
    switch (currentStep) {
      case NewRequestStep.Request_Detail:
        return {
          label: "NewRequestCDM3_Breadcrumb_VehicleIdentification",
          onClick: () =>
            dispatch(setCurrentStep(NewRequestStep.Vehicle_Identification)),
        };

      default:
        return {
          label: "ADS_RemoteDiagnosticDashboard",
          onClick: () =>
            window.open(process.env.REACT_APP_RDD_HOME_PAGE, "_self"),
        };
    }
  };

  const renderBreadcrumbLink = () => {
    const linkInfo = getBreadcrumbInfo();

    return <span className={style.link}>{t(linkInfo.label)}</span>;
  };

  if (
    ![
      NewRequestStep.Vehicle_Identification,
      NewRequestStep.Request_Detail,
    ].includes(currentStep)
  )
    return <></>;

  return (
    <div
      className={`${style.container} ${
        currentStep === NewRequestStep.Request_Detail ||
        currentStep === NewRequestStep.Vehicle_Identification
          ? style.hover_included
          : ""
      }`}
      onClick={getBreadcrumbInfo().onClick}
    >
      {currentStep === NewRequestStep.Request_Detail ||
      currentStep === NewRequestStep.Vehicle_Identification ? (
        <Icon>
          <WideAngledArrow />
        </Icon>
      ) : (
        <></>
      )}
      {renderBreadcrumbLink()}
    </div>
  );
};

export default AdsNewRequestBreadcrumb;
