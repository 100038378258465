import { GlobalSearchData } from "../models/globalSearch/globalSearch";
import { CdmVersion } from "../models/pageConfiguration/CdmVersion";
import { Constants } from "./helpers/Constants";

export const SearchDataGlobal: GlobalSearchData[] = [
  {
    name: "Home_Headline",
    title: "Home_Headline",
    parent: "",
    path: "/",
  },
  {
    name: "MenuHeaderPopup_MyRequests_NewRequest",
    title: "MenuHeaderPopup_MyRequests_NewRequest",
    parent: "MyRequests_Headline",
    path: "/my-requests",
    isLoginRequired: true,
  },
  {
    name: "HomeLayoutCDM3_Login_ButtonLink",
    title: "HomeLayoutCDM3_Login_ButtonLink",
    parent: "HomeLayoutCDM3_Login_ButtonLink",
    path: "/login",
  },
  {
    name: "AccountButton_LogoutButton",
    title: "AccountButton_LogoutButton",
    parent: "MenuHeaderPopup_MyAccount_Headline",
    path: "/logout",
    isLoginRequired: true,
  },
  {
    name: "MenuItem_MyBosch",
    title: "MenuItem_MyBosch",
    parent: "",
    path: "/myprofile",
    isLoginRequired: true,
  },
  {
    name: "MenuHeaderPopup_Support_Headline",
    title: "MenuHeaderPopup_Support_Headline",
    parent: "MenuHeaderPopup_Support_Headline",
    path: "/support",
    ignoreVersions: [CdmVersion.US1v0],
  },
  {
    name: "MenuHeaderPopup_Support_FAQs",
    title: "MenuHeaderPopup_Support_FAQs",
    parent: "MenuHeaderPopup_Support_Headline",
    path: "/support#faq",
    ignoreVersions: [CdmVersion.US1v0],
  },
  {
    name: "MenuHeaderPopup_Support_Videos",
    title: "MenuHeaderPopup_Support_Videos",
    parent: "MenuHeaderPopup_Support_Headline",
    path: "/support#videos",
    ignoreVersions: [CdmVersion.US1v0],
  },
  {
    name: "Breadcrumb_ShoppingCart",
    title: "Breadcrumb_ShoppingCart",
    parent: "Breadcrumb_ShoppingCart",
    path: "/v3/shopping-cart",
  },
  {
    name: "Header_ContactUs",
    title: "Header_ContactUs",
    parent: "Header_ContactUs",
    path: "/contacts",
  },
  {
    name: "MenuHeaderPopup_Subscriptions_Headline",
    title: "MenuHeaderPopup_Subscriptions_Headline",
    parent: "MenuHeaderPopup_Subscriptions_Headline",
    path: "/subscriptions",
    ignoreVersions: [CdmVersion.US1v0],
  },
  {
    name: "MenuHeaderPopup_MyAccount_Products",
    title: "MenuHeaderPopup_MyAccount_Products",
    parent: "MenuItem_MyRemote",
    path: "/dashboard/my-sub",
    isLoginRequired: true,
    ignoreRoles: [Constants.USER_ROLE_INDIVIDUAL_ID],
  },
  {
    name: "DashboardCDM3_MainMenu_InstalledBase_Item",
    title: "DashboardCDM3_MainMenu_InstalledBase_Item",
    parent: "MenuItem_MyRemote",
    path: "/dashboard/hardware",
    isLoginRequired: true,
    ignoreVersions: [CdmVersion.US1v0],
    ignoreRoles: [Constants.USER_ROLE_INDIVIDUAL_ID],
  },
  {
    name: "SettingsMainPage_Billing_Headline",
    title: "SettingsMainPage_Billing_Headline",
    parent: "MenuItem_MyRemote",
    path: "/my-billing",
    isLoginRequired: true,
    ignoreVersions: [CdmVersion.US1v0],
  },
  {
    name: "BreadScrumb_Wallet",
    title: "BreadScrumb_Wallet",
    parent: "MenuItem_MyRemote",
    path: "/dashboard/wallet",
    isLoginRequired: true,
    ignoreRoles: [Constants.USER_ROLE_INDIVIDUAL_ID],
  },
  {
    name: "DashboardCDM3_MainMenu_Team_Item",
    title: "DashboardCDM3_MainMenu_Team_Item",
    parent: "MenuItem_MyRemote",
    path: "/dashboard/team",
    isLoginRequired: true,
    ignoreRoles: [Constants.USER_ROLE_INDIVIDUAL_ID],
  },
  {
    name: "FooterAdditionalLinks_Navigation_CorporateInformation",
    title: "FooterAdditionalLinks_Navigation_CorporateInformation",
    parent: "FooterAdditionalLinks_Navigation_CorporateInformation",
    path: "/corporate-information",
  },
  {
    name: "FooterAdditionalLinks_Navigation_LegalNotice",
    title: "FooterAdditionalLinks_Navigation_LegalNotice",
    parent: "FooterAdditionalLinks_Navigation_LegalNotice",
    path: "/legal-notice",
  },
  {
    name: "FooterAdditionalLinks_Navigation_DataProtectionNotice",
    title: "FooterAdditionalLinks_Navigation_DataProtectionNotice",
    parent: "FooterAdditionalLinks_Navigation_DataProtectionNotice",
    path: "/data-protection-notice",
  },
  {
    name: "FooterAdditionalLinks_Navigation_OSSLicenses",
    title: "FooterAdditionalLinks_Navigation_OSSLicenses",
    parent: "",
    path: "/software-licenses",
  },
  {
    name: "MenuItem_Statistics",
    title: "MenuItem_Statistics",
    parent: "MenuHeaderPopup_Dashboard_Headline",
    path: "/dashboard/statistics",
    isLoginRequired: true,
    ignoreRoles: [Constants.USER_ROLE_INDIVIDUAL_ID],
  },
  {
    name: "DashboardCDM3_MainMenu_Requests_Item",
    title: "DashboardCDM3_MainMenu_Requests_Item",
    parent: "MenuItem_MyRemote",
    path: "/dashboard/requests",
    isLoginRequired: true,
  },
];
