import { CreditPriceModel } from "../models/credit/creditPrice.model";
import { RequestCreditPriceWithVoucherModel } from "../models/credit/request.getCreditInterval.model";
import ResponseResultBase from "../models/ResponseResultBase";
import http from "./HttpService";

export const creditService = {
  getCreditPriceWithVoucher,
};

async function getCreditPriceWithVoucher(
  requestModel: RequestCreditPriceWithVoucherModel
) {
  return await http.get<ResponseResultBase<CreditPriceModel>>(
    `/api/v1/credit/GetCreditPriceWithVoucher?country=${requestModel.countryCode}&ciamId=${requestModel.ciamId}&numberOfCreditsToPurchase=${requestModel.numberOfCreditsToPurchase}&voucherCode=${requestModel.voucherCode}&languageCode=${requestModel.languageCode}`
  );
}
