import { RouteConfigure } from "../models/Route";
import {
  CORPORATION_INFO_ROUTE,
  DASHBOARD_HARDWARE_ROUTE,
  DATA_PROTECTION_NOTICE_ROUTE,
  EDIT_PROFILE_ROUTE,
  EXCEPTION_ROUTE,
  FOSS_LICENSE_ROUTE,
  LEGACY_LOGIN_ROUTE,
  LEGAL_NOTICE_ROUTE,
  LOGIN_REDIRECT_ROUTE,
  LOGOUT_ROUTE,
  ORDER_SUMMARY_ROUTE,
  PRIVACY_POLICY_ROUTE,
  REGISTER_PERSONAL_ACCOUNT_ROUTE,
  SEARCH_RESULTS_ROUTE,
  SETTINGS_MY_PROFILE_ACTIVATION_ROUTE,
  SOFTWARE_LICENSE_ROUTE,
  TEAM_MEMBER_ACCOUNT_CREATION_ROUTE,
  TEAM_MEMBER_ACCOUNT_MODIFICATION_ROUTE,
  TERM_AND_CONDITION_ROUTE,
  WINDOW_MEETING_ROUTE,
} from "../constants/routes/defaultRoutes.constants";

export const defaultRouterConfig: RouteConfigure[] = [
  LEGACY_LOGIN_ROUTE,
  LOGIN_REDIRECT_ROUTE,
  LOGOUT_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERM_AND_CONDITION_ROUTE,
  EXCEPTION_ROUTE,
  SEARCH_RESULTS_ROUTE,
  CORPORATION_INFO_ROUTE,
  LEGAL_NOTICE_ROUTE,
  DATA_PROTECTION_NOTICE_ROUTE,
  FOSS_LICENSE_ROUTE,
  ORDER_SUMMARY_ROUTE,
  DASHBOARD_HARDWARE_ROUTE,
  SETTINGS_MY_PROFILE_ACTIVATION_ROUTE,
  SOFTWARE_LICENSE_ROUTE,
  TEAM_MEMBER_ACCOUNT_CREATION_ROUTE,
  TEAM_MEMBER_ACCOUNT_MODIFICATION_ROUTE,
  REGISTER_PERSONAL_ACCOUNT_ROUTE,
  WINDOW_MEETING_ROUTE,
  EDIT_PROFILE_ROUTE,
];
