import { SubscriptionV3 } from "../models/subscription/SubscriptionV3.model";
import { Constants } from "../utils/helpers/Constants";

export const getDefaultCartQuantity = (subscription: SubscriptionV3) => {
  if (
    subscription?.cdm3SubscriptionType?.id ===
    Constants.SUBSCRIPTION_ENTERPRISE_SUBSCRIPTION_TYPE_ID
  ) {
    return Constants.SUBSCRIPTION_MIN_WORKSHOPS;
  }

  return 1;
};
