import { useMemo } from "react";

/**
 * A hook that returns a case-insensitive map of URL search parameters.
 */
const useCaseInsensitiveSearchParams = () => {
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const map = new Map<string, string>();

    Array.from(params.entries()).forEach(([key, value]) => {
      map.set(key.toLowerCase(), value);
    });

    return map;
  }, [window.location.search]);

  /**
   * Retrieves the value of a search parameter in a case-insensitive manner.
   * @param key The key of the search parameter.
   * @returns The value of the search parameter, or null if not found.
   */
  const getParam = (key: string): string | null => {
    return searchParams.get(key.toLowerCase()) || null;
  };

  return { getParam };
};

export default useCaseInsensitiveSearchParams;
