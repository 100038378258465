import { CdmVersion } from "../../../models/pageConfiguration/CdmVersion";
import { DashboardConfigurationModel } from "../../../models/pageConfiguration/DashboardConfigurationModel";
import { Cdm3DashboardConfiguration } from "./Cdm3DashboardConfiguration";
import { CdmUs1v0DashboardConfiguration } from "./CdmUs1v0DashboardConfiguration";

export const DashboardConfiguration: Record<
  CdmVersion,
  DashboardConfigurationModel
> = {
  "3.0": Cdm3DashboardConfiguration,
  "US-1.0": CdmUs1v0DashboardConfiguration,
  // "3.0": CdmUs1v0DashboardConfiguration,
  // "US-1.0": Cdm3DashboardConfiguration,
};
