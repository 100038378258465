import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserAvailableCredits } from "../../../../redux/newRequestSlice";
import Styles from "./WalletBalanceInfoCdm3.module.css";
import RDButton from "../../../RDComponents/RDButton";
import Boschicon from "../../../BoschIcon/Boschicon";
import DashboardCDM3WalletTopup from "../../../Dashboard/DashboardCDM3/DashboardCDM3WalletTopup";
import RDText from "../../../RDComponents/RDText";
import { useUserSubscription } from "../../../../hooks/useUserSubscription";
import { useAppDispatch } from "../../../../redux/store";
import { selectCiamId } from "../../../../redux/account";
import { getCreditStatusAsync } from "../../../../redux/creditSlide";

const WalletBalanceInfoCdm3: FC = () => {
  const { t } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const userAvailableCredits = useSelector(selectUserAvailableCredits);
  const { isBranchAccount } = useUserSubscription();
  const [isShowBuyCreditModal, setShowBuyCreditModal] = useState(false);

  useEffect(() => {
    dispatchReduxToolkit(getCreditStatusAsync(ciamId));
  }, []);

  if (isBranchAccount()) return <></>;

  return (
    <>
      <div className={Styles.wallet_balance_wrapper}>
        <div className={Styles.wallet_balance_info}>
          <span>{t("WalletBalanceInfo_WalletBalance_Label")}:&nbsp;</span>
          <span className={Styles.credit_value}>{userAvailableCredits}</span>
        </div>
        <div className={Styles.wallet_balance_actions}>
          <RDButton
            small
            minWidth="maxContent"
            type="button"
            onClick={() => {
              setShowBuyCreditModal(true);
            }}
          >
            <Boschicon name="bosch-ic-coin-dollar"></Boschicon>{" "}
            <RDText>{t("DashboardCDM3_WalletInfo_BuyCreditsButton")}</RDText>
          </RDButton>
        </div>
      </div>
      {isShowBuyCreditModal && (
        <DashboardCDM3WalletTopup
          open={true}
          onClose={() => {
            setShowBuyCreditModal(false);
          }}
        />
      )}
    </>
  );
};

export default WalletBalanceInfoCdm3;
