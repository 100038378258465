import { FC, Fragment } from "react";
import { RouteComponentProps } from "react-router-dom";
import LandingPageCDM3 from "./LandingPage/LandingPageCDM3";
import "./LandingPage.css";

const LandingPage: FC<RouteComponentProps> = (
  props: RouteComponentProps<{}>
) => {
  return (
    <Fragment>
      <LandingPageCDM3
        history={props.history}
        location={props.location}
        match={props.match}
      />
    </Fragment>
  );
};

export default LandingPage;
