import { ChristmasBannerConfig } from "@sysTypes/app.type";

export const ADS_APP_PREFIX = "ads";
export const PAGING_25_RECORDS = 25;
export const PAGING_50_RECORDS = 50;
export const PAGING_100_RECORDS = 100;

export const DEFAULT_CHRISTMAS_BANNER_MESSAGE =
  "Dear customers, the Remote Diagnostics Service is unfortunately only available to a limited extent from 23.12.2024-02.01.2025. We ask for your understanding and wish you a merry Christmas and a happy New Year.";
export const CHRISTMAS_BANNER_CONFIGS: ChristmasBannerConfig[] = [
  {
    countryCode: "de",
    message:
      "Liebe Kunden, der Remote Diagnostics Service ist vom 23.12.2024-02.01.2025 leider nur eingeschränkt verfügbar. Wir bitten um ihr Verständnis und wünschen Ihnen eine erholsame Weihnachtszeit und einen guten Rutsch ins neue Jahr.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "at",
    message:
      "Liebe Kunden, der Remote Diagnostics Service ist vom 23.12.2024-02.01.2025 leider nur eingeschränkt verfügbar. Wir bitten um ihr Verständnis und wünschen Ihnen eine erholsame Weihnachtszeit und einen guten Rutsch ins neue Jahr.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "ch",
    message:
      "Liebe Kunden, der Remote Diagnostics Service ist vom 23.12.2024-02.01.2025 leider nur eingeschränkt verfügbar. Wir bitten um ihr Verständnis und wünschen Ihnen eine erholsame Weihnachtszeit und einen guten Rutsch ins neue Jahr.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "it",
    message:
      "Gentile cliente, il servizio di diagnostica remota sarà disponibile solo in misura limitata dal 23.12.2024 al 02.01.2025. Vi chiediamo comprensione e vi auguriamo un buon Natale e un felice anno nuovo.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "ie",
    message:
      "Dear customers, the Remote Diagnostics Service is unfortunately only available to a limited extent from 23.12.2024-02.01.2025. We ask for your understanding and wish you a merry Christmas and a happy New Year.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "uk",
    message:
      "Dear customers, the Remote Diagnostics Service is unfortunately only available to a limited extent from 23.12.2024-02.01.2025. We ask for your understanding and wish you a merry Christmas and a happy New Year.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "pl",
    message:
      "Szanowni Państwo, w dniach 23.12.2024-02.01.2025, Usługa Zdalnej Diagnostyki jest niestety dostępna jedynie w ograniczonym zakresie. Prosimy o wyrozumiałość i życzymy Wesołych Świąt i szczęśliwego Nowego Roku.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "cz",
    message:
      "Vážení zákazníci, služba Vzdálená diagnostika je v termínu od 23.12.2024 do 2.1.2025 dostupná pouze v omezeném rozsahu. Žádáme o pochopení a přejeme příjemné přožití vánočních svátků a šťastný nový rok.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "cs",
    message:
      "Vážení zákazníci, služba Vzdálená diagnostika je v termínu od 23.12.2024 do 2.1.2025 dostupná pouze v omezeném rozsahu. Žádáme o pochopení a přejeme příjemné přožití vánočních svátků a šťastný nový rok.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "sk",
    message:
      "Vážení zákazníci, služba Vzdálená diagnostika je v termínu od 23.12.2024 do 2.1.2025 dostupná pouze v omezeném rozsahu. Žádáme o pochopení a přejeme příjemné přožití vánočních svátků a šťastný nový rok.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "ee",
    message:
      "Dear customers, the Remote Diagnostics Service is unfortunately only available to a limited extent from 23.12.2024-02.01.2025. We ask for your understanding and wish you a merry Christmas and a happy New Year.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "lt",
    message:
      "Dear customers, the Remote Diagnostics Service is unfortunately only available to a limited extent from 23.12.2024-02.01.2025. We ask for your understanding and wish you a merry Christmas and a happy New Year.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "lv",
    message:
      "Dear customers, the Remote Diagnostics Service is unfortunately only available to a limited extent from 23.12.2024-02.01.2025. We ask for your understanding and wish you a merry Christmas and a happy New Year.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "dk",
    message:
      "Kære kunder, Bosch Remote Diagnostic er er kun begrænset tilgænglig i perioden 23.12.2024-02.01.2025. Vi beder om jeres forståelse og ønsker jer en glædelig jul og et godt nytår.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "no",
    message:
      "Kjære kunde. Remote Diagnostics Service er dessverre kun tilgjengelig i begrenset omfang fra 23.12.2024 til 02.01.2025. Vi ber om forståelse og ønsker deg en god jul og et godt nytt år.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "fi",
    message:
      "Hiljennymme pyhien viettoon ja palvelu on käytettävissä rajoitetusti 23.12.2024-02.01.2024. Toivotamme kaikille hyvää joulua ja rauhallista uutta vuotta.",
    startDate: "12/23/2024",
    dueDate: "01/01/2025",
  },
  {
    countryCode: "se",
    message:
      "Kära kunder, Tjänsten Remote Disgnoctics (Fjärrdiagnostik) är tyvärr endast tillgänglig i begränsad omfattning från 23.12.2024-04.01.2025. Vi ber om er förståelse och önskar er en god jul och ett gott nytt år.",
    startDate: "12/23/2024",
    dueDate: "01/03/2025",
  },
];
