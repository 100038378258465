import { createAsyncThunk } from "@reduxjs/toolkit";
import ResponseResultBase from "../models/ResponseResultBase";
import { RequestCheckVoucherCodeModel } from "../models/transaction/request.CheckVoucherCode.model";
import http from "./HttpService";
import { RequestGetWalletTransactions } from "../models/transaction/request.getWalletTransactions.model";
import { AppDispatch, RootState } from "../redux/store";
import axios, { AxiosError } from "axios";
import { WalletTransaction } from "../models/transaction/walletTransaction.model";

export const transactionService = {
  checkVoucherCodeAsync,
};

async function checkVoucherCodeAsync(
  requestModel: RequestCheckVoucherCodeModel
) {
  return await http.get<ResponseResultBase<boolean>>(
    `/api/v1/transaction/CheckVoucherCode?ciamId=${requestModel.ciamId}&voucherType=${requestModel.voucherType}&voucherCode=${requestModel.voucherCode}`
  );
}

export const getWalletTransactions = createAsyncThunk<
  ResponseResultBase<WalletTransaction[]> | null,
  RequestGetWalletTransactions,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "transaction/GetWalletTransactions",
  async (req: RequestGetWalletTransactions, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<WalletTransaction[]>>(
        `/api/v1/transaction/GetWalletTransactions?ciamId=${req.ciamId}&language=${req.language}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);
