import { useTranslation } from "react-i18next";
import RDCustomAlert from "../../../../components/RDComponents/RDCustomAlert";
import style from "./AdsPreconditionModal.module.scss";
import RDButton from "../../../../components/RDComponents/RDButton";

type Props = {
  onAccept: () => void;
};

const AdsPreconditionModal = ({ onAccept }: Props) => {
  const { t } = useTranslation();
  const conditionsList: string[] = [
    "SubmitTicketSuccessModal_Message1",
    "SubmitTicketSuccessModal_Message2",
    "SubmitTicketSuccessModal_Message3",
    "SubmitTicketSuccessModal_Message4",
    "SubmitTicketSuccessModal_Message5",
    "SubmitTicketSuccessModal_Message6",
  ];

  return (
    <RDCustomAlert
      isOpen
      type="info"
      title={t("ADS_PreconditionModal_Headline")}
      AcceptButton={
        <RDButton onClick={onAccept}>{t("ConfirmButton")}</RDButton>
      }
    >
      <div className={style.wrapper}>
        {conditionsList.map((condition, index) => (
          <div className={style.block} key={index}>
            <div className={style.dot}></div>
            <span>{t(condition)}</span>
          </div>
        ))}
      </div>
    </RDCustomAlert>
  );
};

export default AdsPreconditionModal;
