import CustomLoader from "./CustomLoader";
const Loading = () => {
  return (
    <div style={{ paddingTop: 100, textAlign: "center" }}>
      {/* <h1>Loading...</h1> */}
      <CustomLoader enable={true} />
    </div>
  );
};

export default Loading;
