import { useSelector } from "react-redux";
import { selectCdmVersion, selectIsSignIn } from "../../redux/auth";
import { useEffect, useState } from "react";
import {
  MinimalMenuHeaderItem,
  MenuHeaderKey,
  MenuHeaderItem,
} from "../../models/pageConfiguration/MenuHeaderModel";
import {
  loginAsync,
  selectAccount,
  selectAccountSubscription,
} from "../../redux/account";
import { menuHeaderConfiguration } from "../../pages/Configuration/MenuHeaderConfiguration/MenuHeaderConfiguration";
import { CdmVersion } from "../../models/pageConfiguration/CdmVersion";
import { useUserSubscription } from "../useUserSubscription";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import { useRouting } from "../useRouting";

type Props = {
  openConfirmLogoutModal?: Function;
};

export const useMenuHeaderConfiguration = ({
  openConfirmLogoutModal,
}: Props) => {
  const cdmVersion = useSelector(selectCdmVersion);
  const isLoggedIn = useSelector(selectIsSignIn);
  const account = useSelector(selectAccount);
  const userSubscription = useSelector(selectAccountSubscription);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const { checkUserIsMapped, checkSubscriptionIsTrial } = useUserSubscription();
  const { isWalletAndMyProductsRouteAccessible } = useRouting();
  const [menuHeaderConfig, setMenuHeaderConfig] = useState<
    MinimalMenuHeaderItem[]
  >([]);

  useEffect(() => {
    const menuHeaderConfig = menuHeaderConfiguration[cdmVersion as CdmVersion];

    setMenuHeaderConfig(
      minifyMenuHeaderItemList(
        menuHeaderConfig
          ? menuHeaderConfig
          : menuHeaderConfiguration[CdmVersion.CDM3v0]
      )
    );
  }, [
    cdmVersion,
    isLoggedIn,
    account?.cdbId,
    userSubscription,
    account?.userRole,
  ]);

  const validateMenuHeaderItem = (item: MenuHeaderItem): boolean => {
    const {
      isShowOnMinimalMenu,
      isLoginRequired,
      isActive,
      supportedRoles,
      isHideIfLoggedIn,
      isAccountMappedRequired,
    } = item;

    if (!isActive) return false;

    if (!verifyMenuItemByAdditionalCondition(item)) return false;

    if (isShowOnMinimalMenu && !isLoginRequired) return true;

    if (isLoginRequired && !isLoggedIn) return false;

    if (isAccountMappedRequired && !(account && account.cdbId > 0))
      return false;

    const currentUserRoleId = account?.userRole?.id;

    if (isLoginRequired && !currentUserRoleId) return false;

    if (isLoggedIn && !supportedRoles.includes(currentUserRoleId as number))
      return false;

    if (isLoggedIn && !!isHideIfLoggedIn && isHideIfLoggedIn) return false;

    return true;
  };

  const minifyMenuHeaderItemList = (
    items: MenuHeaderItem[]
  ): MinimalMenuHeaderItem[] => {
    if (!items || items.length === 0) return [];

    const result: MinimalMenuHeaderItem[] = [];

    for (const currentItem of items) {
      let minifiedItem = minifyMenuHeaderItem(
        currentItem
      ) as MinimalMenuHeaderItem;

      if (!!minifiedItem.key) {
        if (currentItem.childrenItems && currentItem.childrenItems.length > 0) {
          const tempChildren = minifyMenuHeaderItemList(
            currentItem.childrenItems
          );

          minifiedItem = {
            ...minifiedItem,
            childrenItems: tempChildren as MenuHeaderItem[],
          };
        }

        result.push(minifiedItem);
      }
    }

    return result;
  };

  const minifyMenuHeaderItem = (
    item: MenuHeaderItem
  ): MinimalMenuHeaderItem | {} => {
    if (!validateMenuHeaderItem(item)) return {};

    item = assignOnClickFunctionToMenuItem(item);

    const {
      isLoginRequired,
      supportedRoles,
      isActive,
      isAccountMappedRequired,
      ...rest
    } = item;

    if (!item.childrenItems || item.childrenItems.length === 0)
      return rest as MinimalMenuHeaderItem;

    const itemChildren = item.childrenItems.map((childItem) =>
      minifyMenuHeaderItem(childItem)
    );

    return {
      ...rest,
      childrenItems: itemChildren,
    };
  };

  const verifyMenuItemByAdditionalCondition = (
    menuItem: MenuHeaderItem
  ): boolean => {
    if (menuItem.key === MenuHeaderKey.WALLET)
      return isWalletAndMyProductsRouteAccessible();

    if (menuItem.key === MenuHeaderKey.NEW_REQUEST && !checkUserIsMapped())
      return false;

    if (menuItem.key === MenuHeaderKey.STATISTICS && checkSubscriptionIsTrial())
      return false;

    if (menuItem.key === MenuHeaderKey.MY_PRODUCTS)
      return isWalletAndMyProductsRouteAccessible();

    return true;
  };

  const handleLogInMenuItemClicked = () => {
    dispatchReduxToolkit(loginAsync({ redirectUrl: window.location.pathname }));
  };

  const handleLogoutMenuItemClicked = () => {
    if (openConfirmLogoutModal) openConfirmLogoutModal();
  };

  const returnMenuItemWithOnClickFunction = (
    menuItem: MenuHeaderItem,
    onClickFunction: Function
  ): MenuHeaderItem => {
    return {
      ...menuItem,
      redirectUrl: undefined,
      onClick: onClickFunction,
    };
  };

  const assignOnClickFunctionToMenuItem = (
    menuItem: MenuHeaderItem
  ): MenuHeaderItem => {
    if (menuItem.key === MenuHeaderKey.LOG_IN)
      return returnMenuItemWithOnClickFunction(
        menuItem,
        handleLogInMenuItemClicked
      );

    if (menuItem.key === MenuHeaderKey.LOG_OUT)
      return returnMenuItemWithOnClickFunction(
        menuItem,
        handleLogoutMenuItemClicked
      );

    return menuItem;
  };

  return { menuHeaderConfig };
};
