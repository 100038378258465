import { useTranslation } from "react-i18next";
import { useFieldValidation } from "../../hooks/useFieldValidation";
import {
  ADS_MILEAGE_FIELD_MAX_LENGTH,
  ADS_REPAIR_ORDER_FIELD_MAX_LENGTH,
} from "../constants/adsNewRequest.constant";
import { useSelector } from "react-redux";
import { selectFormValues } from "../../redux/newRequestSlice";
import {
  SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID,
  SEARCH_BY_VIN_METHOD_ID,
  VIN_NUMBER_MAX_LENGTH,
} from "../../constants/automaticSearch.constants";

export const useAdsNewRequest = () => {
  const { t } = useTranslation();
  const { numberCharRegex } = useFieldValidation();
  const formValues = useSelector(selectFormValues);

  const validateMileage = (mileage: string, isRequired?: boolean) => {
    if (!mileage) return !!isRequired ? t("VHC_MileageIsRequired") : "";

    if (!numberCharRegex.test(mileage)) return t("ADS_MileageMustBeNumber");

    if (mileage.length > ADS_MILEAGE_FIELD_MAX_LENGTH)
      return t("ADS_FieldReachMaximumCharacters").replace(
        "{maxLength}",
        ADS_MILEAGE_FIELD_MAX_LENGTH + ""
      );

    return "";
  };

  const validateRepairOrder = (repairOrder: string, isRequired?: boolean) => {
    if (!repairOrder)
      return !!isRequired ? t("AutomaticSearch_InvalidInput") : "";

    if (repairOrder.length > ADS_REPAIR_ORDER_FIELD_MAX_LENGTH)
      return t("ADS_FieldReachMaximumCharacters").replace(
        "{maxLength}",
        ADS_REPAIR_ORDER_FIELD_MAX_LENGTH + ""
      );

    return "";
  };

  const validateVinNumber = (vin?: string): string => {
    const searchMethod = formValues.searchCountry?.searchMethods.find(
      (method) => method.id === SEARCH_BY_VIN_METHOD_ID
    );

    if (!searchMethod)
      return (vin || formValues.vin).length !== VIN_NUMBER_MAX_LENGTH
        ? t("RequestDetailRemoteDiagnostic_VIN_Length_Error")
        : "";

    if (
      !searchMethod.validation ||
      !searchMethod.validation.regex ||
      new RegExp(searchMethod.validation.regex).test(vin || formValues.vin)
    )
      return "";

    return t("RequestDetailRemoteDiagnostic_VIN_Length_Error");
  };

  const validateLicensePlate = (isRequired: boolean) => {
    const trimmedLicensePlate = formValues.licensePlate?.trim();

    if (!isRequired && !trimmedLicensePlate) return "";

    const searchMethod = formValues.searchCountry?.searchMethods.find(
      (method) => method.id === SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID
    );

    let searchError = "";

    if (
      !searchMethod ||
      !searchMethod.validation ||
      !searchMethod.validation.regex ||
      new RegExp(searchMethod.validation.regex).test(trimmedLicensePlate || "")
    )
      searchError = "";
    else searchError = t("AutomaticSearch_InvalidInput");

    return searchError;
  };

  return {
    validateMileage,
    validateRepairOrder,
    validateVinNumber,
    validateLicensePlate,
  };
};
