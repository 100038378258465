import LoadableComponent from "../../components/loading/LoadableComponent";
import { ADS_APP_PREFIX } from "../../constants/app.constants";
import { AdsRoute } from "../types/adsRoute.type";
import { ADS_REQUEST_ID } from "./adsCommonParams.constant";

export const ADS_BASE_ROUTE_PATH = "/" + ADS_APP_PREFIX;

export const ADS_BASE_ROUTE: AdsRoute = {
  path: ADS_BASE_ROUTE_PATH,
  pageTitle: "MyRequests_RequestType_Remote",
  component: LoadableComponent(
    () => import("../pages/adsNewRequest/AdsNewRequest")
  ),
};

export const ADS_SIGN_IN_CALLBACK_ROUTE: AdsRoute = {
  path: ADS_BASE_ROUTE_PATH + "/sign-in-callback",
  pageTitle: "MyRequests_RequestType_Remote",
  component: LoadableComponent(
    () => import("../pages/adsSignInCallback/AdsSignInCallback")
  ),
};

export const ADS_ADD_NEW_TECHNICIAN_ROUTE: AdsRoute = {
  path: ADS_BASE_ROUTE_PATH + "/add-technician",
  pageTitle: "MyRequests_RequestType_Remote",
  component: LoadableComponent(
    () => import("../pages/adsAddTechnician/AdsAddTechnician")
  ),
};

export const ADS_SESSION_OVERVIEW_ROUTE: AdsRoute = {
  path: ADS_BASE_ROUTE_PATH + "/session-overview",
  pageTitle: "MyRequests_RequestType_Remote",
  component: LoadableComponent(
    () => import("../pages/adsSessionOverview/AdsSessionOverview")
  ),
};

export const ADS_REQUEST_DETAILS_ROUTE: AdsRoute = {
  path: ADS_BASE_ROUTE_PATH + `/request-details/:${ADS_REQUEST_ID}`,
  pageTitle: "MyRequests_RequestType_Remote",
  component: LoadableComponent(
    () => import("../pages/adsRequestDetails/AdsRequestDetails")
  ),
};

export const ADS_ALL_PROTECTED_ROUTES: AdsRoute[] = [
  ADS_SIGN_IN_CALLBACK_ROUTE,
  ADS_ADD_NEW_TECHNICIAN_ROUTE,
  ADS_SESSION_OVERVIEW_ROUTE,
  ADS_REQUEST_DETAILS_ROUTE,
  ADS_BASE_ROUTE, // ALWAYS ON BOTTOM IN THIS ARRAY
];

export const ADS_ALL_UNPROTECTED_ROUTES: AdsRoute[] = [
  ADS_SIGN_IN_CALLBACK_ROUTE,
];
