import { Dialog, DialogProps } from "@bosch/react-frok";
import style from "./AdsDialog.module.scss";

type Props = DialogProps & {
  size?: "m" | "l";
};

const AdsDialog = (props: Props) => {
  const { className, size, ...restrictedProps } = props;

  const getAllClassNames = () => {
    const allClassNames: string[] = [style.container];

    if (!!className) allClassNames.push(className);
    if (!!size) allClassNames.push(style[size]);

    return allClassNames.join(" ");
  };

  return <Dialog className={getAllClassNames()} {...restrictedProps} />;
};

export default AdsDialog;
