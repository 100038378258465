import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "@bosch/react-frok";

type Props = {};

const AddDeviceButton = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClickAddDeviceButton = () => {
    history.push("/dashboard/hardware?openAddHardwareModal=true");
  };

  return (
    // <>
    //   <RDButton minWidth="maxContent" small onClick={onClickAddDeviceButton}>
    //     <RDText>{t("DashboardInstallBase_Add")}</RDText>
    //   </RDButton>
    // </>
    <Button mode="primary" onClick={onClickAddDeviceButton}>
      {t("DashboardInstallBase_Add")}
    </Button>
  );
};

export default AddDeviceButton;
